import React, { SVGProps } from "react";

export const THEETH_46 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg xmlns="http://www.w3.org/2000/svg" width="2.92rem" height="5.46rem" viewBox="0 0 38 71" fill="none" {...props}>
<g>
<path d="M37.7794 63.9329C38.661 57.8829 27.7038 63.9293 30.9792 19.3794C24.9548 12.9627 10.5646 0.516172 3.86414 11.9562C3.25089 22.4834 4.71566 66.8426 20.851 69.9805C22.482 70.2977 23.3104 68.3867 22.47 66.9534C17.293 58.1231 14.2907 44.2719 17.0335 40.8498C21.8826 42.4998 18.1335 61.1998 37.7794 63.9329Z" fill="url(#paint0_linear_2354_11224)"/>
<g filter="url(#filter0_f_2354_11224)">
<path d="M12.4585 44.9153C12.2882 42.6225 11.4485 38.3162 8.90085 26.2366C8.90085 26.2366 13.7926 32.9075 14.6819 31.5734C15.5713 30.2392 17.3501 22.6787 17.3501 22.6787C18.966 32.843 20.7684 37.7434 26.2441 44.4706" stroke="#F3F3F3" strokeWidth="2.66829"/>
</g>
<path d="M30.2822 14.2856C30.4369 15.9459 15.7498 53.3282 36.9295 61.9286C37.1851 62.0324 37.4134 61.7255 37.2374 61.5131C27.1834 49.3842 30.8075 30.1385 31.582 16.4522L30.2822 14.2856C30.2787 14.2478 30.2674 14.2285 30.2479 14.2285L30.2822 14.2856Z" fill="#D9D9D9"/>
<path d="M30.2822 14.2856C30.4369 15.9459 15.7498 53.3282 36.9295 61.9286C37.1851 62.0324 37.4134 61.7255 37.2374 61.5131C27.1834 49.3842 30.8075 30.1385 31.582 16.4522L30.2822 14.2856C30.2787 14.2478 30.2674 14.2285 30.2479 14.2285L30.2822 14.2856Z" fill="url(#paint1_linear_2354_11224)"/>
<path d="M1.16825 10.134C1.60139 2.4481 4.63334 2.99709 15.0286 6.29115C18.4938 2.77765 22.8251 5.19313 24.5576 6.84005C26.2901 5.19309 33.6532 2.99709 31.4878 20.0159C26.6366 29.6782 16.7611 25.5059 12.4298 22.2119C9.83097 22.3949 4.37346 22.6511 3.33393 22.2119C2.03452 21.6629 0.735115 17.8199 1.16825 10.134Z" fill="url(#paint2_linear_2354_11224)"/>
<path d="M1.16825 10.134C1.60139 2.4481 4.63334 2.99709 15.0286 6.29115C18.4938 2.77765 22.8251 5.19313 24.5576 6.84005C26.2901 5.19309 33.6532 2.99709 31.4878 20.0159C26.6366 29.6782 16.7611 25.5059 12.4298 22.2119C9.83097 22.3949 4.37346 22.6511 3.33393 22.2119C2.03452 21.6629 0.735115 17.8199 1.16825 10.134Z" fill="url(#paint3_radial_2354_11224)" fillOpacity="0.3"/>
<g filter="url(#filter1_f_2354_11224)">
<path d="M23.0432 12.5236C22.4638 15.4206 25.2091 14.5515 26.8089 14.5515C28.4088 14.5515 27.9677 13.2242 27.9677 11.9442C27.9677 10.6642 23.4843 9.33691 21.8845 9.33691C20.2846 9.33691 21.3051 11.6545 23.0432 12.5236Z" fill="#FDFDFD"/>
</g>
<g filter="url(#filter2_f_2354_11224)">
<path d="M24.4653 6.22363C25.8283 7.55362 26.3782 8.37414 26.2441 10.2262" stroke="black" strokeWidth="0.889432"/>
</g>
<g filter="url(#filter3_f_2354_11224)">
<path d="M14.6626 5.7793C16.0256 7.10928 16.5754 7.9298 16.4414 9.78188" stroke="black" strokeWidth="0.889432"/>
</g>
</g>
<defs>
<filter id="filter0_f_2354_11224" x="0.480251" y="15.2576" width="33.9145" height="37.1708" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3.55773" result="effect1_foregroundBlur_2354_11224"/>
</filter>
<filter id="filter1_f_2354_11224" x="16.6388" y="4.88976" width="15.8318" height="14.2312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.22358" result="effect1_foregroundBlur_2354_11224"/>
</filter>
<filter id="filter2_f_2354_11224" x="18.8177" y="0.568684" width="13.2269" height="15.0262" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.66829" result="effect1_foregroundBlur_2354_11224"/>
</filter>
<filter id="filter3_f_2354_11224" x="9.01497" y="0.124348" width="13.2269" height="15.0262" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.66829" result="effect1_foregroundBlur_2354_11224"/>
</filter>
<linearGradient id="paint0_linear_2354_11224" x1="22.0742" y1="7.2998" x2="22.0742" y2="69.9998" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_2354_11224" x1="27.1132" y1="18.9621" x2="33.2882" y2="54.7304" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_2354_11224" x1="16.4831" y1="4" x2="16.4831" y2="26" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_2354_11224" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.083 12.7839) rotate(128.272) scale(4.19572 6.0852)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>
        </>
	);
};