import React, { FC } from 'react';
import { CardBody } from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import useDarkMode from '../../../../hooks/useDarkMode';
import classNames from 'classnames';

interface ITableProps {}

const Table: FC<ITableProps> = () => {
	const { darkModeStatus } = useDarkMode();

	return (
		<CardBody>
			<div className='row g-4 align-items-center'>
				<div className='col-xl-6'>
					<div
						className={classNames('d-flex align-items-center rounded-2 p-3', {
							'bg-l10-warning': !darkModeStatus,
							'bg-lo25-warning': darkModeStatus,
						})}>
						<div className='flex-shrink-0'>
							<Icon icon='DoneAll' size='3x' color='warning' />
						</div>
						<div className='flex-grow-1 ms-3'>
							<div className='fw-bold fs-3 mb-0'>15</div>
							<div className='text-muted mt-n2 truncate-line-1'>Визитов</div>
						</div>
					</div>
				</div>
				<div className='col-xl-6'>
					<div
						className={classNames('d-flex align-items-center rounded-2 p-3', {
							'bg-l10-info': !darkModeStatus,
							'bg-lo25-info': darkModeStatus,
						})}>
						<div className='flex-shrink-0'>
							<Icon icon='Savings' size='3x' color='info' />
						</div>
						<div className='flex-grow-1 ms-3'>
							<div className='fw-bold fs-3 mb-0'>1,280</div>
							<div className='text-muted mt-n2 truncate-line-1'>Общая сумма</div>
						</div>
					</div>
				</div>
				<div className='col-xl-6'>
					<div
						className={classNames('d-flex align-items-center rounded-2 p-3', {
							'bg-l10-primary': !darkModeStatus,
							'bg-lo25-primary': darkModeStatus,
						})}>
						<div className='flex-shrink-0'>
							<Icon icon='Celebration' size='3x' color='primary' />
						</div>
						<div className='flex-grow-1 ms-3'>
							<div className='fw-bold fs-3 mb-0'>76</div>
							<div className='text-muted mt-n2 truncate-line-1'>Предстоящих</div>
						</div>
					</div>
				</div>
				<div className='col-xl-6'>
					<div
						className={classNames('d-flex align-items-center rounded-2 p-3', {
							'bg-l10-success': !darkModeStatus,
							'bg-lo25-success': darkModeStatus,
						})}>
						<div className='flex-shrink-0'>
							<Icon icon='Timer' size='3x' color='success' />
						</div>
						<div className='flex-grow-1 ms-3'>
							<div className='fw-bold fs-3 mb-0'>42</div>
							<div className='text-muted mt-n2'>Часов работы</div>
						</div>
					</div>
				</div>
			</div>
		</CardBody>
	);
};

export default Table;
