import React, { FC } from 'react';
import { Service } from './Service';
import { Material } from './Material';
import useDarkMode from '../../../../../../../hooks/useDarkMode';

interface IServiceComponent {
	data: any;
	changeData: any;
	canEdit: boolean;
	choosenServices: any;
	isEstimate: any;
}

export const ServiceComponent: FC<IServiceComponent> = ({
	data,
	changeData,
	canEdit,
	choosenServices,
	isEstimate,
}) => {
	const isChecked = (item: any) => {
		return choosenServices?.some((service: any) => {
			if (service.uuid === item.uuid) {
				if (service.service_type === 'bridge') {
					return (
						service?.start?.toString() === item?.start?.toString() &&
						service?.end?.toString() === item?.end?.toString() &&
						service.service_type === item.service_type
					);
				} else {
					return (
						service?.theeth?.toString() === item?.theeth?.toString() &&
						service.service_type === item.service_type
					);
				}
			}
			return false;
		});
	};

	const { themeStatus } = useDarkMode();
	return (
		<div className='mt-3 col-12 d-flex flex-wrap'>
			<hr />
			<div className='col-12 d-flex'>
				<div className='col-1 d-flex justify-content-start'>
					<span style={{ marginLeft: 22 }} className='fw-bold fs-5'>
						№
					</span>
				</div>
				<div className='col-6'>
					<span className='fw-bold fs-5'></span>
				</div>
				<div className='col-2 d-flex justify-content-end'>
					<span className='fw-bold fs-5'>Цена за ед.</span>
				</div>
				<div className='col-2  d-flex justify-content-center'>
					<span className='fw-bold fs-5'>Кол-во</span>
				</div>
				<div className='col-1 d-flex'>
					<span className='fw-bold fs-5'>Всего</span>
				</div>
			</div>
			<div className='col-12'>
				{data &&
					data?.map((service: any, index: number) => (
						<div className='col-12 d-flex flex-wrap m-1'>
							<div className='col-1 d-flex align-items-center'>
								{canEdit && (
									<div className='col-3 d-flex'>
										<div
											style={{ height: 50, width: 35 }}
											className='form-check col-12'>
											<input
												color={themeStatus}
												className='form-check-input'
												onChange={(e: any) =>
													changeData(
														e,
														null,
														index,
														null,
														service,
														service.service_type,
													)
												}
												type='checkbox'
												value={service?.uuid}
												checked={isChecked(service)}
												id={`flexCheckDefault-${index}`}
											/>
										</div>
									</div>
								)}
								<div className='col-6 d-flex justify-content-center'>
									<span className='fw-bold fs-5'>{index + 1}</span>
								</div>
							</div>
							<div className='col-11'>
								<div className='col-12'>
									<Service
										disabled={
											!isEstimate
												? !isEstimate && canEdit
												: !isChecked(service)
										}
										canEdit={canEdit}
										data={service}
										changeData={changeData}
										index={index}
									/>
								</div>
								{service?.materials?.length > 0 && service?.materials && (
									<span>
										<div className='col-12'>
											<Material
												disabled={
													!isEstimate
														? !isEstimate && canEdit
														: !isChecked(service)
												}
												canEdit={canEdit}
												data={service?.materials}
												changeData={changeData}
												index={index}
											/>
										</div>
									</span>
								)}
							</div>
							<div className='col-12'>
								<hr />
							</div>
						</div>
					))}
			</div>
		</div>
	);
};
