import React, { SVGProps } from "react";

export const PRINT_TOOTH_48 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 20 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M18 14.6482C15.9433 46.7946 18.6569 51.8518 17.4555 53.0499C15.4081 57.2915 9.15954 42.6817 8.91062 30.8996C6.97301 33.6777 11.5687 53.783 9.20595 52.1074C1.23175 46.4519 0.68691 20.9346 1.09927 12.8" stroke="black" strokeWidth="0.7"/>
<path id="Vector 135" d="M10 48.4544C12.3189 51.8002 13.8029 54.174 12.5355 47.8" stroke="black" strokeWidth="0.35"/>
<path id="Vector 24" d="M17.5033 3.65763C15.1716 -0.544349 12.2571 2.25675 11.0913 4.59118C9.63393 4.59118 7.04703 0.800003 5.58969 0.800003C4.13235 0.800003 -0.944244 3.30516 1.80061 13.8728C3.513 20.4656 7.54097 22.8 10.5082 22.8C19.3675 21.0352 20.514 9.08319 17.5033 3.65763Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}

