import React, { SVGProps } from "react";


export const PRINT_TOOTH_15 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg   width="36" height="100" viewBox="0 0 18 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M1.03811 37.7726C2.82269 10.7148 -0.00429782 7.40846 1.03811 6.4C7.95723 11.1602 16.1465 31.5531 15.7887 38.4" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M8.45222 31.4C0.674086 32.9899 -0.775683 44.0536 2.31084 48.645C3.97185 51.1158 6.14352 51.7666 12.7705 51.2193C14.05 51.2193 18.507 48.9625 16.0971 39.4423C14.5937 33.503 11.0573 31.4 8.45222 31.4Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}

