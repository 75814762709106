import React, { SVGProps } from "react";

export const THEETH_35 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
   <svg xmlns="http://www.w3.org/2000/svg" width="2.15rem" height="5.92rem" viewBox="0 0 28 77" fill="none" {...props}>
<path d="M13.1501 75.8027C25.15 67.8026 25.7982 28.3334 24.1315 10.0001C23.6987 3.0001 3.6463 9.49941 4.13184 18.0002C4.9379 32.113 10.6318 53.5002 9.64946 74.3033C10.4876 77.2093 11.4081 76.9641 13.1501 75.8027Z" fill="url(#paint0_linear_2354_11135)"/>
<g filter="url(#filter0_f_2354_11135)">
<path d="M15.2666 21.5244C19.2518 35.7699 19.6913 43.8673 16.7666 58.5244" stroke="#F3F3F3" strokeWidth="2.5" strokeLinecap="round"/>
</g>
<path d="M10.9241 75.4657C13.6622 55.6264 11.8806 40.0097 4.33688 15.3039C4.30075 15.1856 4.16976 15.1348 4.08626 15.2261C3.82413 15.5127 3.40701 16.276 3.76695 18.0752C4.26709 20.5752 4.08537 20.2065 4.26695 21.5752C4.75757 25.2733 5.26695 31.0752 5.26695 31.0752L7.76695 47.0752L9.26695 61.0752L9.76496 74.0234C9.76628 74.0577 9.77346 74.0915 9.7862 74.1233L10.3521 75.5381C10.466 75.8229 10.8822 75.7695 10.9241 75.4657Z" fill="url(#paint1_linear_2354_11135)"/>
<path d="M3.51733 10.6359C3.1541 19.6372 8.54351 28 15.5188 28C24.6329 28 27.2472 16.6399 27.4821 10.1228C27.5052 9.48054 27.3875 8.84175 27.0964 8.26874C24.6031 3.36069 18.6042 0 15.5188 0C12.3389 0 5.03287 5.09943 3.63917 9.83948C3.56314 10.0981 3.5282 10.3666 3.51733 10.6359Z" fill="url(#paint2_linear_2354_11135)"/>
<path d="M3.51733 10.6359C3.1541 19.6372 8.54351 28 15.5188 28C24.6329 28 27.2472 16.6399 27.4821 10.1228C27.5052 9.48054 27.3875 8.84175 27.0964 8.26874C24.6031 3.36069 18.6042 0 15.5188 0C12.3389 0 5.03287 5.09943 3.63917 9.83948C3.56314 10.0981 3.5282 10.3666 3.51733 10.6359Z" fill="url(#paint3_radial_2354_11135)" fillOpacity="0.3"/>
<g filter="url(#filter1_f_2354_11135)">
<path d="M13.8628 14.5752C14.8628 19.5752 10.1243 18.0752 7.36293 18.0752C4.6015 18.0752 5.36275 15.7843 5.36275 13.5752C5.36275 11.3661 13.1014 9.0752 15.8628 9.0752C18.6242 9.0752 16.8628 13.0752 13.8628 14.5752Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_2354_11135" x="8.0166" y="14.2739" width="17.8926" height="51.5005" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_2354_11135"/>
</filter>
<filter id="filter1_f_2354_11135" x="0.266602" y="4.0752" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_2354_11135"/>
</filter>
<linearGradient id="paint0_linear_2354_11135" x1="14.4649" y1="7.03418" x2="14.4649" y2="76.6156" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_2354_11135" x1="12.3451" y1="22.5992" x2="-9.51924" y2="71.3388" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_2354_11135" x1="15.5" y1="0" x2="15.5" y2="28" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_2354_11135" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.7341 10.6687) rotate(74.5856) scale(3.85106 3.83463)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>
        </>
	);
};