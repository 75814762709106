import React from 'react';
import Header from '../../../layout/Header/Header';
import HeaderLeftComponent from './HeaderComponents/HeaderLeftComponent/HeaderLeftComponent';
import HeaderRightComponent from './HeaderComponents/HeaderRightComponent/HeaderRightComponent';

const WidgetHeader = () => {
	return (
		<Header>
			<HeaderLeftComponent />
			<HeaderRightComponent />
		</Header>
	);
};

export default WidgetHeader;
