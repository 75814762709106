import React, { SVGProps } from "react";

export const PRINT_TOOTH_22 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100"  viewBox="0 0 17 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M1.40002 36.1063C3.17423 9.02043 10.9044 1.90955 13.9596 1.21166C17.0148 0.513768 14.3153 31.346 13.9596 38.2" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M6.43331 33.2C-1.52225 34.8078 -0.128838 41.8211 2.28886 46.4912C3.68387 49.1858 4.14501 50.6368 10.9232 50.0833C12.2318 50.0833 17.1873 48.9345 14.7225 39.3065C13.1848 33.3 9.09787 33.2 6.43331 33.2Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}