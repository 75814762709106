import React, { FC, useCallback, useState } from 'react';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import HighlightedTitle from '../../../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { CardBody } from '../../../../../components/bootstrap/Card';
import { useQuery } from 'react-query';
import { ClinicService } from '../../../../../custom/api/service/clinic.service';
import * as qs from 'qs';
import Button from '../../../../../components/bootstrap/Button';
import GroupMaterialSelect from '../../../../../custom/components/Select/groupMaterialSelect';
import Icon from '../../../../../components/icon/Icon';
import { GroupService } from '../../../../../custom/api/service/group.service';
import useDarkMode from '../../../../../hooks/useDarkMode';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import { IMkb10 } from '../../../../../custom/api/interface/mkb10.interface';
import { Mkb10DiseaseService } from '../../../../../custom/api/service/mkb-10-disease.service';

interface IAddService {
	uuid?: string;
	setValues(...args: unknown[]): unknown;
	setIsOpen(...args: unknown[]): unknown;
	isOpen: boolean;
	values: any;
}

const AddService: FC<IAddService> = ({ uuid, setValues, values, setIsOpen, isOpen }) => {
	const { data: clinics } = useQuery(['clinics'], () => ClinicService.findAll(qs.stringify({})));

	const [search, setSearch] = useState<any>({
		group: '',
		material: '',
	});

	const changeValues = (key: string, value: any, index?: number) => {
		setValues((prev: any) => {
			if (key === 'material') {
				if (prev.groups.length <= 0) {
					return {
						...prev,
						groups: [''],
						[key]: value,
					};
				}
				return {
					...prev,
					[key]: value,
				};
			}
			if (key === 'en' || key === 'arm' || key === 'rus') {
				return {
					...prev,
					title_all: {
						...prev.title_all,
						[key]: value,
					},
				};
			}
			if (key === 'mkb10DiseaseUuid' && typeof index === 'number') {
				const updatedMkb = [...prev.mkb10DiseaseUuid];

				if (value) {
					updatedMkb[index] = value; // Update the specific material by index
				}
				return {
					...prev,
					mkb10DiseaseUuid: updatedMkb,
				};
			}

			if (key === 'groups' && typeof index === 'number') {
				const updatedGroup = [...prev.groups];

				if (value) {
					updatedGroup[index] = value; // Update the specific material by index
				}
				return {
					...prev,
					groups: updatedGroup,
				};
			}

			if (key !== 'materials') {
				// Directly update the groupUuid
				return {
					...prev,
					[key]: value,
				};
			}

			return prev; // Return previous state if no updates
		});
	};

	const changeSearchValue = useCallback(
		(key: string, value: string | number) => {
			setSearch((prev: any) => ({ ...prev, [key]: value }));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[search],
	);

	const { data: groups }: { data: any[] | any; isLoading: boolean } = useQuery(
		['groups', search.group],
		() =>
			GroupService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						...(search.group && {
							or: [
								{ 'title_all.arm': { iLike: `%${search.group}% ` } },
								{ 'title_all.en': { iLike: `%${search.group}% ` } },
								{ 'title_all.rus': { iLike: `%${search.group}% ` } },
							],
						}),
					},
				}),
			),
		{},
	);
	const { themeStatus } = useDarkMode();

	const addGroup = () => {
		setValues((prev: any) => ({
			...prev,
			groups: [...prev.groups, {}],
		}));
	};

	const addMkb = () => {
		setValues((prev: any) => ({
			...prev,
			mkb10DiseaseUuid: [...prev.mkb10DiseaseUuid, ''],
		}));
	};

	const deleteGroup = (index: number) => {
		setValues((prev: any) => {
			const updatedMaterials = prev.groups.filter((_: any, i: number) => i !== index);
			if (updatedMaterials.length <= 0) {
				return {
					...prev,
					groups: updatedMaterials,
					material: false,
				};
			}
			return {
				...prev,
				groups: updatedMaterials,
			};
		});
	};

	const deleteMkb = (index: number) => {
		setValues((prev: any) => {
			const updatedMaterials = prev.mkb10DiseaseUuid.filter(
				(_: any, i: number) => i !== index,
			);

			return {
				...prev,
				mkb10DiseaseUuid: updatedMaterials,
			};
		});
	};

	const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
		['categories'],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'subCategory',
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const categories = categoriesWithSubCategories?.data
		.filter((item: any) => !item.categoryUuid)
		.map((category: any) => ({
			...category,
			categoriesUuid: category.uuid,
		}));
	const subCategories = categoriesWithSubCategories?.data
		.filter((item: any) => item.categoryUuid === values.categoryUuid)
		.map((subCategory: any) => ({
			...subCategory,
			subCategoryUuid: subCategory.uuid,
		}));

	const { data: mkb10Diseases } = useQuery<IMkb10 | null, Error>(
		['mkb10Diseases', search.mkb],
		() =>
			Mkb10DiseaseService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},

					filterMeta: {
						...(search.mkb && {
							websearchQuery: { searchVector: search.mkb },
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const changeLocalValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (value === '' || /^[0-9]*$/.test(value)) {
			setValues((prev: any) => ({ ...prev, price: value }));
		}
	};

	return (
		<div className={'col-12'}>
			<CardBody style={{ borderRadius: 12 }}>
				<div
					className='p-1 d-flex flex-column gap-2'
					style={{ maxWidth: '100%', overflow: 'auto' }}>
					<div className='col-12 d-flex flex-column  gap-2'>
						<HighlightedTitle title={'*Название Группы Материалов'} />

						<FormGroup label='RUS' className='col-12'>
							<Input
								required
								type='text'
								onChange={(e: any) => changeValues('rus', e.target.value)}
								value={values?.title_all?.rus}
							/>
						</FormGroup>
						<FormGroup label='ARM' className='col-12'>
							<Input
								required
								type='text'
								onChange={(e: any) => changeValues('arm', e.target.value)}
								value={values?.title_all?.arm}
							/>
						</FormGroup>
						<FormGroup label='ENG' className='col-12'>
							<Input
								required
								type='text'
								onChange={(e: any) => changeValues('en', e.target.value)}
								value={values?.title_all?.en}
							/>
						</FormGroup>
					</div>

					<div className='col-12 d-flex gap-3'>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Общая Спецализация'} />

							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e: any) =>
										changeValues('categoryUuid', e.target.value)
									}
									value={values.categoryUuid}
									className='form-select'>
									{categories?.map((category: any) => (
										<option value={category.uuid}>{category.name.rus}</option>
									))}
									<option value='' hidden selected></option>
								</select>
							</div>
						</FormGroup>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Подспецализация'} />

							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e: any) =>
										changeValues('subCategoryUuid', e.target.value)
									}
									value={values.subCategoryUuid}
									disabled={!values.categoryUuid}
									className='form-select'>
									{subCategories?.map((subCategory: any) => (
										<option value={subCategory.uuid}>
											{subCategory.name.rus}
										</option>
									))}
									<option value='' hidden selected></option>
								</select>
							</div>
						</FormGroup>
					</div>

					<div className='col-12 d-flex gap-3'>
						<FormGroup className='col-2'>
							<HighlightedTitle title={'*Код'} />
							<Input
								required
								type='text'
								value={values.code}
								onChange={(e: any) => changeValues('code', e.target.value)}
							/>
						</FormGroup>
					</div>

					<div className='col-12 d-flex gap-3'>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Группа услуг'} />
							<div style={{ position: 'relative' }}>
								<select
									onChange={(e: any) =>
										changeValues('service_type', e.target.value)
									}
									value={values.service_type}
									className='form-select'>
									<option value='' hidden selected></option>
									<option value='bridge'>Мост</option>
									<option value='implant'>Имплант</option>
									<option value='event'>Другие услуги</option>
									<option value='deletion'>Удаление</option>
									<option value='crown'>Коронка</option>
									<option value='cosmetology'>Косметология</option>
									<option value='lor'>ЛОР</option>
								</select>
							</div>
						</FormGroup>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Форма оказания услуги'} />

							<div style={{ position: 'relative' }}>
								<select
									onChange={(e: any) =>
										changeValues('treatment_type', e.target.value)
									}
									value={values.treatment_type}
									className='form-select'>
									<option value='' hidden selected></option>
									<option value='Амбулаторная'>Амбулаторная</option>
									<option value='Стационарная'>Стационарная</option>
								</select>
							</div>
						</FormGroup>
					</div>
					<div
						style={{ marginTop: 10 }}
						className='col-12 d-flex justify-content-between'>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Филиал'} />
							<div className='col-11'>
								<select
									onChange={(e: any) =>
										changeValues('clinicUuid', e.target.value)
									}
									value={values.clinicUuid}
									className='form-select'>
									{clinics?.data?.map((clinic: any) => (
										<option value={clinic.uuid}>{clinic.name}</option>
									))}
								</select>
							</div>
						</FormGroup>

						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Стоимость'} />

							<div className='col-10'>
								<Input
									type='text'
									onChange={(e: any) => changeLocalValue(e)}
									required
									value={values.price ?? ''}
								/>
							</div>
						</FormGroup>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Валюта'} />

							<div className='col-10'>
								<Input
									type='text'
									onChange={(e: any) => changeValues('currency', e.target.value)}
									required
									value={values.currency ?? ''}
								/>
							</div>
						</FormGroup>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Ед. Изм.'} />
							<div className='col-10'>
								<Input
									type='text'
									onChange={(e: any) =>
										changeValues('unit_measurement', e.target.value)
									}
									required
									value={values.unit_measurement ?? ''}
								/>
							</div>
						</FormGroup>
					</div>
					<hr />
					<span className='fw-bold fs-3 d-flex gap-2 align-items-center'>
						<Icon icon='MedicalServices' color={'primary'} size={'2x'} />
						Применяемость к болезням
					</span>
					<div
						className='p-4 rounded-1'
						style={{ position: 'relative', backgroundColor: '#DAFFDF' }}>
						<HighlightedTitle title='МКБ-10' />
						{values?.mkb10DiseaseUuid?.map((disease: any, index: number) => (
							<div
								style={{
									marginTop: 10,
								}}
								className='col-12 d-flex gap-3 align-items-center'>
								<FormGroup
									id='mkb10DiseaseUuid'
									label=''
									className='m-0 d-flex  gap-1 col-12'>
									<div className='col-11'>
										<GroupMaterialSelect
											placeholder=''
											disabled={false}
											name='mkb10DiseaseUuid'
											search='mkb'
											value={disease}
											options={mkb10Diseases?.data?.map((item: any) => ({
												value: item.uuid,
												label: item.name.arm,
												disabled: values?.mkb10DiseaseUuid?.includes(
													item.uuid,
												),
											}))}
											changeSearchValue={changeSearchValue}
											changeValues={changeValues}
											index={index}
										/>
									</div>
									<div className='col-1'>
										<div
											key={`div-11-${index}`}
											style={{
												width: 'auto',
												// position: 'absolute',
											}}
											className='d-flex flex justify-content-end gap-3 border-0'>
											<Button
												isDisable={values.mkb10DiseaseUuid.length === 1}
												isOutline={true}
												style={{ width: 45, height: 34 }}
												onClick={() => deleteMkb(index)}
												className='col-4 h-50 rounded delete-add-dental delete-dental'>
												<Icon
													icon={'Delete'}
													color={'danger'}
													size={'lg'}
												/>
											</Button>
										</div>
									</div>
								</FormGroup>
							</div>
						))}
					</div>
					<Button isOutline onClick={addMkb} color='primary' className='col-3'>
						Привязать еще Диагноз{' '}
					</Button>
					<hr />

					<span className='fw-bold fs-3 d-flex gap-2 align-items-center'>
						<div
							className='form-check m-0'
							key={`form-check-material`}
							style={{ border: 'unset' }}>
							<input
								color={themeStatus}
								className='form-check-input'
								style={{ top: 7, right: 0, left: '3rem' }}
								checked={values.material}
								onChange={(e: any) => changeValues('material', e.target.checked)}
								type='checkbox'
								id={`form-check-material`}
							/>
							<label
								className=' col-12 d-flex gap-3 p-2 bg-transparent border-0 border-bottom-0'
								htmlFor={`form-check-material`}>
								<p className='d-flex m-0 ms-4 ps-2' style={{ width: '100%' }}>
									<span className='overflow-hidden w-100 d-flex m-0'>
										<span className='fw-bold'>Материалы</span>
									</span>
								</p>
							</label>
						</div>
					</span>
					{values.material && (
						<>
							<div
								className='p-4 rounded-1'
								style={{ position: 'relative', backgroundColor: '#DBEAFF' }}>
								<HighlightedTitle title='*Группа материалов' />
								{values?.groups?.map((disease: any, index: number) => (
									<div
										style={{
											marginTop: 10,
										}}
										className='col-12 d-flex gap-3 align-items-center'>
										<FormGroup
											id='groups'
											label=''
											className='m-0 d-flex  gap-1 col-12'>
											<div className='col-5'>
												<GroupMaterialSelect
													placeholder=''
													disabled={false}
													name='groups'
													search='group'
													value={disease}
													options={groups?.data?.map((item: any) => ({
														value: item.uuid,
														label: item.title_all.arm,
														disabled: values?.groups?.includes(
															item.uuid,
														),
													}))}
													changeSearchValue={changeSearchValue}
													changeValues={changeValues}
													index={index}
												/>
											</div>
											<div className='col-1'>
												<div
													key={`div-11-${index}`}
													style={{
														width: 'auto',
														// position: 'absolute',
													}}
													className='d-flex flex justify-content-end gap-3 border-0'>
													<Button
														isOutline={true}
														style={{ width: 45, height: 34 }}
														onClick={() => deleteGroup(index)}
														className='col-4 h-50 rounded delete-add-dental delete-dental'>
														<Icon
															icon={'Delete'}
															color={'danger'}
															size={'lg'}
														/>
													</Button>
												</div>
											</div>
										</FormGroup>
									</div>
								))}
							</div>
							<Button isOutline onClick={addGroup} color='primary' className='col-3'>
								Прикрепить еще Группу{' '}
							</Button>
						</>
					)}
				</div>
			</CardBody>
		</div>
	);
};

export default AddService;
