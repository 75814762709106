import React, { FC, memo } from 'react';
import UserCard from './UserCard';

interface UserListProps {
	users: any[];
	usersList: any[];
	themeStatus: string;
	selectUser(...args: unknown[]): unknown;
}

const UserList: FC<UserListProps> = memo(({ users, usersList, themeStatus, selectUser }) => (
	<div className='d-flex px-2 bd-highlight mt-0' style={{ gap: 10 }}>
		{users.map((user, index) => (
			<div className='mx-1' key={`${index}-${user.uudi}`}>
				<UserCard
					user={user}
					themeStatus={themeStatus}
					isSelected={usersList.includes(user.uuid)}
					onSelect={() => selectUser(user)}
				/>
			</div>
		))}
	</div>
));

export default UserList;
