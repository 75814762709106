import React, { FC, useState } from 'react';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import HighlightedTitle from '../../../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { CardBody } from '../../../../../components/bootstrap/Card';
import { useQuery } from 'react-query';
import { ClinicService } from '../../../../../custom/api/service/clinic.service';
import * as qs from 'qs';
import Button from '../../../../../components/bootstrap/Button';
import AddGroup from '../../../../../custom/pages/Directories/Components/GroupComponents/AddMaterialGroups';

interface IAddMaterial {
	uuid?: string;
	setValues(...args: unknown[]): unknown;
	setIsOpen(...args: unknown[]): unknown;
	setAddGroup(...args: unknown[]): unknown;
	isOpen: boolean;
	addGroup: boolean;
	values: any;
	categories: any;
	groups: any;
}

const AddMaterial: FC<IAddMaterial> = ({
	uuid,
	setValues,
	values,
	categories,
	setIsOpen,
	isOpen,
	groups,
	addGroup,
	setAddGroup,
}) => {
	const { data: clinics } = useQuery(['clinics'], () => ClinicService.findAll(qs.stringify({})));

	const changeValues = (key: string, value: any) => {
		setValues((prev: any) => ({ ...prev, [key]: value }));
	};

	const changeLocalValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (value === '' || /^[0-9]*$/.test(value)) {
			setValues((prev: any) => ({ ...prev, price: value }));
		}
	};

	return (
		<div className={'col-12'}>
			<CardBody style={{ borderRadius: 12 }}>
				<div
					className='p-1 d-flex flex-column gap-2'
					style={{ maxWidth: '100%', overflow: 'auto' }}>
					<div className='col-12 d-flex gap-3'>
						<FormGroup className='col-4'>
							<HighlightedTitle title={'*Наименование'} />
							<Input
								type='text'
								onChange={(e: any) => changeValues('name', e.target.value)}
								value={values.name}
							/>
						</FormGroup>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Код'} />

							<Input
								type='text'
								onChange={(e: any) => changeValues('code', e.target.value)}
								value={values.code}
							/>
						</FormGroup>
					</div>

					<div className='col-12 d-flex gap-3'>
						<FormGroup className='col-4'>
							<HighlightedTitle title={'*Бренд'} />

							<div style={{ position: 'relative' }}>
								<select
									name='brand'
									onChange={(e: any) => changeValues('brand', e.target.value)}
									value={values.brand}
									className='form-select'>
									<option value={undefined} hidden>
										Выберите Бренд
									</option>
									<option value='Композит'>Композит</option>
								</select>
							</div>
						</FormGroup>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Филиал'} />

							<div style={{ position: 'relative' }}>
								<select
									name='brand'
									onChange={(e: any) =>
										changeValues('clinicUuid', e.target.value)
									}
									value={values.clinicUuid}
									className='form-select'>
									{clinics?.data?.map((clinic: any) => (
										<option value={clinic.uuid}>{clinic.name}</option>
									))}
								</select>
							</div>
						</FormGroup>
					</div>

					<div className='col-12 d-flex gap-3'>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'*Склад'} />

							<div style={{ position: 'relative' }}>
								<select
									onChange={(e: any) =>
										changeValues('store_type', e.target.value)
									}
									value={values.store_type}
									className='form-select'>
									<option value={undefined} hidden>
										Выберите Бренд
									</option>
									<option value='Композит'>Композит</option>
								</select>
							</div>
						</FormGroup>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'Тип Материала'} />

							<div style={{ position: 'relative' }}>
								<select
									onChange={(e: any) =>
										changeValues('material_type', e.target.value)
									}
									value={values.material_type}
									className='form-select'>
									<option value={undefined} hidden>
										Выберите Бренд
									</option>
									<option value='Композит'>Композит</option>
								</select>
							</div>
						</FormGroup>

						<FormGroup className='col-3'>
							<HighlightedTitle title={'Категория'} />

							<div style={{ position: 'relative' }}>
								<select
									onChange={(e: any) =>
										changeValues('categoryUuid', e.target.value)
									}
									value={values.categoryUuid}
									className='form-select'>
									{categories?.map((category: any) => (
										<option value={category?.uuid}>
											{category?.name?.rus}
										</option>
									))}
									<option value={undefined} selected hidden></option>
								</select>
							</div>
						</FormGroup>
					</div>

					<div className='col-12 d-flex gap-3'>
						<FormGroup className='col-3'>
							<HighlightedTitle title={'Ед. Изм'} />

							<Input
								type='text'
								onChange={(e: any) =>
									changeValues('unit_measurment', e.target.value)
								}
								value={values.unit_measurment}
							/>
						</FormGroup>
						<FormGroup className='col-2'>
							<HighlightedTitle title={'Размеры'} />

							<Input
								type='text'
								onChange={(e: any) => changeValues('size', e.target.value)}
								value={values.size}
							/>
						</FormGroup>
						<FormGroup className='col-2'>
							<HighlightedTitle title={'Срок годности'} />

							<Input
								type='date'
								onChange={(e: any) =>
									changeValues('expiration_date', e.target.value)
								}
								value={values.expiration_date}
							/>
						</FormGroup>
					</div>
					<div className='col-12 d-flex gap-3'>
						<FormGroup className='col-12'>
							<HighlightedTitle title={'* Стоимость (цена реализации)'} />
							<Input
								style={{ width: 200 }}
								className='col-2'
								type='text'
								onChange={(e: any) => changeLocalValue(e)}
								value={values.price}
							/>
						</FormGroup>
					</div>
					{values?.groups.length > 0 && groups?.data?.length > 0 && (
						<div className='col-12 d-flex gap-3'>
							<FormGroup className='col-12'>
								<span className='text-muted fw-bold fs-5'>
									Выбранные группы материалов
								</span>
								<div className='col-12 d-flex gap-1'>
									{values?.groups?.map((group: any, index: number) => {
										const title = groups?.data?.find(
											(item: any) => group === item?.uuid,
										)?.title_all.rus;
										return (
											<span key={group} className='fw-bold'>
												{title} {index !== values.groups.length - 1 && ','}
											</span>
										);
									})}
								</div>
							</FormGroup>
						</div>
					)}

					<div className='col-12 d-flex gap-3'>
						<Button onClick={() => setIsOpen(true)} color={'primary'} isOutline>
							Добавить материал в группу
						</Button>
						{uuid && (
							<Button onClick={() => setAddGroup(true)} color={'primary'}>
								Создать Группу Материалов{' '}
							</Button>
						)}
					</div>
				</div>
			</CardBody>
		</div>
	);
};

export default AddMaterial;
