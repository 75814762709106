/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export interface IFormDataContextProps {
	formData: any;
	setFormData: any;
	default_form_data: any;
	implant_colors: string[];
	bridge_colors: string[];
	event_colors: string[];
	referral_colors: string[];
}
const FormDataContext = createContext<IFormDataContextProps>({} as IFormDataContextProps);

interface IFormDataContextProviderProps {
	children: ReactNode;
}

export const FormDataContextProvider: FC<IFormDataContextProviderProps> = ({ children }) => {
	const implant_colors = ['#EDF4FF', '#D4E5FF', '#F0F6FF', '#CFE2FF', '#E0EAF9'];

	const bridge_colors = ['#FFF1C3', '#FFF5D4', '#FFEFBA', '#FFF7DC', '#FBEAB2'];

	const event_colors = ['#FFE8FA', '#FFD3F5', '#FFEEFB', '#FFC9F3', '#FCE3F6'];

	const referral_colors = ['#E5FFEE', '#D4FFCB', '#EAFFF1', '#CBFFC1', '#CBFFDC'];

	const [formData, setFormData] = useState({
		info_theeth: [],
		stage: {},
		complaint: '',
		isExamination: false,
		description: '',
		dicount: null,
		dicount_type_is_percent: false,
		diagnosis_MKB_10: [],
		implant: [],
		bridge: [],
		isEvent: false,
		event: [],
		event_lor: [],
		event_cosmetology: [],
		referral: [],
		referral_lor: [],
		referral_cosmetolog: [],
		empty: [],
		deletion: [],
		milk_theeth: [],
		globalData: [],
		rightMindalData: [],
		leftMindalData: [],
		right: [],
		left: [],
	});

	const default_form_data = {
		stage: {},
		complaint: '',
		isExamination: false,
		description: '',
		diagnosis_MKB_10: [],
		implant: [],
		bridge: [],
		isEvent: false,
		event: [],
		event_lor: [],
		event_cosmetology: [],
		referral: [],
		referral_lor: [],
		referral_cosmetolog: [],
		empty: [],
		deletion: [],
		milk_theeth: [],
		globalData: [],
		rightMindalData: [],
		leftMindalData: [],
		rightData: [],
		leftData: [],
	};

	const value = useMemo(
		() => ({
			formData,
			default_form_data,
			setFormData,
			implant_colors,
			event_colors,
			bridge_colors,
			referral_colors,
		}),
		[bridge_colors, default_form_data, event_colors, formData, implant_colors, referral_colors],
	);

	return <FormDataContext.Provider value={value}>{children}</FormDataContext.Provider>;
};

// Define PropTypes for FormDataContextProvider component

// Export FormDataContextProvider component
FormDataContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FormDataContext;
