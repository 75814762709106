import React, { FC, memo } from 'react';
import { CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import { CardActions } from '../../../../components/bootstrap/Card';
import dayjs from 'dayjs';

interface IHeaderProps {}

const Header: FC<IHeaderProps> = memo(() => {
	return (
		<CardHeader>
			<CardLabel icon='ShowChart' iconColor='secondary'>
				<CardTitle tag='div' className='h5'>
					Статистика
				</CardTitle>
			</CardLabel>
			<CardActions>
				<strong>{dayjs().format('MMM')}</strong>.
			</CardActions>
		</CardHeader>
	);
});

export default Header;
