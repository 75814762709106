import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import UserImage3 from '../../../../../assets/img/wanna/wanna3.png';
import Avatar from '../../../../../components/Avatar';
import { getFontSizeFromLocalStorage } from '../../../../../utils/functions';

interface IUserInfoProps {
	user: any;
}

const UserInfo: FC<IUserInfoProps> = memo(({ user }) => {
	const calculateAge = useCallback(() => {
		const birthDateObj = new Date(user?.dateOfBirth);
		const calculationDateObj = new Date();
		const diffTime = Math.abs(calculationDateObj.getTime() - birthDateObj.getTime());
		const ageInYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
		return ageInYears;
	}, [user?.dateOfBirth]);

	const getRootFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);
	const [dynamicFont, setDynamicFont] = useState(getRootFontSize());

	const updateFontSize = () => {
		const fontSizeValue = getFontSizeFromLocalStorage();
		setDynamicFont(fontSizeValue);
	};

	useEffect(() => {
		updateFontSize();

		const handleFontSizeChange = () => {
			updateFontSize();
		};

		window.addEventListener('fontSizeChange', handleFontSizeChange);

		return () => {
			window.removeEventListener('fontSizeChange', handleFontSizeChange);
		};
	}, []);

	return (
		<>
			<div className='col-12 d-flex align-items-center gap-4 mb-3'>
				<div className=''>
					<Avatar
						src={
							user?.image
								? `https://backend.amracode.am${user?.image}`
								: UserImage3
						}
						size={dynamicFont * 8}
						color='primary'
					/>
				</div>

				<div className='d-flex flex-column gap-2'>
					<div className='col-12 d-flex align-items-start flex-row gap-3 flex-wrap'>
						<div className='d-flex align-items-start flex-column'>
							<span className='text-muted fs-6'>Возраст</span>
							<span className='fw-bold fs-5'>
								{user?.dateOfBirth ? calculateAge() : 'Нет данных'}{' '}
							</span>
						</div>
						<div className='d-flex align-items-start flex-column'>
							<span className='text-muted fs-6'>Пол</span>
							<span className='fw-bold fs-5'>
								{user?.gender ? user?.gender : 'Нет данных'}{' '}
							</span>
						</div>
					</div>
					<div className='col-12 d-flex align-items-start flex-column'>
						<span className='text-muted fs-6'>Номер телефона</span>
						<span className='fw-bold fs-5'>{user?.phone ?? 'Нет данных'} </span>
					</div>
					<div className='col-12 d-flex align-items-start flex-column'>
						<span className='text-muted fs-6'>Адрес</span>
						<span className='fw-bold fs-5'>{user?.address ?? 'Нет данных'} </span>
					</div>
				</div>
			</div>
		</>
	);
});

export default UserInfo;
