import { authHost } from '../axios';
import { IPassport } from '../interface/passport.interface';

export class PassportService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IPassport[] | null> {
		const { data } = await authHost.get(`passport?${query}`);
		return data;
	}

	static async findOne(uuid: string, query: any) {
		const { data } = await authHost.get(`passport/${uuid}?${query}`);
		return data;
	}

	static async create(data: IPassport) {
		const passport = await authHost.post('passport',data)
		return passport;
	}

	static async update(uuid: string, data: IPassport) {		
		const passport = await authHost.patch(`passport/${uuid}`, data)
		return passport;
	}
}
