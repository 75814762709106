import React, { FC } from 'react';
import ServicePage from './ServiceComponents/ServicePage';
interface IServices {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const Services:FC<IServices> = ({search, setSearch}) => {
	return <ServicePage setSearch={setSearch} search={search} />;
};

export default Services;
