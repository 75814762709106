import React, { FC } from 'react';

import CategoryPage from './CategoryComponents/CategoryPage';
interface ICategory {
	search: any;
	setSearch(...args: unknown[]): unknown;
}


const Category:FC<ICategory> = ({search, setSearch}) => {
	return <CategoryPage setSearch={setSearch} search={search} />;
};

export default Category;
