import React, { FC, useCallback, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MedicalCasesService } from '../../../custom/api/service/medicalCase.service';
import { IMedicalCase } from '../../../custom/api/interface/medicalCases.interface';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { UserService } from '../../../custom/api/service/user.service';
import * as qs from 'qs';

interface IMedicalCaseModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	patient: any;
}

interface CreateMedicalCaseParams {
	inputDto: IMedicalCase;
}

const MedicalCaseModal: FC<IMedicalCaseModalProps> = ({ isOpen, setIsOpen, patient }) => {
	const [values, setValues] = useState<IMedicalCase>({
		title: '',
		description: '',
		clinicUuid: '',
		patientUuid: null,
	});

	const { data: user_clinic } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);

	const changeValues = useCallback((key: string, value: string | number) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	}, []);

	useEffect(() => {
		changeValues('clinicUuid', user_clinic?.clinicUuid);
	}, [changeValues, user_clinic?.clinicUuid]);

	

	useEffect(() => {
		setValues({
			title: '',
			description: '',
			clinicUuid: patient?.clinic?.uuid || user_clinic?.clinicUuid || null,
			patientUuid: patient?.uuid || null,
		});
	}, [patient, user_clinic?.clinicUuid]);

	const queryClient = useQueryClient();

	const { mutate: createMedicalCase } = useMutation(
		({ inputDto }: CreateMedicalCaseParams) => MedicalCasesService.create(inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('medicalCases');
				queryClient.invalidateQueries('patient');
				setIsOpen(false);
				setValues({
					title: '',
					description: '',
					clinicUuid: patient?.clinic?.uuid || user_clinic?.clinicUuid || null,
					patientUuid: patient?.uuid || null,
				});
			},
			onError: (error) => {
				console.error(error);
			},
		},
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		createMedicalCase({ inputDto: values });
	};

	return (
		<>
			<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen} size='lg' modalLevel={1060}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-3 fw-bold'>
						<Icon icon='Person' className='me-2 fs-2' color='primary' />
						Создать медицинский случай
					</span>
				</ModalHeader>
				<form onSubmit={handleSubmit}>
					<ModalBody className='px-4'>
						<div className='medicalCard'>
							<div className='row'>
								<FormGroup className='col-md-12' id='fillDate' label='Пациент'>
									<Input
										type='text'
										disabled
										value={`${patient?.name} ${patient?.surname} ${patient?.middleName}`}
									/>
								</FormGroup>
							</div>
							<div className='row'>
								<FormGroup className='col-12' id='medicine' label=' Название'>
									<Input
										type='text'
										onChange={(e: any) => changeValues('title', e.target.value)}
										value={values.title}
									/>
								</FormGroup>
							</div>
							<div className='row'>
								<FormGroup className='col-12' id='medicine' label='Описание'>
									<Textarea
										style={{ maxHeight: 300 }}
										onChange={(e: any) =>
											changeValues('description', e.target.value)
										}
										value={values.description}
									/>
								</FormGroup>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between w-100'>
							<Button
								className='fw-bold fs-6'
								color='link'
								isLight
								style={{ color: '#0D6EFD' }}
								onClick={() => {
									setIsOpen(false);
								}}>
								Отменить
							</Button>
							<Button
								className='fw-bold fs-6'
								color='info'
								type='submit'
								onClick={() => {
									setIsOpen(false);
								}}>
								Создать
							</Button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default MedicalCaseModal;
