import React, { SVGProps } from "react";


export const PRINT_TOOTH_27 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100"  viewBox="0 0 19 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M17.4001 41.4871C15.3434 11.6928 18.057 7.00565 16.8556 5.8952C14.8082 1.964 8.55968 15.5048 8.31076 26.4248C6.37316 23.8499 10.9688 5.21573 8.6061 6.7688C0.631896 12.0104 0.0870564 35.6606 0.499416 43.2" stroke="black" strokeWidth="0.7"/>
<path id="Vector 136" d="M12.256 11.2C12.7554 7.0259 11.9939 7.37797 9.40015 11.2" stroke="black" strokeWidth="0.35"/>
<path id="Vector 24" d="M16.9034 52.3424C14.5717 56.5443 11.6573 53.7433 10.4914 51.4088C9.03407 51.4088 6.44717 55.2 4.98983 55.2C3.53249 55.2 -1.5441 52.6948 1.20075 42.1272C2.91315 35.5344 6.94112 33.2 9.90837 33.2C18.7677 34.9648 19.9141 46.9168 16.9034 52.3424Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}

