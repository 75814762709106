import React, { FC, memo } from 'react';
import AddDocumentButton from './AddDocumentButton';
import UserEditButton from '../UserHeader/UserEditButton';
import AddParentButton from '../UserFooter/AddParentButton';

interface IUserFooterProps {
	setAddParent(...args: unknown[]): unknown;
	setActivation(...args: unknown[]): unknown;
	setViewDocuments(...args: unknown[]): unknown;
	setEditCard(...args: unknown[]): unknown;
	user: any;
}

const UserFooter: FC<IUserFooterProps> = memo(
	({ setAddParent, setActivation, setViewDocuments, setEditCard, user }) => {
		return (
			<>
				<UserEditButton setEditCard={setEditCard} />
				<AddParentButton setAddParent={setAddParent} patient={user} />
				{/* <AddOutUserCardButton setActivation={setActivation} /> */}
				<AddDocumentButton setViewDocuments={setViewDocuments} />
			</>
		);
	},
);

export default UserFooter;
