import React, { memo } from 'react';
import { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import { CardLabel, CardTitle } from '../../../../components/bootstrap/Card';

const SubHeaderLeftComponent = memo(() => {
	return (
		<SubHeaderLeft className='col-auto d-flex flex-nowrap align-items-center'>
			<CardLabel icon='LocalPharmacy' iconColor='info'>
				<CardTitle tag='div' className='fs-5'>
					Группы
				</CardTitle>
			</CardLabel>
		</SubHeaderLeft>
	);
});
export default SubHeaderLeftComponent;
