import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ClinicService } from '../../../../../custom/api/service/clinic.service';
import * as qs from 'qs';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import Icon from '../../../../../components/icon/Icon';
import ClinicItemButtonComponent from './ClinicItemButtonComponent';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../../../components/PaginationButtons';
import { IFile } from '../../../../../custom/api/interface/file.interface';
import dayjs from 'dayjs';
import HighlightedTitle from '../../../../../custom/pages/medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { capitalizeFirstLetter } from '../../../../../utils/functions';
import DeletionSubmit from '../DeletionSubmit';

type NotificationPlacement = NotificationArgsProps['placement'];

interface UpdateClinicParams {
	uuid: string;
	inputDto: any;
}

interface DeleteClinicParams {
	uuid: string;
}
const ClinicsComponent = () => {
	const [clinicUuid, setClinicUuid] = useState('');
	const [deleteSubmit, setDeleteSubmit] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);

	const [values, setValues] = useState({
		name: '',
		legalName: '',
		comercialName: '',
		legalAddress: '',
		actualAddress: '',
		phone: '',
		phoneSpare: '',
		email: '',
		emailSpare: '',
		propertyType: '',
	});
	const [api, contextHolder] = notification.useNotification();

	const openNotificationBridgePlace = (placement: NotificationPlacement, description: string) => {
		api.info({
			message: 'Важно!',
			description,
			placement,
			className: 'ant-notification-custom',
			props: { style: { zIndex: 150000 } },
		});
	};

	const { data: clinic } = useQuery(
		['clinic', clinicUuid],
		() =>
			ClinicService.findOne(
				clinicUuid,
				qs.stringify({
					includeMeta: [
						{
							association: 'files',
						},
					],
					filterMeta: {},
				}),
			),
		{
			onError: (error: any) => {
				if (error.response.data.message === 'Forbidden resource') {
					openNotificationBridgePlace('bottomRight', 'У вас нет доступа');
				}
			},
			enabled: !!clinicUuid,
		},
	);

	const changeValues = (key: string, value: any) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	};

	useEffect(() => {
		setValues({
			name: clinic?.name || '',
			legalName: clinic?.legalName || '',
			comercialName: clinic?.comercialName || '',
			legalAddress: clinic?.legalAddress || '',
			actualAddress: clinic?.actualAddress || '',
			phone: clinic?.phone || '',
			phoneSpare: clinic?.phoneSpare || '',
			email: clinic?.email || '',
			emailSpare: clinic?.emailSpare || '',
			propertyType: clinic?.propertyType || '',
		});
	}, [clinic]);

	const handleDownload = useCallback((fileUrl: string, fileName: string) => {
		fetch(fileUrl)
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			})
			.catch((error) => {
				console.error('Download error:', error);
			});
	}, []);

	const queryClient = useQueryClient();

	const { mutate: clinicUpdate } = useMutation(
		({ uuid, inputDto }: UpdateClinicParams) => ClinicService.update(uuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('clinic');
				queryClient.invalidateQueries('clinics');

				setValues({
					name: clinic?.name || '',
					legalName: clinic?.legalName || '',
					comercialName: clinic?.comercialName || '',
					legalAddress: clinic?.legalAddress || '',
					actualAddress: clinic?.actualAddress || '',
					phone: clinic?.phone || '',
					phoneSpare: clinic?.phoneSpare || '',
					email: clinic?.email || '',
					emailSpare: clinic?.emailSpare || '',
					propertyType: clinic?.propertyType || '',
				});
			},
			onError: (error: any) => {
				if (error.response.data.message === 'Forbidden resource') {
					openNotificationBridgePlace('bottomRight', 'У вас нет доступа');
				}
			},
		},
	);

	const { mutate: deleteClinic } = useMutation(
		({ uuid }: DeleteClinicParams) => ClinicService.delete(uuid),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('clinic');
				queryClient.invalidateQueries('clinics');

				setValues({
					name: clinic?.name || '',
					legalName: clinic?.legalName || '',
					comercialName: clinic?.comercialName || '',
					legalAddress: clinic?.legalAddress || '',
					actualAddress: clinic?.actualAddress || '',
					phone: clinic?.phone || '',
					phoneSpare: clinic?.phoneSpare || '',
					email: clinic?.email || '',
					emailSpare: clinic?.emailSpare || '',
					propertyType: clinic?.propertyType || '',
				});
				setDeleteSubmit(false);
			},
			onError: (error: any) => {
				if (error.response.data.message === 'Forbidden resource') {
					openNotificationBridgePlace('bottomRight', 'У вас нет доступа');
				}
			},
		},
	);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		clinicUpdate({ uuid: clinicUuid, inputDto: values });
	};

	const handleDelete = (e: any) => {
		e.preventDefault();
		deleteClinic({ uuid: clinicUuid });
	};

	const handleCancele = () => {
		setValues({
			name: clinic?.name || '',
			legalName: clinic?.legalName || '',
			comercialName: clinic?.comercialName || '',
			legalAddress: clinic?.legalAddress || '',
			actualAddress: clinic?.actualAddress || '',
			phone: clinic?.phone || '',
			phoneSpare: clinic?.phoneSpare || '',
			email: clinic?.email || '',
			emailSpare: clinic?.emailSpare || '',
			propertyType: clinic?.propertyType || '',
		});
	};

	return (
		<div className='col-12 d-flex flex-column gap-3'>
			<span>{contextHolder}</span>
			<form onSubmit={handleSubmit}>
				<div className='col-12'>
					<ClinicItemButtonComponent
						clinicUuid={clinicUuid}
						setClinicUuid={setClinicUuid}
					/>
				</div>

				{clinic && clinicUuid && (
					<div
						style={{ backgroundColor: '#E8F1FF', borderRadius: 12, marginTop: 20 }}
						className='col-8 d-flex justify-content-center'>
						<div className='col-12 d-flex flex-column align-items-center gap-4'>
							<div
								style={{ marginTop: 20 }}
								className='col-11 d-flex justify-content-between'>
								<div className='d-flex align-items-center'>
									<Icon
										icon={capitalizeFirstLetter(clinic.name) + 'Icon'}
										style={{
											width: 40,
											height: 40,
										}}
									/>
									<span className={`fs-3 fw-bold text-dark`}>{clinic.name}</span>
								</div>
								<div className='d-flex align-items-center gap-3'>
									<Button type='submit' color={'primary'}>
										Сохранить
									</Button>
									<Button onClick={handleCancele} color={'danger'} isOutline>
										Отменить
									</Button>
									<Button className='p-0' onClick={() => setDeleteSubmit(true)}>
										<Icon icon={'Delete'} color={'danger'} size={'2x'} />
									</Button>
								</div>
							</div>
							<FormGroup className='col-11' id='name'>
								<HighlightedTitle
									title={'*Полное наименование компании (Юридическое)'}
								/>

								<Input
									required
									onChange={(e: any) => changeValues('legalName', e.target.value)}
									value={values.legalName}
								/>
							</FormGroup>

							<FormGroup className='col-11' id='name'>
								<HighlightedTitle
									title={'*Полное наименование компании (Тарговое)'}
								/>

								<Input
									required
									onChange={(e: any) =>
										changeValues('comercialName', e.target.value)
									}
									value={values.comercialName}
								/>
							</FormGroup>

							<FormGroup className='col-11' id='name'>
								<HighlightedTitle title={'*Юридический адрес'} />

								<Input
									required
									onChange={(e: any) =>
										changeValues('legalAddress', e.target.value)
									}
									value={values.legalAddress}
								/>
							</FormGroup>

							<FormGroup className='col-11' id='name'>
								<HighlightedTitle title={'*Фактический адрес'} />

								<Input
									required
									onChange={(e: any) =>
										changeValues('actualAddress', e.target.value)
									}
									value={values.actualAddress}
								/>
							</FormGroup>

							<div className='col-11 d-flex align-items-center gap-3'>
								<FormGroup className='col-4' id='name'>
									<HighlightedTitle title={'*Номер телефона 1'} />

									<Input
										required
										onChange={(e: any) => changeValues('phone', e.target.value)}
										value={values.phone}
									/>
								</FormGroup>

								<FormGroup className='col-4' id='name'>
									<HighlightedTitle title={'Номер телефона 2'} />

									<Input
										onChange={(e: any) =>
											changeValues('phoneSpare', e.target.value)
										}
										value={values.phoneSpare}
									/>
								</FormGroup>
							</div>

							<div className='col-11 d-flex align-items-center gap-3'>
								<FormGroup className='col-6' id='name'>
									<HighlightedTitle title={'Email 1'} />

									<Input
										onChange={(e: any) => changeValues('email', e.target.value)}
										required
										value={values.email}
									/>
								</FormGroup>

								<FormGroup className='col-6' id='name'>
									<HighlightedTitle title={'Email 2'} />

									<Input
										onChange={(e: any) =>
											changeValues('emailSpare', e.target.value)
										}
										value={values.emailSpare}
									/>
								</FormGroup>
							</div>
							<FormGroup className='col-11' aria-required id='name'>
								<HighlightedTitle title={'*Тип собственности'} />

								<div className='col-2 d-flex justify-content-start'>
									<select
										onChange={(e: any) =>
											changeValues('propertyType', e.target.value)
										}
										value={values?.propertyType}
										name='uuid'
										className='form-select'>
										<option hidden selected value=''></option>
										<option value='ИП'>ИП</option>
										<option value='ИasdaП'>ИasdaП</option>
									</select>
								</div>
							</FormGroup>
							<div className='col-11'>
								<span
									style={{ marginBottom: 20, overflow: 'hidden' }}
									className='fs-3 fw-bold'>
									<Icon
										icon={'Receipt'}
										style={{ width: 26, height: 26 }}
										color={'primary'}
									/>{' '}
									Лицензии и разрешения
								</span>
								<table className='table table-modern'>
									<thead>
										<tr>
											<th>№</th>
											<th>Название документа</th>
											<th>Описание</th>
											<th>Дата добавления</th>
											<th>Действие</th>
										</tr>
									</thead>
									<tbody>
										{dataPagination(clinic?.files, currentPage, perPage)?.map(
											(item: IFile) => (
												<tr key={item?.id} className='fw-bold'>
													<td>
														<div className='d-flex align-items-center'>
															{item?.fileId}
														</div>
													</td>
													<td>
														<div className='d-flex align-items-center'>
															{item?.title}
														</div>
													</td>
													<td>
														<div className='d-flex align-items-center'>
															{item?.description}
														</div>
													</td>
													<td>
														<div className='d-flex align-items-center'>
															<div className=' col-12'>
																<span className='text-nowrap  fw-bold fs-5'>
																	{dayjs(
																		`${item?.createdAt}`,
																	)?.format('MMM D, YYYY')}
																	{', '}
																</span>
																<span className='text-nowrap'>
																	{dayjs(
																		`${item?.createdAt}`,
																	)?.format('hh:mm')}{' '}
																</span>
															</div>
														</div>
													</td>
													<td>
														<div className='d-flex align-items-center'>
															<Button
																color='primary'
																tag='a'
																target='_blank'
																href={
																	`https://backend.amracode.am` +
																	item?.file
																}
																isLight
																className='text-nowrap me-1'>
																Посмотреть
															</Button>{' '}
															<Button
																onClick={() =>
																	handleDownload(
																		`https://backend.amracode.am${item?.file}`,
																		`${item?.title}.${item?.file?.split(
																			'.',
																		)[1]}`,
																	)
																}
																isLight>
																<Icon icon='Download' size='2x' />
															</Button>
														</div>
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>

								<PaginationButtons
									data={clinic?.files || []}
									label='customers'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									small
									setPerPage={setPerPage}
									totalPage={Math.ceil(clinic?.files?.length / perPage) || 1}
								/>
							</div>
						</div>
					</div>
				)}
			</form>
			<DeletionSubmit
				setIsOpen={setDeleteSubmit}
				isOpen={deleteSubmit}
				submit={handleDelete}
			/>
		</div>
	);
};

export default ClinicsComponent;
