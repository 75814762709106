import React, { FC } from 'react';
import * as qs from 'qs';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import { useQuery } from 'react-query';
import Input from '../../../../../components/bootstrap/forms/Input';
import HighlightedTitle from '../../../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import { ClinicService } from '../../../../../custom/api/service/clinic.service';
import { CardBody } from '../../../../../components/bootstrap/Card';
import { UserService } from '../../../../../custom/api/service/user.service';

interface IAddClinicOrganisation {
	uuid?: string;
	setValues(...args: unknown[]): unknown;
	values: any;
}

const AddClinicOrganisation: FC<IAddClinicOrganisation> = ({ uuid, setValues, values }) => {
	const changeValues = (key: string, value: any, index?: number) => {
		setValues((prev: any) => {
			if (key === 'material') {
				if (prev.groups.length <= 0) {
					return {
						...prev,
						groups: [''],
						[key]: value,
					};
				}
				return {
					...prev,
					[key]: value,
				};
			}
			if (key === 'en' || key === 'arm' || key === 'rus') {
				return {
					...prev,
					title_all: {
						...prev.title_all,
						[key]: value,
					},
				};
			}
			if (key === 'mkb10DiseaseUuid' && typeof index === 'number') {
				const updatedMkb = [...prev.mkb10DiseaseUuid];

				if (value) {
					updatedMkb[index] = value; // Update the specific material by index
				}
				return {
					...prev,
					mkb10DiseaseUuid: updatedMkb,
				};
			}

			if (key === 'groups' && typeof index === 'number') {
				const updatedGroup = [...prev.groups];

				if (value) {
					updatedGroup[index] = value; // Update the specific material by index
				}
				return {
					...prev,
					groups: updatedGroup,
				};
			}

			if (key !== 'materials') {
				// Directly update the groupUuid
				return {
					...prev,
					[key]: value,
				};
			}

			return prev; // Return previous state if no updates
		});
	};

	const { data: clinics } = useQuery(['clinics'], () => ClinicService.findAll(qs.stringify({})));

	const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
		['categories'],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'subCategory',
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: doctors } = useQuery(
		'doctors',
		() =>
			UserService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'roles',
							where: {
								role: ['mainDoctor', 'doctor'],
							},
							include: [
								{
									association: 'clinics',
								},
							],
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const categories = categoriesWithSubCategories?.data
		.filter((item: any) => !item.categoryUuid)
		.map((category: any) => ({
			...category,
			categoriesUuid: category.uuid,
		}));

	return (
		<div className={'col-12'}>
			<CardBody style={{ backgroundColor: '#DBEAFF', borderRadius: 12 }}>
				<div className='p-1' style={{ maxWidth: '100%', overflow: 'auto' }}>
					<div className='col-12 d-flex  gap-4'>
						<div className='col-6'>
							<HighlightedTitle title={'*Название кабинета'} />
							<Input
								required
								type='text'
								onChange={(e: any) => changeValues('officeName', e.target.value)}
								value={values?.officeName}
							/>
						</div>
						<div className='col-2   '>
							<HighlightedTitle title={'Номер кабинета'} />
							<Input
								required
								type='text'
								onChange={(e: any) => changeValues('officeNumber', e.target.value)}
								value={values?.officeNumber}
							/>
						</div>
					</div>

					<div className='col-12 d-flex gap-3' style={{ marginTop: 10 }}>
						<FormGroup className='col-2'>
							<HighlightedTitle title={'*Тип кабинета'} />
							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e: any) =>
										changeValues('officeType', e.target.value)
									}
									value={values.officeType}
									className='form-select'>
									<option value='' hidden selected></option>
									<option value='Операционная'>Операционная</option>
									<option value='Палата'>Палата</option>
									<option value='Врачебный кабинет'>Врачебный кабинет</option>
									<option value='Прочее'>Прочее</option>
								</select>
							</div>
						</FormGroup>
						<FormGroup className='col-2'>
							<HighlightedTitle title={'*Филиал'} />

							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e: any) =>
										changeValues('clinicUuid', e.target.value)
									}
									value={values.clinicUuid}
									className='form-select'>
									{clinics?.data?.map((clinic: any) => (
										<option value={clinic.uuid}>{clinic.name}</option>
									))}
									<option value='' hidden selected></option>
								</select>
							</div>
						</FormGroup>
						<FormGroup className='col-2'>
							<HighlightedTitle title={'*Спецализация'} />
							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e: any) =>
										changeValues('categoryUuid', e.target.value)
									}
									className='form-select'
									value={values.categoryUuid}>
									{categories?.map((category: any) => (
										<option value={category.uuid}>{category.name.en}</option>
									))}
									<option value='' hidden selected></option>
								</select>
							</div>
						</FormGroup>
						<FormGroup className='col-1'>
							<HighlightedTitle title={'*Кол. коек'} />

							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e: any) => changeValues('bedCount', e.target.value)}
									value={values.bedCount}
									className='form-select'>
									<option value='1'>1</option>
									<option value='2'>2</option>
									<option value='3'>3</option>
									<option value='4'>4</option>
									<option value='5'>5</option>
									<option value='6'>6</option>
									<option value='7'>7</option>
									<option value='8'>8</option>
									<option value='9'>9</option>
									<option value='10'>10</option>

									<option value='' hidden selected></option>
								</select>
							</div>
						</FormGroup>
					</div>

					<div className='col-12 d-flex gap-3' style={{ marginTop: 10 }}>
						<FormGroup className='col-2'>
							<HighlightedTitle title={'*Ответственный персонал'} />
							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e: any) =>
										changeValues('responibleUserUuid', e.target.value)
									}
									value={values.responibleUserUuid}
									className='form-select'>
									{doctors?.data.map((user: any) => (
										<option value={user.uuid}>
											{user.surname} {user.name}
										</option>
									))}
									<option value='' hidden selected></option>
								</select>
							</div>
						</FormGroup>
						<FormGroup className='col-2'>
							<HighlightedTitle title={'Ответственный персонал 2'} />
							<div style={{ position: 'relative' }}>
								<select
									required
									onChange={(e: any) =>
										changeValues('responibleUser2Uuid', e.target.value)
									}
									value={values.responibleUser2Uuid}
									className='form-select'>
									{doctors?.data.map((user: any) => (
										<option value={user.uuid}>
											{user.surname} {user.name}
										</option>
									))}
									<option value='' hidden selected></option>
								</select>
							</div>
						</FormGroup>

						<FormGroup className='col-2'>
							<HighlightedTitle title={'Контактный телефон'} />
							<Input
								required
								type='text'
								onChange={(e: any) => changeValues('phone', e.target.value)}
								value={values?.phone}
							/>
						</FormGroup>
					</div>
				</div>
			</CardBody>
		</div>
	);
};

export default AddClinicOrganisation;
