import React, { FC, memo } from 'react';
import Card, { CardBody } from '../../../../../../../components/bootstrap/Card';
import Popovers from '../../../../../../../components/bootstrap/Popovers';
import Avatar from '../../../../../../../components/Avatar';
import UserImage3 from '../../../../../../../assets/img/wanna/wanna3.png';

interface UserCardProps {
	user: any;
	themeStatus: string;
	isSelected: boolean;
	onSelect(...args: unknown[]): unknown;
}

const UserCard: FC<UserCardProps> = memo(({ user, themeStatus, isSelected, onSelect }) => (
	<Popovers
		trigger='hover'
		desc={<div className='h6'>{`${user.surname} ${user.name} ${user.middleName}`}</div>}>
		<div className='position-relative'>
			<Card onClick={onSelect} className='rounded-2'>
				<CardBody
					className={`p-3 d-flex flex-column align-items-center justify-content-center gap-2 cursor-pointer rounded-1 border border-4 border-${
						isSelected ? 'info' : themeStatus
					}`}>
					<Avatar
						src={user?.image ? `https://backend.amracode.am${user.image}` : UserImage3}
						size={60}
					/>
					<p className='m-0 text-center' style={{ minWidth: '7.7rem' }}>
						{`${user.surname} ${String(user.name).charAt(0)}.`}
					</p>
				</CardBody>
			</Card>
		</div>
	</Popovers>
));

export default UserCard;
