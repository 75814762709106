import React, { SVGProps } from "react";

export const PRINT_TOOTH_24 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100"  viewBox="0 0 18 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M1.6001 36.0619C3.42036 8.40066 5.43115 5.05869 9.2622 3.2769C13.0933 1.49511 16.941 31.2004 16.576 38.2" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M7.73379 32.2C-0.362533 33.8135 -0.725721 43.7443 2.48707 48.404C4.21603 50.9115 6.47654 51.5721 13.3746 51.0166C14.7064 51.0166 19.3807 48.7108 16.8723 39.0489C15.3074 33.0213 10.4455 32.2 7.73379 32.2Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>    
  )
}

