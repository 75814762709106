import React, { SVGProps } from "react";

export const PRINT_TOOTH_32 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100" viewBox="0 0 17 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M15.6 15.8937C13.8258 42.9796 6.09559 50.0904 3.0404 50.7883C-0.0147922 51.4862 2.68468 20.654 3.0404 13.8" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M10.5667 18.8C18.5222 17.1922 17.1288 10.1789 14.7111 5.50883C13.3161 2.81421 12.855 1.36323 6.07685 1.9167C4.76818 1.9167 -0.187342 3.06555 2.2775 12.6935C3.81521 18.7 7.90213 18.8 10.5667 18.8Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}


