import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Icon from '../../../../components/icon/Icon';
import Modal, { ModalBody, ModalHeader } from '../../../../components/bootstrap/Modal';
import { UserService } from '../../../../custom/api/service/user.service';
import * as qs from 'qs';
import { GoogleService } from '../../../../custom/api/service/google-callendar.service';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DentalContext from '../../../../contexts/dentalContext';
import useDarkMode from '../../../../hooks/useDarkMode';
import { IVisit } from '../../../../custom/api/interface/visit.interface';
import { VisitService } from '../../../../custom/api/service/visit.service';
import ScheduleModal from '../../doctors/schedule/ScheduleModal';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import Button from '../../../../components/bootstrap/Button';
import { ConsultationListService } from '../../../../custom/api/service/consulatation-list.service';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import CustomSelect from '../../../../custom/components/Select/select';
import { IClinic } from '../../../../custom/api/interface/clinic.interface';
import { ClinicService } from '../../../../custom/api/service/clinic.service';
import Card, { CardBody, CardHeader, CardLabel } from '../../../../components/bootstrap/Card';
import {
	connectClick,
	formatDateToYYYYMMDD,
	getServices,
	getUserFullName,
	getValue,
} from '../../../../utils/functions';
import ServiceChooseModal from '../../services/ServiceChooseModal/ServiceChooseModal';
import { StageService } from '../../../../custom/api/service/stage.service';
import { IStage } from '../../../../custom/api/interface/stage.interface';
import DentalContextFunctions from '../../../../contexts/dentalContextFunctions';
import Loading from '../../../../custom/components/loading/Loading';

type NotificationPlacement = NotificationArgsProps['placement'];

interface IEditVisitModalProps {
	updateVisitModal: any;
	setUpdateVisitModal(...args: unknown[]): unknown;
	setVisitUpdate(...args: unknown[]): unknown;
	visitUpdate: any;
}

const EditVisitModal: FC<IEditVisitModalProps> = ({
	setUpdateVisitModal,
	updateVisitModal,
	setVisitUpdate,
	visitUpdate,
}) => {
	const { setSelectMany, setSelectedItems, setSelectManyAll, setSelectedItemsAll } =
		useContext(DentalContext);
	const { getInfoForCreateVisit } = useContext(DentalContextFunctions);
	const [values, setValues] = useState<IVisit>({});
	const { themeStatus } = useDarkMode();
	const [api, contextHolder] = notification.useNotification();
	const [isGoogleCalendar, setIsGoogleCallendar] = useState<boolean>(false);
	const [isGoogleCalendarLink, setIsGoogleCallendarLink] = useState<any>(null);
	const [visitData, setVisitData] = useState([]);
	const [popperIsOpenService, setPopperIsOpenService] = useState(false);
	const [schedulModalOpen, setSchedulModalOpen] = useState(false);
	const [consultationListUuid, setConslutationListUuid] = useState<any>('');
	const [consultationListData, setConslutationListdata] = useState<any>({});

	const changeLocalValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (value === '' || /^[0-9]*$/.test(value)) {
			changeValues('visitNumber', e.target.value);
		}
	};

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
		});
	};

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);
	const queryClient = useQueryClient();

	const updateInfo = useCallback(() => {
		queryClient.invalidateQueries('consultation_list');
		queryClient.invalidateQueries('stages');
		queryClient.invalidateQueries('visits');
		setValues({});
		setUpdateVisitModal();
		setSelectMany(false);
		setSelectManyAll(false);
		setSelectedItemsAll([]);
		setVisitUpdate();
		setSelectedItems([]);
	}, [
		queryClient,
		setSelectMany,
		setSelectManyAll,
		setSelectedItems,
		setSelectedItemsAll,
		setUpdateVisitModal,
		setVisitUpdate,
	]);

	useEffect(() => {
		setIsUserDoctor(
			user_role?.roles?.some(
				(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
			),
		);
	}, [user_role]);

	const { data: stage }: { data: IStage[] | any; isLoading: boolean } = useQuery(
		['stage', visitUpdate?.stageUuid],
		() =>
			visitUpdate?.stageUuid
				? StageService.findOne(visitUpdate?.stageUuid)
				: Promise.resolve(null),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: consulatation_list }: { data: any[] | any; isLoading: boolean } = useQuery(
		['consulatation_list', stage?.uuid],
		() =>
			ConsultationListService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'stage',
							where: {
								uuid: stage?.uuid,
							},
						},
					],
				}),
			),
		{
			enabled: !!stage?.uuid,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (consulatation_list?.data?.length > 0) {
			setConslutationListUuid(consulatation_list?.data[0]?.uuid);
			setConslutationListdata(consulatation_list?.data[0]?.dentalFormula);
		}
	}, [consulatation_list]);

	useEffect(() => {
		if (visitUpdate && consulatation_list?.data[0]?.dentalFormula) {
			const data = {
				services: getValue(values?.services, getServices(visitUpdate?.services)) || [],
				complaint: visitUpdate?.complaint,
				visitNumber: visitUpdate?.visitNumber,
				clinicUuid: visitUpdate?.clinicUuid,
				patientUuid: visitUpdate?.patientUuid,
				medicalCaseUuid: visitUpdate?.medicalCaseUuid,
				stageUuid: visitUpdate?.stageUuid,
				userFullName: getValue(getUserFullName(visitUpdate?.user), values?.userFullName),
				userUuid: getValue(visitUpdate?.userUuid, values?.userUuid),
				visitDate: getValue(visitUpdate?.visitDate, values?.visitDate),
				startDate: getValue(visitUpdate?.startDate, values?.startDate),
				endDate: getValue(visitUpdate?.endDate, values?.endDate),
			};
			const visit_data = JSON.parse(JSON.stringify(data));
			const services_visit = visitUpdate?.services.map((item: any) => item?.event);
			// const services_formData = consulatation_list?.data[0]?.dentalFormula.event;
			// const services_forset = services_formData.filter(
			// 	(item: any) => !services_visit.includes(item.event_uuid),
			// );

			const data_list = getInfoForCreateVisit(
				visit_data?.services,
				JSON.stringify(consulatation_list?.data[0]?.dentalFormula),
				services_visit,
			);
			setValues({
				...visit_data,
				services: visit_data?.services,
				form_data: data_list.data,
				consulatation_list_uuid: consulatation_list?.data[0]?.uuid,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consulatation_list?.data, visitUpdate]);

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			updateInfo();
		},
		onError: (error: any) => {
			if (error.response.data.message === 'calendar_auth_failed') {
				setIsGoogleCallendar(false);
				setTimeout(() => setIsGoogleCallendar(true), 0);
			}
			if (error.response.data.message === 'choose_user_date_time')
				openNotification(
					'bottomRight',
					'Важно !',
					'Выберите дату и врача чтобы создать визит',
				);
			if (error.response.data.message === 'end_date_empty')
				openNotification('bottomRight', 'Важно !', 'выберите время окончания визита');

			if (error.response.data.message === 'start_date_empty')
				openNotification('bottomRight', 'Важно !', 'выберите время начала визита');

			if (error.response.data.message === 'can_not_create_visit_whitout_services')
				openNotification('bottomRight', 'Важно !', 'выберите услуги чтобы создать визит');
		},
	};

	const { mutate: updateVisit, isLoading: visitUpdateLoading } = useMutation(
		({ uuid, data }) => VisitService.update(uuid, data),
		queryOptions,
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			if (visitUpdate?.uuid) {
				await updateVisit({
					uuid: visitUpdate.uuid,
					data: { ...values, services: values?.services },
				});
			}
		} catch (error) {
			console.error('Error updating visit:', error);
		}
	};

	useEffect(() => {
		if (consultationListData && consultationListUuid) {
			const services_visit = visitUpdate?.services.map((item: any) => item?.event);
			// const services_formData = consulatation_list?.data[0]?.dentalFormula.event;
			// const services_forset = services_formData.filter(
			// 	(item: any) => !services_visit.includes(item.event_uuid),
			// );

			const data = getInfoForCreateVisit(
				values?.services,
				JSON.stringify(consultationListData),
				services_visit,
			);
			setValues((prev: any) => ({
				...prev,
				services: values?.services,
				form_data: data?.data,
				consulatation_list_uuid: consultationListUuid,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.services, consultationListData, consultationListUuid]);

	const changeValues = useCallback((key: string, value: string | number) => {
		if (key === 'services') {
			setValues((prev) => ({
				...prev,
				[key]: [...(Array.isArray(prev[key]) ? prev[key] : []), value],
			}));
		}
		if (key === 'users') {
			setValues((prev) => ({
				...prev,
				userName: undefined,
				[key]: [...(Array.isArray(prev[key]) ? prev[key] : []), value],
			}));
		}
		setValues((prev) => ({
			...prev,
			[key]: value,
		}));
	}, []);

	const { data: calendarAccess } = useQuery(
		['calendar_access', isGoogleCalendar],
		async () => {
			if (isGoogleCalendar) {
				return await GoogleService.generateGoogleCallendarAccses();
			}
			return null;
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: clinics } = useQuery<IClinic | null, Error>(
		['clinics'],
		() =>
			ClinicService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		return setIsGoogleCallendarLink(calendarAccess);
	}, [isGoogleCalendar, calendarAccess]);

	useEffect(() => {
		if (isGoogleCalendar && isGoogleCalendarLink && typeof isGoogleCalendarLink === 'string') {
			connectClick(isGoogleCalendarLink);
		}
	}, [isGoogleCalendar, isGoogleCalendarLink]);

	const timeFormatter = (time: string) => {
		return new Date(time).toTimeString().slice(0, 5);
	};

	const dateOfVisit = (start: any, end: any) => {
		if (start && !end) {
			return timeFormatter(start);
		}
		if (start && end) {
			return `${timeFormatter(start)} -	${timeFormatter(end)}`;
		}
	};

	console.log(values, '5556656664842495918');

	return (
		<Modal
			isStaticBackdrop
			isCentered
			isOpen={updateVisitModal}
			setIsOpen={setUpdateVisitModal}
			closeModalCustom={updateInfo}
			size='xl'>
			<ModalHeader
				closeModalCustom={updateInfo}
				style={{ padding: '1.5rem 2.25rem' }}
				setIsOpen={setUpdateVisitModal}
				className='p-4'>
				<span className='fs-3 fw-bold'>
					<Icon icon='Person' className='me-2 fs-2' color='primary' />
					Редактировать визит
				</span>
			</ModalHeader>
			<ModalBody style={{ padding: '1.5rem 2.25rem' }} className='px-4'>
				<>
					<span>{contextHolder}</span>
					<form style={{ width: '100%' }} onSubmit={handleSubmit}>
						<div className='d-flex flex-row justify-content-around align-items-start mb-4 col-12'>
							<div className='d-flex flex-column gap-4 col-5'>
								<FormGroup className='col-12' id='visitNumber' label='Номер визита'>
									<Input
										className='p-3 pb-1 pt-1'
										type='text'
										required
										onChange={changeLocalValue}
										value={values.visitNumber}
									/>
								</FormGroup>
								<FormGroup className='col-12' id='patient' label='Пациент'>
									<Input
										disabled={true}
										value={`${visitUpdate?.patient?.surname} ${visitUpdate?.patient?.name} ${visitUpdate?.patient?.middleName}`}
										type='text'
									/>
								</FormGroup>
								<FormGroup id='clinic' label='Филиал' className='mb-2'>
									<CustomSelect
										disabled={!!visitUpdate || isUserDoctor}
										placeholder={'Выберите филиал'}
										name={'clinic'}
										value={values.clinicUuid}
										options={clinics?.data.map(
											(item: IClinic, index: number) => {
												return {
													value: item.uuid,
													label: item.name,
												};
											},
										)}
										changeSearchValue={() => {}}
										changeValues={changeValues}
									/>
								</FormGroup>

								<FormGroup className='col-12' id='complaint' label='Жалоба'>
									<Textarea
										required
										value={values.complaint}
										onChange={(e: any) =>
											changeValues('complaint', e.target.value)
										}
									/>
								</FormGroup>
							</div>
							<div className='d-flex flex-column gap-5 col-5'>
								{' '}
								<FormGroup label='Услуги' className='col-12'>
									<Button
										style={{
											boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
										}}
										onClick={() => {
											setPopperIsOpenService(true);
										}}
										color={themeStatus}
										className='text-nowrap me-2 col-12'>
										Управление услугами
									</Button>
								</FormGroup>
								<FormGroup id='doctor' label=' ' className='mb-2'>
									<Button
										onClick={() => {
											setSchedulModalOpen(true);
										}}
										isLight
										color='primary'
										className='text-nowrap fw-normal me-2 col-12 d-flex align-items-center justify-content-between'>
										Выбрать врача, дату и время визита
									</Button>
								</FormGroup>
								<Card className={`bg-light-primary rounded-2 mb-0 shadow-none`}>
									<CardHeader className='bg-transparent pb-3'>
										<CardLabel icon='Article' iconColor='primary'>
											<span className='fs-4 fw-bold'>Детали визита</span>
										</CardLabel>
									</CardHeader>
									<CardBody className='pt-0 d-flex flex-column gap-2'>
										<p
											className='m-0 fs-6 d-flex'
											style={{ color: themeStatus, fontWeight: 400 }}>
											<span className='w-100' style={{ maxWidth: '3.5rem' }}>
												Дата
											</span>
											<span className='text-primary fw-bold'>
												{values?.visitDate &&
													formatDateToYYYYMMDD(
														new Date(values?.visitDate),
													)}
											</span>
										</p>
										<p
											className='m-0 fs-6 d-flex'
											style={{ color: themeStatus, fontWeight: 400 }}>
											<span className='w-100' style={{ maxWidth: '3.5rem' }}>
												Время
											</span>
											<span className='text-primary fw-bold'>
												{dateOfVisit(values?.visitDate, values?.endDate)}
											</span>
										</p>
										<p
											className='m-0 fs-6 d-flex'
											style={{ color: themeStatus, fontWeight: 400 }}>
											<span className='w-100' style={{ maxWidth: '3.5rem' }}>
												Врач
											</span>
											<span className='text-primary fw-bold'>
												{values?.userFullName}
											</span>
										</p>
									</CardBody>
								</Card>
							</div>
						</div>
						<div className='d-flex justify-content-end w-100'>
							<Button
								isDisable={visitUpdateLoading}
								className='fw-bold fs-6 position-relative'
								color='info'
								type='submit'>
								<Loading loading={visitUpdateLoading} />
								Сохранить
							</Button>
						</div>
					</form>
					{schedulModalOpen && (
						<ScheduleModal
							medicalCaseUuid={visitUpdate?.medicalCaseUuid}
							prevVisit={visitUpdate}
							clinicUuid={values?.clinicUuid}
							changeValues={changeValues}
							visitData={visitData}
							setVisitData={setVisitData}
							isOpen={schedulModalOpen}
							setIsOpen={setSchedulModalOpen}
							values={values}
						/>
					)}

					{popperIsOpenService && (
						<ServiceChooseModal
							stage_type={stage?.type}
							setIsOpen={setPopperIsOpenService}
							isOpen={popperIsOpenService}
							stage={values.stageUuid}
							setChosenValues={setValues}
							serviceDropdownInfo={values?.services}
							createVisit
						/>
					)}
				</>
			</ModalBody>
		</Modal>
	);
};

export default EditVisitModal;
