import React, { FC, useEffect, useState, useRef } from 'react';
import { CardHeader, CardTitle } from 'react-bootstrap';
import Button from '../../../../../components/bootstrap/Button';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import Card from '../../../../../components/bootstrap/Card';
import Icon from '../../../../../components/icon/Icon';
import UserImage3 from '../../../../../assets/img/wanna/wanna3.png';
import Avatar from '../../../../../components/Avatar';
import { useQuery } from 'react-query';
import { IMedicalCase } from '@textnpayme/custom/api/interface/medicalCases.interface';
import * as qs from 'qs';
import { MedicalCasesService } from '../../../../../custom/api/service/medicalCase.service';
import Stage from './Stage';
import PaginationButtons, {
	PER_COUNT_MEDICAL_CASE,
} from '../../../../../components/PaginationButtons';
import { PatientService } from '../../../../../custom/api/service/patient.service';
import { IPatient } from '../../../../../custom/api/interface/patient.interface';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
} from '../../../../../layout/SubHeader/SubHeader';
import MedicalCaseModal from '../../../medicalCard/MedicalCaseModal';
import Tooltips from '../../../../../components/bootstrap/Tooltips';
import { getFontSizeFromLocalStorage } from '../../../../../utils/functions';

interface IChooseMedicalCaseProps {
	setCreateVisit(...args: unknown[]): unknown;
	patientId: string;
	setServiceId(...args: unknown[]): unknown;
	setMedicalCaseUuid(...args: unknown[]): unknown;
	setStageUuid(...args: unknown[]): unknown;
	setCloseModal(...args: unknown[]): unknown;
	setClinicUuid(...args: unknown[]): unknown;
	setPatientCreatedId?(...args: unknown[]): unknown;
	setIsPatientChoosen?(...args: unknown[]): unknown;
	isPatientChoosen?: boolean | null;
}

function formatDate(strDate: string): any {
	const date = new Date(strDate);
	const year: number = date.getFullYear();
	const month: number = date.getMonth() + 1;
	const day: number = date.getDate();
	const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
	const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
	const formattedDateDot: string = `${formattedDay}.${formattedMonth}.${year}`;
	return formattedDateDot;
}

const ChooseMedicalCase: FC<IChooseMedicalCaseProps> = ({
	setCreateVisit,
	setServiceId,
	setStageUuid,
	setPatientCreatedId,
	setCloseModal,
	setMedicalCaseUuid,
	setClinicUuid,
	patientId,
	setIsPatientChoosen,
	isPatientChoosen,
}) => {
	const [medicalCaseId, setMedicalCaseId] = useState<string>('');
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT_MEDICAL_CASE['3']);
	const [modalMedicalCase, setModalMedicalCase] = useState<boolean>(false);
	const [chosenMedicalCase, setChosenMedicalCase] = useState<any>(null);
	const [isHidePagination, setHidePagination] = useState<boolean>(false);

	const [dynamicFont, setDynamicFont] = useState('Нормальный');

	const updateFontSize = () => {
		const fontSizeName = getFontSizeFromLocalStorage();
		setDynamicFont(fontSizeName);
	};

	useEffect(() => {
		updateFontSize();

		const handleFontSizeChange = () => {
			updateFontSize();
		};

		window.addEventListener('fontSizeChange', handleFontSizeChange);

		return () => {
			window.removeEventListener('fontSizeChange', handleFontSizeChange);
		};
	}, []);

	const { data: patient } = useQuery<IPatient, Error>(
		['patient', patientId],
		() => PatientService.findOne(patientId, qs.stringify({})),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!patientId,
		},
	);

	const { data: medicalCases }: { data: IMedicalCase[] | any; isLoading: boolean } = useQuery(
		['medicalCases', patient?.uuid, currentPage, perPage],
		() =>
			MedicalCasesService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'visits',
						},
						{
							association: 'patient',
							where: {
								uuid: patient?.uuid,
							},
						},
					],
					filterMeta: {
						status: 'Активный',
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!patient?.uuid,
		},
	);

	const { data: medicalCasesCount }: { data: IMedicalCase[] | any; isLoading: boolean } =
		useQuery(
			['medicalCasesCount', patient?.uuid, currentPage, perPage],
			() =>
				MedicalCasesService.findAll(
					qs.stringify({
						includeMeta: [
							{
								association: 'visits',
							},
							{
								association: 'patient',
								where: {
									uuid: patient?.uuid,
								},
							},
						],
						filterMeta: {
							status: 'Активный',
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				enabled: !!patient?.uuid,
			},
		);

	const [goToNewStage, setGoToNewStage] = useState(false);
	const [stageId, setStageId] = useState<string | null>('');

	const [values, setValues] = useState<IVisit>({
		services: [],
		serviceName: '',
		serviceNameChosen: '',
	});

	const formRef = useRef<HTMLFormElement>(null);

	const [isNoServices, setIsNoServices] = useState(false);
	const [chosenServicesCount, setChosenServicesCount] = useState(0);

	const isDisabledForwardBtn =
		(!isNoServices && chosenServicesCount < 1) || (stageId === '' && isNoServices);

	const setAndLogServices = (service: any) => {
		setValues((prev: any) => ({
			...prev,
			services: service?.services,
		}));
	};

	const goToNextStage = async (values: any) => {
		setServiceId(values.services);
		setStageUuid(stageId);
		setCreateVisit(true);
	};

	const handleForwardClick = () => {
		if (formRef.current) {
			formRef.current.dispatchEvent(
				new Event('submit', { cancelable: false, bubbles: true }),
			);
		}
		setGoToNewStage(true);
	};

	useEffect(() => {
		if (goToNewStage) {
			goToNextStage(values);
			setGoToNewStage(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goToNewStage]);

	return (
		<div
			className='d-flex flex-column justify-content-center align-items-center'
			style={{ gap: '1.8rem' }}>
			<div className='d-flex flex-wrap flex-row w-100'>
				{' '}
				{patient && (
					<div className='col-12'>
						<FormGroup id='patient'>
							<SubHeader className='shadow-none m-0 light-border-name-string'>
								<SubHeaderLeft className='d-flex flex-row align-items-center p-3 m-0'>
									<div
										className='fw-bold me-3 code-frame fs-5'
										style={{
											borderRadius: '0.5rem',
										}}>
										{patient.patientId}
									</div>
									<Avatar
										src={
											patient?.image
												? `https://backend.amracode.am${patient?.image}`
												: UserImage3
										}
										size={48}
										color='primary'
										className='me-3 m-0'
									/>
									<h4 className='m-0 fs-5'>
										{patient.name} {patient.surname} {patient.middleName}
										<p className='text-muted m-0'>{patient.phone}</p>
									</h4>
								</SubHeaderLeft>
								<SubHeaderRight>
									<div className='text-nowrap fw-bold d-flex align-items-center justify-content-center gap-2'>
										{patient.status === 'active' ? (
											<>
												<Icon icon='Circle' color='info' />
												<span
													className='fs-6'
													style={{
														color: '#0d6efd',
													}}>
													Активный
												</span>
											</>
										) : (
											<>
												<Icon icon='Circle' color='warning' />
												<span
													className='fs-6'
													style={{
														color: '#FFCF52',
													}}>
													Неактивный
												</span>
											</>
										)}
									</div>
								</SubHeaderRight>
							</SubHeader>
						</FormGroup>
					</div>
				)}
			</div>
			{!chosenMedicalCase && (
				<div className='d-flex align-items-center justify-content-between w-100 ps-2'>
					<SubHeaderLeft className='col-4 text-muted'>
						<p className='fs-4 fw-semibold'>
							{medicalCases?.data.length
								? `Активные мед случаи`
								: `Нет активных мед.случаев`}
						</p>{' '}
					</SubHeaderLeft>
					<SubHeaderRight className='col-8'>
						<Button
							tag='button'
							color='info'
							className='text-nowrap fw-semibold fs-5 new-med-case-btn'
							onClick={() => setModalMedicalCase(true)}>
							Новый мед случай{' '}
						</Button>
					</SubHeaderRight>
				</div>
			)}

			<div
				className='d-flex flex-column justify-content-center w-100'
				style={{ gap: '1.25rem' }}>
				{chosenMedicalCase ? (
					<>
						<SubHeader className='shadow-none m-0 light-border-name-string'>
							<SubHeaderLeft className='d-flex flex-row align-items-center p-3'>
								<div
									className='fw-bold me-3 code-frame fs-5'
									style={{
										background: '#0D6EFD',
										borderRadius: '0.5rem',
									}}>
									{chosenMedicalCase.medicalCaseId}
								</div>
								<CardTitle className='h5 d-flex flex-column'>
									{chosenMedicalCase.title}{' '}
									<span className='text-muted fs-6'>
										{formatDate(String(chosenMedicalCase.startDate))}
									</span>
								</CardTitle>
							</SubHeaderLeft>
						</SubHeader>
					</>
				) : (
					<>
						{medicalCases?.data.map((i: IMedicalCase, index: number) => (
							<Card key={`${i.uuid}-${index}`} className='col-12 m-0'>
								<CardHeader className='rounded-5'>
									<SubHeaderLeft className='col-10'>
										<div
											className={`fw-bold me-3 code-frame fs-5 ${
												dynamicFont === 'Маленький' ? 'col-1' : 'col-2'
											}`}
											style={{
												background: '#0D6EFD',
												borderRadius: '0.5rem',
											}}>
											{i.medicalCaseId}
										</div>
										<CardTitle
											className='d-flex flex-column fs-5 col-10 '
											style={{ width: '80%' }}>
											<span className='overflow-hidden w-100'>
												<Tooltips
													title={i.title}
													placement='top'
													flip={['top']}>
													<span className='text-truncate'>{i.title}</span>
												</Tooltips>
											</span>
											<span className='text-muted fs-6'>
												{formatDate(String(i.startDate))}
											</span>
										</CardTitle>
									</SubHeaderLeft>
									<SubHeaderRight>
										<Button
											className='fw-bold fs-6 col-3 w-100'
											color='primary'
											isLight
											onClick={() => {
												setChosenMedicalCase(i);
												if (i.uuid) {
													setMedicalCaseId(i.uuid);
													setMedicalCaseUuid(i.uuid);
													setClinicUuid(i.clinicUuid);
												}
												setHidePagination(true);
											}}>
											<span className='text-nowrap m-0'>Выбрать</span>
										</Button>
									</SubHeaderRight>
								</CardHeader>
							</Card>
						))}
					</>
				)}
			</div>
			{chosenMedicalCase && (
				<div className='d-flex flex-column justify-content-center w-100'>
					<Stage
						setCloseModal={setCloseModal}
						patientUuid={patientId}
						setMedicalCaseUuid={setMedicalCaseUuid}
						setStageUuid={setStageUuid}
						setServiceId={setServiceId}
						setCreateVisit={setCreateVisit}
						medicalCaseId={medicalCaseId}
						setIsBackHidden={setHidePagination}
						stageId={stageId}
						setStageId={setStageId}
						setAndLogServices={setAndLogServices}
						formRef={formRef}
						setValues={setValues}
						values={values}
						setIsNoServices={setIsNoServices}
						setChosenServicesCount={setChosenServicesCount}
					/>
				</div>
			)}

			<>
				{medicalCasesCount?.data?.length > perPage && !isHidePagination && (
					<div className='w-100 d-flex justify-content-end'>
						<PaginationButtons
							data={medicalCases || []}
							label='customers'
							medicalCase
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(medicalCasesCount?.data?.length / perPage) || 3}
							className='mx-2 mt-2'
						/>
					</div>
				)}
				{(isPatientChoosen || chosenMedicalCase || stageId) && (
					<div className='d-flex justify-content-between w-100'>
						<Button
							className='fw-bold fs-6'
							color='link'
							isLight
							style={{ color: '#0D6EFD' }}
							onClick={() => {
								if (chosenMedicalCase) {
									setChosenMedicalCase(null);
									medicalCases?.meta?.count > perPage && setHidePagination(false);
								} else if (setIsPatientChoosen) {
									setIsPatientChoosen(false);
								} else if (stageId) {
									setStageId('');
									setHidePagination(false);
								}
								if (setPatientCreatedId) {
									setPatientCreatedId(null);
								}
								if (setStageUuid) {
									setStageUuid(null);
								}
							}}>
							Назад
						</Button>
						<Button
							className='fw-bold fs-6'
							color='info'
							isOutline={isDisabledForwardBtn}
							isDisable={isDisabledForwardBtn}
							onClick={() => (isDisabledForwardBtn ? null : handleForwardClick())}>
							Вперед
						</Button>
					</div>
				)}
			</>

			<MedicalCaseModal
				setIsOpen={setModalMedicalCase}
				isOpen={modalMedicalCase}
				patient={patient}
			/>
		</div>
	);
};

export default ChooseMedicalCase;
