import DentalContextFunctions from '../../../../../../../contexts/dentalContextFunctions';
import React, { FC, useContext } from 'react';

interface ITeethRectangle {
	data: any;
	print?: boolean;
}

export const TeethRectangle: FC<ITeethRectangle> = ({ data, print }) => {
	const { generateSequence } = useContext(DentalContextFunctions);
	return (
		<React.Fragment>
			{/* Case when print is false */}
			{!print ? (
				data?.start && data?.end ? (
					<>
						{/* Start Block */}
						<div
							className='d-flex p-2 align-items-center justify-content-center'
							style={{
								width: 20,
								height: 20,
								borderRadius: 3,
								backgroundColor: '#19ABED',
							}}>
							<p className='m-0 fs-6 text-light'>{data.start}</p>
						</div>

						{/* Sequence Blocks */}
						{generateSequence(data.start, data.end)?.map((item: any) => (
							<div
								key={item}
								className='d-flex p-2 align-items-center justify-content-center bg-info'
								style={{
									width: 20,
									height: 20,
									borderRadius: 3,
								}}>
								<p className='m-0 fs-6 text-light'>{item}</p>
							</div>
						))}

						{/* End Block */}
						<div
							className='d-flex p-2 align-items-center justify-content-center'
							style={{
								width: 20,
								height: 20,
								borderRadius: 3,
								backgroundColor: '#19ABED',
							}}>
							<p className='m-0 fs-6 text-light'>{data.end}</p>
						</div>
					</>
				) : data?.theeth ? (
					<div
						className='d-flex p-2 align-items-center justify-content-center bg-info'
						style={{
							width: 20,
							height: 20,
							borderRadius: 3,
						}}>
						<p className='m-0 fs-6 text-light'>{data.theeth}</p>
					</div>
				) : (
					'Зуб'
				)
			) : /* Case when print is true */
			data?.start && data?.end ? (
				<React.Fragment>
					{/* Render start */}
					{data.start},{/* Render sequence */}
					{generateSequence(data.start, data.end)
						?.map((item: any) => item)
						.join(',')}
					{/* Render end */},{data.end}
				</React.Fragment>
			) : data?.theeth ? (
				/* Render data.theeth */
				data.theeth
			) : (
				/* Default text */
				'Зуб'
			)}
		</React.Fragment>
	);
};
