import React, { SVGProps } from "react";

export const PRINT_TOOTH_18 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100" viewBox="0 0 26 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M5 49.5519C7.05671 17.4054 4.34314 12.3482 5.5445 11.1501C7.5919 6.90853 13.8405 21.5183 14.0894 33.3004C16.027 30.5223 11.4313 10.417 13.794 12.0927C21.7683 17.7481 22.3131 43.2654 21.9007 51.4" stroke="black" strokeWidth="0.7"/>
<path id="Vector 135" d="M13 15.7456C10.6811 12.3998 9.19714 10.026 10.4645 16.4" stroke="black" strokeWidth="0.35"/>
<path id="Vector 24" d="M5.49672 60.5424C7.82845 64.7444 10.7429 61.9433 11.9087 59.6088C13.3661 59.6088 15.953 63.4 17.4103 63.4C18.8677 63.4 23.9442 60.8948 21.1994 50.3272C19.487 43.7344 15.459 41.4 12.4918 41.4C3.63246 43.1648 2.48603 55.1168 5.49672 60.5424Z" stroke="black" strokeWidth="0.7"/>
<g id="Union">
</g>
</g>
</svg>
  )
}

