import React, { FC } from 'react';

import SubCategoryPage from './SubCategoryComponents/SubCategoryPage';

interface ISubCategory {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const SubCategory:FC<ISubCategory> = ({search, setSearch}) => {
	return <SubCategoryPage setSearch={setSearch} search={search} />;
};

export default SubCategory;
