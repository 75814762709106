import React, { FC } from 'react';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { InputWithTitle } from '../../medicalCard/medicalCaseComponents/UI/Inputs';
import { TheethDropdown } from './TheethDropdown';
import Badge from '../../../../components/bootstrap/Badge';

type ItemType =
	| 'bridge'
	| 'referral'
	| 'implant'
	| 'event'
	| 'deletion'
	| 'milk_theeth'
	| 'empty'
	| 'cosmetology'
	| 'lor';

interface IServices {
	index: number;
	isDisabled: boolean;
	item: any;
	changeChosenServicesValue?: any;
	handleDeleteService?: any;
	chosenServices: any;
	disable?: boolean;
	setChosenServices: any;
	isChecked?: boolean;
	handleCheckboxChange?: any;
	localChosenServices: any;
	setLocalChosenServices: any;
	type: ItemType;
	themeStatus?: any;
}

export const ServicesList: FC<IServices> = ({
	index,
	isDisabled,
	changeChosenServicesValue,
	chosenServices,
	setChosenServices,
	item,
	disable,
	type,
	localChosenServices,
	setLocalChosenServices,
	handleDeleteService,
	isChecked,
	handleCheckboxChange,
	themeStatus,
}) => {
	return (
		<div
			key={`${index}-${item.serviceId}`}
			className='shadow col-12 rounded-1 light-border-services-tab p-2'>
			<div
				className='col-12 d-flex justify-content-end align-items-center'
				style={{ height: 30 }}>
				{!!item.visitUuid && !!item.visitId && (
					<Badge className='fw-bold fs-6 rounded-1'>
						Визит ID
						<span className='badge fs-6 text-decoration-underline text-light'>
							<em>{item?.visitId}</em>
						</span>
					</Badge>
				)}
			</div>
			<div className='form-check m-0 gap-2' key={index}>
				{handleCheckboxChange ? (
					<input
						color={themeStatus}
						className='form-check-input'
						onChange={(e: any) => handleCheckboxChange(e, item, type)}
						type='checkbox'
						value={item.uuid}
						name='services'
						checked={isChecked}
						id={`flexCheckDefault-${index}`}
						// disabled={isDisabled}
					/>
				) : (
					<Button
						// isDisable={isDisabled}
						onClick={() => handleDeleteService(item?.event_uuid, index)}
						className='d-flex justify-content-center align-items-center form-check-input'>
						<Icon className='deletIcone' color={'red'} size={'2x'} icon='Delete'></Icon>
					</Button>
				)}

				<label
					aria-disabled={isDisabled}
					className='form-check-label col-12 d-flex gap-3 p-0'>
					<div style={{ width: '11%' }}>
						<span
							className='p-2 d-flex justify-content-center col-12 fw-normal text-white'
							style={{
								borderRadius: '0.5rem',
								backgroundColor: '#2c3178',
								width: '100%',
							}}>
							{item.serviceId}
						</span>
					</div>
					<p className='d-flex m-0' style={{ width: '70%' }}>
						<span className='overflow-hidden w-100'>
							<Tooltips title={item?.title_all?.rus} placement='top' flip={['top']}>
								<span className='text-truncate'>{item?.title_all?.rus}</span>
							</Tooltips>
						</span>
					</p>
				</label>
			</div>
			{type !== 'lor' && type !== 'cosmetology' && (
				<div className='d-flex gap-3 align-items-center'>
					<>
						<TheethDropdown
							disable={disable}
							index={index}
							setData={setChosenServices}
							all_data={chosenServices}
							data={item}
							type={type}
						/>
						<InputWithTitle
							title='*Кол.'
							item={item}
							isDisabled={disable}
							name={'count'}
							index={index}
							setValue={changeChosenServicesValue}
							placeholder='1'
							width='4rem'
							value={item.count}
						/>
						<InputWithTitle
							item={item}
							index={index}
							isDisabled={disable}
							name='price'
							title='*Сумма за 1х'
							placeholder='60,000'
							setValue={changeChosenServicesValue}
							value={item.price}
						/>
						<InputWithTitle
							name='amount'
							item={item}
							index={index}
							isDisabled={disable}
							title='*Общая сумма'
							placeholder='60,000'
							setValue={changeChosenServicesValue}
							value={item.amount ? item.amount : item.price * item.count}
						/>
					</>
				</div>
			)}
		</div>
	);
};
