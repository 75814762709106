import React, { FC } from 'react';

import ClinicOrganisationPage from './ClinicOrganisationComponents/ClinicOrganisationPage';
interface IClinicsOrganisations {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const ClinicsOrganisations:FC<IClinicsOrganisations> = ({search, setSearch}) => {
	return <ClinicOrganisationPage setSearch={setSearch} search={search} />;
};

export default ClinicsOrganisations;
