import React, { FC, useEffect, useState } from 'react';
import MaterialGroupsTable from './MaterialGroupsTable';
import { useQuery } from 'react-query';
import qs from 'qs';
import { PER_COUNT } from '../../../../../components/PaginationButtons';
import { IService } from '../../../../api/interface/service.interface';
import { UserService } from '../../../../api/service/user.service';
import { GroupService } from '../../../../../custom/api/service/group.service';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface IMaterialGroupsPage {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const MaterialGroupsPage: FC<IMaterialGroupsPage> = ({ search, setSearch }) => {
	const [serviceSort, setServiceSort] = useState<boolean>(false);

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	const [values, setValues] = useState<IService>({
		title_all: {
			en: '',
			arm: '',
			rus: '',
		},
	});

	const [filter, setFilter] = useState<IService>({
		clinic: '',
		officeType: '',
		MaterialGroups: '',
		responsibleUser: '',
	});

	const deboncedSearch = useDebounce(search);

	const { data: groups }: { data: any[] | any; isLoading: boolean } = useQuery(
		['groups', currentPage, perPage, deboncedSearch],
		() =>
			GroupService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						...(!!deboncedSearch && {
							websearchQuery: { searchVector: deboncedSearch },
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
		},
	);

	return (
		<>
			<MaterialGroupsTable
				isFluid
				setValues={setValues}
				values={values}
				groups={groups}
				isUserDoctor={isUserDoctor}
				setCurrentPage={setCurrentPage}
				setPerPage={setPerPage}
				currentPage={currentPage}
				perPage={perPage}
				filter={filter}
				setFilter={setFilter}
				sort={serviceSort}
				setSort={setServiceSort}
			/>
		</>
	);
};

export default MaterialGroupsPage;
