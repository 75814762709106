import React, { Dispatch, FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import dayjs, { Dayjs } from 'dayjs';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import PaginationButtons, { PER_COUNT } from '../../../components/PaginationButtons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as qs from 'qs';
import { VisitService } from '../../../custom/api/service/visit.service';
import { IVisit } from '../../../custom/api/interface/visit.interface';
import { UserService } from '../../../custom/api/service/user.service';
import { useDebounce } from '../../../hooks/useDebounce';
import TableComponent from '../../components/tables/TableComponent';
import { formatDate, getFontSizeFromLocalStorage } from '../../../utils/functions';
import { TColor } from '../../../type/color-type';
import VisitFilterComponent from './VisistPageComponents/FilterComponent/VisitFilterComponent';
import OffCanvasComponent from './OffCanvasComponent/OffCanvasComponent';
import EditVisitModal from './VisistPageComponents/EditVisitModal';
import ResestComponent from './VisistPageComponents/ResestComponent/ResestComponent';
import CancelVisitModal from './VisistPageComponents/Modals/CancelVisitModal';
import ActModal from './VisistPageComponents/Modals/ActModal';
import СreateActModal from './VisistPageComponents/Modals/CreateActModal';
import AuthContext from '../../../contexts/authContext';

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setDateDay: (value: React.SetStateAction<string>) => void;
	setAllDayButton(...args: unknown[]): unknown;
	allDayButton?: any;
	dateDay?: any;
	dateInterval?: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
	setDateInterval?: Dispatch<[Dayjs | null, Dayjs | null] | null>;
}

const VisitTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		setDateDay,
		dateDay,
		dateInterval,
		allDayButton,
		setAllDayButton,
		setDateInterval,
	}) => {
		const { actVisit, setActVisit, createActModal, setCreateActModal } =
			useContext(AuthContext);
		const [actModal, setActModal] = useState<boolean>(false);
		const [openCancelVisits, setOpenCancelVisits] = useState<boolean>(false);
		const [currentPage, setCurrentPage] = useState<number>(1);
		const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
		const [visitUuid, setVisitUuid] = useState<undefined | string>(undefined);
		const [updateVisitModal, setUpdateVisitModal] = useState<boolean>(false);
		const [visitUpdate, setVisitUpdate] = useState<IVisit>();
		const [eventAdding, setEventAdding] = useState(false);
		const [canvasData, setCanvasData] = useState<any>([]);
		const [searchIconClicked, setSearchIconClicked] = useState(false);
		const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
		const [canvasColor, setCanvasColor] = useState<TColor>('light');
		const [values, setValues] = useState<IVisit>({
			users: [],
			services: [],
			statuses: [],
			order: 'DESC',
			visitId: undefined,
			serviceName: '',
			userName: '',
		});

		const isSmallDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
		const isTablet = useMediaQuery({ query: '(max-width: 1200px)' });
		const isLargeDesktop = useMediaQuery({ query: '(min-width: 1620px)' });

		const { data: user_role } = useQuery(
			['user_role'],
			() => UserService.getProfile(qs.stringify({})),
			{ refetchOnWindowFocus: true },
		);

		const isMainDoctorOrDoctor = user_role?.roles?.some(
			(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
		);

		useEffect(() => {
			setIsUserDoctor(isMainDoctorOrDoctor);
		}, [isMainDoctorOrDoctor, user_role]);

		const changeStatus = async (data: IVisit) => {
			await mutate(data);
		};

		const handleOpenCancel = () => {
			setOpenCancelVisits(!openCancelVisits);
		};

		const queryClient = useQueryClient();

		const queryOptions = {
			onSuccess: (response: any, formData: any) => {
				queryClient.invalidateQueries('visits');
			},
			onError: (error: any) => {},
		};

		const { mutate } = useMutation(
			(data: IVisit) => VisitService.update(data.uuid, data),
			queryOptions,
		);

		const DebounsedSearch = useDebounce(values);

		const filterQuery = [
			{
				association: 'patient',
			},
			{
				association: 'services',
				// required: true,
				...(values?.services?.length && { where: { serviceUuid: values.services } }),
			},
			{
				association: 'services',
			},
			{
				association: 'canceledVisit',
			},
			{
				association: 'user',
				required: true,
				...(isUserDoctor && !!user_role && { where: { uuid: user_role?.uuid } }),
				...(!isUserDoctor && values.users.length && { where: { uuid: values.users } }),
				include: [
					{
						association: 'roles',
					},
				],
			},
			{
				association: 'canceledVisit',
			},

			{
				association: 'medicalCase',
				include: [
					{
						association: 'stage',
					},
				],
			},

			{
				association: 'clinic',
			},
			{
				association: 'estimate',
				include: [
					{
						association: 'act',
					},
					{
						association: 'services',
					},
				],
			},
		];

		const filterQueryMeta = {
			...(values?.visitSearch && {
				websearchQuery: { searchVectorVisit: values?.visitSearch },
			}),
			...(values.visitId && {
				or: [
					{ visitId: { iLike: `%${values.visitId}%` } },
					{ visitNumber: { iLike: `%${values.visitId}%` } },
				],
			}),
			...(values.statuses.length && { status: values.statuses }),
			visitDate: {
				...(!allDayButton &&
					(!!dateInterval && !!dateDay
						? {
								...(!!dateInterval[0] &&
									dateInterval[1] && {
										between: [
											`${dateInterval[0]?.format(
												'YYYY-MM-DD',
											)}T00:00:00.000Z`,
											`${dateInterval[1]?.format(
												'YYYY-MM-DD',
											)}T23:59:59.999Z`,
										],
									}),
						  }
						: {
								...(!!dateDay.date1 && {
									between: [
										`${dateDay.date1}T00:00:00.000Z`,
										`${dateDay.date1}T23:59:59.999Z`,
									],
								}),
						  })),
			},
		};

		const { data: visits }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
			[
				'visits',
				currentPage,
				perPage,
				DebounsedSearch,
				dateDay,
				allDayButton,
				dateInterval,
				isUserDoctor,
				// filterQuery,
				// filterQueryMeta,
			],
			() =>
				VisitService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
							limit: perPage,
							page: currentPage,
							order: { createdAt: 'DESC' },
						},
						includeMeta: filterQuery,
						filterMeta: filterQueryMeta,
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);
			
		const getRootFontSize = () =>
			parseFloat(getComputedStyle(document.documentElement).fontSize);
		const [dynamicFont, setDynamicFont] = useState(getRootFontSize());

		const updateFontSize = () => {
			const fontSizeValue = getFontSizeFromLocalStorage();
			setDynamicFont(fontSizeValue);
		};

		useEffect(() => {
			updateFontSize();
			window.addEventListener('fontSizeChange', updateFontSize);
			return () => {
				window.removeEventListener('fontSizeChange', updateFontSize);
			};
		}, []);

		useEffect(() => {
			if (canvasData?.status === 'Завершен') {
				setCanvasColor('success');
			} else if (canvasData?.status === 'Отменен' || canvasData?.status === 'Не оплачен') {
				setCanvasColor('danger');
			} else {
				setCanvasColor('info');
			}
		}, [canvasData]);

		const visitDropDownProps = {
			setEventAdding: setEventAdding,
			setCanvasData: setCanvasData,
			setUpdateVisitModal: setUpdateVisitModal,
			setActModal: setActModal,
			setActVisit: setActVisit,
			setCreateActModal: setCreateActModal,
			setVisitUpdate: setVisitUpdate,
			handleOpenCancel: handleOpenCancel,
			setVisitUuid: setVisitUuid,
			changeStatus: changeStatus,
		};
		const tableHeaders = [
			{ header: 'ID Визита', key: 'id' },
			{ header: 'Номер Визита', key: 'visitPill' },
			{ header: 'Дата и Время', key: 'datePill' },
			{ header: 'Пациент', key: 'nameWithAvatar' },
			{ header: 'Врач', key: 'name' },
			{ header: 'Жалоба', key: 'string' },
			{ header: 'Статус', key: 'status' },
			{ header: 'Действие', key: 'action' },
		];

		const reset = useCallback(() => {
			setAllDayButton(false);
			setValues({
				users: [],
				services: [],
				statuses: [],
				order: 'DESC',
				visitId: undefined,
				serviceId: undefined,
				userName: '',
			});
			setDateDay(formatDate!(new Date()));
			if (setDateInterval) setDateInterval(null);
			setAllDayButton(false);
			localStorage.setItem('datePicker', 'null');
			localStorage.setItem('dateOfVisit', JSON.stringify(new Date()));
		}, [setAllDayButton, setDateDay, setDateInterval]);

		return (
			<>
				<Card stretch={isFluid}>
					<div className='table-row-header'>
						<div className='d-flex flex-row align-items-center w-full grow'>
							<VisitFilterComponent
								values={values}
								isLargeDesktop={isLargeDesktop}
								isSmallDesktop={isSmallDesktop}
								isUserDoctor={isUserDoctor}
								isTablet={isTablet}
								searchIconClicked={searchIconClicked}
								setValues={setValues}
								setSearchIconClicked={setSearchIconClicked}
								canvasColor={canvasColor}
								dynamicFont={dynamicFont}
							/>
						</div>
						<div>
							<ResestComponent reset={reset} isSmallDesktop={isSmallDesktop} />
						</div>
					</div>
					<CardBody className='table-responsive' isScrollable={isFluid}>
						<TableComponent
							data={visits?.data}
							headers={tableHeaders}
							visitDropDownProps={visitDropDownProps}
						/>
					</CardBody>
					<PaginationButtons
						data={visits || []}
						label='customers'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
						totalPage={Math.ceil(visits?.meta?.count / perPage) || 1}
					/>
				</Card>
				<CancelVisitModal
					visitUuid={visitUuid}
					setIsOpen={setOpenCancelVisits}
					isOpen={openCancelVisits}
					id='0'
				/>
				<OffCanvasComponent
					setEventAdding={setEventAdding}
					eventAdding={eventAdding}
					canvasData={canvasData}
					canvasColor={canvasColor}
				/>
				<ActModal
					setActVisit={setActVisit}
					setIsOpen={setActModal}
					isOpen={actModal}
					visit={actVisit}
				/>
				<СreateActModal
					setIsOpen={setCreateActModal}
					isOpen={createActModal}
					visit={actVisit}
					setActVisit={setActVisit}
					estimate={actVisit?.estimate}
				/>
				<EditVisitModal
					setUpdateVisitModal={setUpdateVisitModal}
					updateVisitModal={updateVisitModal}
					visitUpdate={visitUpdate}
					setVisitUpdate={setVisitUpdate}
				/>
			</>
		);
	},
);
VisitTable.defaultProps = {
	isFluid: false,
};
export default VisitTable;
