import Tooltips from '../../../../../components/bootstrap/Tooltips';
import React, { FC, memo } from 'react';

interface IUserInfoProps {
	user: any;
}

const UserInfo: FC<IUserInfoProps> = memo(({ user }) => {
	return (
		<React.Fragment>
			{user?.name?.length + user?.surname?.length + user?.middleName?.length + 3 >
			30 ? (
				<Tooltips
					className='fs-4'
					title={`${user?.name} ${user?.surname} ${user?.middleName}`}>
					<span
						style={{ width: '50%' }}
						className='display-4 fw-bold me-3 fs-1 text-truncate'>
						{user?.surname ?? 'не указан'} {user?.name ?? 'не указан'}{' '}
						{user?.middleName ?? 'не указан'}{' '}
					</span>
				</Tooltips>
			) : (
				<span className='display-4 fw-bold me-3 fs-1'>
					{user?.surname ?? 'не указан'} {user?.name ?? 'не указан'}{' '}
					{user?.middleName ?? 'не указан'}{' '}
				</span>
			)}
		</React.Fragment>
	);
});

export default UserInfo;
