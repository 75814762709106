import React, { FC, useContext } from 'react';
import Button from '../../../../../../components/bootstrap/Button';
import Popovers from '../../../../../../components/bootstrap/Popovers';
import ThemeContext from '../../../../../../contexts/themeContext';

interface IFullScreenItemProps {
	styledBtn: any;
}

const FullScreenItem: FC<IFullScreenItemProps> = ({ styledBtn }) => {
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);

	return (
		<div className='col-auto'>
			<Popovers trigger='hover' desc='Полноэкранный режим'>
				<Button
					{...styledBtn}
					icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
					onClick={() => setFullScreenStatus(!fullScreenStatus)}
					aria-label='Toggle fullscreen'
				/>
			</Popovers>
		</div>
	);
};

export default FullScreenItem;
