import React, { FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import Button from '../../../../../../components/bootstrap/Button';
import AuthContext from '../../../../../../contexts/authContext';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../../components/bootstrap/Dropdown';
import Popovers from '../../../../../../components/bootstrap/Popovers';

interface IFontItemProps {
	styledBtn: any;
}

const small = { name: 'Маленький', value: 13 };
const normal = { name: 'Нормальный', value: 16 };
const large = { name: 'Большой', value: 20 };
const extraLarge = { name: 'Очень большой', value: 24 };

const FontItem: FC<IFontItemProps> = memo(({ styledBtn }) => {
	const { fontSize, setFontSize } = useContext(AuthContext);
	const [fonSizeChange, setFontSizeChange] = useState(false);

	const setAndSaveFontSize = useCallback(
		(fontSizeName: string, fontSize: number) => {
			setFontSize(fontSizeName);
			const jsonItem = JSON.stringify({ name: fontSizeName, value: fontSize });
			localStorage.setItem('fontSize', jsonItem);
			const event = new CustomEvent('fontSizeChange', { detail: jsonItem });
			window.dispatchEvent(event);
		},
		[setFontSize],
	);

	useEffect(() => {
		if (fontSize === small.name) {
			document.documentElement.style.fontSize = `${small.value}px`;
			document.documentElement.style.transition = '0.3s';
		}

		if (fontSize === normal.name) {
			document.documentElement.style.fontSize = `${normal.value}px`;
			document.documentElement.style.transition = '0.3s';
		}

		if (fontSize === large.name) {
			document.documentElement.style.fontSize = `${large.value}px`;
			document.documentElement.style.transition = '0.3s';
		}

		if (fontSize === extraLarge.name) {
			document.documentElement.style.fontSize = `${extraLarge.value}px`;
			document.documentElement.style.transition = '0.3s';
		}
	}, [fonSizeChange, fontSize]);

	return (
		<div className='d-flex justify-content-start col-auto'>
			<Dropdown isOpen={fonSizeChange} setIsOpen={setFontSizeChange}>
				<DropdownToggle>
					<>
						<Popovers
							trigger='hover'
							desc={fonSizeChange ? 'Уменьшить шрифт' : 'Увеличить шрифт'}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								onClick={() => setFontSizeChange((prev: boolean) => !prev)}
								icon='TextFormat'
								aria-label='fontsize'
								size={'lg'}
							/>
						</Popovers>
					</>
				</DropdownToggle>
				<DropdownMenu
					className='d-flex col-12 flex-column justify-content-around align-items-center w-100'
					style={{ minWidth: '12rem', padding: '15px 10px' }}>
					<Button
						className='fw-bold text-nowrap  mt-2  fs-6 w-100'
						isOutline={true}
						color={fontSize === small.name ? 'primary' : 'info'}
						isLight={fontSize === small.name}
						onClick={() => {
							setAndSaveFontSize(small.name, small.value);
						}}>
						Маленький
					</Button>

					<Button
						className='fw-bold text-nowrap mt-2 fs-6 w-100'
						isOutline={true}
						color={fontSize === normal.name ? 'primary' : 'info'}
						isLight={fontSize === normal.name}
						onClick={() => {
							setAndSaveFontSize(normal.name, normal.value);
						}}>
						Нормальный
					</Button>

					<Button
						className='fw-bold text-nowrap  mt-2  fs-6 w-100'
						isOutline={true}
						color={fontSize === large.name ? 'primary' : 'info'}
						isLight={fontSize === large.name}
						onClick={() => {
							setAndSaveFontSize(large.name, large.value);
						}}>
						Большой
					</Button>

					<Button
						className='fw-bold text-nowrap  mt-2  fs-6 w-100'
						isOutline={true}
						color={fontSize === extraLarge.name ? 'primary' : 'info'}
						isLight={fontSize === extraLarge.name}
						onClick={() => {
							setAndSaveFontSize(extraLarge.name, extraLarge.value);
						}}>
						Очень большой
					</Button>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
});

export default FontItem;
