import { authHost } from '../axios';
import { IClinic } from '../interface/clinic.interface';
import { CommonEntity } from '../interface/common-entity';

export class ClinicOrganisationService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query?: any): Promise<any | null> {
		const { data } = await authHost.get(`clinic_organisation?${query}`);
		return data;
	}

	static async findOne(uuid: string, query?: any) {
		const { data } = await authHost.get(`clinic_organisation/${uuid}?${query}`);
		return data;
	}

	static async create(data: CommonEntity) {
		const clinic_organisation = await authHost.post('clinic_organisation', data);
		return clinic_organisation;
	}

	static async update(uuid: string, data: IClinic) {
		const clinic_organisation = await authHost.patch(`clinic_organisation/${uuid}`, data);
		return clinic_organisation;
	}

	static async delete(uuid: string) {
		const clinic_organisation = await authHost.delete(`clinic_organisation/${uuid}`);
		return clinic_organisation;
	}
}
