import React, { FC, useContext, useState } from 'react';
import Button from '../../../../components/bootstrap/Button';
import Dropdown, { DropdownToggle, DropdownMenu } from '../../../../components/bootstrap/Dropdown';
import DentalContextFunctions from '../../../../contexts/dentalContextFunctions';
import HighlightedTitle from '../../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { TheethDropdownItems } from './TheethDropdown/TheethDropdownItems';
import { TeethRectangle } from '../../visit/VisistPageComponents/Modals/ActComponents/UI/TeethRectangle';

type ItemType =
	| 'bridge'
	| 'referral'
	| 'implant'
	| 'event'
	| 'deletion'
	| 'milk_theeth'
	| 'empty'
	| 'cosmetology'
	| 'crown'
	| 'lor';

interface ITheethDropdownProps {
	index: number;
	data: any;
	type: ItemType;
	disable?: boolean;
	all_data: any;
	setData: any;
}

export const TheethDropdown: FC<ITheethDropdownProps> = ({
	index,
	data,
	setData,
	disable,
	all_data,
	type,
}) => {
	const { contextHolder } =
		useContext(DentalContextFunctions);

	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div style={{ position: 'relative' }}>
			<span>{contextHolder}</span>
			<HighlightedTitle title={'*Зуб'} />
			<Dropdown
				isOpen={disable ? false : isOpen}
				setIsOpen={setIsOpen}
				className='border rounded-1'>
				<DropdownToggle>
					<Button
						className='m-0 d-flex justify-content-center gap-2 align-items-center'
						isLight>
						<TeethRectangle data={data} />
					</Button>
				</DropdownToggle>
				<DropdownMenu
					aria-disabled={disable}
					style={{ border: '1px solid #E7E7E7' }}
					isAlignmentEnd>
					<TheethDropdownItems
						index={index}
						data={data}
						type={type === 'crown' ? 'bridge' : type}
						all_data={all_data}
						setData={setData}
						setIsOpen={setIsOpen}
					/>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};
