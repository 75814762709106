import React, { SVGProps } from "react";


export const PRINT_TOOTH_11 = (props: SVGProps<SVGSVGElement>) => {
  return (   
<svg   width="36" height="100" viewBox="0 0 20 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 11">
<path id="Vector 70" d="M18.004 42.2C19.1048 29.3663 15.549 7.78508 7.82251 1.46395C6.67484 0.525027 5.21533 2.23904 4.66178 4.27334L2.19995 42.2" stroke="black" strokeWidth="0.7"/>
<path id="Vector 17" d="M5.03466 57.2H17.4725C18.8545 57.2 19.2 55.7882 19.2 55.0823C19.2 54.3765 18.8545 33.2 9.52609 33.2C0.197731 33.2 1.23421 51.9059 1.23421 53.3176C1.23421 54.7294 3.65268 57.2 5.03466 57.2Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>

  )
}



