import React, { SVGProps } from "react";


export const PRINT_TOOTH_44 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 18 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M1.6001 15.9382C3.42036 43.5993 5.43115 46.9413 9.2622 48.7231C13.0933 50.5049 16.941 20.7996 16.576 13.8" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M7.73379 19.8C-0.362533 18.1865 -0.725721 8.25569 2.48707 3.59604C4.21603 1.08846 6.47654 0.427944 13.3746 0.983362C14.7064 0.983362 19.3807 3.28923 16.8723 12.9511C15.3074 18.9787 10.4455 19.8 7.73379 19.8Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}

