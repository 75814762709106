/* eslint-disable array-callback-return */
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import PaginationButtons, { dataPagination } from '../../../../../components/PaginationButtons';
import { IService } from '../../../../../custom/api/interface/service.interface';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { ServicesListButton } from '../../components/ServicesListButton';
import Input from '../../../../../components/bootstrap/forms/Input';
import { itemIndexInArray } from '../../../../../utils/functions';

interface IHeaderProps {
	setChoosenService(...args: unknown[]): unknown;
	setLocalChosenService(...args: unknown[]): unknown;
	localChoosenServices: any;
	choosenServices: any;
	createVisit: any;
	isStage: any;
	values: any;
	servicesPerPage: any;
	handleCheckboxChange(...args: unknown[]): unknown;
	hendelDeleteService(...args: unknown[]): unknown;
	setServicesPerPage(...args: unknown[]): unknown;
	visits_services: any;
}

const ChoosenServices: FC<IHeaderProps> = memo(
	({
		handleCheckboxChange,
		hendelDeleteService,
		choosenServices,
		setChoosenService,
		localChoosenServices,
		setLocalChosenService,
		createVisit,
		isStage,
		servicesPerPage,
		values,
		setServicesPerPage,
		visits_services,
	}) => {
		const [serviceNameChosenArray, setServiceNameChosenArray] = useState([]);
		const [arryLength, setArrayLength] = useState<number>(0);
		const [arryLengthFilter, setArrayLengthFilter] = useState<number>(0);
		const [servicesChosenCurrentPage, setServicesChosenCurrentPage] = useState<number>(1);
		const [servicesChosenCurrentFilterPage, setServicesChosenCurrentFilterPage] =
			useState<number>(1);
		const DebounsedSearchFilterLocal = useDebounce(values.serviceNameChosen);

		useEffect(() => {
			const totalPages = Math.ceil(choosenServices?.length / servicesPerPage) || 1;
			setArrayLength(totalPages);
			if (servicesChosenCurrentPage > totalPages) {
				setServicesChosenCurrentPage(totalPages);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [choosenServices, servicesPerPage, values?.serviceNameChosen]);

		useEffect(() => {
			const totalFilterPages =
				Math.ceil(serviceNameChosenArray?.length / servicesPerPage) || 1;
			setArrayLengthFilter(totalFilterPages);
			if (servicesChosenCurrentFilterPage > totalFilterPages) {
				setServicesChosenCurrentFilterPage(totalFilterPages);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [serviceNameChosenArray, servicesPerPage, values?.serviceNameChosen]);

		useEffect(() => {
			if (choosenServices?.length === servicesPerPage) {
				setServicesChosenCurrentPage(1);
			}
		}, [choosenServices?.length, servicesChosenCurrentFilterPage, servicesPerPage]);

		useEffect(() => {
			if (serviceNameChosenArray.length === servicesPerPage) {
				setServicesChosenCurrentFilterPage(1);
			}
		}, [
			choosenServices.length,
			serviceNameChosenArray.length,
			servicesChosenCurrentFilterPage,
			servicesPerPage,
		]);
		useEffect(() => {
			if (values?.serviceNameChosen) {
				let filteredArray = [] as any;
				if (/\d/.test(values?.serviceNameChosen)) {
					choosenServices?.filter((item: any) => {
						item?.serviceId?.includes(values?.serviceNameChosen);
					});
				} else {
					filteredArray = choosenServices?.filter(
						(item: any) => item?.title?.includes(values?.serviceNameChosen),
					);
				}
				setServiceNameChosenArray(JSON.parse(JSON.stringify(filteredArray)));
			}
		}, [DebounsedSearchFilterLocal, choosenServices, values?.serviceNameChosen]);

		const changeChosenServicesValue = useCallback(
			(key: string, value: string | number, uuid: string) => {
				setChoosenService((prev: any) => {
					const updatedServices = prev.map((item: any) => {
						if (uuid === item.event_uuid) {
							if (key === 'price') {
								// If the key is "price", calculate amount by multiplying item.count by value
								return {
									...item,
									[key]: value,
									amount: item.count * Number(value),
								};
							} else if (key === 'count') {
								// If the key is "count", calculate price by multiplying item.price by value
								return {
									...item,
									[key]: value,
									amount: item.price * Number(value),
								};
							}
							return { ...item, [key]: value }; // Default case if it's neither "price" nor "count"
						}
						return item;
					});

					return updatedServices;
				});
			},
			[setChoosenService],
		);

		return (
			<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
				<span className='fs-5 fw-bold'>Выбранные Услуги</span>
				<div className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
					<div className='d-flex flex-column flex-wrap col-12 gap-2'>
						<FormGroup
							id='surname'
							label='Введите название или код услуги'
							className='col-12'>
							<Input
								name='serviceNameChosen'
								type={'search'}
								onChange={(e: any) => handleCheckboxChange(e)}
								value={values?.serviceNameChosen}
							/>
						</FormGroup>
						{DebounsedSearchFilterLocal ? (
							serviceNameChosenArray.length ? (
								JSON.parse(
									JSON.stringify(
										dataPagination(
											serviceNameChosenArray,
											servicesChosenCurrentFilterPage,
											servicesPerPage,
										),
									),
								).map((i: IService, index: number) => (
									<ServicesListButton
										key={i.event_uuid}
										localChosenServices={localChoosenServices}
										setLocalChosenServices={setLocalChosenService}
										type={i?.service_type}
										changeChosenServicesValue={changeChosenServicesValue}
										chosenServices={choosenServices}
										setChosenServices={setChoosenService}
										index={itemIndexInArray(serviceNameChosenArray, i)}
										item={i}
										isDisabled={
											!createVisit &&
											!isStage &&
											visits_services?.data.some(
												(item: any) =>
													item?.services.some(
														(service: any) => service?.uuid === i?.uuid,
													),
											)
										}
										handleDeleteService={hendelDeleteService}
									/>
								))
							) : (
								<h4>Услуги не найдены</h4>
							)
						) : choosenServices ? (
							JSON.parse(
								JSON.stringify(
									dataPagination(
										choosenServices,
										servicesChosenCurrentPage,
										servicesPerPage,
									),
								),
							).map((i: IService, index: number) => (
								<ServicesListButton
									key={i.event_uuid}
									type={i?.service_type}
									localChosenServices={localChoosenServices}
									setLocalChosenServices={setLocalChosenService}
									chosenServices={choosenServices}
									changeChosenServicesValue={changeChosenServicesValue}
									setChosenServices={setChoosenService}
									index={itemIndexInArray(choosenServices, i)}
									item={i}
									isDisabled={
										!createVisit &&
										!isStage &&
										visits_services?.data.some(
											(item: any) =>
												item?.services.some(
													(service: any) => service?.uuid === i?.uuid,
												),
										)
									}
									handleDeleteService={hendelDeleteService}
								/>
							))
						) : (
							<h4>Услуги не найдены</h4>
						)}
					</div>
					{!values?.serviceNameChosen &&
						serviceNameChosenArray.length <= 0 &&
						choosenServices?.length > 0 && (
							<div className='col-12'>
								<PaginationButtons
									data={choosenServices || []}
									label='customers'
									small
									filterPagination
									serviceModal
									setCurrentPage={setServicesChosenCurrentPage}
									currentPage={servicesChosenCurrentPage}
									perPage={servicesPerPage}
									setPerPage={setServicesPerPage}
									totalPage={arryLength}
								/>
							</div>
						)}
					{values?.serviceNameChosen && serviceNameChosenArray.length > 0 && (
						<div className='col-12'>
							<PaginationButtons
								data={serviceNameChosenArray || []}
								label='customers'
								small
								filterPagination
								serviceModal
								setCurrentPage={setServicesChosenCurrentFilterPage}
								currentPage={servicesChosenCurrentFilterPage}
								perPage={servicesPerPage}
								setPerPage={setServicesPerPage}
								totalPage={arryLengthFilter}
							/>
						</div>
					)}
				</div>
			</div>
		);
	},
);

export default ChoosenServices;
