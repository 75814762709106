import React, { SVGProps } from "react";

export const THEETH_36 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg xmlns="http://www.w3.org/2000/svg" width="2.92rem" height="5.46rem" viewBox="0 0 38 71" fill="none" {...props}>
<path d="M0.22059 63.9329C-0.660962 57.8829 10.2962 63.9293 7.02077 19.3794C13.0452 12.9627 27.4354 0.516172 34.1359 11.9562C34.7491 22.4834 33.2843 66.8426 17.149 69.9805C15.518 70.2977 14.6896 68.3867 15.53 66.9534C20.707 58.1231 23.7093 44.2719 20.9665 40.8498C16.1174 42.4998 19.8665 61.1998 0.22059 63.9329Z" fill="url(#paint0_linear_2354_11125)"/>
<g filter="url(#filter0_f_2354_11125)">
<path d="M25.5415 44.9153C25.7118 42.6225 26.5515 38.3162 29.0991 26.2366C29.0991 26.2366 24.2074 32.9075 23.3181 31.5734C22.4287 30.2392 20.6499 22.6787 20.6499 22.6787C19.034 32.843 17.2316 37.7434 11.7559 44.4706" stroke="#F3F3F3" strokeWidth="2.66829"/>
</g>
<path d="M7.71781 14.2856C7.56314 15.9459 22.2502 53.3282 1.07053 61.9286C0.81492 62.0324 0.586562 61.7255 0.762623 61.5131C10.8166 49.3842 7.19253 30.1385 6.41797 16.4522L7.71781 14.2856C7.72134 14.2478 7.73255 14.2285 7.75207 14.2285L7.71781 14.2856Z" fill="#D9D9D9"/>
<path d="M7.71781 14.2856C7.56314 15.9459 22.2502 53.3282 1.07053 61.9286C0.81492 62.0324 0.586562 61.7255 0.762623 61.5131C10.8166 49.3842 7.19253 30.1385 6.41797 16.4522L7.71781 14.2856C7.72134 14.2478 7.73255 14.2285 7.75207 14.2285L7.71781 14.2856Z" fill="url(#paint1_linear_2354_11125)"/>
<path d="M36.8317 10.134C36.3986 2.4481 33.3667 2.99709 22.9714 6.29115C19.5062 2.77765 15.1749 5.19313 13.4424 6.84005C11.7099 5.19309 4.34677 2.99709 6.51223 20.0159C11.3634 29.6782 21.2389 25.5059 25.5702 22.2119C28.169 22.3949 33.6265 22.6511 34.6661 22.2119C35.9655 21.6629 37.2649 17.8199 36.8317 10.134Z" fill="url(#paint2_linear_2354_11125)"/>
<path d="M36.8317 10.134C36.3986 2.4481 33.3667 2.99709 22.9714 6.29115C19.5062 2.77765 15.1749 5.19313 13.4424 6.84005C11.7099 5.19309 4.34677 2.99709 6.51223 20.0159C11.3634 29.6782 21.2389 25.5059 25.5702 22.2119C28.169 22.3949 33.6265 22.6511 34.6661 22.2119C35.9655 21.6629 37.2649 17.8199 36.8317 10.134Z" fill="url(#paint3_radial_2354_11125)" fillOpacity="0.3"/>
<g filter="url(#filter1_f_2354_11125)">
<path d="M14.9568 12.5236C15.5362 15.4206 12.7909 14.5515 11.1911 14.5515C9.59123 14.5515 10.0323 13.2242 10.0323 11.9442C10.0323 10.6642 14.5157 9.33691 16.1155 9.33691C17.7154 9.33691 16.6949 11.6545 14.9568 12.5236Z" fill="#FDFDFD"/>
</g>
<g filter="url(#filter2_f_2354_11125)">
<path d="M13.5347 6.22363C12.1717 7.55362 11.6218 8.37414 11.7559 10.2262" stroke="black" strokeWidth="0.889432"/>
</g>
<g filter="url(#filter3_f_2354_11125)">
<path d="M23.3374 5.7793C21.9744 7.10928 21.4246 7.9298 21.5586 9.78188" stroke="black" strokeWidth="0.889432"/>
</g>
<defs>
<filter id="filter0_f_2354_11125" x="3.60525" y="15.2576" width="33.9145" height="37.1708" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3.55773" result="effect1_foregroundBlur_2354_11125"/>
</filter>
<filter id="filter1_f_2354_11125" x="5.52941" y="4.88976" width="15.8318" height="14.2312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.22358" result="effect1_foregroundBlur_2354_11125"/>
</filter>
<filter id="filter2_f_2354_11125" x="5.9554" y="0.568684" width="13.2269" height="15.0262" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.66829" result="effect1_foregroundBlur_2354_11125"/>
</filter>
<filter id="filter3_f_2354_11125" x="15.7581" y="0.124348" width="13.2269" height="15.0262" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.66829" result="effect1_foregroundBlur_2354_11125"/>
</filter>
<linearGradient id="paint0_linear_2354_11125" x1="15.9258" y1="7.2998" x2="15.9258" y2="69.9998" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_2354_11125" x1="10.8868" y1="18.9621" x2="4.71179" y2="54.7304" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_2354_11125" x1="21.5169" y1="4" x2="21.5169" y2="26" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_2354_11125" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.917 12.7839) rotate(51.7279) scale(4.19572 6.0852)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>
        </>
	);
};