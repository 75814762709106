import { authHost } from '../axios';
import { IAct, IEstimate, IPayment, IPayPayment } from '../interface/act.interface';

export class ActService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<IAct[] | null> {
		const { data } = await authHost.get(`act?${query}`);
		return data;
	}

	static async findOne(uuid: string, query?: any) {
		const { data } = await authHost.get(`act/${uuid}?${query}`);
		return data;
	}
	static async getActByEstimate(uuid: string) {
		const { data } = await authHost.get(`act/getActByEstimate/${uuid}`);
		return data;
	}

	static async create(data: IAct) {
		const act = await authHost.post('act', data);
		return act;
	}

	static async createPayment(data: IPayment) {
		const act = await authHost.post('payment', data);
		return act;
	}

	static async payPayment(data: IPayPayment) {
		const act = await authHost.post('payment/payPayment', data);
		return act;
	}

	static async getPayment(query: any) {
		const act = await authHost.get(`payment?${query}`);
		return act;
	}

	static async createEstimate(data: IEstimate) {

		const act = await authHost.post('estimate', data);
		return act;
	}

	static async update(uuid: string, data: IAct) {
		const act = await authHost.patch(`act/${uuid}`, data);
		return act;
	}

	static async actFilterCount(query: any) {
		const act = await authHost.get(`act/filterAmount?${query}`);
		return act;
	}
}
