/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UserService } from '../../../custom/api/service/user.service';
import { PatientService } from '../../../custom/api/service/patient.service';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import * as qs from 'qs';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';

type NotificationPlacement = NotificationArgsProps['placement'];
interface IDeleteSubmitModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	title: string;
	patient: boolean;
	uuid?: string;
}

const DeleteSubmitModal: FC<IDeleteSubmitModalProps> = ({
	isOpen,
	setIsOpen,
	title,
	patient,
	uuid,
}) => {
	const queryClient = useQueryClient();

	const [values, setValues] = useState({
		clinicUuid: '',
	});

	const [api, contextHolder] = notification.useNotification();

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
			className: 'ant-notification-custom',
			props: { style: { zIndex: 150000 } },
		});
	};

	const [isSuperAdmin, setIsSuperAdmin] = useState(false);

	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(
			qs.stringify({
				includeMeta: [
					{
						association: 'roles',
						includes: [
							{
								association: 'clinics',
							},
						],
					},
				],
			}),
		),
	);

	const { data: user } = useQuery(
		['user', uuid],
		() =>
			UserService.findOne(
				uuid ?? '',
				qs.stringify({
					includeMeta: [
						{
							association: 'roles',
							include: [
								{
									association: 'clinics',
								},
							],
						},
					],
				}),
			),
		{
			enabled: !!uuid,
			refetchOnWindowFocus: true,
		},
	);

	useEffect(() => {
		if (user_role?.roles?.find((i: any) => i.role === 'superAdmin')) {
			setIsSuperAdmin(true);
		}
		setValues((prev: any) => ({
			...prev,
			clinicUuid: '',
		}));
	}, [user_role]);

	const { mutate: deleteUser } = useMutation(
		({ uuid, clinicUuid }: { uuid: string; clinicUuid: string }) =>
			UserService.delete(uuid, clinicUuid),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('admins');
				queryClient.invalidateQueries('workers');
				queryClient.invalidateQueries('users');
				setIsOpen(false);
			},
			onError: (error: any) => {
				if (error.response.data.message === 'wrong_role_to_delete')
					openNotification('bottomRight', 'Важно !', 'Вы не можете удалить пользователя');

				if (error.response.data.message === 'admin_cant_delete_user_from_other_clinic')
					openNotification(
						'bottomRight',
						'Важно !',
						'Админ не может удалить роль пользователя из другого филиала',
					);

				if (error.response.data.message === 'super_admin_must_provide_clinic')
					openNotification(
						'bottomRight',
						'Важно !',
						'Супер Администратор должен выбрать филиал',
					);

				if (error.response.data.message === 'user_not_in_provided_clinic')
					openNotification(
						'bottomRight',
						'Важно !',
						'Пользователь не зарегистрирован в выбранном филиале',
					);

				if (error.response.data.message === 'super_admin_cant_be_deleted_super_admin')
					openNotification(
						'bottomRight',
						'Важно !',
						'Супер админ не может удалить Супер админа',
					);

				if (error.response.data.message === 'admin_cant_delete_super_admin')
					openNotification(
						'bottomRight',
						'Важно !',
						'Админ не может удалить Супер админа',
					);

				if (error.response.data.message === 'admin_cant_delete_admin')
					openNotification('bottomRight', 'Важно !', 'Админ не может удалить админа');
			},
		},
	);

	const { mutate: deletePatient } = useMutation((uuid: string) => PatientService.delete(uuid), {
		onSuccess: () => {
			queryClient.invalidateQueries('patients');
			setIsOpen(false);
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (uuid && !patient) {
			deleteUser({ uuid, clinicUuid: values.clinicUuid });
		}
		if (uuid && patient) {
			deletePatient(uuid);
		}
	};


	return (
		<>
			<Modal isStaticBackdrop isCentered isOpen={isOpen} setIsOpen={setIsOpen}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-5 fw-bold'>
						<Icon icon='Payment' className='me-2 fs-2' color='primary' />
						Подтверждение удаления
					</span>
				</ModalHeader>
				<form onSubmit={handleSubmit}>
					<ModalBody className='px-4'>
						<span>{contextHolder}</span>
						<p className='fs-4 '>Вы уверены что хотите удалить {title} ?</p>
						{isSuperAdmin && !patient && (
							<FormGroup label={'Филиал'} className='col-3 fs-6'>
								<div className='col-11'>
									<select
										onChange={(e: any) =>
											setValues((prev: any) => ({
												...prev,
												clinicUuid: e.target.value,
											}))
										}
										value={values.clinicUuid}
										name='uuid'
										className='form-select'>
										{user?.roles?.map((clinic: any) => (
											<option value={clinic?.clinics?.uuid}>
												{clinic?.clinics?.name}
											</option>
										))}
										<option value='' hidden selected></option>
									</select>
								</div>
							</FormGroup>
						)}
					</ModalBody>
					<ModalFooter>
						<div className='d-flex flex-row col-12 gap-3 px-3'>
							<Button
								isDisable={!values.clinicUuid}
								color='danger'
								className='col-6'
								onClick={() => {
									setIsOpen(false);
								}}>
								Нет
							</Button>
							<Button color={'success'} type='submit' className='col-6'>
								Да
							</Button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default DeleteSubmitModal;
