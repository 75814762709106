import React, { FC } from 'react';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import HighlightedTitle from '../../../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { CardBody } from '../../../../../components/bootstrap/Card';

interface IAddMaterialGroups {
	setValues(...args: unknown[]): unknown;
	values: any;
}

const AddMaterialGroups: FC<IAddMaterialGroups> = ({ setValues, values }) => {
	const changeValues = (key: string, value: any) => {
		setValues((prev: any) => ({
			...prev,
			title_all: {
				...prev.title_all,
				[key]: value,
			},
		}));
	};

	return (
		<div className={'col-12'}>
			<CardBody style={{ backgroundColor: '#DBEAFF', borderRadius: 12 }}>
				<div className='p-1 ' style={{ maxWidth: '100%', overflow: 'auto' }}>
					<div className='col-12 d-flex flex-column  gap-2'>
						<HighlightedTitle title={'*Название Специализации'} />

						<FormGroup label='RUS' className='col-12'>
							<Input
								type='text'
								onChange={(e: any) => changeValues('rus', e.target.value)}
								value={values.title_all.rus}
							/>
						</FormGroup>
						<FormGroup label='ARM' className='col-12'>
							<Input
								type='text'
								onChange={(e: any) => changeValues('arm', e.target.value)}
								value={values.title_all.arm}
							/>
						</FormGroup>
						<FormGroup label='ENG' className='col-12'>
							<Input
								type='text'
								onChange={(e: any) => changeValues('en', e.target.value)}
								value={values.title_all.en}
							/>
						</FormGroup>
					</div>
				</div>
			</CardBody>
		</div>
	);
};

export default AddMaterialGroups;
