import React, { FC, useCallback } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import Icon from '../../../../../components/icon/Icon';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Card, { CardBody, CardLabel, CardTitle } from '../../../../../components/bootstrap/Card';
import { formatNotDate } from '../../../../../utils/functions';
import Tooltips from '../../../../../components/bootstrap/Tooltips';

interface INotificationComponentItemItemProps {
	not: any;
	readNots(...args: unknown[]): unknown;
	readNot(...args: unknown[]): unknown;
	clearNots(...args: unknown[]): unknown;
	deleteNot(...args: unknown[]): unknown;
	chnageStatusVisit(...args: unknown[]): unknown;
	setActVisit(...args: unknown[]): unknown;
	setCreateActModal(...args: unknown[]): unknown;
}
const NotificationComponentItem: FC<INotificationComponentItemItemProps> = ({
	not,
	readNots,
	deleteNot,
	clearNots,
	chnageStatusVisit,
	setCreateActModal,
	setActVisit,
	readNot,
}) => {
	const { themeStatus } = useDarkMode();

	const formatDateRange = (isoStart: string, isoEnd: string) => {
		const start = new Date(isoStart);
		const end = new Date(isoEnd);
		const formattedDate = start.toLocaleDateString('en-GB').replace(/\//g, '.');
		const formattedStartTime = start.toTimeString().slice(0, 5);
		const formattedEndTime = end.toTimeString().slice(0, 5);
		return `${formattedDate}, ${formattedStartTime}-${formattedEndTime}`;
	};

	const handelOpenAct = useCallback(() => {
		setActVisit(not.visit);
		setCreateActModal(true);
	}, [not.visit, setActVisit, setCreateActModal]);
	const isReadNot = async (uuid: string) => {
		await readNot(uuid);
	};

	const ButtonStyles = () => {
		if (
			not?.type === 'В ожидании' &&
			not?.title === 'Пациент в ожидании' &&
			not?.visit.status === 'В ожидании'
		) {
			return (
				<Button
					onClick={() =>
						chnageStatusVisit({ uuid: not?.visit?.uuid, status: 'В процессе' })
					}
					className='d-flex rounded-3 align-items-center gap-2'
					style={{
						border: '1px solid #664D03',
						backgroundColor: 'inherit',
						padding: '4px 8px 4px 8px',
					}}>
					<span style={{ color: '#664D03' }}>Начать прием</span>
					<Icon style={{ color: '#664D03' }} icon={'East'} size={'lg'} />
				</Button>
			);
		}
		if (
			not?.type === 'Задолженность' &&
			(not?.title === 'Задолженность' || not?.title === 'Пациент частично оплатил')
		) {
			return (
				<Button
					onClick={handelOpenAct}
					className='d-flex rounded-3 align-items-center gap-2'
					style={{
						border: '1px solid #BA0092',
						backgroundColor: 'inherit',
						padding: '4px 8px 4px 8px',
					}}>
					<span style={{ color: '#BA0092' }}>Посмотреть акт</span>
					<Icon style={{ color: '#BA0092' }} icon={'East'} size={'lg'} />
				</Button>
			);
		}

		if (not?.type === 'Задолженность' && not?.title === 'Акт создан') {
			return (
				<Button
					onClick={handelOpenAct}
					className='d-flex rounded-3 align-items-center gap-2'
					style={{
						border: '1px solid #664D03',
						backgroundColor: 'inherit',
						padding: '4px 8px 4px 8px',
					}}>
					<span style={{ color: '#664D03' }}>Посмотреть акт</span>
					<Icon style={{ color: '#664D03' }} icon={'East'} size={'lg'} />
				</Button>
			);
		}

		if (not?.type === 'Завершен' && not?.title === 'Пациент оплатил') {
			return (
				<Button
					onClick={handelOpenAct}
					className='d-flex rounded-3 align-items-center gap-2'
					style={{
						border: '1px solid #0A3622',
						backgroundColor: 'inherit',
						padding: '4px 8px 4px 8px',
					}}>
					<span style={{ color: '#0A3622' }}>Посмотреть акт</span>
					<Icon style={{ color: '#0A3622' }} icon={'East'} size={'lg'} />
				</Button>
			);
		}
	};

	const cardStyles = () => {
		if (
			not?.type === 'Предстоящий' &&
			(not?.title === 'Новый визит' || not?.title === 'Напоминание о визите')
		) {
			return {
				backgroundColor: '#CFE2FF',
				border: !not.isRead ? '2px solid #052C65' : '1px solid #052C6533',
				textColor: '#052C65',
				icon: {
					name: not?.title === 'Новый визит' ? 'Alarm' : 'NotificationImportant',
					color: '#0D6EFD',
				},
			};
		}
		if (
			(not?.type === 'Предстоящий' ||
				not?.type === 'В ожидании' ||
				not?.type === 'Задолженность') &&
			(not?.title === 'Перенос визита' ||
				not?.title === 'Пациент в ожидании' ||
				not?.title === 'Акт создан')
		) {
			return {
				backgroundColor: '#FFF3CD',
				border: !not.isRead ? '2px solid #664D03' : '1px solid #664D0333',
				textColor: '#664D03',
				icon: {
					name:
						not?.title === 'Пациент в ожидании'
							? 'PersonPinCircle'
							: not?.title === 'Акт создан'
							  ? 'NotAct'
							  : 'DoneAll',
					color: '#664D03',
				},
			};
		}
		if (
			not?.type === 'Отменен' &&
			(not?.title === 'Отказ Врача' ||
				not?.title === 'Отказ пациента' ||
				not?.title === 'Визит отменен' ||
				not?.title === 'Пациент не пришел')
		) {
			return {
				backgroundColor: '#F8D7DA',
				border: !not.isRead ? '2px solid #58151C' : '1px solid #58151C33',
				textColor: '#58151C',
				icon: {
					name: not?.title === 'Пациент не пришел' ? 'PersonOff' : 'RemoveDone',
					color: '#58151C',
				},
			};
		}
		if (
			(not?.type === 'Завершен' || not?.type === 'В процессе') &&
			(not?.title === 'Пациент оплатил' || not?.title === 'Пациент на приеме')
		) {
			return {
				backgroundColor: '#D1E7DD',
				border: !not.isRead ? '2px solid #0A3622' : '1px solid #0A362233',
				textColor: '#0A3622',
				icon: {
					name: not?.title === 'Пациент оплатил' ? 'Payments' : 'PersonPinCircle',
					color: '#0A3622',
				},
			};
		}

		if (
			not?.type === 'Задолженность' &&
			(not?.title === 'Задолженность' || not?.title === 'Пациент частично оплатил')
		) {
			return {
				backgroundColor: '#FFCAF4',
				border: !not.isRead ? '2px solid #BA0092' : '1px solid #BA009233',
				textColor: '#BA0092',
				icon: {
					name: not?.title === 'Задолженность' ? 'MoneyOffCsred' : 'Money',
					color: '#BA0092',
				},
			};
		}
	};
	const isDark = () => {
		return themeStatus === 'light' ? 'l25-' : 'lo25-';
	};

	return (
		<>
			<div key={not.uuid} className='col-12 rounded-3'>
				<Card
					onClick={() => {
						// handelReade();
						isReadNot(not.uuid);
					}}
					className={`p-0 bg-${isDark()} d-flex justify-content-between rounded-1 cursor-pointer`}>
					<CardBody
						style={{
							border: cardStyles()?.border,
							backgroundColor: cardStyles()?.backgroundColor,
							borderRadius: 13,
						}}
						className='p-3 pt-2'>
						<div className='d-flex justify-content-between'>
							<div className='d-flex gap-2'>
								<div className='d-flex align-items-center gap-3'>
									<Icon
										style={{
											width: 20,
											height: 20,
											color: cardStyles()?.icon.color,
										}}
										icon={cardStyles()?.icon.name}
									/>
								</div>
								<div className='d-flex align-items-center gap-3'>
									<CardLabel>
										<CardTitle
											className='fs-5'
											style={{ color: cardStyles()?.textColor }}>
											{not?.title}
										</CardTitle>
									</CardLabel>
								</div>
							</div>
							<div>
								<div className='d-flex gap-2'>
									<div className='d-flex align-items-center gap-3'>
										<span style={{ color: '#4D4D4D' }} className='fs-6'>
											{formatNotDate(not.createdAt)}
										</span>
									</div>
									<div className='d-flex align-items-center gap-3'>
										<Button onClick={() => deleteNot(not.uuid)} className='p-0'>
											<Icon
												style={{ width: 20, height: 20, color: '#4D4D4D' }}
												icon='Clear'
												// color='dark'
												size='lg'
											/>
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div style={{ marginTop: 7 }} className=' gap-3'>
							<div className='d-flex gap-2'>
								<div
									style={{ width: 20 }}
									className='d-flex align-items-center gap-3'></div>
								<div className='d-flex flex-column align-items-start'>
									<span
										style={{ color: cardStyles()?.textColor }}
										className='p-0 m-0 fs-6'>
										{not.description.patient}
									</span>
									<span
										style={{ color: cardStyles()?.textColor }}
										className='p-0 m-0 fs-6'>
										{formatDateRange(
											not.description.startDate,
											not.description.endDate,
										)}
									</span>
									<span
										style={{ color: cardStyles()?.textColor }}
										className='p-0 m-0 fs-6'>
										Врач: {not.description.user}
									</span>
									<span
										style={{ color: cardStyles()?.textColor }}
										className='col-8 p-0 m-0 fs-6 d-flex gap-1'>
										Услуга:{' '}
										{not?.description?.services?.length > 0 && (
											<Tooltips
												title={`${not?.description?.services[0]?.title_all
													?.rus}${
													not.description?.services?.length > 1
														? ', и другие ...'
														: ''
												}`}>
												<span
													className='text-truncate'
													style={{ color: cardStyles()?.textColor }}>
													{not?.description?.services[0]?.title_all?.rus}
												</span>
											</Tooltips>
										)}
									</span>
									{not.description.act && (
										<span
											style={{ color: cardStyles()?.textColor }}
											className='col-8 p-0 m-0 fs-6 d-flex gap-1'>
											Акт:
											<span className='fw-bold'>№ {not.description.act}</span>
										</span>
									)}
								</div>
							</div>
							<div className='d-flex justify-content-end gap-2'>{ButtonStyles()}</div>
						</div>
					</CardBody>
					{/* )} */}
				</Card>
			</div>

			{/* <StatusSubmitModal
				uuid={not?.visit?.uuid}
				patientUuid={not?.visit?.patientUuid}
				setIsOpen={setStatusModal}
				isOpen={statusModal}
				changeStatus={handleStatusChange}
			/> */}
		</>
	);
};

export default NotificationComponentItem;
