import React, { Dispatch, FC, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import dayjs, { Dayjs } from 'dayjs';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import PaginationButtons, { PER_COUNT } from '../../../components/PaginationButtons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as qs from 'qs';
import { VisitService } from '../../../custom/api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { UserService } from '../../../custom/api/service/user.service';
import { useDebounce } from '../../../hooks/useDebounce';
import TableComponent from '../../components/tables/TableComponent';
import { formatDate, getFontSizeFromLocalStorage } from '../../../utils/functions';
import FinanceFilterComponent from './FilterComponent/FinanceFilterComponent';
import AmountComponent from './FilterComponent/AmountComponent/AmountComponent';
import OffCanvasComponent from '../visit/OffCanvasComponent/OffCanvasComponent';
import { TColor } from '@textnpayme/type/color-type';
import CancelVisitModal from '../visit/VisistPageComponents/Modals/CancelVisitModal';
import ActModal from '../visit/VisistPageComponents/Modals/ActModal';
import CreateActModal from '../visit/VisistPageComponents/Modals/CreateActModal';

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setDateDay: (value: React.SetStateAction<string>) => void;
	setAllDayButton(...args: unknown[]): unknown;
	allDayButton?: any;
	dateDay?: any;
	dateInterval?: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
	setDateInterval?: Dispatch<[Dayjs | null, Dayjs | null] | null>;
}

const FinancesTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		setDateDay,
		dateDay,
		dateInterval,
		allDayButton,
		setAllDayButton,
		setDateInterval,
	}) => {
		const [actVisit, setActVisit] = useState<IVisit | null>(null);
		const [actModal, setActModal] = useState<boolean>(false);
		const [openCancelVisits, setOpenCancelVisits] = useState<boolean>(false);
		const [currentPage, setCurrentPage] = useState<number>(1);
		const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
		const [visitUuid, setVisitUuid] = useState<undefined | string>(undefined);
		const [eventAdding, setEventAdding] = useState(false);
		const [canvasData, setCanvasData] = useState<any>([]);
		const [searchIconClicked, setSearchIconClicked] = useState(false);
		const isLargeDesktop = useMediaQuery({ query: '(min-width: 1620px)' });
		const isSmallDesktop = useMediaQuery({ query: '(max-width: 1300px)' });
		const isTablet = useMediaQuery({ query: '(max-width: 1250px)' });
		const [canvasColor, setCanvasColor] = useState<TColor>('light');
		const [createActModal, setCreateActModal] = useState<boolean>(false);

		const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);

		const { data: user_role } = useQuery(
			['user_role'],
			() => UserService.getProfile(qs.stringify({})),
			{ refetchOnWindowFocus: true },
		);

		const isMainDoctorOrDoctor = user_role?.roles?.some(
			(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
		);

		useEffect(() => {
			setIsUserDoctor(isMainDoctorOrDoctor);
		}, [isMainDoctorOrDoctor, user_role]);

		const changeStatus = async (data: IVisit) => {
			await mutate(data);
		};

		const handleOpenCancel = () => {
			setOpenCancelVisits(!openCancelVisits);
		};

		const queryClient = useQueryClient();

		const queryOptions = {
			onSuccess: (response: any, formData: any) => {
				queryClient.invalidateQueries('visits');
			},
			onError: (error: any) => {},
		};

		const { mutate } = useMutation(
			(data: IVisit) => VisitService.update(data.uuid, data),
			queryOptions,
		);

		const [values, setValues] = useState<IVisit>({
			users: [],
			services: [],
			statuses: [],
			order: 'DESC',
			visitId: undefined,
			serviceName: '',
			userName: '',
		});

		const DebounsedSearch = useDebounce(values);

		const filterQuery = [
			{
				association: 'patient',
			},
			{
				association: 'services',
				required: true,
				...(values?.services?.length && { where: { uuid: values.services } }),
			},
			{
				association: 'services',
			},
			{
				association: 'canceledVisit',
			},
			{
				association: 'user',
				required: true,
				...(isUserDoctor && !!user_role && { where: { uuid: user_role?.uuid } }),
				...(!isUserDoctor && values.users.length && { where: { uuid: values.users } }),
			},
			{
				association: 'canceledVisit',
			},

			{
				association: 'medicalCase',
				include: [
					{
						association: 'stage',
					},
				],
			},

			{
				association: 'clinic',
			},
			{
				association: 'estimate',
				include: [
					{
						association: 'act',
					},
					{
						association: 'services',
					},
				],
			},
		];

		const filterQueryMeta = {
			...(values?.visitSearch && {
				websearchQuery: { searchVectorVisit: values?.visitSearch },
			}),
			...(values.visitId && {
				or: [
					{ visitId: { iLike: `%${values.visitId}%` } },
					{ visitNumber: { iLike: `%${values.visitId}%` } },
				],
			}),
			...(values.statuses.length
				? { status: values.statuses }
				: { status: ['Задолженность', 'Завершен'] }),
			visitDate: {
				...(!allDayButton &&
					(!!dateInterval && !!dateDay
						? {
								...(!!dateInterval[0] &&
									dateInterval[1] && {
										between: [
											`${dateInterval[0]?.format(
												'YYYY-MM-DD',
											)}T00:00:00.000Z`,
											`${dateInterval[1]?.format(
												'YYYY-MM-DD',
											)}T23:59:59.999Z`,
										],
									}),
						  }
						: {
								...(!!dateDay.date1 && {
									between: [
										`${dateDay.date1}T00:00:00.000Z`,
										`${dateDay.date1}T23:59:59.999Z`,
									],
								}),
						  })),
			},
		};

		const { data: visits }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
			[
				'visits',
				currentPage,
				perPage,
				DebounsedSearch,
				dateDay,
				allDayButton,
				dateInterval,
				isUserDoctor,
			],
			() =>
				VisitService.findAll(
					qs.stringify({
						includeMeta: filterQuery,
						filterMeta: filterQueryMeta,
						queryMeta: {
							paginate: true,
							limit: perPage,
							page: currentPage,
							order: { createdAt: values.order },
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const getRootFontSize = () =>
			parseFloat(getComputedStyle(document.documentElement).fontSize);
		const [dynamicFont, setDynamicFont] = useState(getRootFontSize());

		const updateFontSize = () => {
			const fontSizeValue = getFontSizeFromLocalStorage();
			setDynamicFont(fontSizeValue);
		};

		useEffect(() => {
			updateFontSize();
			const handleFontSizeChange = () => {
				updateFontSize();
			};
			window.addEventListener('fontSizeChange', handleFontSizeChange);

			return () => {
				window.removeEventListener('fontSizeChange', handleFontSizeChange);
			};
		}, []);

		useEffect(() => {
			if (canvasData?.status === 'Завершен') {
				setCanvasColor('success');
			} else if (canvasData?.status === 'Отменен' || canvasData?.status === 'Не оплачен') {
				setCanvasColor('danger');
			} else {
				setCanvasColor('info');
			}
		}, [canvasData]);

		const visitDropDownProps = {
			setEventAdding: setEventAdding,
			setCanvasData: setCanvasData,
			setActModal: setActModal,
			setCreateActModal: setCreateActModal,
			setActVisit: setActVisit,
			handleOpenCancel: handleOpenCancel,
			setVisitUuid: setVisitUuid,
			changeStatus: changeStatus,
		};
		const tableHeaders = [
			{ header: 'ID Визита', key: 'id' },
			{ header: 'Номер Визита', key: 'visitPill' },
			{ header: 'Дата и Время', key: 'datePill' },
			{ header: 'Пациент', key: 'nameWithAvatar' },
			{ header: 'Врач', key: 'name' },
			{ header: 'Сумма оплаты', key: 'sum' },
			{ header: 'Сумма долга', key: 'debt' },
			{ header: 'Статус', key: 'status' },
			{ header: 'Действие', key: 'action' },
		];

		const reset = useCallback(() => {
			setAllDayButton(false);
			setValues({
				users: [],
				services: [],
				statuses: [],
				order: 'DESC',
				visitId: undefined,
				serviceId: undefined,
				userName: '',
			});
			setDateDay(formatDate!(new Date()));
			if (setDateInterval) setDateInterval(null);
			setAllDayButton(false);
			localStorage.setItem('datePicker', 'null');
			localStorage.setItem('dateOfVisit', JSON.stringify(new Date()));
		}, [setAllDayButton, setDateDay, setDateInterval]);

		return (
			<>
				<Card stretch={isFluid}>
					<div className='table-row-header'>
						<div className='d-flex flex-row align-items-center w-full grow'>
							<FinanceFilterComponent
								reset={reset}
								values={values}
								isLargeDesktop={isLargeDesktop}
								isSmallDesktop={isSmallDesktop}
								isUserDoctor={isUserDoctor}
								isTablet={isTablet}
								searchIconClicked={searchIconClicked}
								setValues={setValues}
								setSearchIconClicked={setSearchIconClicked}
								canvasColor={canvasColor}
								dynamicFont={dynamicFont}
							/>
						</div>
						<div className='d-flex align-items-center gap-4'>
							<AmountComponent
								values={values}
								isSmallDesktop={isSmallDesktop}
								isUserDoctor={isUserDoctor}
								dateDay={dateDay}
								allDayButton={allDayButton}
								filterQuery={filterQuery}
								filterQueryMeta={filterQueryMeta}
								dateInterval={dateInterval}
								reset={reset}
							/>
						</div>
					</div>
					<CardBody className='table-responsive' isScrollable={isFluid}>
						<TableComponent
							data={visits?.data}
							headers={tableHeaders}
							visitDropDownProps={visitDropDownProps}
						/>
					</CardBody>
					<PaginationButtons
						data={visits || []}
						label='customers'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
						totalPage={Math.ceil(visits?.meta?.count / perPage) || 1}
					/>
				</Card>
				<CancelVisitModal
					visitUuid={visitUuid}
					setIsOpen={setOpenCancelVisits}
					isOpen={openCancelVisits}
					id='0'
				/>
				<OffCanvasComponent
					setEventAdding={setEventAdding}
					eventAdding={eventAdding}
					canvasData={canvasData}
					canvasColor={canvasColor}
				/>
				<ActModal
					setActVisit={setActVisit}
					setIsOpen={setActModal}
					isOpen={actModal}
					visit={actVisit}
				/>
				<CreateActModal
					setIsOpen={setCreateActModal}
					isOpen={createActModal}
					visit={actVisit}
					setActVisit={setActVisit}
					estimate={actVisit?.estimate}
				/>
			</>
		);
	},
);
FinancesTable.defaultProps = {
	isFluid: false,
};

export default FinancesTable;
