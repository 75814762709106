import React, { FC, useCallback, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalHeader } from '../../../../../../components/bootstrap/Modal';
import ChooseMedicalCase from '../../../../../../custom/pages/visit/planVisit/steps/ChooseMedicalCase';
import Icon from '../../../../../../components/icon/Icon';
import { IVisit } from '../../../../../../custom/api/interface/visit.interface';
import CreateVisit from './Components/CreateVisit';

interface ICreateVisitPatientProps {
	setIsOpen(...args: unknown[]): unknown;
	isOpen: boolean;
	patient: any;
}

const CreateVisitPatient: FC<ICreateVisitPatientProps> = ({ setIsOpen, isOpen, patient }) => {
	const [createVisit, setCreateVisit] = useState(false);
	const [serviceUuid, setServiceUuid] = useState<null | []>(null);
	const [medicalCaseUuid, setMedicalCaseUuid] = useState<null | string>(null);
	const [clinicUuid, setClinicUuid] = useState<string | null>(null);
	const [stageUuid, setStageUuid] = useState<null | string>(null);
	const [visitData, setVisitData] = useState([]);
	const [schedulModalOpen, setSchedulModalOpen] = useState(false);
	const [values, setValues] = useState<IVisit>({});

	const changeValues = useCallback((key: string, value: string | number) => {
		setValues((prev: any) => ({ ...prev, [key]: value }));
	}, []);

	useEffect(() => {
		if (!createVisit || !isOpen) {
			setValues({});
		}
	}, [createVisit, isOpen]);

	return (
		<Modal
			isStaticBackdrop
			isCentered
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			closeModalCustom={() => {
				setCreateVisit(false);
				setValues({});
			}}
			size='xl'>
			<ModalHeader
				closeModalCustom={() => {
					setCreateVisit(false);
					setValues({});
				}}
				style={{ padding: '1.5rem 2.25rem' }}
				setIsOpen={setIsOpen}
				className='p-4'>
				<span className='fs-3 fw-bold'>
					<Icon icon='Person' className='me-2 fs-2' color='primary' />
					Запланировать визит
				</span>
			</ModalHeader>
			<ModalBody style={{ padding: '1.5rem 2.25rem' }}>
				{!createVisit && (
					<ChooseMedicalCase
						setCloseModal={setIsOpen}
						setServiceId={setServiceUuid}
						setMedicalCaseUuid={setMedicalCaseUuid}
						setStageUuid={setStageUuid}
						patientId={patient?.uuid}
						setCreateVisit={setCreateVisit}
						setClinicUuid={setClinicUuid}
					/>
				)}

				{createVisit && (
					<CreateVisit
						setCreateVisit={setCreateVisit}
						createVisit_state={createVisit}
						setValues={setValues}
						values={values}
						setIsOpen={setIsOpen}
						patientId={patient?.uuid}
						setServiceUuid={setServiceUuid}
						serviceId={serviceUuid}
						medicalCaseUuid={medicalCaseUuid}
						clinicUuid={clinicUuid}
						stageUuid={stageUuid}
						visitData={visitData}
						setVisitData={setVisitData}
						schedulModalOpen={schedulModalOpen}
						changeValues={changeValues}
						setSchedulModalOpen={setSchedulModalOpen}
					/>
				)}
			</ModalBody>
		</Modal>
	);
};

export default CreateVisitPatient;
