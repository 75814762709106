import React, { FC } from 'react';
import Button from '../../../../../../components/bootstrap/Button';
import Icon from '../../../../../../components/icon/Icon';
import Popovers from '../../../../../../components/bootstrap/Popovers';
import useDarkMode from '../../../../../../hooks/useDarkMode';

interface IThemeItemProps {
	styledBtn: any;
}

const ThemeItem: FC<IThemeItemProps> = ({ styledBtn }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	return (
		<div className='col-auto'>
			<Popovers trigger='hover' desc='Темная / Светлая тема'>
				<Button
					{...styledBtn}
					onClick={() => setDarkModeStatus(!darkModeStatus)}
					className='btn-only-icon fs-6 d-flex align-items-center justify-content-center'
					data-tour='dark-mode'
					aria-label='Toggle dark mode'>
					<Icon
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						color={darkModeStatus ? 'info' : 'warning'}
						className='btn-icon'
					/>
				</Button>
			</Popovers>
		</div>
	);
};

export default ThemeItem;
