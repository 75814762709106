import React, { FC } from 'react';

interface HighlightedTitleProps {
	className?: string | {};
	title: string;
}

const HighlightedTitle: FC<HighlightedTitleProps> = ({ title, className }) => {
	return <span className={`text-info mb-0 ${className}`}>{title}</span>;
};

export default HighlightedTitle;
