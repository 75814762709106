import { CategoryService } from './service/category.service';

export const fetchCategory = async (categoryUuid: string) => {
	try {
		const subCategory = await CategoryService.findOne(categoryUuid);

		const categoryUuidForCategory = subCategory?.categoryUuid;

		let category = null;
		if (categoryUuidForCategory) {
			category = await CategoryService.findOne(categoryUuidForCategory);
		}

		return {
			isLoading: false,
			category: category?.name?.rus,
			subCategory: subCategory?.name?.rus,
		};
	} catch (error) {
		console.error('Error fetching category data:', error);
		return {
			isLoading: true,
			category: '',
			subCategory: '',
			error: 'Failed to fetch category data',
		};
	}
};
