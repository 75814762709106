import React, { FC, useCallback, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import { useMutation, useQueryClient } from 'react-query';
import Input from '../../../components/bootstrap/forms/Input';
import HighlightedTitle from '../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { GroupService } from '../../../custom/api/service/group.service';
import Loading from '../../../custom/components/loading/Loading';
import TableComponent from '../../../custom/components/tables/TableComponent';

interface IEditConfigProps {
	isOpen: any;
	setIsOpen(...args: unknown[]): unknown;
	materials: any;
}

const MaterialInfo: FC<IEditConfigProps> = ({ isOpen, setIsOpen, materials }) => {
	const queryClient = useQueryClient();

	const { mutate, isLoading } = useMutation(
		({ inputDto }: { inputDto: any }) => GroupService.create(inputDto),
		{
			onSuccess: (response, formData) => {
				queryClient.invalidateQueries('groups');
				setIsOpen(false);
			},
			onError: (error) => {
				console.log(error);
			},
		},
	);

	const [values, setValues] = useState<any>({
		title_all: {
			en: '',
			arm: '',
			rus: '',
		},
	});

	const changeValues = (key: string, value: any) => {
		setValues((prev: any) => ({
			...prev,
			title_all: {
				...prev.title_all,
				[key]: value,
			},
		}));
	};

	const handleSubmit = useCallback(
		(e: any) => {
			e.preventDefault();
			mutate({ inputDto: values });
		},
		[mutate, values],
	);

	const tableHeaders = [
		{ header: '№', key: 'index' },
		{ header: 'Название', key: 'item_name' },
		{ header: 'Код материала', key: 'code' },
		{ header: 'Бренд', key: 'brand' },
		{ header: 'Филиал', key: 'clinic' },
		{ header: 'Склад', key: 'store_type' },
		{ header: 'Тип материала', key: 'material_type' },
		{ header: 'Категория', key: 'category' },
		{ header: 'Стоимость', key: 'price' },
		{ header: 'Ед.изм', key: 'unit_measurment' },
		{ header: 'Размеры', key: 'size' },
		// { header: 'Срок годности', key: 'expiration_date' },
	];

	return (
		<Modal isStaticBackdrop isOpen={isOpen} setIsOpen={setIsOpen} size={'xl'} isCentered>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='title' style={{ fontSize: '1.75rem' }}>
					Материалы{' '}
				</ModalTitle>
			</ModalHeader>
			<form onSubmit={handleSubmit}>
				<ModalBody className='px-4 d-flex flex-column gap-4'>
					<TableComponent
						data={materials?.length ? materials : []}
						headers={tableHeaders}
						visitDropDownProps={undefined}
					/>
				</ModalBody>
			</form>
		</Modal>
	);
};

export default MaterialInfo;
