import React, { FC, useEffect, useState } from 'react';
import ClinicOrganisationTable from './ClinicOrganisationTable';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { PER_COUNT } from '../../../../../components/PaginationButtons';
import { IService } from '../../../../api/interface/service.interface';
import { UserService } from '../../../../api/service/user.service';
import { ClinicOrganisationService } from '../../../../../custom/api/service/clinicOrganisations.service';

interface IClinicOrganisationPage {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const ClinicOrganisationPage: FC<IClinicOrganisationPage> = ({ search, setSearch }) => {
	const [serviceSort, setServiceSort] = useState<boolean>(false);

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	const [values, setValues] = useState<IService>({
		officeName: '',
		officeType: '',
		clinicUuid: '',
		categoryUuid: '',
		officeNumber: '',
		bedCount: '',
		phone: '',
		responibleUserUuid: '',
	});

	const [filter, setFilter] = useState<IService>({
		clinic: '',
		officeType: '',
		category: '',
		responsibleUser: '',
	});

	const filterDebounce = useDebounce(filter);
	const deboncedSearch = useDebounce(search);

	const { data: clinic_organisation }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['clinic_organisation', filterDebounce, currentPage, perPage, deboncedSearch],
		() =>
			ClinicOrganisationService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'responibleUser',
						},

						{
							association: 'clinic',
							...(!!filter.clinic && {
								where: {
									uuid: filter.clinic,
								},
							}),
						},
						{
							association: 'category',
							...(!!filter.category && {
								where: {
									uuid: filter.category,
								},
							}),
						},
					],
					filterMeta: {
						...(!!deboncedSearch && {
							websearchQuery: { searchVector: deboncedSearch },
						}),
						...(!!filter.responibleUser && {
							responibleUserUuid: filter.responibleUser,
						}),
						...(!!filter.officeType && {
							officeType: filter.officeType,
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
		},
	);

	return (
		<ClinicOrganisationTable
			isFluid
			setValues={setValues}
			values={values}
			clinic_organisation={clinic_organisation}
			isUserDoctor={isUserDoctor}
			setCurrentPage={setCurrentPage}
			setPerPage={setPerPage}
			currentPage={currentPage}
			perPage={perPage}
			filter={filter}
			setFilter={setFilter}
			sort={serviceSort}
			setSort={setServiceSort}
		/>
	);
};

export default ClinicOrganisationPage;
