import { authHost } from '../axios';
// import { IFile } from '../interface/file.interface';

export class ImagesService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(stageUuid?: string): Promise<any[] | null> {
		const { data } = await authHost.get(`theeth_image`, {
			params: {
				stageUuid,
			},
		});
		return data;
	}

	async findOne(uuid: string) {
		const { data } = await authHost.get(`theeth_image/${uuid}`);
		return data;
	}

	static async create(data: any) {
		const image = await authHost.post('theeth_image', data);
		return image;
	}

	static async delete(uuid: string) {
		const image = await authHost.delete(`theeth_image/${uuid}`);
		return image;
	}
}
