import React, { SVGProps } from "react";

export const PRINT_TOOTH_16 = (props: SVGProps<SVGSVGElement>) => {
  return (<svg width="36" height="100" viewBox="0 0 20 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M1.46724 41.9917C3.46908 11.6264 0.297666 7.21173 1.46697 6.07999C2.8734 3.25232 5.72894 9.67704 7.97387 17.3464M18.0137 43.4C18.415 35.7162 18.8808 14.5907 11.1193 9.24867C9.9871 8.46939 10.7218 12.4684 11.2931 16.8742M11.2931 16.8742C11.8823 21.4171 12.2979 26.3925 10.314 27.0074C9.73959 23.9576 8.9098 20.5438 7.97387 17.3464M11.2931 16.8742C8.11507 11.5339 7.27234 11.2968 7.97387 17.3464" stroke="black" strokeWidth="0.7" strokeLinecap="round"/>
<path id="Vector 24" d="M2.69668 52.5424C5.0284 56.7444 7.94282 53.9433 9.10869 51.6088C10.566 51.6088 13.1529 55.4 14.6103 55.4C16.0676 55.4 21.1442 52.8948 18.3993 42.3272C16.6869 35.7344 12.659 33.4 9.69173 33.4C0.832407 35.1648 -0.314022 47.1168 2.69668 52.5424Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}


