import React, { SVGProps } from 'react';

const SvgUploadFile = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5.33008 8.49992H6.50008V2.49992H8.50008V8.49992H9.67008L7.50008 10.6699L5.33008 8.49992Z" fill="url(#paint0_linear_1243_2602)"/>
			<path d="M14.5 15.5H0.5V17.5H14.5V15.5Z" fill="#1266F1"/>
			<path d="M0.5 6.5H4.5V0.5H10.5V6.5H14.5L7.5 13.5L0.5 6.5ZM8.5 8.5V2.5H6.5V8.5H5.33L7.5 10.67L9.67 8.5H8.5Z" fill="#1266F1"/>
			<defs>
			<linearGradient id="paint0_linear_1243_2602" x1="7.50008" y1="10.6699" x2="7.50008" y2="2.49992" gradientUnits="userSpaceOnUse">
			<stop stopColor="#3855B3" stopOpacity="0.3"/>
			<stop offset="1" stopColor="#3855B3" stopOpacity="0"/>
			</linearGradient>
			</defs>
		</svg>
	);
};

export default SvgUploadFile;
