import Input from '../../../../../components/bootstrap/forms/Input';
import React, { FC } from 'react';
import HighlightedTitle from './HighlightedTitle';
import Select from '../../../../../components/bootstrap/forms/Select';
import Option from '../../../../../components/bootstrap/Option';

interface IInput {
	value?: any;
	setValue?: any;
	title: string;
	placeholder?: string;
	isDisabled?: boolean;
	item?: any;
	name?: string;
	index?: number;
	className?: string;
	width?: string;
}

interface ISelect {
	value?: any;
	setValue?: any;
	title: string;
	placeholder?: string;
	options: Array<{ value: string; label: string }>;
	isDisabled?: boolean;

	className?: string;
	width?: string;
}

export const InputWithTitle: FC<IInput> = ({
	value,
	setValue,
	name,
	index,
	title,
	item,
	placeholder,
	isDisabled,
	className = '',
	width = '8rem',
}) => {
	const cName = `d-flex flex-column gap-1 ${className}`;

	const handleChange = (e: any) => {
		if (setValue && name) {
			setValue(name, e.target.value, item.event_uuid);
		}
	};

	return (
		<div className={cName} style={{ width }}>
			<HighlightedTitle title={title} />
			<Input
				type='text'
				value={value}
				onChange={handleChange}
				required
				placeholder={placeholder}
				disabled={isDisabled}
			/>
		</div>
	);
};

export const SelectWithTitle: FC<ISelect> = ({
	value,
	setValue,
	title,
	placeholder,
	isDisabled,
	className = '',
	width = '8rem',
	options,
}) => {
	const cName = `d-flex flex-column gap-1 ${className}`;

	const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setValue?.(e.target.value);
	};

	return (
		<div className={cName} style={{ width }}>
			<HighlightedTitle title={title} />
			<Select
				value={value}
				onChange={onSelect}
				placeholder={placeholder}
				ariaLabel='select'
				disabled={isDisabled}>
				{options &&
					options.map((option, index) => (
						<Option key={index} value={option?.value}>
							{option?.label}
						</Option>
					))}
			</Select>
		</div>
	);
};
