import React, { FC, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import Button from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import * as qs from 'qs';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../../components/PaginationButtons';
import { FileService } from '../../../..//custom/api/service/file.service';
import { IFile } from '@textnpayme/custom/api/interface/file.interface';
import { useQuery } from 'react-query';
interface IDocumentsModalTable {
	files: any;
}
const DocumentsModalTable: FC<IDocumentsModalTable> = ({ files }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);

	const handleDownload = useCallback((fileUrl: string, fileName: string) => {
		fetch(fileUrl)
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			})
			.catch((error) => {
				console.error('Download error:', error);
			});
	}, []);

	return (
		<>
			<table className='table table-modern'>
				<thead>
					<tr>
						<th>№</th>
						<th>Название документа</th>
						<th>Описание</th>
						<th>Дата добавления</th>
						<th>Действие</th>
					</tr>
				</thead>
				<tbody>
					{dataPagination(files, currentPage, perPage)?.map((item: IFile) => (
						<tr key={item?.id} className='fw-bold'>
							<td>
								<div className='d-flex justify-content-center align-items-center'>
									{item?.fileId}
								</div>
							</td>
							<td>
								<div className='d-flex justify-content-center align-items-center'>
									{item?.title}
								</div>
							</td>
							<td>
								<div className='d-flex justify-content-center align-items-center'>
									{item?.description}
								</div>
							</td>
							<td>
								<div className='d-flex justify-content-center align-items-center'>
									<div className=' col-12'>
										<span className='text-nowrap  fw-bold fs-5'>
											{dayjs(`${item?.createdAt}`)?.format('MMM D, YYYY')}
											{', '}
										</span>
										<span className='text-nowrap'>
											{dayjs(`${item?.createdAt}`)?.format('hh:mm')}{' '}
										</span>
									</div>
								</div>
							</td>
							<td>
								<div className='d-flex justify-content-center align-items-center'>
									<Button
										color='primary'
										tag='a'
										target='_blank'
										href={`https://backend.amracode.am` + item?.file}
										isLight
										className='text-nowrap me-1'>
										Посмотреть
									</Button>{' '}
									<Button
										onClick={() =>
											handleDownload(
												`https://backend.amracode.am${item?.file}`,
												`${item?.title}.${item?.file?.split('.')[1]}`,
											)
										}
										isLight>
										<Icon icon='Download' size='2x' />
									</Button>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<PaginationButtons
				data={files || []}
				label='customers'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				small
				setPerPage={setPerPage}
				totalPage={Math.ceil(files?.length / perPage) || 1}
			/>
		</>
	);
};

export default DocumentsModalTable;
