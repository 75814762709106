import React, { FC, useEffect, useState } from 'react';
import CategoryTable from './CategoryTable';
import { useQuery } from 'react-query';
import qs from 'qs';
import { PER_COUNT } from '../../../../../components/PaginationButtons';
import { IService } from '../../../../api/interface/service.interface';
import { UserService } from '../../../../api/service/user.service';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface ICategoryPage {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const CategoryPage: FC<ICategoryPage> = ({ search, setSearch }) => {
	const [serviceSort, setServiceSort] = useState<boolean>(false);

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	const [values, setValues] = useState<IService>({
		categories: [
			{
				name: {
					en: '',
					arm: '',
					rus: '',
				},
			},
		],
		name: {
			en: '',
			arm: '',
			rus: '',
		},
	});

	const [filter, setFilter] = useState<IService>({
		clinic: '',
		officeType: '',
		category: '',
		responsibleUser: '',
	});

	const deboncedSearch = useDebounce(search);

	const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
		['categoriesWithSubCategories', deboncedSearch],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'subCategory',
						},
					],
					// filterMeta: {
					// 	...(!!deboncedSearch && {
					// 		websearchQuery: { searchVectorCategory: deboncedSearch },
					// 	}),
					// },
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const categories = categoriesWithSubCategories?.data
		?.filter((item: any) => !item.categoryUuid)
		?.map((category: any) => ({
			...category,
			categoriesUuid: category.uuid,
		}));

	return (
		<>
			<CategoryTable
				isFluid
				setValues={setValues}
				values={values}
				category={{ data: categories }}
				isUserDoctor={isUserDoctor}
				setCurrentPage={setCurrentPage}
				setPerPage={setPerPage}
				currentPage={currentPage}
				perPage={perPage}
				filter={filter}
				setFilter={setFilter}
				sort={serviceSort}
				setSort={setServiceSort}
			/>
		</>
	);
};

export default CategoryPage;
