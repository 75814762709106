import { authHost } from '../axios';
import { CommonEntity } from '../interface/common-entity';

export class GroupService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query?: any): Promise<any | null> {
		const { data } = await authHost.get(`group?${query}`);
		return data;
	}

	async findOne(uuid: string) {
		const { data } = await authHost.get(`group/${uuid}`);
		return data;
	}

	static async create(data: CommonEntity) {
		console.log(data, 'datadatadata')
		const group = await authHost.post('group', data);
		return group;
	}

	static async createMaterial(data: CommonEntity) {
		const group = await authHost.post('group/group_material', data);
		return group;
	}

	static async update(uuid: string, data: CommonEntity) {
		const group = await authHost.patch(`group/${uuid}`, data);
		return group;
	}

	static async delete(uuid: string) {
		const group = await authHost.delete(`group/${uuid}`);
		return group;
	}
}
