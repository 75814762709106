import { authHost } from '../axios';

export class ConfigService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query?: any) {
		try {
			const configuration = await authHost.get(`configuration?${query}`);
			return configuration;
		} catch (error: any) {
			if (error.response.data.message === 'Forbidden resource') {
				window.location.href = '/';
			}
			return error;
		}
	}

	static async findOne(uuid: string, query?: any) {
		const configuration = await authHost.get(`configuration/:${uuid}?${query}`);
		return configuration;
	}

	static async update(uuid: string, data: any) {
		const configuration = await authHost.patch(`configuration/:${uuid}`, data);
		return configuration;
	}
}
