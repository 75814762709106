import React, { SVGProps } from "react";


export const PRINT_TOOTH_31 = (props: SVGProps<SVGSVGElement>) => {
  return (    
<svg width="36" height="100"  viewBox="0 0 20 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 12">
<path id="Vector 70" d="M18.004 15.8C19.1048 28.6337 15.549 50.2149 7.82251 56.5361C6.67484 57.475 5.21533 55.761 4.66178 53.7267L2.19995 15.8" stroke="black" stroke-opacity="1" strokeWidth="0.7"/>
<path id="Vector 17" d="M5.03466 0.800003H17.4725C18.8545 0.800003 19.2 2.21177 19.2 2.91765C19.2 3.62353 18.8545 24.8 9.52609 24.8C0.197731 24.8 1.23421 6.09412 1.23421 4.68236C1.23421 3.27059 3.65268 0.800003 5.03466 0.800003Z" stroke="black" stroke-opacity="1" strokeWidth="0.7"/>
</g>
</svg>
  )
}

