import React, { FC, memo, useCallback } from 'react';
import FormGroup from '../../../../../../components/bootstrap/forms/FormGroup';
import { IClinic } from '../../../../../../custom/api/interface/clinic.interface';
import * as qs from 'qs';
import { CategoryService } from '../../../../../../custom/api/service/category.service';
import { useQuery } from 'react-query';
import { ClinicService } from '../../../../../../custom/api/service/clinic.service';

interface IVisitFilterComponentProps {
	filter: any;
	setFilter(...args: unknown[]): unknown;
}

const MaterialsFilterComponent: FC<IVisitFilterComponentProps> = memo(({ filter, setFilter }) => {
	const changeFilterValues = useCallback(
		(e: any) => {
			const { value, name } = e.target;
			setFilter((prev: any) => ({
				...prev,
				[name]: value,
			}));
		},
		[setFilter],
	);

	const { data: clinics } = useQuery<any>(['clinics'], () =>
		ClinicService.findAll(qs.stringify({})),
	);

	const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
		['categories'],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'subCategory',
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const categories = categoriesWithSubCategories?.data
		.filter((item: any) => !item.categoryUuid)
		.map((category: any) => ({
			...category,
			categoriesUuid: category.uuid,
		}));

	return (
		<div className='d-flex flex-row align-items-center w-full grow'>
			<div className='d-flex flex-row align-items-center gap-2'>
				<FormGroup id='clinic' label='ФИЛИАЛ' className='m-0 d-flex flex-column col-3'>
					<div style={{ position: 'relative' }}>
						<select
							required
							onChange={(e) => {
								changeFilterValues(e);
							}}
							name='clinicUuid'
							value={filter?.clinicUuid}
							className='form-select'>
							<option value={''}>Все</option>
							{clinics?.data?.map((item: IClinic, index: number) => (
								<option value={item.uuid}>{item.name}</option>
							))}
						</select>
					</div>
				</FormGroup>
				<FormGroup
					id='healing_type'
					label='ТИП МАТЕРИАЛА'
					className='m-0 d-flex flex-column col-3'>
					<div style={{ position: 'relative' }}>
						<select
							name='material_type'
							required
							onChange={(e) => {
								changeFilterValues(e);
							}}
							value={filter?.material_type}
							className='form-select'>
							<option value={''}>Все</option> <option value={'Товар'}>Товар</option>
						</select>
					</div>
				</FormGroup>

				<FormGroup id='healing_type' label='СКЛАД' className='m-0 d-flex flex-column col-3'>
					<div style={{ position: 'relative' }}>
						<select
							required
							name='store_type'
							onChange={(e) => {
								changeFilterValues(e);
							}}
							value={filter?.store_type}
							className='form-select'>
							<option value={''}>Все</option>{' '}
							<option value={'Основной'}>Основной</option>
						</select>
					</div>
				</FormGroup>
				<FormGroup id='category' label='КАТЕГОРИЯ' className='m-0 d-flex flex-column'>
					<div style={{ position: 'relative' }}>
						<select
							required
							name='categoryUuid'
							onChange={(e) => {
								changeFilterValues(e);
							}}
							value={filter?.categoryUuid}
							className='form-select'>
							<option value={''}>Все</option>
							{categories?.map((item: any, index: number) => (
								<option value={item.uuid}>{item?.name?.rus}</option>
							))}
						</select>
					</div>
				</FormGroup>
			</div>
		</div>
	);
});
export default MaterialsFilterComponent;
