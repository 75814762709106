export const rolesTranslate: Record<string, string> = {
	doctor: 'Доктор',
	mainDoctor: 'Главный врач',
	nurse: 'Медсестра',
	superAdmin: 'Супер-администратор',
	admin: 'Администратор',
	receptionist: 'Регистратура',
	employee: 'Работник',
	user: 'Пользователь',
	any: 'Любой неавторизованный пользователь',
	all: 'Любой авторизованный пользователь'
};

export const roles = [
	'doctor',
	'mainDoctor',
	'nurse',
	'superAdmin',
	'admin',
	'receptionist',
	'employee',
	'user',
	'any',
	'all',
];
