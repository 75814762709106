import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Button from '../../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../../hooks/useDarkMode';
import { ImagesService } from '../../../api/service/images.service';
import { useMutation, useQueryClient } from 'react-query';

interface ICommonUpcomingEventsProps {
	image: any;
}

const DeleteOpen: FC<ICommonUpcomingEventsProps> = ({
	image,
}) => {
	const [dropdownClose, setDropdownClose] = useState<boolean>(false);
	const { darkModeStatus } = useDarkMode();

	const queryClient = useQueryClient();

	const { mutate: deleteImage } = useMutation((uuid: string) => ImagesService.delete(uuid), {
		onSuccess: () => {
			queryClient.invalidateQueries(['images', image.stageUuid]);
		},
		onError: (error) => {
			console.error(error);
		},
	});
	const handleDelete = (e: React.FormEvent<HTMLFormElement>) => {
		deleteImage(image.uuid);
	};

	const handleOpen = () => {
	};

	return (
		<div className='d-flex w-100 align-items-center justify-content-center'>
			<Dropdown isOpen={dropdownClose} setIsOpen={setDropdownClose}>
				<DropdownToggle hasIcon={false}>
					<Button
						isOutline={!darkModeStatus}
						color='dark'
						isLight={darkModeStatus}
						className={classNames(
							'text-nowrap',

							{
								'border-light': !darkModeStatus,
							},
							'fw-normal',
							'fs-6',
						)}>
						•••
					</Button>
				</DropdownToggle>
				<DropdownMenu className='fw-bold'>
					<>
						<DropdownItem>
							<Button onClick={handleOpen}>Открыть</Button>
						</DropdownItem>
						<DropdownItem>
							<Button onClick={handleDelete}>Удалить</Button>
						</DropdownItem>
					</>
				</DropdownMenu>
			</Dropdown>

			{/* <StatusSubmitModal
				setIsOpen={setStatusModal}
				isOpen={statusModal}
				changeStatus={handleStatusChange}
			/> */}
		</div>
	);
};

export default DeleteOpen;
