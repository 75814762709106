import React, { SVGProps } from 'react';

const SvgMimIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g filter="url(#filter0_d_361_2025)">
			<g clip-path="url(#clip0_361_2025)">
			<rect x="3" y="3.5" width="40" height="40" rx="6" fill="white"/>
			<path d="M17.6416 10.2614C17.6757 11.7785 20.2021 13.0506 23.2804 13.0933C26.3588 13.1361 28.8369 11.907 28.8325 10.419C28.7985 8.90189 26.238 7.65351 23.1596 7.61078C20.0813 7.56805 17.6372 8.77338 17.6416 10.2614ZM19.4996 10.3092C19.4897 9.27234 21.129 8.47846 23.2124 8.51839C25.2661 8.52929 26.9602 9.38704 26.9745 10.3712C26.9844 11.408 25.3452 12.2019 23.2618 12.162C21.2081 12.1511 19.5139 11.2933 19.4996 10.3092Z" fill="url(#paint0_linear_361_2025)"/>
			<path d="M5.74239 13.8212C5.66141 14.0261 5.6822 14.1601 5.66469 14.3702C5.83204 18.1132 7.21083 21.507 8.21207 25.2134C8.65962 27.1283 8.52827 28.7042 8.56106 30.6112C8.53041 30.9789 8.50414 31.2941 8.38047 31.6276C8.34217 31.7038 8.30387 31.78 8.26556 31.8561C8.06529 32.342 6.95781 33.361 8.04447 33.742C9.28762 34.1625 10.023 33.0069 10.2408 32.3109C10.2835 32.1822 10.2966 32.0246 10.3393 31.8959C10.8329 29.4243 9.73541 23.8057 9.1128 20.9242C9.06246 20.7614 9.04167 20.6274 8.95741 20.4882C8.89066 20.1388 8.65977 19.4585 8.69042 19.0908C8.64009 18.9279 8.77579 18.8334 8.77579 18.8334C9.00012 18.8255 9.27369 19.3772 9.35796 19.5164C10.8878 21.8649 11.4568 24.2394 12.3727 26.6692C12.9778 28.2269 13.4177 29.8503 14.5689 31.3739C14.8983 31.6392 15.0756 31.8126 15.5243 31.7969C16.3199 31.8365 16.4501 30.6571 16.6164 30.1949C16.7106 29.8324 16.7751 29.4411 16.8102 29.0208C16.8047 27.936 16.7686 27.2189 16.7675 26.0816C16.5651 24.2928 15.7193 21.0225 16.1253 21.1355C16.3748 21.2091 16.5094 21.5112 16.6188 21.7319C17.2655 23.5575 17.8105 25.454 18.618 27.2665C19.1345 28.7375 19.7484 30.1902 20.138 31.6506C20.4433 32.9719 20.4958 33.8754 20.5767 35.2045L20.6391 37.1404C20.638 37.537 20.8897 38.3513 21.6261 38.333C22.4939 38.2728 22.5137 37.2694 22.6253 36.6968C22.9154 31.2991 20.1009 26.7283 18.2603 21.9738C18.0754 21.5088 17.8904 21.0439 17.676 20.55C16.9439 18.9818 16.144 17.4609 15.0814 16.0239C14.845 15.7927 14.6929 15.7008 14.4051 15.7034C14.1512 15.6823 14.0407 15.8583 13.9641 16.0106C13.6281 16.591 13.5657 17.7231 13.6489 18.2589C13.9706 19.7667 15.9248 29.2912 15.1085 29.1177C13.9791 28.8654 10.0376 15.5792 7.18692 13.3592L7.06436 13.2961C7.00527 13.2383 6.84878 13.1989 6.84878 13.1989C6.24361 13.1752 5.96783 13.4168 5.74239 13.8212Z" fill="url(#paint1_linear_361_2025)"/>
			<path d="M38.9848 13.3477C38.9848 13.3477 38.9164 13.3953 38.8481 13.4429C35.9991 15.577 32.2198 28.8731 31.1067 29.1004C30.2878 29.2721 32.1472 19.7053 32.403 18.1818C32.4768 17.6819 32.4118 16.5313 32.047 15.8884C31.9919 15.7773 31.8476 15.5789 31.6216 15.5868C31.3318 15.5894 31.1951 15.6846 30.9515 15.9041C29.8693 17.3058 29.0978 18.8398 28.3858 20.432C28.1885 20.8684 28.021 21.3339 27.8534 21.7995C26.0415 26.5497 23.2796 31.1146 23.631 36.5502C23.7445 37.1189 23.7886 38.1346 24.6835 38.2089C25.4208 38.2434 25.6489 37.4367 25.6501 37.0373C25.6732 36.3734 25.6622 35.7332 25.6512 35.0931C25.7956 33.7468 25.7769 32.8131 26.0789 31.5065C26.458 30.0465 27.0289 28.6024 27.5359 27.153C28.3295 25.3545 28.842 23.4528 29.4482 21.5855C29.5595 21.4083 29.684 21.0724 29.9783 21.0169C30.3706 20.9429 29.5517 24.2041 29.4018 26.0027C29.4073 27.0951 29.4095 27.841 29.3808 28.9572C29.4436 29.3619 29.5064 29.7666 29.6034 30.1475C29.7896 30.6158 29.9186 31.7717 30.7198 31.8115C31.1716 31.7957 31.3469 31.6238 31.6885 31.3858C32.8094 29.9074 33.2403 28.212 33.8157 26.715C34.6963 24.2579 35.2782 21.9091 36.7595 19.5817C36.8367 19.4283 37.1067 18.8914 37.3326 18.8835C37.3326 18.8835 37.4516 18.9999 37.4384 19.1586C37.4075 19.5289 37.2565 20.1823 37.1276 20.5711C37.0846 20.7007 37.0714 20.8594 37.0284 20.989C36.4321 23.8958 35.4147 29.5375 35.9492 32.0054C35.9999 32.1694 36.0208 32.3043 36.0418 32.4392C36.3041 33.1535 37.0424 34.3333 38.2669 33.9288C39.3888 33.5957 38.2239 32.5137 38.0377 32.0455C38.0123 31.9635 37.987 31.8815 37.9319 31.7704C37.7963 31.4662 37.7589 31.1435 37.7258 30.7678C37.727 28.8237 37.5694 27.2393 37.9838 25.3561C38.9703 21.6294 40.3138 18.2519 40.3712 14.4747C40.3888 14.2631 40.402 14.1044 40.2874 13.9351C40.0968 13.5198 39.829 13.2579 39.1853 13.2578C39.117 13.3054 39.0531 13.3001 38.9848 13.3477Z" fill="url(#paint2_linear_361_2025)"/>
			<path d="M17.0745 14.8196C16.9687 14.8108 16.8557 14.8896 16.9013 14.9815L16.9122 16.1283C16.9049 16.2159 17.007 16.2684 17.1128 16.2772L21.6626 16.3239C21.7683 16.3327 21.8285 16.2495 21.8358 16.162L21.7966 15.0349C21.8039 14.9473 21.7301 14.8751 21.6243 14.8663L17.0745 14.8196Z" fill="url(#paint3_linear_361_2025)"/>
			<path d="M17.8336 16.7191C17.7283 16.7103 17.6439 16.7692 17.6366 16.8563L17.6756 17.9783C17.6684 18.0655 17.7419 18.1374 17.8472 18.1461L21.6358 18.1751C21.7411 18.1838 21.8255 18.125 21.8328 18.0379L21.7938 16.9158C21.801 16.8287 21.7275 16.7568 21.6222 16.748L17.8336 16.7191Z" fill="url(#paint4_linear_361_2025)"/>
			<path d="M18.5209 18.5328C18.4165 18.5241 18.3571 18.6062 18.3499 18.6926L18.3688 19.4117C18.3616 19.4981 18.4344 19.5693 18.5388 19.578L21.6362 19.6174C21.7684 19.6066 21.8278 19.5246 21.835 19.4382L21.8162 18.7191C21.8234 18.6327 21.7226 18.5809 21.6182 18.5722L18.5209 18.5328Z" fill="url(#paint5_linear_361_2025)"/>
			<path d="M19.1205 19.9776C19.0168 19.969 18.906 20.0462 18.9507 20.1363L18.9417 20.8697C18.9346 20.9555 19.0346 21.007 19.1383 21.0156L21.6405 21.0503C21.7442 21.0589 21.855 20.9817 21.8103 20.8917L21.8192 20.1582C21.8264 20.0724 21.7263 20.0209 21.6227 20.0123L19.1205 19.9776Z" fill="url(#paint6_linear_361_2025)"/>
			<path d="M19.5037 21.4046C19.4006 21.3961 19.342 21.4771 19.3349 21.5623L19.326 22.2913C19.3464 22.3574 19.4423 22.4512 19.5214 22.4363L21.6442 22.4835C21.7473 22.4921 21.8334 22.3919 21.813 22.3258L21.8219 21.5969C21.829 21.5116 21.7331 21.4178 21.6265 21.4519L19.5037 21.4046Z" fill="url(#paint7_linear_361_2025)"/>
			<path d="M20.0226 22.8154C19.9447 22.8301 19.8361 22.9057 19.8562 22.9709L19.858 23.5635C19.851 23.6476 19.949 23.698 20.0506 23.7064L21.6593 23.734C21.7372 23.7193 21.8187 23.6626 21.8257 23.5786L21.7968 23.0048C21.8038 22.9208 21.7329 22.8514 21.6313 22.843L20.0226 22.8154Z" fill="url(#paint8_linear_361_2025)"/>
			<path d="M20.7486 24.1077C20.6726 24.122 20.5668 24.1957 20.56 24.2776L20.5881 24.8367C20.5813 24.9186 20.6735 25.0088 20.7494 24.9944L21.6438 25.0274C21.7462 24.9946 21.8256 24.9393 21.8324 24.8574L21.8307 24.2799C21.8111 24.2164 21.7189 24.1263 21.6429 24.1406L20.7486 24.1077Z" fill="url(#paint9_linear_361_2025)"/>
			<path d="M22.9588 16.2002C22.8526 16.1914 22.7638 16.2947 22.7849 16.3628L22.8059 17.7137C22.7986 17.8016 22.8727 17.8741 22.9789 17.8829L28.1864 17.9387C28.2926 17.9475 28.353 17.8641 28.3603 17.7762L28.3393 16.4252C28.3182 16.3571 28.2441 16.2846 28.1379 16.2758L22.9588 16.2002Z" fill="url(#paint10_linear_361_2025)"/>
			<path d="M22.9637 18.341C22.8579 18.3322 22.773 18.3913 22.7657 18.4789L22.7766 19.6266C22.794 19.7383 22.8962 19.7909 22.9774 19.7755L27.6612 19.8552C27.7953 19.8443 27.8802 19.7851 27.8592 19.7172L27.8483 18.5696C27.831 18.4579 27.757 18.3856 27.6758 18.4009L22.9637 18.341Z" fill="url(#paint11_linear_361_2025)"/>
			<path d="M22.9393 20.2593C22.8339 20.2506 22.7739 20.3334 22.7667 20.4206L22.7884 21.4322C22.8057 21.5433 22.8793 21.6153 22.9601 21.6L26.9335 21.6661C27.0389 21.6749 27.127 21.5724 27.1061 21.5048L27.1125 20.4737C27.0953 20.3625 27.0217 20.2905 26.9408 20.3058L22.9393 20.2593Z" fill="url(#paint12_linear_361_2025)"/>
			<path d="M22.9518 22.0675C22.847 22.0588 22.763 22.1173 22.7802 22.2278L22.7811 23.1662C22.8018 23.2334 22.875 23.3049 22.9797 23.3136L26.2733 23.3467C26.3781 23.3554 26.4621 23.2969 26.4449 23.1864L26.4161 22.2674C26.4233 22.1807 26.3501 22.1092 26.2454 22.1005L22.9518 22.0675Z" fill="url(#paint13_linear_361_2025)"/>
			<path d="M22.9642 23.7847C22.8606 23.7761 22.7775 23.834 22.7703 23.9198L22.7784 24.7624C22.7712 24.8481 22.8954 24.9232 22.9749 24.9082L25.3728 24.9343C25.4765 24.9429 25.5596 24.8851 25.5667 24.7993L25.531 23.976C25.5381 23.8902 25.4416 23.7959 25.3621 23.8109L22.9642 23.7847Z" fill="url(#paint14_linear_361_2025)"/>
			<path d="M22.9687 25.3886C22.8662 25.3801 22.784 25.4374 22.7769 25.5222L22.7919 26.2707C22.7849 26.3556 22.8564 26.4255 22.9863 26.415L24.7907 26.4365C24.8932 26.445 24.9754 26.3877 24.9824 26.3029L24.9674 25.5544C24.9745 25.4696 24.9029 25.3996 24.773 25.4101L22.9687 25.3886Z" fill="url(#paint15_linear_361_2025)"/>
			<path d="M22.9526 26.8782C22.8517 26.8698 22.7674 26.9679 22.7874 27.0326L22.7856 27.6628C22.7786 27.7463 22.8491 27.8152 22.9769 27.8048L24.2683 27.8488C24.3961 27.8384 24.4535 27.7591 24.4605 27.6756L24.4353 27.0642C24.4422 26.9807 24.3718 26.9118 24.2709 26.9035L22.9526 26.8782Z" fill="url(#paint16_linear_361_2025)"/>
			<path d="M22.9537 28.2887C22.8555 28.2805 22.7768 28.3354 22.77 28.4167L22.775 28.9491C22.7911 29.0527 22.8597 29.1198 22.9842 29.1097L23.7506 29.1118C23.8488 29.12 23.9276 29.0651 23.9344 28.9838L23.9293 28.4514C23.9132 28.3478 23.8184 28.299 23.7201 28.2908L22.9537 28.2887Z" fill="url(#paint17_linear_361_2025)"/>
			<path d="M22.9701 29.5717C22.8507 29.5814 22.7751 29.6341 22.7686 29.7121L22.7832 30.106C22.7986 30.2055 22.8645 30.2698 22.9587 30.2776L23.339 30.2699C23.4333 30.2777 23.534 30.2075 23.5405 30.1295L23.5259 29.7356C23.5105 29.6362 23.4447 29.5718 23.3252 29.5815L22.9701 29.5717Z" fill="url(#paint18_linear_361_2025)"/>
			</g>
			</g>
			<defs>
			<filter id="filter0_d_361_2025" x="0" y="0.5" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="1" dy="1"/>
			<feGaussianBlur stdDeviation="2"/>
			<feComposite in2="hardAlpha" operator="out"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_361_2025"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_361_2025" result="shape"/>
			</filter>
			<linearGradient id="paint0_linear_361_2025" x1="-3.53458" y1="28.9876" x2="24.9923" y2="-0.184477" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint1_linear_361_2025" x1="2.40768" y1="33.9104" x2="30.8078" y2="4.87294" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint2_linear_361_2025" x1="10.4963" y1="41.7268" x2="39.0956" y2="12.4835" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint3_linear_361_2025" x1="1.76972" y1="27.8299" x2="25.4327" y2="3.6318" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint4_linear_361_2025" x1="3.07323" y1="29.0524" x2="26.6301" y2="4.96279" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint5_linear_361_2025" x1="4.28923" y1="30.0729" x2="27.6385" y2="6.19569" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint6_linear_361_2025" x1="5.33759" y1="30.9919" x2="28.5253" y2="7.27979" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint7_linear_361_2025" x1="6.31089" y1="31.8789" x2="29.3577" y2="8.31093" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint8_linear_361_2025" x1="7.36254" y1="32.6629" x2="30.0845" y2="9.42713" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint9_linear_361_2025" x1="8.56373" y1="33.3665" x2="30.707" y2="10.7225" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint10_linear_361_2025" x1="5.43935" y1="31.0931" x2="29.1908" y2="6.80463" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint11_linear_361_2025" x1="6.54015" y1="32.1737" x2="30.2214" y2="7.95698" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint12_linear_361_2025" x1="7.51862" y1="33.095" x2="31.0898" y2="8.99077" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint13_linear_361_2025" x1="8.45856" y1="33.9589" x2="31.8917" y2="9.99588" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint14_linear_361_2025" x1="9.35109" y1="34.6805" x2="32.5329" y2="10.9745" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint15_linear_361_2025" x1="10.2381" y1="35.4145" x2="33.1659" y2="11.9682" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint16_linear_361_2025" x1="11.1154" y1="36.0668" x2="33.6794" y2="12.9926" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint17_linear_361_2025" x1="12.0245" y1="36.5904" x2="33.9973" y2="14.1206" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<linearGradient id="paint18_linear_361_2025" x1="13.0027" y1="36.9922" x2="34.0877" y2="15.4304" gradientUnits="userSpaceOnUse">
			<stop stop-color="#31ACE2"/>
			<stop offset="0.99" stop-color="#6BC4AA"/>
			<stop offset="1" stop-color="#6BC4AA"/>
			</linearGradient>
			<clipPath id="clip0_361_2025">
			<rect x="3" y="3.5" width="40" height="40" rx="6" fill="white"/>
			</clipPath>
			</defs>
		</svg>


	);
};

export default SvgMimIcon;
