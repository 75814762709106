import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';
import PaginationButtons, { dataPagination } from '../../../../../components/PaginationButtons';
import TableComponent from '../../../../components/tables/TableComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import SubHeaderRightComponent from './SubHeaderComponent/SubHeaderRightComponent';
import AddCategory from './AddCategory';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import { useMutation, useQueryClient } from 'react-query';
import { notification, NotificationArgsProps } from 'antd';
import StatusSubmitModal from '../StatusSubmitModal';

type NotificationPlacement = NotificationArgsProps['placement'];

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	category: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
	sort: any;
	setSort: any;
}

const CategoryTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		category,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
		sort,
		setSort,
	}) => {
		const [api, contextHolder] = notification.useNotification();

		const openNotification = (
			placement: NotificationPlacement,
			message: string,
			description: string,
		) => {
			api.info({
				message,
				description,
				placement,
			});
		};

		const [addCategory, setAddCategory] = useState(false);
		const [statusModal, setStatusModal] = useState(false);
		const [uuid, setUuid] = useState('');

		const tableHeaders = [
			{ header: 'Название Ru', key: 'name_rus' },
			{ header: 'Название Arm ', key: 'name_arm' },
			{ header: 'Название En', key: 'name_en' },
			{ header: 'Действие', key: 'clinicOrganisationActions' },
		];
		const directionDropDownProps = {
			setUuid: setUuid,
			setAddCategory: setAddCategory,
			setStatusModal: setStatusModal,
			uuid: uuid,
		};

		const queryClient = useQueryClient();

		const { mutate: createOrganisation } = useMutation(
			({ inputDto }: { inputDto: any }) => CategoryService.create(inputDto),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('categoriesWithSubCategories');
					queryClient.invalidateQueries('category');
					setValues({
						categories: [
							{
								name: {
									en: '',
									arm: '',
									rus: '',
								},
							},
						],
						name: {
							en: '',
							arm: '',
							rus: '',
						},
					});
					setUuid('');
					setAddCategory(false);
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: updateOrganisation } = useMutation(
			({ inputDto, uuid }: { inputDto: any; uuid: string }) => {
				return CategoryService.update(uuid, inputDto);
			},
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('categoriesWithSubCategories');
					queryClient.invalidateQueries('category');
					setValues({
						categories: [
							{
								name: {
									en: '',
									arm: '',
									rus: '',
								},
							},
						],
						name: {
							en: '',
							arm: '',
							rus: '',
						},
					});
					setAddCategory(false);
					setUuid('');
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: deleteOrganisation } = useMutation(
			({ uuid }: { uuid: string }) => CategoryService.delete(uuid),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('categoriesWithSubCategories');
					queryClient.invalidateQueries('category');
					setValues({
						categories: [
							{
								name: {
									en: '',
									arm: '',
									rus: '',
								},
							},
						],
						name: {
							en: '',
							arm: '',
							rus: '',
						},
					});
					setAddCategory(false);
					setUuid('');
					setStatusModal(false);
				},

				onError: (error: any) => {
					if (error.response.data.message === 'cant_create_whithout_office_name')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Название кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_office_type')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Тип кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_clinic')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без филиала',
						);

					if (error.response.data.message === 'cant_create_whithout_responible_user')
						openNotification(
							'bottomRight',
							'Важно !',
							'Вы не можете создать организацию для филиала без  ответственного  персонала',
						);
				},
			},
		);
		const handleSubmit = useCallback(
			(e: any, uuid?: string) => {
				e.preventDefault();
				if (uuid) {
					console.log({ inputDto: values, uuid: uuid }, 'kkkjjjhhhihi');
					return updateOrganisation({ inputDto: values, uuid: uuid });
				}
				return createOrganisation({ inputDto: values });
			},
			[createOrganisation, updateOrganisation, values],
		);

		const handleCancele = useCallback(() => {
			setValues({
				categories: [
					{
						name: {
							en: '',
							arm: '',
							rus: '',
						},
					},
				],
				name: {
					en: '',
					arm: '',
					rus: '',
				},
			});
			setAddCategory(false);
			setUuid('');
		}, [setValues]);

		const handleSubmitDelete = useCallback(
			(e: any, uuid: string) => {
				e.preventDefault();
				deleteOrganisation({ uuid });
			},
			[deleteOrganisation],
		);
		useEffect(() => {
			if (uuid && values.name.en === '' && values.name.arm === '' && values.name.rus === '') {
				setValues((prev: any) => {
					const data = category?.data.find((item: any) => item.uuid === uuid);
					return {
						categories: [
							...prev.categories,
							{
								name: {
									en: data?.name?.en,
									arm: data?.name?.arm,
									rus: data?.name?.rus,
								},
							},
						],
						...prev,
						name: {
							en: data?.name?.en,
							arm: data?.name?.arm,
							rus: data?.name?.rus,
						},
					};
				});
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [uuid]);

		return (
			<>
				<Card className={addCategory ? 'col-8' : 'col-12'}  stretch={isFluid}>
					{contextHolder}
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						<SubHeaderRightComponent
							setStatusModal={setStatusModal}
							handleSubmit={handleSubmit}
							uuid={uuid}
							addCategory={addCategory}
							setAddCategory={setAddCategory}
							handleCancele={handleCancele}
						/>
					</div>
					<CardBody className='table-responsive' isScrollable={isFluid}>
						{!addCategory ? (
							<TableComponent
								data={dataPagination(category?.data, currentPage, perPage)}
								headers={tableHeaders}
								directionDropDownProps={directionDropDownProps}
								visitDropDownProps={undefined}
							/>
						) : (
							<AddCategory setValues={setValues} values={values} uuid={uuid} />
						)}
					</CardBody>
					{!addCategory && (
						<PaginationButtons
							data={category || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(category?.data?.length / perPage) || 1}
						/>
					)}
				</Card>
				<StatusSubmitModal
					setIsOpen={setStatusModal}
					isOpen={statusModal}
					handleSubmitDelete={handleSubmitDelete}
					uuid={uuid}
				/>
			</>
		);
	},
);

export default CategoryTable;
