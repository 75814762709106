import React, { SVGProps } from 'react';

const SvgTheethCrown = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
		id="mask0_3358_992"
		style={{ maskType: 'luminance' }}
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="25"
		height="24"
		>
		<path d="M24.5 0H0.5V24H24.5V0Z" fill="white" />
		</mask>
		<g mask="url(#mask0_3358_992)">
		<path d="M24.5 0H0.5V24H24.5V0Z" fill="white"/>
		<g filter="url(#filter0_i_3358_992)">
		<path d="M7.88176 5.25C2.14714 5.25004 3.35396 13.4892 4.29759 18.348C4.36141 18.6767 4.75951 18.7906 4.98372 18.547L7.76232 15.5275C7.83856 15.4446 7.94469 15.3977 8.05572 15.3977H16.5997C16.7029 15.3977 16.8023 15.4383 16.8772 15.5112L20.0929 18.6378C20.3171 18.8558 20.6878 18.7396 20.7574 18.4308C23.0594 8.22229 19.5238 5.25 16.7605 5.25C14.8525 5.25 13.5888 6.0057 12.9695 6.49631C12.7424 6.6762 12.3485 6.63604 12.133 6.44164C11.5899 5.95159 10.3767 5.24996 7.88176 5.25Z" fill="#A3A3A3"/>
		</g>
		<path d="M0.384277 18.7878H4.9826L7.97566 15.3574H16.6998L20.1852 18.7878H24.8252" stroke="#009FE8" strokeWidth="2"/>
		</g>
		<defs>
		<filter id="filter0_i_3358_992" x="3.5" y="5.25" width="18" height="17.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
		<feFlood floodOpacity="0" result="BackgroundImageFix"/>
		<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
		<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
		<feOffset dy="4"/>
		<feGaussianBlur stdDeviation="4.25"/>
		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
		<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
		<feBlend mode="normal" in2="shape" result="effect1_innerShadow_3358_992"/>
		</filter>
		</defs>
		</svg>
		
	);
};

export default SvgTheethCrown;