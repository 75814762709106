import React, { FC, memo } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import Icon from '../../../../../components/icon/Icon';
import useDarkMode from '../../../../../hooks/useDarkMode';

interface IAddDocumentButtonProps {
	setViewDocuments(...args: unknown[]): unknown;
}

const AddDocumentButton: FC<IAddDocumentButtonProps> = memo(({ setViewDocuments }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<>
			<div className='col-12'>
				<div
					className={`d-flex align-items-center bg-l${
						darkModeStatus ? 'o25' : '10'
					}-info rounded-2 p-3`}>
					<div className='flex-shrink-0'>
						<Icon icon='Receipt' size='2x' color='info' />
					</div>
					<div className='flex-grow-1 ms-3'>
						<div className='fw-bold fs-5 mb-1'>Документы </div>
						<div className='text-muted mt-n2 fs-sm truncate-line-1'>
							Нажмите на стрелочку{' '}
						</div>
					</div>
					<Button color='info' isLink onClick={() => setViewDocuments(true)}>
						<Icon icon='ArrowForward' size='2x' />
					</Button>
				</div>
			</div>
		</>
	);
});

export default AddDocumentButton;
