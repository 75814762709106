import React, { FC, memo } from 'react';
import Button from '../../../../../../components/bootstrap/Button';

interface IResetComponentProps {
	reset(...args: unknown[]): unknown;
	setGroupModal(...args: unknown[]): unknown;
}

const ResetComponent: FC<IResetComponentProps> = memo(({ reset, setGroupModal }) => {
	return (
		<div className='d-flex gap-3'>
			<Button color='info' onClick={setGroupModal} isLight>
				+ Добавить группу{' '}
			</Button>

			<Button color='info' onClick={reset} isLight>
				Сбросить фильтры
			</Button>
		</div>
	);
});

export default ResetComponent;
