import React, { SVGProps } from 'react';

const SvgTheethImplant = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_i_596_546)">
			<path fillRule="evenodd" clipRule="evenodd" d="M5.49993 18.23C4.72605 18.0948 4.11064 17.0351 3.61116 15.4727L7.29519 16.1315C6.86362 17.2635 6.29617 18.0213 5.49993 18.23ZM3.50017 15.111L7.41072 15.8103L7.81101 15.8819C8.26681 15.9634 8.70359 15.6644 8.79057 15.2113C8.87896 14.7509 8.57288 14.3073 8.10973 14.2244L3.18899 13.3444C2.73319 13.2629 2.29641 13.5619 2.20943 14.015C2.12104 14.4754 2.42712 14.9191 2.89027 15.0019L3.50017 15.111ZM3.24862 13.0133L7.9209 13.8489C7.98962 13.5116 8.05417 13.1605 8.11562 12.7976L2.7491 11.7744C2.82314 12.1919 2.90082 12.6002 2.98248 12.9959C3.06981 12.9918 3.15895 12.9973 3.24862 13.0133ZM2.68749 11.42L2.10437 11.3088C1.64191 11.2207 1.34091 10.7731 1.43498 10.3135C1.52747 9.8617 1.96757 9.56801 2.42223 9.6547L8.70196 10.8521C9.16442 10.9402 9.46542 11.3878 9.37135 11.8474C9.27886 12.2992 8.83876 12.5929 8.3841 12.5062L8.17049 12.4655L2.68749 11.42ZM8.5588 9.69504C8.5259 9.95389 8.49287 10.2101 8.45945 10.4633L2.48568 9.32427C2.44436 9.31639 2.40313 9.31076 2.36212 9.30729C2.32166 9.01822 2.28248 8.72816 2.24447 8.43812L8.5588 9.69504ZM2.19888 8.08594L8.60111 9.36036L9.15326 9.47023C9.60709 9.56057 10.0495 9.27064 10.1459 8.81974C10.2439 8.36078 9.94653 7.91049 9.4846 7.81854L1.84674 6.29816C1.39291 6.20782 0.950509 6.49775 0.854149 6.94866C0.756069 7.40761 1.05347 7.8579 1.5154 7.94985L2.19888 8.08594ZM1.94475 5.9746L8.85317 7.34978C8.91288 6.88137 8.97451 6.40948 9.03943 5.93669L1.75806 4.29694C1.81722 4.83816 1.87916 5.40026 1.94475 5.9746ZM1.71946 3.94327L0.909443 3.76086C0.449342 3.65725 0.163267 3.19872 0.273711 2.7419C0.38199 2.29402 0.831337 2.01584 1.28242 2.11742L10.0906 4.10099C10.5507 4.20461 10.8367 4.66313 10.7263 5.11996C10.618 5.56783 10.1687 5.84601 9.71758 5.74443L9.08591 5.60218L1.71946 3.94327ZM10.0906 0.22998C9.8268 1.25703 9.57979 2.50317 9.39396 3.59922L1.48284 1.81765C1.41287 1.21862 1.34528 0.682513 1.27798 0.22998H10.0906Z" fill="#0FB9DC"/>
			</g>
			<defs>
			<filter id="filter0_i_596_546" x="0.25" y="0.22998" width="10.5" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
			<feFlood floodOpacity="0" result="BackgroundImageFix"/>
			<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dy="4"/>
			<feGaussianBlur stdDeviation="4.25"/>
			<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="shape" result="effect1_innerShadow_596_546"/>
			</filter>
			</defs>
		</svg>
	);
};

export default SvgTheethImplant;
