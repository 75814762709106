import React, { SVGProps } from "react";


export const PRINT_TOOTH_35 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 18 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M1.03811 14.4275C2.82269 41.4852 -0.00429782 44.7915 1.03811 45.8C7.95723 41.0398 16.1465 20.6469 15.7887 13.8" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M8.45222 20.8C0.674086 19.2101 -0.775683 8.14638 2.31084 3.55505C3.97185 1.08423 6.14352 0.433399 12.7705 0.980674C14.05 0.980674 18.507 3.23751 16.0971 12.7577C14.5937 18.697 11.0573 20.8 8.45222 20.8Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}

