import React, { SVGProps } from "react";

export const THEETH_48 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
                  <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="5.53rem" viewBox="0 0 39 72" fill="none" {...props}>
            <g filter="url(#filter0_i_2354_11245)">
            <path d="M33.1419 68.7111C47.2506 73.9361 30.0495 60.6107 33.9319 18.2881C26.7912 12.1923 9.73445 0.368031 1.79235 11.2361C1.01395 21.9457 6.23902 71.0608 20.8692 71.5833C27.6775 71.8265 13.5541 48.0707 17.2117 44.4132C24.0051 44.9356 25.6467 65.9353 33.1419 68.7111Z" fill="url(#paint0_radial_2354_11245)"/>
            </g>
            <g filter="url(#filter1_i_2354_11245)">
            <path d="M33.5946 17.8896C20.0095 6.40044 35.6852 66.9657 37.7749 69.5823C39.005 71.1226 39.8647 68.5401 35.1622 50.7751C33.9297 46.1192 33.9105 40.3731 33.5946 34.055C33.0721 23.605 36.4165 20.2761 33.5946 17.8896Z" fill="url(#paint1_linear_2354_11245)"/>
            </g>
            <path d="M36.3185 8.36002C33.8104 -0.836003 23.43 2.4384 18.5532 5.2251C16.9858 2.61267 10.193 0 7.58049 0C4.96795 0 -1.82463 2.09001 0.787899 14.63C2.87793 24.6621 13.5023 23.3384 18.5532 21.4226C30.5709 27.6926 39.4535 19.8551 36.3185 8.36002Z" fill="url(#paint2_linear_2354_11245)"/>
            <path d="M36.3185 8.36002C33.8104 -0.836003 23.43 2.4384 18.5532 5.2251C16.9858 2.61267 10.193 0 7.58049 0C4.96795 0 -1.82463 2.09001 0.787899 14.63C2.87793 24.6621 13.5023 23.3384 18.5532 21.4226C30.5709 27.6926 39.4535 19.8551 36.3185 8.36002Z" fill="url(#paint3_radial_2354_11245)" fillOpacity="0.3"/>
            <g filter="url(#filter2_f_2354_11245)">
            <path d="M17.8279 10.4361L13.6415 5.55203C13.2598 5.10683 13.7883 4.46741 14.2974 4.75833L15.3359 5.35172L18.4709 7.44173L23.4118 5.69792C23.9917 5.49323 24.3872 6.28424 23.8755 6.62538L18.1025 10.474C18.0145 10.5326 17.8967 10.5164 17.8279 10.4361Z" fill="#BBA8A8"/>
            <path d="M17.8279 10.4361L13.6415 5.55203C13.2598 5.10683 13.7883 4.46741 14.2974 4.75833L15.3359 5.35172L18.4709 7.44173L23.4118 5.69792C23.9917 5.49323 24.3872 6.28424 23.8755 6.62538L18.1025 10.474C18.0145 10.5326 17.8967 10.5164 17.8279 10.4361Z" stroke="#BBA8A8" strokeWidth="1.04501"/>
            </g>
            <g filter="url(#filter3_f_2354_11245)">
            <path d="M20.9837 13.7114C19.9386 18.9364 24.8904 17.3689 27.7761 17.3689C30.6618 17.3689 29.8663 14.9749 29.8663 12.6664C29.8663 10.3578 21.7793 7.96387 18.8936 7.96387C16.0079 7.96387 17.8486 12.1439 20.9837 13.7114Z" fill="#FDFDFD"/>
            </g>
            <defs>
            <filter id="filter0_i_2354_11245" x="-2.46226" y="2.63247" width="41.263" height="68.9525" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="-6.27005" dy="-6.27005"/>
            <feGaussianBlur stdDeviation="2.09002"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2354_11245"/>
            </filter>
            <filter id="filter1_i_2354_11245" x="28.1113" y="16.4414" width="12.7228" height="55.6091" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="2.09002" dy="2.09002"/>
            <feGaussianBlur stdDeviation="2.09002"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2354_11245"/>
            </filter>
            <filter id="filter2_f_2354_11245" x="9.84935" y="1.02318" width="17.9234" height="13.1436" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="1.56751" result="effect1_foregroundBlur_2354_11245"/>
            </filter>
            <filter id="filter3_f_2354_11245" x="12.2281" y="2.73883" width="22.9638" height="20.0756" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="2.61252" result="effect1_foregroundBlur_2354_11245"/>
            </filter>
            <radialGradient id="paint0_radial_2354_11245" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.7844 35.6562) rotate(84.6678) scale(39.3579 22.5329)">
            <stop stopColor="#D7BA8E"/>
            <stop offset="0.4967" stopColor="#A47F4A"/>
            <stop offset="1" stopColor="#886024"/>
            </radialGradient>
            <linearGradient id="paint1_linear_2354_11245" x1="27.8281" y1="22.989" x2="41.4131" y2="70.0142" gradientUnits="userSpaceOnUse">
            <stop stopColor="#725935"/>
            <stop offset="1" stopColor="#5B482A"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2354_11245" x1="18.5767" y1="0" x2="18.5767" y2="23.6443" gradientUnits="userSpaceOnUse">
            <stop offset="0.64" stopColor="white"/>
            <stop offset="1" stopColor="#B48B8B"/>
            </linearGradient>
            <radialGradient id="paint3_radial_2354_11245" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.31708 9.8353) rotate(32.905) scale(10.5799 16.4453)">
            <stop stopColor="white" stopOpacity="0"/>
            <stop offset="1" stopColor="#9B9B9B"/>
            </radialGradient>
            </defs>
            </svg>

    </>
	);
};