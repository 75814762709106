import React, { FC } from 'react';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import HighlightedTitle from '../../../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { CardBody } from '../../../../../components/bootstrap/Card';
import Button from '../../../../../components/bootstrap/Button';
import Icon from '../../../../../components/icon/Icon';

interface IAddCategory {
	uuid?: string;
	setValues(...args: unknown[]): unknown;
	values: any;
}

const AddCategory: FC<IAddCategory> = ({ uuid, setValues, values }) => {
	const changeValues = (key: string, value: any) => {
		setValues((prev: any) => ({
			...prev,
			name: {
				...prev.name,
				[key]: value,
			},
		}));
	};

	const changeValuesArray = (key: string, value: any, index: number) => {
		setValues((prev: any) => {
			const updatedCategories = [...prev.categories];
			if (updatedCategories[index]) {
				updatedCategories[index] = {
					...updatedCategories[index],
					name: {
						...updatedCategories[index].name,
						[key]: value,
					},
				};
			}
			return { ...prev, categories: updatedCategories };
		});
	};

	const addCategory = () => {
		setValues((prev: any) => ({
			...prev,
			categories: [
				...prev.categories,
				{
					name: {
						en: '',
						arm: '',
						rus: '',
					},
				},
			],
		}));
	};

	const deleteCategory = (index: number) => {
		setValues((prev: any) => {
			const updatedMaterials = prev.categories.filter((_: any, i: number) => i !== index);
			if (updatedMaterials.length <= 0) {
				return {
					...prev,
					categories: updatedMaterials,
				};
			}
			return {
				...prev,
				categories: updatedMaterials,
			};
		});
	};


	return (
		<div className={'col-12'}>
			<CardBody style={{ backgroundColor: '#DBEAFF', borderRadius: 12 }}>
				<div className='p-1 ' style={{ maxWidth: '100%', overflow: 'auto' }}>
					{!uuid &&
						values?.categories?.map((item: any, index: number) => (
							<div
								className='col-12 d-flex flex-column  gap-2'
								style={{ marginBottom: 20 }}>
								{values?.categories?.length > 1 && (
									<div className='col-12'>
										<div
											key={`div-11-${index}`}
											style={{
												width: 'auto',
												// position: 'absolute',
											}}
											className='d-flex flex justify-content-end gap-3 border-0'>
											<Button
												isOutline={true}
												style={{ width: 45, height: 34 }}
												onClick={() => deleteCategory(index)}
												className='col-4 h-50 rounded delete-add-dental delete-dental'>
												<Icon
													icon={'Delete'}
													color={'danger'}
													size={'lg'}
												/>
											</Button>
										</div>
									</div>
								)}
								<HighlightedTitle title={'*Название Специализации'} />

								<FormGroup label='RUS' className='col-12'>
									<Input
										type='text'
										onChange={(e: any) =>
											changeValuesArray('rus', e.target.value, index)
										}
										value={item.name.rus}
									/>
								</FormGroup>
								<FormGroup label='ARM' className='col-12'>
									<Input
										type='text'
										onChange={(e: any) =>
											changeValuesArray('arm', e.target.value, index)
										}
										value={item.name.arm}
									/>
								</FormGroup>
								<FormGroup label='ENG' className='col-12'>
									<Input
										type='text'
										onChange={(e: any) =>
											changeValuesArray('en', e.target.value, index)
										}
										value={item.name.en}
									/>
								</FormGroup>
							</div>
						))}
					{uuid && (
						<div className='col-12 d-flex flex-column  gap-2'>
							<HighlightedTitle title={'*Название Специализации'} />

							<FormGroup label='RUS' className='col-12'>
								<Input
									type='text'
									onChange={(e: any) => changeValues('rus', e.target.value)}
									value={values.name.rus}
								/>
							</FormGroup>
							<FormGroup label='ARM' className='col-12'>
								<Input
									type='text'
									onChange={(e: any) => changeValues('arm', e.target.value)}
									value={values.name.arm}
								/>
							</FormGroup>
							<FormGroup label='ENG' className='col-12'>
								<Input
									type='text'
									onChange={(e: any) => changeValues('en', e.target.value)}
									value={values.name.en}
								/>
							</FormGroup>
						</div>
					)}
					{!uuid && (
						<Button
							onClick={addCategory}
							style={{ marginTop: 20 }}
							color={'primary'}
							isOutline>
							Добавить еще специлизацию
						</Button>
					)}
				</div>
			</CardBody>
		</div>
	);
};

export default AddCategory;
