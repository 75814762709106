import React, { FC, memo } from 'react';
import { SubHeaderRight } from '../../../../../layout/SubHeader/SubHeader';
import Button from '../../../../../components/bootstrap/Button';
import classNames from 'classnames';
import useDarkMode from '../../../../../hooks/useDarkMode';

interface ISubHeaderRightCompnentProps {
	setIsOpen(...args: unknown[]): unknown;
}

const SubHeaderRightCompnent: FC<ISubHeaderRightCompnentProps> = memo(({ setIsOpen }) => {
	const { darkModeStatus } = useDarkMode();
	return (
		<SubHeaderRight className='mw-auto'>
			<Button
				color='primary'
				className={classNames('text-nowrap', {
					'border-light': !darkModeStatus,
				})}
				icon='Add'
				onClick={setIsOpen}>
				Запланировать визит{' '}
			</Button>
		</SubHeaderRight>
	);
});

export default SubHeaderRightCompnent;
