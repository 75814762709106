import React, { FC, memo } from 'react';
import UserInfo from './Components.tsx/UserInfo';
import UserStatus from './Components.tsx/UserStatus';

interface IUserProfileStatusComponentProps {
	setActivation(...args: unknown[]): unknown;
	user: any;
}

const UserProfileStatusComponent: FC<IUserProfileStatusComponentProps> = memo(
	({ setActivation, user }) => {
		return (
			<>
				<div className='pt-3 pb-3 d-flex align-items-center justify-content-between'>
					<div className='d-flex align-items-center'>
						{/* <PateinetId user={user} /> */}
						<UserInfo user={user} />
						<UserStatus setActivation={setActivation} user={user} />
					</div>
					<div className='d-flex align-items-center'>
						{/* <PatientVisit visits={patient?.visits} />
						<PatientSum patientUuid={patient?.uuid} />
						<PatientSumDebt patientUuid={patient?.uuid} /> */}
					</div>
				</div>
			</>
		);
	},
);

export default UserProfileStatusComponent;
