import React, { SVGProps } from 'react';

const SvgNotSuccessful = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 18 18" fill='currentColor' xmlns="http://www.w3.org/2000/svg" {...props} >
            <path opacity="0.3" d="M8.99976 4.875C7.13226 4.875 5.99976 6.39 5.99976 8.25V12.75H11.9998V8.25C11.9998 6.39 10.8673 4.875 8.99976 4.875Z" fill="white"/>
            <path d="M8.99976 16.5C9.82476 16.5 10.4998 15.825 10.4998 15H7.49976C7.49976 15.825 8.17476 16.5 8.99976 16.5ZM13.4998 12V8.25C13.4998 5.9475 12.2773 4.02 10.1248 3.51V3C10.1248 2.3775 9.62226 1.875 8.99976 1.875C8.37726 1.875 7.87476 2.3775 7.87476 3V3.51C5.72976 4.02 4.49976 5.94 4.49976 8.25V12L2.99976 13.5V14.25H14.9998V13.5L13.4998 12ZM11.9998 12.75H5.99976V8.25C5.99976 6.39 7.13226 4.875 8.99976 4.875C10.8673 4.875 11.9998 6.39 11.9998 8.25V12.75Z" fill="white"/>
            <circle cx="13.5618" cy="14.3038" r="2.88554" fill="white"/>
            <path d="M15.2832 12.8611L12.8981 15.2462L11.9558 14.3061L11.6379 14.624L12.8981 15.8841L15.6033 13.1789L15.2832 12.8611Z" fill="#0D6EFD"/>
        </svg>
    );
};

export default SvgNotSuccessful;
