import React, { FC } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import useDarkMode from '../../../../../hooks/useDarkMode';
import { TColor } from '../../../../../type/color-type';
interface IOffCanvasComponentMedicalCaseProps {
	canvasData: any;
	canvasColor: TColor;
}

const OffCanvasComponentMedicalCase: FC<IOffCanvasComponentMedicalCaseProps> = ({
	canvasData,
	canvasColor,
}) => {
	const { themeStatus } = useDarkMode();
	const healingPlan = canvasData?.medicalCase?.stage?.find(
		(item: any) => item?.uuid === canvasData?.stageUuid,
	);
	return (
		<Card
			className={`mb-10 ${themeStatus === 'light' ? `bg-l10-${canvasColor}` : 'bg-dark'}`}
			shadow='sm'>
			<CardHeader
				className={`${
					themeStatus === 'light' ? `bg-l25-${canvasColor}` : `bg-${canvasColor}`
				}`}>
				<CardLabel
					iconColor={themeStatus === 'light' ? `${canvasColor}` : 'light'}
					icon='DoneAll'>
					<CardTitle
						className={`${
							themeStatus === 'light' ? `text-${canvasColor}` : 'text-white'
						}`}>
						Медицинский случай
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<FormGroup id='date' label='Название  Медицинского  случая' className='mb-2'>
					<p
						style={{
							color: themeStatus === 'light' ? 'black' : 'white',
						}}>
						{canvasData?.medicalCase?.title}
					</p>
				</FormGroup>
				<FormGroup id='date' label='Описание  медицинского случая' className='mb-2'>
					<p
						style={{
							color: themeStatus === 'light' ? 'black' : 'white',
						}}>
						{canvasData?.medicalCase?.description
							? canvasData?.medicalCase?.description
							: 'нет описания'}
					</p>
				</FormGroup>
				<FormGroup id='date' label='План Лечения' className='mb-2'>
					<p
						style={{
							color: themeStatus === 'light' ? 'black' : 'white',
						}}>
						{healingPlan?.title}
					</p>
				</FormGroup>
			</CardBody>
		</Card>
	);
};
export default OffCanvasComponentMedicalCase;
