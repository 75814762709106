import React, { SVGProps } from "react";

export const THEETH_42 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2rem" height="6.15rem" viewBox="0 0 25 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M9.81836 78C1.61555 70.0399 3.55124 33.7482 4.69052 15.5067C4.98638 8.54175 16.6433 0.0839558 16.3114 8.54222C15.7604 22.5843 12.8184 62.5 12.2113 76.508C11.6384 79.3995 11.0091 79.1555 9.81836 78Z" fill="url(#paint0_linear_402_4955)"/>
<g filter="url(#filter0_f_402_4955)">
<path d="M9.5791 27.6533L7.5791 44.1533" stroke="#F3F3F3" strokeWidth="1.5" strokeLinecap="round"/>
</g>
<path d="M10.2226 77.4359L16.6724 5C16.6724 5 13.6724 71 12.6724 76.5C11.901 80.7424 11.156 78.9971 10.6058 78.2359C10.4953 78.083 10.2059 77.6238 10.2226 77.4359Z" fill="url(#paint1_linear_402_4955)"/>
<path d="M17.4501 14.5C17.4501 17 15.4501 29.5 9.45014 29.5C3.95009 29.5 1.9502 17.5 1.9502 14.5V4.5C1.9502 3.5 1.9502 0 3.9502 0H13.4502C15.9502 0 16.9501 1.5 17.4501 3.5C17.9501 5.5 17.4501 12 17.4501 14.5Z" fill="url(#paint2_linear_402_4955)"/>
<path d="M17.4501 14.5C17.4501 17 15.4501 29.5 9.45014 29.5C3.95009 29.5 1.9502 17.5 1.9502 14.5V4.5C1.9502 3.5 1.9502 0 3.9502 0H13.4502C15.9502 0 16.9501 1.5 17.4501 3.5C17.9501 5.5 17.4501 12 17.4501 14.5Z" fill="url(#paint3_radial_402_4955)" fillOpacity="0.3"/>
<g filter="url(#filter1_f_402_4955)">
<path d="M11.0762 12.5C10.0762 17.5 14.8146 16 17.576 16C20.3375 16 19.5762 13.7091 19.5762 11.5C19.5762 9.29086 11.8376 7 9.07614 7C6.31472 7 8.07617 11 11.0762 12.5Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_f_402_4955" x="0.829102" y="20.9033" width="15.5" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4955"/>
</filter>
<filter id="filter1_f_402_4955" x="2.69775" y="2" width="21.9746" height="19.2109" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_402_4955"/>
</filter>
<linearGradient id="paint0_linear_402_4955" x1="9.97957" y1="5" x2="9.97957" y2="78.8088" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4955" x1="11.2815" y1="14.1293" x2="46.514" y2="54.1545" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4955" x1="9.81128" y1="0" x2="9.81128" y2="29.5" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4955" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.07227 11.2402) rotate(99.7279) scale(3.96848 2.56829)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};