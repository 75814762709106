import React, { FC, memo } from 'react';
import { IEvents } from '../../../../../../../common/data/events';
import { IUserProps } from '../../../../../../../common/data/userDummyData';
import Icon from '../../../../../../../components/icon/Icon';
import { TColor } from '../../../../../../../type/color-type';
import dayjs from 'dayjs';

interface IEvent extends IEvents {
	user?: IUserProps;
	users?: IUserProps[];
	color?: TColor;
}

interface MyEventProps {
	event: IEvent;
	setIsOpen: (isOpen: boolean) => void;
	setTimePickerIsOpen: (isOpen: boolean) => void;
}

export const MyEvent: FC<MyEventProps> = memo(({ event, setIsOpen, setTimePickerIsOpen }) => {
	const title = `${dayjs(event.start).format('LT')} - ${dayjs(event.end).format('LT')}`;
	return (
		<span>
			{event.id === 'visitDate' && (
				<div
					color={event.color}
					className='d-flex flex-column delete-data-time p-2 align-items-center justify-content-center gap-2'
					style={{
						width: '100%',
						height: '100%',
						position: 'absolute',
						left: 0,
						top: 0,
						backgroundColor: '#1266f1',
						color: '#fff',
						fontSize: '.75rem',
					}}>
					<span className='text-truncate text-center'>{title}</span>
					<div className='d-flex justify-content-center align-items-start gap-3'>
						<span onClick={() => setTimePickerIsOpen(true)}>
							<Icon icon='Edit' size='lg' />
						</span>

						{new Date(event?.prevVisit?.startDate).getTime() !==
							event?.start?.getTime() &&
							new Date(event?.prevVisit?.endDate).getTime() !==
								event?.end?.getTime() && (
								<span
									onClick={() => {
										setIsOpen(true);
									}}>
									<Icon icon='Delete' size='lg' />
								</span>
							)}
					</div>
				</div>
			)}

			<div className='col-auto'></div>
		</span>
	);
});
