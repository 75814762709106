import React, { SVGProps } from "react";


export const PRINT_TOOTH_14 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg   width="67" height="100" viewBox="0 0 18 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M16.4 36.2619C14.5798 8.60066 12.569 5.25869 8.73792 3.4769C4.90687 1.69511 1.05914 31.4004 1.42409 38.4" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M10.2663 32.4C18.3627 34.0135 18.7258 43.9443 15.5131 48.604C13.7841 51.1115 11.5236 51.7721 4.62551 51.2166C3.29368 51.2166 -1.38062 48.9108 1.12784 39.2489C2.69276 33.2213 7.55463 32.4 10.2663 32.4Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}


