import React, { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface ILoadingProps {
	loading: boolean;
}

const Loading: FC<ILoadingProps> = ({ loading }) => {
	return (<>
    {
            loading&&
            <div
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
			className='col-12 h-100'>
			<Spin style={{ color: 'black' }} indicator={<LoadingOutlined spin />} />
		</div>
        }
    </>
        
		
	);
};

export default Loading;
