import React, { FC, memo, useEffect, useState } from 'react';
import CustomSelect from '../../../../../../../../custom/components/Select/select';
import FormGroup from '../../../../../../../../components/bootstrap/forms/FormGroup';
import { IClinic } from '@textnpayme/custom/api/interface/clinic.interface';
import { useDebounce } from '../../../../../../../../hooks/useDebounce';
import { ClinicService } from '../../../../../../../../custom/api/service/clinic.service';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { CategoryService } from '../../../../../../../../custom/api/service/category.service';
import ResetComponent from './ResetComponent';

interface IHeaderProps {
	values: any;
	changeSearchValue(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	search: any;
	isUserDoctor: boolean;
	setUsersList(...args: unknown[]): unknown;
	resetFilter(...args: unknown[]): unknown;
}

const SearchUserSelect: FC<IHeaderProps> = memo(
	({ values, isUserDoctor, changeSearchValue,resetFilter, changeValues, search, setUsersList }) => {
		const [selectedCategory, setSelectedCategory] = useState<any>(null);
		const DebouncedSearch = useDebounce(search);
		const { data: clinics } = useQuery<IClinic | null, Error>(
			['clinics', DebouncedSearch],
			() =>
				ClinicService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},
						filterMeta: {
							...(search?.clinic && { name: { iLike: `%${search.clinic}%` } }),
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
			['categories'],
			() =>
				CategoryService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
						},
						includeMeta: [
							{
								association: 'subCategory',
							},
						],
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const categories = categoriesWithSubCategories?.data
			.filter((item: any) => !item.categoryUuid)
			.map((category: any) => ({
				...category,
				categoriesUuid: category.uuid,
			}));

		const subCategories = categoriesWithSubCategories?.data
			.filter((item: any) => item.categoryUuid)
			.map((subCategory: any) => ({
				...subCategory,
				subCategoryUuid: subCategory.uuid,
			}));

		const filteredSubCategories = subCategories?.filter(
			(subCategory: any) => subCategory.categoryUuid === selectedCategory,
		);

		useEffect(() => {
			setSelectedCategory(values.categoriesUuid);
		}, [values.categoriesUuid]);

		return (
			<div className='d-flex gap-4 align-items-center'>
				<div className='d-flex gap-3 align-items-center'>
					<FormGroup id='clinic' className='m-0 d-flex flex-column'>
						<CustomSelect
							placeholder={'Выберите филиал'}
							disabled={isUserDoctor}
							name={'clinic'}
							value={values.clinicUuid}
							options={clinics?.data.map((item: IClinic, index: number) => {
								return {
									value: item.uuid,
									label: item.name,
								};
							})}
							changeSearchValue={changeSearchValue}
							changeValues={changeValues}
						/>
					</FormGroup>
					<FormGroup
						id='category'
						// label='Категория'
						className='m-0 d-flex flex-column'>
						<CustomSelect
							placeholder={'Выберите Специализацию'}
							name={'categories'}
							value={values.categoriesUuid}
							options={categories?.map((item: any, index: number) => {
								return {
									value: item.categoriesUuid,
									label: item?.name?.en,
								};
							})}
							changeSearchValue={changeSearchValue}
							changeValues={changeValues}
						/>
					</FormGroup>
					{selectedCategory && (
						<FormGroup
							id='category'
							// label='Специализация'
							className='m-0 d-flex flex-column'>
							<CustomSelect
								placeholder={'Выберите Специализацию'}
								// disabled={isUserDoctor}
								name={'subCategory'}
								value={values.subCategoryUuid}
								options={filteredSubCategories?.map((item: any, index: number) => {
									return {
										value: item.subCategoryUuid,
										label: item?.name?.en,
									};
								})}
								changeSearchValue={changeSearchValue}
								changeValues={changeValues}
							/>
						</FormGroup>
					)}
				</div>
				<ResetComponent
					reset={resetFilter}
				/>
			</div>
		);
	},
);

export default SearchUserSelect;
