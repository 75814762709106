import React, { FC, useEffect, useState } from 'react';
import ServiceTable from './ServiceTable';
import { useQuery } from 'react-query';
import qs from 'qs';
import { PER_COUNT } from '../../../../../components/PaginationButtons';
import { IService } from '../../../../api/interface/service.interface';
import { UserService } from '../../../../api/service/user.service';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import { ServiceService } from '../../../../../custom/api/service/visit-service.service';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface IServicePage {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const ServicePage: FC<IServicePage> = ({ search, setSearch }) => {
	const [serviceSort, setServiceSort] = useState<boolean>(false);

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	const [values, setValues] = useState<IService>({
		categoryUuid: '',
		clinicUuid: '',
		code: '',
		currency: '',
		groups: [''],
		material: true,
		mkb10DiseaseUuid: [''],
		price: '',
		service_type: '',
		subCategoryUuid: '',
		title_all: { rus: '', arm: '', en: '' },
		treatment_type: '',
		unit_measurement: '',
		order: { createdAt: 'DESC' },
	});

	const [filter, setFilter] = useState<IService>({
		clinic: '',
		treatment_type: '',
		category: '',
		subCategory: '',
		code: '',
		search: '',
	});
	const filterDebounce = useDebounce(filter);
	const deboncedSearch = useDebounce(search)

	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['services', currentPage, perPage, filterDebounce, serviceSort, deboncedSearch],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'clinic',
							...(!!filter.clinic && {
								where: {
									uuid: filter.clinic,
								},
							}),
						},
						{
							association: 'category',
							...(!!filter.category && {
								where: {
									categoryUuid: filter.category,
								},
							}),
						},

						{
							association: 'subCategory',
							...(!!filter.subCategory && {
								where: {
									subCategory: filter.subCategory,
								},
							}),
						},

						{
							association: 'mkb10s',
						},

						{
							association: 'groups',
						},

						// {
						// 	association: 'subCategory',
						// 	...(!!filter.subCategory && {
						// 		where: {
						// 			subCategoryUuid: filter.subCategory,
						// 		},
						// 	}),
						// },
					],
					filterMeta: {
						...(!!deboncedSearch && {
							websearchQuery: { searchVector: deboncedSearch },
						}),
						// websearchQuery: { searchVector: search.mkb },

						// ...(values.serviceName && {
						// 	...(values.serviceName && /\d/.test(values.serviceName)
						// 		? {
						// 				or: [
						// 					{ serviceId: { iLike: `%${values.serviceName}% ` } },
						// 					{ code: { iLike: `%${values.serviceName}% ` } },
						// 				],
						// 		  }
						// 		: { title: { iLike: `%${values.serviceName}%` } }),
						// }),
						...(!!filter.treatment_type && {
							treatment_type: filter.treatment_type,
						}),
						// ...(filter.code && {
						// 	or: [
						// 		{ serviceId: { iLike: `%${filter.code}%` } },
						// 		{ code: { iLike: `%${filter.code}%` } },
						// 	],
						// }),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	console.log(services, 'servicesservicesservicesservicesservices');

	const { data: categoriesWithCategories } = useQuery<any | null, Error>(
		['categoriesWithCategories'],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const categories = categoriesWithCategories?.data?.filter((item: any) => !item.categoryUuid);

	return (
		<>
			<ServiceTable
				isFluid
				setValues={setValues}
				values={values}
				services={services}
				categories={categories}
				isUserDoctor={isUserDoctor}
				setCurrentPage={setCurrentPage}
				setPerPage={setPerPage}
				currentPage={currentPage}
				perPage={perPage}
				filter={filter}
				setFilter={setFilter}
				sort={serviceSort}
				setSort={setServiceSort}
			/>
		</>
	);
};

export default ServicePage;
