import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../hooks/useDarkMode';

interface ICommonUpcomingEventsProps {
	data?: any;
	setOpenModal(...args: unknown[]): unknown;
	setData(...args: unknown[]): unknown;
	setOpenSubmitModal(...args: unknown[]): unknown;
	setUuid(...args: unknown[]): unknown;
}

const GroupsDropdownActions: FC<ICommonUpcomingEventsProps> = ({
	data,
	setData,
	setOpenModal,
	setOpenSubmitModal,
	setUuid,
}) => {
	const [dropdownClose, setDropdownClose] = useState<boolean>(false);
	const { darkModeStatus } = useDarkMode();

	return (
		<Dropdown isOpen={dropdownClose} setIsOpen={setDropdownClose}>
			<DropdownToggle hasIcon={false}>
				<Button
					isOutline={!darkModeStatus}
					color='dark'
					isLight={darkModeStatus}
					className={classNames(
						'text-nowrap',

						{
							'border-light': !darkModeStatus,
						},
						'fw-normal',
						'fs-6',
					)}>
					•••
				</Button>
			</DropdownToggle>
			<DropdownMenu className='fw-bold'>
				<DropdownItem>
					<Button
						onClick={() => {
							setOpenModal(true);
							setData(data.materials);
							setDropdownClose(false);
						}}>
						Материалы
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						onClick={() => {
							setOpenSubmitModal(true);
							setUuid(data.uuid);
							setDropdownClose(false);
						}}>
						Удалить
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default GroupsDropdownActions;
