import React, { FC, memo } from 'react';

interface IUserStatusProps {
	setActivation(...args: unknown[]): unknown;
	user: any;
}

const UserStatus: FC<IUserStatusProps> = memo(({ setActivation, user }) => {
	return (
		<div className='row flex-nowrap pt-1 g-2 mt-2 doctor-roles-wrapper'>
			{user?.roles
				? user?.roles.map((i: any) => {
						return (
							<>
								<div className='col-auto'>
									<span className='border border-success border-2 text-success fw-bold px-2 py-2 rounded'>
										{i?.clinics?.name} - {i?.role}
									</span>
								</div>
							</>
						);
				  })
				: null}
		</div>
	);
});

export default UserStatus;
