import React, { FC, useCallback, useEffect, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import { useMutation,  useQueryClient } from 'react-query';
import { IPatientCreate } from '@textnpayme/custom/api/interface/patient.interface';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { roles, rolesTranslate } from '../../../utils/dataUtil';
import { ConfigService } from '../../../custom/api/service/config.service';

interface IEditConfigProps {
	config: any;
	isOpen: any;
	setIsOpen(...args: unknown[]): unknown;
	setConfig(...args: unknown[]): unknown;
	id: string;
}

const EditConfigModal: FC<IEditConfigProps> = ({ isOpen, setIsOpen, id, config, setConfig }) => {
	const queryClient = useQueryClient();

	const { mutate } = useMutation(
		({ uuid, inputDto }: { uuid: string; inputDto: IPatientCreate }) =>
			ConfigService.update(uuid, inputDto),
		{
			onSuccess: (response, formData) => {
				queryClient.invalidateQueries('configs');
				setIsOpen(false);
			},
			onError: (error) => {
				console.log(error);
			},
		},
	);

	const [values, setValues] = useState<any>({
		description: '',
		access: [],
		uuid: '',
	});

	useEffect(() => {
		if (isOpen) {
			setValues((prev: any) => ({
				description: config?.value?.description ?? '',
				access: config?.value?.access,
				uuid: config?.uuid,
			}));
		}
	}, [config, isOpen]);

	const changeConfig = useCallback((field: 'access' | 'description', value: string) => {
		setValues((prev: any) => {
			if (field === 'description') {
				return {
					...prev,
					description: value, // Update description directly
				};
			}

			if (field === 'access') {
				let updatedAccess = [...prev.access];

				if (value === 'any' || value === 'all') {
					updatedAccess = [value]; // Clear all and set only "any" or "all"
				} else {
					if (updatedAccess.includes(value)) {
						updatedAccess = updatedAccess.filter((item) => item !== value); // Remove if exists
					} else {
						// Remove "any" or "all" if adding a role
						updatedAccess = updatedAccess.filter(
							(item) => item !== 'any' && item !== 'all',
						);
						updatedAccess.push(value); // Add the new role
					}
				}

				return {
					...prev,
					access: updatedAccess,
				};
			}

			return prev;
		});
	}, []);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		mutate({ uuid: values.uuid, inputDto: values });
	};

	return (
		<Modal isStaticBackdrop isOpen={isOpen} setIsOpen={setIsOpen} size={'xl'} isCentered>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='title' style={{ fontSize: '1.75rem' }}>
					Редактировать доступ
				</ModalTitle>
			</ModalHeader>
			<form onSubmit={handleSubmit}>
				<ModalBody className='px-4 d-flex flex-column gap-4'>
					<span className='fs-5'>
						<span className='fw-bold'>Запрос</span> - {config?.value?.operation}
					</span>
					<FormGroup id='name' label='Описание'>
						<Textarea
							onChange={(e: any) => changeConfig('description', e.target.value)}
							value={values.description}
						/>
					</FormGroup>
					<div className='col-12'>
						<span className='fs-4 fw-bold'>Доступы</span>
						<div className='d-flex col-10 flex-wrap'>
							{roles.map((role: string, role_index: number) => (
								<div
									className='form-check m-0'
									style={{
										border: 'unset',
										minWidth: 120,
									}}>
									<input
										// color={themeStatus}
										className='form-check-input'
										style={{
											top: 7,
											right: 0,
											left: '2rem',
										}}
										type='checkbox'
										name='services'
										onChange={(e: any) => {
											changeConfig('access', role);
										}}
										checked={values?.access?.includes(role)}
										id={`${config?.controller}_${role_index}`}
									/>
									<label
										className=' col-12 d-flex gap-3 p-2 bg-transparent border-0 border-bottom-0'
										htmlFor={`${config?.controller}_${role_index}`}>
										<span
											className='d-flex m-0 ms-4 ps-2 text-truncate'
											style={{ width: '100%' }}>
											{rolesTranslate[role]}
										</span>
									</label>
								</div>
							))}
						</div>
					</div>
				</ModalBody>

				<ModalFooter className='px-4 pb-4 '>
					<Button color='info' type='submit' className='col-12'>
						Редактировать
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default EditConfigModal;
