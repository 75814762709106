import React, { FC, memo, useCallback } from 'react';
import { formatDateToYYYYMMDD } from '../../../../../utils/functions';
import Card, { CardBody, CardHeader, CardLabel } from '../../../../../components/bootstrap/Card';
import useDarkMode from '../../../../../hooks/useDarkMode';

interface IVisitInfoComponentProps {
	values: any;
}

const VisitInfoComponent: FC<IVisitInfoComponentProps> = memo(({ values }) => {
	const timeFormatter = useCallback((time: string) => {
		return new Date(time).toTimeString().slice(0, 5);
	}, []);

	const dateOfVisit = useCallback(
		(start: any, end: any) => {
			if (start && !end) {
				return timeFormatter(start);
			}
			if (start && end) {
				return `${timeFormatter(start)} -	${timeFormatter(end)}`;
			}
		},
		[timeFormatter],
	);

	const { themeStatus } = useDarkMode();

	return (
		<Card className={`bg-light-primary rounded-2 mb-0 shadow-none`}>
			<CardHeader className='bg-transparent pb-3'>
				<CardLabel icon='Article' iconColor='primary'>
					<span className='fs-4 fw-bold'>Детали визита</span>
				</CardLabel>
			</CardHeader>
			<CardBody className='pt-0 d-flex flex-column gap-2'>
				<p className='m-0 fs-6 d-flex' style={{ color: themeStatus, fontWeight: 400 }}>
					<span className='w-100' style={{ maxWidth: '3.5rem' }}>
						Дата
					</span>
					<span className='text-primary fw-bold'>
						{!!values?.visitDate && formatDateToYYYYMMDD(new Date(values?.visitDate))}
					</span>
				</p>
				<p className='m-0 fs-6 d-flex' style={{ color: themeStatus, fontWeight: 400 }}>
					<span className='w-100' style={{ maxWidth: '3.5rem' }}>
						Время
					</span>
					<span className='text-primary fw-bold'>
						{!!values?.visitDate && dateOfVisit(values?.visitDate, values?.endDate)}
					</span>
				</p>
				<p className='m-0 fs-6 d-flex' style={{ color: themeStatus, fontWeight: 400 }}>
					<span className='w-100' style={{ maxWidth: '3.5rem' }}>
						Врач
					</span>
					<span className='text-primary fw-bold'>
						{values?.userFullName && values?.userFullName}
					</span>
				</p>
			</CardBody>
		</Card>
	);
});

export default VisitInfoComponent;
