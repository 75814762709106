import React from "react";
import {ICircle} from '../../custom/api/interface/colored_circles'

export const TC_BL: React.FC<ICircle> = ({
  fill,
  startColor, 
  endColor,
  ...props
}) => {
      const uniqueId = `circle-${Math.random().toString(36).substr(2, 9)}`;
    return (
            <svg width="1.6rem" height="1.6rem" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id={`path-1-inside-${uniqueId}`} fill={"white"}>
                <path d="M0.5 0.22998C0.5 2.85642 1.01731 5.45714 2.02241 7.88365C3.0275 10.3102 4.50069 12.5149 6.35786 14.3721C8.21504 16.2293 10.4198 17.7025 12.8463 18.7076C15.2728 19.7127 17.8736 20.23 20.5 20.23V0.22998H0.5Z"/>
                </mask>
                <g filter="url(#filter0_i_523_10065)">
                <path d="M0.5 0.22998C0.5 2.85642 1.01731 5.45714 2.02241 7.88365C3.0275 10.3102 4.50069 12.5149 6.35786 14.3721C8.21504 16.2293 10.4198 17.7025 12.8463 18.7076C15.2728 19.7127 17.8736 20.23 20.5 20.23V0.22998H0.5Z"  fill={`url(#paint0_linear_${uniqueId})`} fillOpacity={!!fill?"0.8":"0.3"}/>
                </g>
                <path d="M0.5 0.22998C0.5 2.85642 1.01731 5.45714 2.02241 7.88365C3.0275 10.3102 4.50069 12.5149 6.35786 14.3721C8.21504 16.2293 10.4198 17.7025 12.8463 18.7076C15.2728 19.7127 17.8736 20.23 20.5 20.23V0.22998H0.5Z" stroke="black" strokeOpacity="0.2" mask={`url(#path-1-inside-${uniqueId})`}/>
                <defs>
                <filter id="filter0_i_523_10065" x="0.5" y="-3.77002" width="20" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_523_10065"/>
                </filter>
                <linearGradient id={`paint0_linear_${uniqueId}`} x1="20.5" y1="19.73" x2="0.5" y2="0.229978" gradientUnits="userSpaceOnUse">
            <stop stopColor={fill && startColor ? startColor : "#F8F4ED"} />
            <stop offset="1" stopColor={fill && endColor ? endColor : "#9B9B9B"} />
                </linearGradient>
                </defs>
            </svg>
	);
};