import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { demoPagesMenu } from '../../../menu';
import GroupsTable from './GroupsTable';
import PlanVisitModal from '../visit/planVisit/PlanVisitModal';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useDebounce } from '../../../hooks/useDebounce';
import { PER_COUNT } from '../../../components/PaginationButtons';
import { IService } from '../../api/interface/service.interface';
import { UserService } from '../../api/service/user.service';
import { GroupService } from '../../../custom/api/service/group.service';
import Header from './SubHeaderComponent/Search/Header';

const GroupsPage = () => {
	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
	const [materialsSort, setmaterialsSort] = useState<any>({});

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(qs.stringify({})),
	);

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	const [values, setValues] = useState<IService>({
		serviceName: undefined,
		name: undefined,
		order: { createdAt: 'DESC' },
	});

	const [filter, setFilter] = useState<IService>({
		clinicUuid: '',
		material_type: '',
		categoryUuid: '',
		store_type: '',
	});

	const [search, setSearch] = useState('');

	const DebouncedSearch = useDebounce(values);
	const filterDebounce = useDebounce(filter);
	const searchDebounce = useDebounce(search);

	const { data: groups }: { data: any[] | any; isLoading: boolean } = useQuery(
		['groups', filter, DebouncedSearch, currentPage, perPage, filterDebounce, searchDebounce],
		() =>
			GroupService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: searchDebounce?.length ? 1 : currentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'materials',
						},
					],
					filterMeta: {
						...(search && {
							websearchQuery: { searchVector: search },
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	return (
		<PageWrapper title={demoPagesMenu.listPages.subMenu.listFluid.text}>
			<Header
				search={search}
				setSearch={setSearch}
				sort={materialsSort}
				setSort={setmaterialsSort}
			/>
			<Page container='fluid'>
				<div />
				<GroupsTable
					isFluid
					setValues={setValues}
					values={values}
					groups={groups}
					isUserDoctor={isUserDoctor}
					setCurrentPage={setCurrentPage}
					setPerPage={setPerPage}
					currentPage={currentPage}
					perPage={perPage}
					filter={filter}
					setFilter={setFilter}
				/>
			</Page>
			<PlanVisitModal
				setIsOpen={setUpcomingEventsEditOffcanvas}
				isOpen={upcomingEventsEditOffcanvas}
			/>
		</PageWrapper>
	);
};

export default GroupsPage;
