import React, { SVGProps } from 'react';

const SvgNotAct = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path opacity="0.3" d="M4.1665 4.1665V15.8332H11.6665V11.6665H15.8332V4.1665H4.1665ZM9.99984 11.6665H5.83317V9.99984H9.99984V11.6665ZM14.1665 8.33317H5.83317V6.6665H14.1665V8.33317Z" fill="#664D03"/>
			<path d="M15.8333 4.16667V11.6667H11.6667V15.8333H4.16667V4.16667H15.8333ZM15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H12.5L17.5 12.5V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM10 11.6667H5.83333V10H10V11.6667ZM14.1667 8.33333H5.83333V6.66667H14.1667V8.33333Z" fill="#664D03"/>
		</svg>
	);
};

export default SvgNotAct;
