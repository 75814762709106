import React, { SVGProps } from "react";

export const THEETH_43 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="1.85rem" height="6.7rem" viewBox="0 0 24 87" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M16.6781 85.5141C4.67815 77.514 3.01141 40.8475 4.67808 22.5142C5.11089 15.5142 22.1638 7.0138 21.6783 15.5146C20.8722 29.6274 16.6781 69.0146 20.1787 84.0146C19.3406 86.9207 18.4201 86.6755 16.6781 85.5141Z" fill="url(#paint0_linear_402_4968)"/>
<g filter="url(#filter0_f_402_4968)">
<path d="M10.5791 42.7361C9.21172 51.0949 9.28026 55.4466 10.5791 62.7361" stroke="#F3F3F3"/>
</g>
<path d="M19.311 86C13.0638 60.1236 13.9125 43.5666 21.811 12C20.311 42.5 16.811 63 20.4841 83.3077C20.636 85.515 19.385 85.9748 19.3142 85.999L19.311 86Z" fill="url(#paint1_linear_402_4968)"/>
<path d="M23.1676 14.6837C22.067 30.2947 15.5998 33.1411 12.311 32.5146C0.811035 32.5146 0.311035 12.5146 0.311035 10.5146C0.311035 8.51465 4.31104 0.514633 11.311 0.0146332C15.7638 -0.303424 19.6687 4.62622 21.9196 8.67023C22.9361 10.4966 23.3146 12.5987 23.1676 14.6837Z" fill="url(#paint2_linear_402_4968)"/>
<path d="M23.1676 14.6837C22.067 30.2947 15.5998 33.1411 12.311 32.5146C0.811035 32.5146 0.311035 12.5146 0.311035 10.5146C0.311035 8.51465 4.31104 0.514633 11.311 0.0146332C15.7638 -0.303424 19.6687 4.62622 21.9196 8.67023C22.9361 10.4966 23.3146 12.5987 23.1676 14.6837Z" fill="url(#paint3_radial_402_4968)" fillOpacity="0.3"/>
<defs>
<filter id="filter0_f_402_4968" x="3.0791" y="36.6553" width="13.9937" height="32.1685" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4968"/>
</filter>
<linearGradient id="paint0_linear_402_4968" x1="12.9111" y1="11.9546" x2="12.9111" y2="86.327" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4968" x1="15.3137" y1="21.0533" x2="48.7809" y2="67.2619" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4968" x1="11.811" y1="0" x2="11.811" y2="32.5936" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4968" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.81103 15) rotate(150.255) scale(4.03113 8.06399)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};