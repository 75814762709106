import React, { FC, memo } from 'react';
import Button from '../../../../../components/bootstrap/Button';

interface IResestComponentProps {
	reset(...args: unknown[]): unknown;
	isSmallDesktop: any;
}

const ResestComponent: FC<IResestComponentProps> = memo(({ reset, isSmallDesktop }) => {
	return (
		<>
			<Button color='info' onClick={reset} isLight>
				Сбросить все {isSmallDesktop ? 'выбранные' : ''} даты и фильтры
			</Button>
		</>
	);
});

export default ResestComponent;
