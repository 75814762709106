import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Avatar from '../../../../components/Avatar';
import User1Img from '../../../../assets/img/wanna/wanna2.png';
import { demoPagesMenu } from '../../../../menu';
import { UserService } from '../../../../custom/api/service/user.service';
import { IPatientCreate } from '../../../../custom/api/interface/patient.interface';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ClinicService } from '../../../../custom/api/service/clinic.service';
import * as qs from 'qs';
import { notification, Upload } from 'antd';
import type { NotificationArgsProps } from 'antd';
import CommonMyWallet from '../../../../pages/_common/CommonMyWallet';
import Icon from '../../../../components/icon/Icon';
import showNotification from '../../../../components/extras/showNotification';
import editPasswordValidate from '../../../../pages/presentation/demo-pages/helper/editPasswordValidate';
import { CategoryService } from '../../../../custom/api/service/category.service';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import { rolesTranslate } from '../../../../utils/dataUtil';

type NotificationPlacement = NotificationArgsProps['placement'];

interface IValues {
	name: string;
	surname: string;
	middleName: string;
	email: string;
	phone: string;
	clinics: any;
}
interface Clinic {
	uuid: string;
	role?: string; // Role can be optional
}

interface IPreviewItemProps {
	title: string;
	value: any | any[];
}
const PreviewItem: FC<IPreviewItemProps> = ({ title, value }) => {
	return (
		<>
			<div className='col-3 text-end'>{title}</div>
			<div className='col-9 fw-bold'>{value || '-'}</div>
		</>
	);
};

const validate = (values: IValues) => {
	const errors: Partial<IValues> = {}; // Убираем пустые строки

	if (!values.name) {
		errors.name = 'Required';
	} else if (values.name.length < 3) {
		errors.name = 'Must be 3 characters or more';
	} else if (values.name.length > 20) {
		errors.name = 'Must be 20 characters or less';
	}

	if (!values.surname) {
		errors.surname = 'Required';
	} else if (values.surname.length < 3) {
		errors.surname = 'Must be 3 characters or more';
	} else if (values.surname.length > 20) {
		errors.surname = 'Must be 20 characters or less';
	}

	if (!values.middleName) {
		errors.middleName = 'Required';
	} else if (values.middleName.length > 30) {
		errors.middleName = 'Must be 30 characters or less'; // Исправлено
	}

	if (!values.email) {
		errors.email = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.phone) {
		errors.phone = 'Required';
	} else if (!/^\+?[0-9]{10,12}$/i.test(values.phone)) {
		errors.phone = 'Invalid phone number';
	}

	return errors;
};


interface IRoles {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const Roles:FC<IRoles> = ({search, setSearch}) => {
	return <h1>Должности и роли</h1>;
};

export default Roles;
