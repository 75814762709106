import React, { SVGProps } from "react";

export const THEETH_33 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="1.85rem" height="6.7rem" viewBox="0 0 24 87" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.94399 85.5141C18.9439 77.514 20.6107 40.8475 18.944 22.5142C18.5112 15.5142 1.45823 7.0138 1.94377 15.5146C2.74983 29.6274 6.94401 69.0146 3.44334 84.0146C4.28145 86.9207 5.20198 86.6755 6.94399 85.5141Z" fill="url(#paint0_linear_402_4975)"/>
<g filter="url(#filter0_f_402_4975)">
<path d="M13.043 42.7361C14.4104 51.0949 14.3418 55.4466 13.043 62.7361" stroke="#F3F3F3"/>
</g>
<path d="M4.31104 86C10.5582 60.1236 9.70961 43.5666 1.81104 12C3.31104 42.5 6.81104 63 3.13797 83.3077C2.98611 85.515 4.23712 85.9748 4.30792 85.999L4.31104 86Z" fill="url(#paint1_linear_402_4975)"/>
<path d="M0.454444 14.6837C1.55507 30.2947 8.02231 33.1411 11.311 32.5146C22.811 32.5146 23.311 12.5146 23.311 10.5146C23.311 8.51465 19.311 0.514633 12.311 0.0146332C7.85823 -0.303424 3.95338 4.62622 1.7025 8.67023C0.685954 10.4966 0.307442 12.5987 0.454444 14.6837Z" fill="url(#paint2_linear_402_4975)"/>
<path d="M0.454444 14.6837C1.55507 30.2947 8.02231 33.1411 11.311 32.5146C22.811 32.5146 23.311 12.5146 23.311 10.5146C23.311 8.51465 19.311 0.514633 12.311 0.0146332C7.85823 -0.303424 3.95338 4.62622 1.7025 8.67023C0.685954 10.4966 0.307442 12.5987 0.454444 14.6837Z" fill="url(#paint3_radial_402_4975)" fillOpacity="0.3"/>
<defs>
<filter id="filter0_f_402_4975" x="6.54932" y="36.6553" width="13.9937" height="32.1685" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4975"/>
</filter>
<linearGradient id="paint0_linear_402_4975" x1="10.711" y1="11.9546" x2="10.711" y2="86.327" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4975" x1="8.30841" y1="21.0533" x2="-25.1588" y2="67.2619" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4975" x1="11.811" y1="0" x2="11.811" y2="32.5936" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4975" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.811 15) rotate(29.7449) scale(4.03113 8.06399)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};