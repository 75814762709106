import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../../../../components/bootstrap/Modal';
import Icon from '../../../../../components/icon/Icon';
import Button from '../../../../../components/bootstrap/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';
import { ActService } from '../../../../../custom/api/service/act.service';
import { ServiceService } from '../../../../../custom/api/service/visit-service.service';
import { deepCopyAll, formatDate } from '../../../../../utils/functions';
import { notification, NotificationArgsProps } from 'antd';
import { ServiceComponent } from './ActComponents/ServicesComponent/ServiceComponent';
import { EstimateService } from '../../../../../custom/api/service/estimate.service';
import { StagaeServiceService } from '../../../../../custom/api/service/stage-visit-service.service';
import { ActDiscount } from './ActComponents/ActDiscount';

type NotificationPlacement = NotificationArgsProps['placement'];

interface IActModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	setActVisit(...args: unknown[]): unknown;
	visit: any;
}
const ActModal: FC<IActModalProps> = ({ isOpen, setIsOpen, visit, setActVisit }) => {
	const [values, setValues] = useState<IStage>({
		visitUuid: '',
		stageUuid: '',
		patientUuid: '',
		discount_type: 'amount',
		discount_amount: 0,
		services: [],
	});
	const printRef = useRef(null);

	const [api, contextHolder] = notification.useNotification();

	const openNotificationService = useCallback(
		(placement: NotificationPlacement, description: string) => {
			api.info({
				message: 'Важно!',
				description,
				placement,
				className: 'ant-notification-custom',
				props: { style: { zIndex: 150000 } },
			});
		},
		[api],
	);

	const [discount, setDiscount] = useState<any>('');
	const [isEstimate, setIsEstimate] = useState<boolean>(false);
	const [canEdit, setCanEdit] = useState<boolean>(true);

	const queryClient = useQueryClient();

	const { data: visit_services } = useQuery(
		['visit_services', visit, isOpen],
		() => ServiceService.findActServices(visit?.uuid),
		{
			refetchOnWindowFocus: false,
			enabled: !!visit?.uuid,
		},
	);
	// const { data: stage_services } = useQuery<any>(
	// 	['stage_services', visit, isOpen],
	// 	() =>
	// 		StagaeServiceService.findAll(
	// 			qs.stringify({
	// 				queryMeta: {
	// 					order: { createdAt: 'DESC' },
	// 				},
	// 				filterMeta: {
	// 					stageUuid: visit?.stageUuid,
	// 				},
	// 			}),
	// 		),
	// 	{
	// 		refetchOnWindowFocus: false,
	// 		enabled: !!visit?.stageUuid,
	// 	},
	// );

	const { data: stage_services } = useQuery<any>(
		['stage_services', visit, isOpen],
		() => StagaeServiceService.findAllFreeServicesAct(visit?.uuid),
		{
			enabled: !!visit?.uuid,
			refetchOnWindowFocus: false,
		},
	);

	const { data: estimate } = useQuery<any>(
		['estimate', visit, isOpen],
		() => EstimateService.getEstimate(visit?.uuid),
		{
			refetchOnWindowFocus: false,
			enabled: !!visit?.uuid,
		},
	);

	useEffect(() => {
		if (estimate?.data) {
			setValues((prev: any) => {
				return {
					visitUuid: estimate.data.visitUuid,
					patientUuid: estimate.data.patientUuid,
					stageUuid: estimate.data.stageUuid,
					discount_type: estimate.data.discount_type,
					discount_amount: estimate.data.discount_amount,
				};
			});
			setDiscount(estimate?.data?.discount_amount);
			setIsEstimate(true);
		} else {
			setCanEdit(true);
			setIsEstimate(false);
		}
	}, [estimate, isOpen]);

	const [localServices, setLocalServices] = useState<any[]>([]);

	useEffect(() => {
		const updatedServices_visit = visit_services?.map((service: any) => ({
			...service,
			amount: service.count * service.price,
		}));
		const updatedServices_stage = stage_services?.map((service: any) => ({
			...service,
			amount: service?.count * service?.price.replace(/\D/g, ''),
		}));
		const services = estimate?.data.services.map((item: any) => ({
			...item.event,
			stageServiceUuid: item?.uuid,
		}));
		if (visit_services && !estimate?.data) {
			setCanEdit(true);
			setValues((prev: any) => ({
				...prev,
				services: updatedServices_visit,
			}));
		}
		if (visit_services && estimate?.data) {
			setCanEdit(false);
			setValues((prev: any) => ({
				...prev,
				services: services,
			}));
		}
		setLocalServices(updatedServices_stage);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visit_services, estimate, stage_services]);

	useEffect(() => {
		if (visit) {
			setValues((prev: any) => {
				return {
					...prev,
					visitUuid: visit?.uuid,
					patientUuid: visit?.patientUuid,
					stageUuid: visit?.stageUuid,
				};
			});
		}
	}, [visit]);

	const resetEstimateState = () => {
		setIsOpen(false);
		setValues({
			visitUuid: '',
			patientUuid: '',
			stageUuid: '',
			discount_type: 'amount',
			discount_amount: '0',
			services: [],
		});
		setCanEdit(false);
		setIsEstimate(false);
		setDiscount(null);
	};

	const handleEstimateError = (error: any) => {
		const messages: { [key: string]: string } = {
			estimate_whith_this_visitUuid_already_created: 'Смета для этого визита уже существует',
			visit_must_be_complated: 'Визит должен быть завершен',
			services_donot_belongs_to_stage: 'Услуги не принадлежат этому плану лечения',
			discount_cant_be_greater_than_amount: 'Скидка не может превышать сумму оплаты',
			cant_update_estimate: 'Невозможно редактировать смету',
			estimat_not_found: 'Смета не найдено',
			act_whith_this_estimateUuid_already_created: 'Акт на основе этой сметы уже существует',
		};

		const message = messages[error.response.data.message];
		if (message) openNotificationService('bottomRight', message);
	};

	const { mutate: createEstimate } = useMutation(
		(estimateData: any) => EstimateService.createEstimate({ ...estimateData }),
		{
			onSuccess: () => {
				resetEstimateState();
				queryClient.invalidateQueries('stage_services');
				queryClient.invalidateQueries('visits');
				queryClient.invalidateQueries('stage_services');
				queryClient.invalidateQueries('visit');
				queryClient.invalidateQueries('visit_services');
				queryClient.invalidateQueries('consultation_list');
				queryClient.invalidateQueries('estimate');
				queryClient.invalidateQueries('act');
			},
			onError: handleEstimateError,
		},
	);

	const { mutate: actEstimate } = useMutation(
		(uuid: any) => ActService.create({ estimateUuid: uuid }),
		{
			onSuccess: () => {
				resetEstimateState();
				queryClient.invalidateQueries('visits');
				queryClient.invalidateQueries('stage_services');
				queryClient.invalidateQueries('visit');
				queryClient.invalidateQueries('consultation_list');
				queryClient.invalidateQueries('estimate');
				queryClient.invalidateQueries('visit_services');
				queryClient.invalidateQueries('act');
			},
			onError: handleEstimateError,
		},
	);

	const { mutate: updateEstimate } = useMutation(
		({ uuid, estimateData }: { uuid: string; estimateData: any }) =>
			EstimateService.updateEstimate(uuid, estimateData),
		{
			onSuccess: (response: any) => {
				console.log(response.data, 'valuesvaluesvaascascluesvaluesvaluesvalues');

				queryClient.invalidateQueries('visit_services');
				queryClient.invalidateQueries('visits');
				queryClient.invalidateQueries('stage_services');
				queryClient.invalidateQueries('visit');
				queryClient.invalidateQueries('consultation_list');
				queryClient.invalidateQueries('estimate');
				queryClient.invalidateQueries('act');
				setCanEdit(false);
				setIsEstimate(false);
			},
			onError: handleEstimateError,
		},
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!isEstimate && canEdit) {
			createEstimate({
				...values,
				discount_amount: +values.discount_amount,
			});
		}
		if (isEstimate && canEdit) {
			updateEstimate({
				uuid: estimate.data.uuid,
				estimateData: {
					...values,
					discount_amount: parseFloat(values.discount_amount) || 0,
					services: values.services,
				},
			});
		}
		if (isEstimate && !canEdit) {
			actEstimate(estimate.data.uuid);
		}
	};
	const handleCheckboxChange = useCallback(
		(
			e: any,
			type_event?: string,
			service_index?: number,
			material_index?: number | null,
			item?: any,
			type?: string,
		) => {
			const { name, value } = e.target;
			if (e.target.type === 'checkbox') {
				const itemExists = values.services.some((service: any) => {
					if (service.uuid === item.uuid) {
						return item?.event_uuid === service?.event_uuid;
					}
					return false;
				});
				setValues((prev: any) => {
					let newData;
					if (!itemExists) {
						newData = [...prev.services, item];
					} else {
						newData = prev?.services?.filter((service: any) => {
							if (service.uuid === item.uuid) {
								return item?.event_uuid !== service?.event_uuid;
							}
							return true;
						});
					}
					return {
						...prev,
						services: newData,
					};
				});
			}
			if (e.target.type === 'text') {
				const isNumericOrEmpty = /^[0-9]*$/.test(value);
				if (!isNumericOrEmpty) return;
				setLocalServices((prevLocalServices: any) => {
					const updatedLocalServices = deepCopyAll(prevLocalServices);
					if (
						type_event === 'material' &&
						service_index !== undefined &&
						material_index !== null &&
						material_index !== undefined
					) {
						updatedLocalServices[service_index].materials[material_index] = {
							...updatedLocalServices[service_index].materials[material_index],
							[name]: value,
							amount:
								value *
								updatedLocalServices[service_index].materials[material_index].count,
						};
						setValues((prev: any) => {
							const services = prev.services.map((service: any) => {
								if (
									service.event_uuid ===
									updatedLocalServices[service_index].event_uuid
								) {
									return updatedLocalServices[service_index];
								}
								return service;
							});

							return {
								...prev,
								services,
							};
						});
					}

					if (
						type_event === 'service' &&
						service_index !== undefined &&
						material_index === null
					) {
						updatedLocalServices[service_index] = {
							...updatedLocalServices[service_index],
							[name]: value,
							amount: value * updatedLocalServices[service_index].count,
						};
						setValues((prev: any) => {
							const services = prev.services.map((service: any) => {
								if (
									service.event_uuid ===
									updatedLocalServices[service_index].event_uuid
								) {
									return updatedLocalServices[service_index];
								}
								return service;
							});

							return {
								...prev,
								services,
							};
						});
					}

					return updatedLocalServices;
				});
			}
		},
		[values.services],
	);
	const reset = () => {
		setValues({
			visitUuid: '',
			stageUuid: '',
			patientUuid: '',
			discount_type: 'amount',
			discount_amount: '0',
			services: [],
		});
		setLocalServices([]);
		setDiscount(null);
		setCanEdit(false);
		setIsEstimate(false);
		setActVisit(null);
	};

	return (
		<>
			<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen} size='xl'>
				<ModalHeader closeModalCustom={reset} setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-3 fw-bold'>
						<Icon icon='Person' className='me-2 fs-2' color='primary' />
						{isEstimate && canEdit && 'Редактировать Смету'}
						{isEstimate && !canEdit && `Смета № ${estimate?.data?.id}`}
						{!isEstimate && canEdit && 'Создать Смету'}
					</span>
				</ModalHeader>
				<form ref={printRef} onSubmit={handleSubmit}>
					<ModalBody className='px-4'>
						<span>{contextHolder}</span>
						<div className='d-flex justify-content-between align-items-center w-100 pb-4'>
							<span className='fs-3 fw-bold'>
								{estimate?.data && canEdit
									? `Смета №${estimate?.data?.id}`
									: 'Смета'}
							</span>
							<div className='d-flex gap-3'>
								{isEstimate && (
									<Button onClick={() => setCanEdit(true)} color='info' isLight>
										Редактировать
									</Button>
								)}
								{/* <Button onClick={handlePrint} color='info' isLight icon='Print'>
									Распечатать
								</Button> */}
							</div>
						</div>
						<div className='d-flex gap-4'>
							<div className='d-flex flex-column gap-2'>
								<p className='m-0 fs-6'>
									Пациент:{' '}
									{`${visit?.patient?.name} ${visit?.patient?.middleName} ${visit?.patient?.surname}`}
								</p>
								<p className='m-0 fs-6'>
									Дата создания:{' '}
									{visit?.createdAt &&
										formatDate(new Date(visit.createdAt)).date2}
								</p>
							</div>
							<div className='d-flex flex-column gap-2'>
								<p className='m-0 fs-6'>
									На основе мед. случая №{visit?.medicalCase?.medicalCaseId}
								</p>
								<p className='m-0 fs-6'>
									План лечения: {visit?.medicalCase?.title}
								</p>
							</div>
						</div>
						<ServiceComponent
							isEstimate={!isEstimate}
							choosenServices={values?.services}
							canEdit={canEdit}
							data={canEdit ? localServices : values?.services}
							changeData={handleCheckboxChange}
						/>
						<ActDiscount
							discount={discount}
							setDiscount={setDiscount}
							setValues={setValues}
							countValues={[values.services]}
							isShort={!canEdit}
							values={values}
						/>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between w-100'>
							<Button type='submit' className='fw-bold fs-5 w-100 p-2' color='info'>
								{!isEstimate || canEdit ? 'Сохранить' : 'Создать акт'}
							</Button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default ActModal;
