import React, { FC } from 'react';

import MaterialPage from './MaterialComponents/MaterialPage';
interface IMaterial {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const Material:FC<IMaterial> = ({search, setSearch}) => {
	return <MaterialPage setSearch={setSearch} search={search} />;
};

export default Material;
