import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { PER_COUNT_FILTER } from '../../../../components/PaginationButtons';
import { VisitService } from '../../../../custom/api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import Services from './Components/Services';
import Devider from './Components/Devider';
import ChoosenServices from './Components/ChoosenServices';
import DentalContext from '../../../../contexts/dentalContext';
import {
	generateUuid,
	mergeServices,
	updateFormulaTheethDropDown,
} from '../../../../utils/functions';
import { StagaeServiceService } from '../../../../custom/api/service/stage-visit-service.service';

type NotificationPlacement = NotificationArgsProps['placement'];

interface IServiceChooseModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	serviceDropdownInfo?: any;
	setServiceDropdownInfo?(...args: unknown[]): unknown;
	stage: any;
	medicalCard?: boolean;
	stage_type: string;
	isStage?: boolean;
	createVisit?: boolean;
	createEvent?: boolean;
	setChosenValues?(...args: unknown[]): unknown;
	setServiceUuid?(...args: unknown[]): unknown;
	isNewPatient?: boolean;
}

const ServiceChooseModal: FC<IServiceChooseModalProps> = ({
	isOpen,
	serviceDropdownInfo,
	setServiceUuid,
	setServiceDropdownInfo,
	stage_type,
	setIsOpen,
	stage,
	medicalCard,
	setChosenValues,
	isStage,
	createVisit,
	createEvent,
	isNewPatient,
}) => {
	const { default_dental_formula_dropdown, setDentalFormulaDropdown } = useContext(DentalContext);

	const [api, contextHolder] = notification.useNotification();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const openNotificationPlace = useCallback(
		(placement: NotificationPlacement, description: string) => {
			api.info({
				message: 'Важно!',
				description,
				placement,
				className: 'ant-notification-custom',
				props: { style: { zIndex: 150000 } },
			});
		},
		[api],
	);

	const [values, setValues] = useState<IService>({
		services: [],
		serviceName: '',
		serviceNameChosen: '',
	});

	const [filter, setFilter] = useState<IService>({
		clinic: '',
		treatmen_type: '',
		category: '',
	});

	const { data: visits_services }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
		['visits_services', stage],
		() =>
			VisitService.findAll(
				qs.stringify({
					filterMeta: {
						stageUuid: stage,
					},
					includeMeta: [
						{
							association: 'services',
						},
					],

					queryMeta: {
						order: { createdAt: 'DESC' },
					},
				}),
			),
		{
			enabled: !!stage,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: services_free } = useQuery<any>(
		['services_free', stage],
		() => StagaeServiceService.findAllFreeServices(stage),
		{
			enabled: !!stage,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (
			serviceDropdownInfo &&
			serviceDropdownInfo?.length &&
			serviceDropdownInfo.some((i: any) => !values?.services?.includes(i)) &&
			services_free
		) {
			setValues((prev) => ({
				...prev,
				services: serviceDropdownInfo,
			}));
			setDentalFormulaDropdown(
				JSON.parse(
					updateFormulaTheethDropDown(
						JSON.stringify(services_free),
						default_dental_formula_dropdown,
					),
				),
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceDropdownInfo, services_free]);

	const [choosenServices, setChoosenService] = useState<any[]>([]);
	const [localChoosenServices, setLocalChosenService] = useState<any[]>([...values?.services]);

	useEffect(() => {
		if (values?.services?.length > 0) {
			setChoosenService([...values?.services]);
			setLocalChosenService([...values?.services]);
		}
	}, [values?.services]);

	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const handleCheckboxChange = useCallback(
		(e: any, name_btn?: string, value?: any) => {
			const name = e.target.name;
			if (e.target.type !== 'search' && name_btn && value) {
				return setChoosenService((prev) => {
					const generated_event_uuid = generateUuid();
					const generated_event_uuid_material = generateUuid();

					setDentalFormulaDropdown(
						JSON.parse(
							updateFormulaTheethDropDown(
								JSON.stringify(
									mergeServices(services_free, [
										...prev,
										{
											...value,

											event_uuid: generated_event_uuid,
										},
									]),
								),
								default_dental_formula_dropdown,
							),
						),
					);
					return [
						...prev,
						{
							...value,
							...((value.service_type === 'bridge' ||
								value.service_type === 'crown') && {
								materials: [],
								material_event_uuid: generated_event_uuid_material,
							}),
							event_uuid: generated_event_uuid,
						},
					];
				});
			} else if (e.target.type === 'search') {
				setValues((prev) => ({ ...prev, [name]: e.target.value }));
			}
		},
		[default_dental_formula_dropdown, services_free, setDentalFormulaDropdown],
	);

	const hendelDeleteService = useCallback(
		(type: string, item: any) => {
			setChoosenService((prev: any[]) => {
				const data = [
					...prev.filter((service: any) => {
						if (service.uuid === item.uuid) {
							return item.event_uuid !== service.event_uuid;
						}
						return true;
					}),
				];
				// updateDentalTheethDropDown(JSON.stringify([...data]));
				setDentalFormulaDropdown(
					JSON.parse(
						updateFormulaTheethDropDown(
							JSON.stringify(mergeServices(services_free, [...data])),
							default_dental_formula_dropdown,
						),
					),
				);
				return [...data];
			});
		},
		[default_dental_formula_dropdown, services_free, setDentalFormulaDropdown],
	);

	const handleSaveList = async (e: React.FormEvent<HTMLFormElement>) => {
		if (setChosenValues) {
			setChosenValues((prev: any) => ({
				...prev,
				services: choosenServices,
			}));
		}

		if (setServiceUuid) {
			setServiceUuid(choosenServices);
		}
		setIsOpen(false);
	};

	useEffect(() => {
		if (serviceDropdownInfo?.length <= 0 && isNewPatient) {
			setValues((prev: any) => ({
				...prev,
				services: [],
			}));
			setChoosenService([]);
			setLocalChosenService([]);
		}
	}, [serviceDropdownInfo, isNewPatient]);
	return (
		<>
			<Modal
				isCentered
				isStaticBackdrop
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				size='xl'
				modalLevel={1060}>
				<ModalHeader setIsOpen={setIsOpen} style={{ padding: '2.25rem 2.25rem 0' }}>
					<></>
				</ModalHeader>
				<form style={{ padding: '0rem 2.25rem 0' }}>
					<ModalBody className='col-12 d-flex w-100 justify-content-between'>
						<span>{contextHolder}</span>
						<Services
							stage_type={stage_type}
							setChoosenService={setChoosenService}
							setLocalChosenService={setLocalChosenService}
							setServicesPerPage={setServicesPerPage}
							localChoosenServices={localChoosenServices}
							choosenServices={choosenServices}
							createVisit={createVisit}
							isStage={isStage}
							medicalCard={medicalCard}
							stage={stage}
							values={values}
							filter={filter}
							servicesPerPage={servicesPerPage}
							setFilter={setFilter}
							handleCheckboxChange={handleCheckboxChange}
						/>
						<Devider />
						<ChoosenServices
							setChoosenService={setChoosenService}
							setLocalChosenService={setLocalChosenService}
							localChoosenServices={localChoosenServices}
							choosenServices={choosenServices}
							createVisit={createVisit}
							isStage={isStage}
							values={values}
							servicesPerPage={servicesPerPage}
							handleCheckboxChange={handleCheckboxChange}
							hendelDeleteService={hendelDeleteService}
							setServicesPerPage={setServicesPerPage}
							visits_services={visits_services}
						/>
					</ModalBody>
				</form>
				<ModalFooter className='pt-0 pb-4'>
					<div className='d-flex justify-content-end col-12 gap-3 px-3 py-0'>
						<Button
							className='fw-bold fs-6'
							color='info'
							type='submit'
							onClick={handleSaveList}>
							Сохранить
						</Button>
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ServiceChooseModal;
