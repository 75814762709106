import React, { FC, memo } from 'react';
import Button from '../../../../../../../../components/bootstrap/Button';

interface IResetComponentProps {
	reset(...args: unknown[]): unknown;
	setAddService(...args: unknown[]): unknown;
}

const ResetComponent: FC<IResetComponentProps> = memo(({ reset, setAddService }) => {
	return (
		<div className='d-flex gap-2'>
			<Button
				style={{
					textDecoration: 'underline',
					border: 'none',
					color: '#0D6EFD',
				}}
				onClick={reset}>
				Сбросить фильтры
			</Button>
		</div>
	);
});

export default ResetComponent;
