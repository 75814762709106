import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import Avatar from '../../../components/Avatar';
import User1Img from '../../../assets/img/wanna/wanna2.png';
import { demoPagesMenu } from '../../../menu';
import { UserService } from '../../../custom/api/service/user.service';
import { IPatientCreate } from '../../../custom/api/interface/patient.interface';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ClinicService } from '../../../custom/api/service/clinic.service';
import * as qs from 'qs';
import CommonMyWallet from '../../../pages/_common/CommonMyWallet';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import editPasswordValidate from '../../../pages/presentation/demo-pages/helper/editPasswordValidate';
import { CategoryService } from '../../../custom/api/service/category.service';
import Wizard, { WizardItem } from '../../../components/Wizard';
import { rolesTranslate } from '../../../utils/dataUtil';
import { notification, Upload } from 'antd';
import type { NotificationArgsProps, UploadFile } from 'antd';

type NotificationPlacement = NotificationArgsProps['placement'];

interface PassportInfo {
	passportNumber: string;
	countryCode: string;
	name: string;
	surname: string;
	dateOfBirth: string;
	dateOfIssue: string;
	dateOfExpiry: string;
	ssCardId: string;
	middleName: string;
	country: string;
	documentType: string;
}

interface IValues {
	image: string;
	imagePath: string;
	imageBlob: string;
	name: string;
	surname: string;
	middleName: string;
	email: string;
	phone: string;
	clinics: any;
	passportFile: any;
	passportFileBlob: any;
	passport: PassportInfo;
}

interface IPreviewItemProps {
	title: string;
	value: any | any[];
}
const PreviewItem: FC<IPreviewItemProps> = ({ title, value }) => {
	return (
		<>
			<div className='col-3 text-end'>{title}</div>
			<div className='col-9 fw-bold'>{value || '-'}</div>
		</>
	);
};

const validate = (values: IValues) => {
	const errors: Partial<IValues> = {};

	if (!errors.passport) {
		errors.passport = {} as PassportInfo; // Explicitly type as PassportInfo
		console.log(errors, '4445455aluesvalues');
	}
	if (!values.passport.name) {
		errors.passport.name = 'Required';
	} else if (values.passport.name.length < 3) {
		errors.passport.name = 'Must be 3 characters or more';
	} else if (values.passport.name.length > 20) {
		errors.passport.name = 'Must be 20 characters or less';
	}

	// Passport Surname Validation
	if (!values.passport.surname) {
		errors.passport.surname = 'Required';
	} else if (values.passport.surname.length < 3) {
		errors.passport.surname = 'Must be 3 characters or more';
	} else if (values.passport.surname.length > 20) {
		errors.passport.surname = 'Must be 20 characters or less';
	}

	// Passport Middle Name Validation
	if (!values.passport.middleName) {
		errors.passport.middleName = 'Required';
	} else if (values.passport.middleName.length > 30) {
		errors.passport.middleName = 'Must be 30 characters or less';
	}

	// Date of Birth Validation
	if (!values.passport.dateOfBirth) {
		errors.passport.dateOfBirth = 'Required';
	} // Optionally, add date-format validation if needed

	// Date of Expiry Validation
	if (!values.passport.dateOfExpiry) {
		errors.passport.dateOfExpiry = 'Required';
	} // Optionally, validate that the expiry date is in the future

	// Date of Issue Validation
	if (!values.passport.dateOfIssue) {
		errors.passport.dateOfIssue = 'Required';
	} // Optionally, add validation for logical date range

	// Country Validation
	if (!values.passport.country) {
		errors.passport.country = 'Required';
	} else if (values.passport.country.length < 2) {
		errors.passport.country = 'Invalid country name';
	}

	// Country Code Validation (e.g., issuing authority code)
	if (!values.passport.countryCode) {
		errors.passport.countryCode = 'Required';
	} // You can add further checks if this should be numeric

	// Social Security Card Id (ssCardId) Validation
	if (!values.passport.ssCardId) {
		errors.passport.ssCardId = 'Required';
	} // Add further numeric or format validation as needed

	// Passport Number Validation
	if (!values.passport.passportNumber) {
		errors.passport.passportNumber = 'Required';
	} else if (values.passport.passportNumber.length < 5) {
		errors.passport.passportNumber = 'Must be at least 5 characters';
	}

	if (!values.name) {
		errors.name = 'Required';
	} else if (values.name.length < 3) {
		errors.name = 'Must be 3 characters or more';
	} else if (values.name.length > 20) {
		errors.name = 'Must be 20 characters or less';
	}

	if (!values.surname) {
		errors.surname = 'Required';
	} else if (values.surname.length < 3) {
		errors.surname = 'Must be 3 characters or more';
	} else if (values.surname.length > 20) {
		errors.surname = 'Must be 20 characters or less';
	}

	if (!values.middleName) {
		errors.middleName = 'Required';
	} else if (values.middleName.length > 30) {
		errors.middleName = 'Must be 30 characters or less'; // Исправлено
	}

	if (!values.email) {
		errors.email = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.phone) {
		errors.phone = 'Required';
	} else if (!/^\+?[0-9]{10,12}$/i.test(values.phone)) {
		errors.phone = 'Invalid phone number';
	}

	if (Object.keys(errors.passport).length === 0) {
		delete errors.passport;
	}

	return errors;
};

const AddAdminPage = () => {
	const navigate = useNavigate();

	const [api, contextHolder] = notification.useNotification();

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
		});
	};
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const queryClient = useQueryClient();

	const { data: user_role } = useQuery(['user_role'], () =>
		UserService.getProfile(
			qs.stringify({
				includeMeta: [
					{
						association: 'roles',
					},
				],
			}),
		),
	);

	const { data: clinics } = useQuery(['clinics'], () => ClinicService.findAll(qs.stringify({})));

	useEffect(() => {
		if (user_role?.roles?.find((i: any) => i.role === 'superAdmin')) {
			setIsSuperAdmin(true);
		}
	}, [user_role]);

	const { mutate } = useMutation((inputDto: IPatientCreate) => UserService.register(inputDto), {
		onSuccess: (response, formData) => {
			queryClient.invalidateQueries('users');
			queryClient.invalidateQueries('user');
			queryClient.invalidateQueries('workers');
			queryClient.invalidateQueries('worker');
			queryClient.invalidateQueries('doctors');
			queryClient.invalidateQueries('doctor');
			queryClient.invalidateQueries('admins');
			queryClient.invalidateQueries('admin');
			navigate(-1);
		},
		onError: (error: any) => {
			if (error.response.data.message === 'passport_number_unique')
				openNotification(
					'bottomRight',
					'Важно !',
					'Пользователь с таким номером паспорта уже существует',
				);

			if (error.response.data.message === 'ss_card_unique')
				openNotification(
					'bottomRight',
					'Важно !',
					'Пользователь с таким номером социальной карты уже существует',
				);

			if (error.response.data.message === 'email_exist')
				openNotification('bottomRight', 'Важно !', 'есть пользователь с таким Эл. почтой');

			if (error.response.data.message === 'must_have_clinic_role')
				openNotification('bottomRight', 'Важно !', 'выберите клинику и роль');

			if (error.response.data.message === 'must_choose_clinic')
				openNotification('bottomRight', 'Важно !', 'выберите клинику');

			if (error.response.data.message === 'must_choose_role')
				openNotification('bottomRight', 'Важно !', 'выберете роль');

			if (error.response.data.message === 'phone_exist')
				openNotification(
					'bottomRight',
					'Важно !',
					'есть пользователь с таким номером телефона',
				);
		},
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			surname: '',
			middleName: '',
			email: '',
			image: '',
			imagePath: '',
			imageBlob: '',
			phone: '+',
			clinics: [],
			passportFile: '',
			passportFileBlob: '',
			passport: {
				passportNumber: '',
				countryCode: '',
				name: '',
				surname: '',
				dateOfBirth: '',
				dateOfIssue: '',
				dateOfExpiry: '',
				ssCardId: '',
				middleName: '',
				country: '',
				documentType: 'Паспорт',
			},
		},
		validate,
		onSubmit: async (values) => {
			const formData = new FormData();

			Object.entries(formik.values).forEach(([key, value]) => {
				if (key === 'clinics' && Array.isArray(value)) {
					formData.append(key, JSON.stringify(value));
				} else if ((key === 'image' || key === 'passportFile') && value instanceof File) {
					formData.append(key, value);
				} else if (key === 'passport' && typeof value === 'object' && value !== null) {
					Object.entries(value as Record<string, any>).forEach(
						([passportKey, passportValue]) => {
							formData.append(`passport[${passportKey}]`, String(passportValue));
						},
					);
				} else if (value !== null && value !== undefined) {
					formData.append(key, String(value));
				}
			});

			mutate(formData);
		},
	});

	
	const TABS = {
		ACCOUNT_DETAIL: 'Account Details',
		PASSWORD: 'Password',
		MY_WALLET: 'My Wallet',
	};
	const [activeTab, setActiveTab] = useState(TABS.ACCOUNT_DETAIL);

	// Handle checkbox selection

	useEffect(() => {
		if (user_role?.clinicUuid) {
			formik.setFieldValue('clinics', [
				{
					uuid: user_role.clinicUuid,
					role: 'admin',
					categoryUuid: '',
					subCategoryUuid: '',
				},
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuperAdmin, user_role]);

	useEffect(() => {
		if (fileList.length) {
			const originFileObj = fileList[0]?.originFileObj;
			if (originFileObj) {
				formik.setFieldValue('passportFile', originFileObj);
			} else {
				formik.setFieldValue('passportFile', null);
			}
		} else {
			formik.setFieldValue('passportFile', null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileList]);


	const handleChange = (info: { file: UploadFile; fileList: UploadFile[] }) => {
		const { status, originFileObj } = info.file;

		if (status !== 'uploading') {
		}

		if (status === 'done') {
			const reader = new FileReader();

			if (originFileObj) {
				reader.readAsDataURL(originFileObj);
			}
		} else if (status === 'error') {
		}

		// Update the fileList state
		setFileList(info.fileList);
	};

	const props = {
		fileList,
		onChange: handleChange,
		beforeUpload: (file: UploadFile) => {
			// You can add file validation here if needed
			return false; // Prevent automatic upload
		},
	};

	const triggerFileInputClick = () => {
		const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
		if (fileInput && fileList.length < 1) {
			fileInput.click();
		}
	};

	const handleImageChange = (e: any, passport?: boolean) => {
		const file = e.target.files[0];
		e.target.value = '';
		if (file) {
			const imageUrl = URL.createObjectURL(file);
			formik.setFieldValue('image', file);
			formik.setFieldValue('imageBlob', imageUrl);
		}
		if (file && passport) {
			const imageUrl = URL.createObjectURL(file);
			formik.setFieldValue('passport.file', file);
			formik.setFieldValue('passport.fileBlob', imageUrl);
		}
	};

	const handleImageDelete = () => {
		formik.setFieldValue('imagePath', '');
		formik.setFieldValue('image', '');
		formik.setFieldValue('imageBlob', '');
		formik.setFieldValue('passport.file', '');
		formik.setFieldValue('passport.fileBlob', '');
	};

	

	const formikPassword = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
		validate: editPasswordValidate,
		onSubmit: () => {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Updated Successfully</span>
				</span>,
				"The user's password have been successfully updated.",
			);
		},
	});

	const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
		['categories'],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'subCategory',
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const categories = categoriesWithSubCategories?.data
		.filter((item: any) => !item.categoryUuid)
		.map((category: any) => ({
			...category,
			categoriesUuid: category.uuid,
		}));

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const { value, name } = e.target;
		const selectedClinics = formik.values.clinics.map((clinic: any, index_clinic: number) => {
			if (index_clinic === index) {
				return {
					...clinic,
					[name]: value,
				};
			}
			return clinic;
		});

		formik.setFieldValue('clinics', selectedClinics);
	};

	const addRole = () => {
		formik.setFieldValue('clinics', [
			...formik.values.clinics,
			{ uuid: '', categoryUuid: '', subCategoryUuid: '', role: 'admin' },
		]);
	};

	const deleteRole = (index: number) => {
		formik.setFieldValue(
			'clinics',
			formik.values.clinics.filter((_, i) => i !== index),
		);
	};

	return (
		<PageWrapper title={demoPagesMenu.editPages.subMenu.editWizard.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Back to List
					</Button>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<span>{contextHolder}</span>
				<div className='row h-100 pb-3'>
					<div className='col-lg-3 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='AccountCircle'>
									<CardTitle tag='div' className='h5'>
										User Information
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Contacts'
											color='info'
											className='w-100 p-3'
											isLight={TABS.ACCOUNT_DETAIL !== activeTab}
											onClick={() => setActiveTab(TABS.ACCOUNT_DETAIL)}>
											{TABS.ACCOUNT_DETAIL}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='LocalPolice'
											color='info'
											className='w-100 p-3'
											isLight={TABS.PASSWORD !== activeTab}
											onClick={() => setActiveTab(TABS.PASSWORD)}>
											{TABS.PASSWORD}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Style'
											color='info'
											className='w-100 p-3'
											isLight={TABS.MY_WALLET !== activeTab}
											onClick={() => setActiveTab(TABS.MY_WALLET)}>
											{TABS.MY_WALLET}
										</Button>
									</div>
								</div>
							</CardBody>
							<CardFooter>
								<CardFooterLeft className='w-100'>
									<Button
										icon='Delete'
										color='danger'
										isLight
										className='w-100 p-3'>
										Delete User
									</Button>
								</CardFooterLeft>
							</CardFooter>
						</Card>
					</div>
					<div className='col-lg-9 col-md-6'>
						{TABS.ACCOUNT_DETAIL === activeTab && (
							<Wizard
								submitDisabled={!formik.isValid}
								createUser
								onSubmit={formik.handleSubmit}
								showButtons
								current={0}
								// isHeader
								stretch
								color='info'
								noValidate
								className='shadow-3d-info'>
								<WizardItem id='step1' title='Данные аккаунта'>
									<Card>
										<CardBody>
											<div className='column g-4 align-items-center'>
												<div className='col-12 d-flex justify-content-center'>
													<Avatar
														src={
															formik.values?.imageBlob ||
															formik.values?.imagePath ||
															User1Img
														} // Show preview or default image
														size={200}
														color='primary'
													/>
												</div>
												<div style={{ marginTop: 15 }} className='col-xl'>
													<div className='row g-4'>
														<input
															type='file'
															accept='image/*'
															onChange={(e: any) =>
																handleImageChange(e)
															}
															style={{ display: 'none' }}
															id='upload-input'
															disabled={
																!!formik.values.image ||
																!!formik.values.imageBlob
															}
														/>
														<label
															htmlFor='upload-input'
															style={{
																cursor: 'pointer',
																position: 'relative',
																zIndex: '1',
															}}
															className='col-12'>
															<Button
																color='primary'
																isDisable={
																	!!formik.values.image ||
																	!!formik.values.imageBlob
																}
																style={{
																	display:
																		(formik.values.image ||
																			formik.values
																				.imageBlob) &&
																		'none',
																	width: '100%',
																	position: 'relative',
																	zIndex: '-1',
																}}>
																Загрузить
															</Button>
														</label>
														{(formik.values.image ||
															formik.values.imageBlob) && (
															<Button
																color='primary'
																onClick={handleImageDelete}>
																удалить
															</Button>
														)}
													</div>
												</div>
											</div>
										</CardBody>
									</Card>

									<Card>
										<CardHeader>
											<CardLabel icon='Edit' iconColor='warning'>
												<CardTitle>Личные данные</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody className='pt-0'>
											<div className='row g-4'>
												<div className='col-md-6'>
													<FormGroup id='name' label='Имя' isFloating>
														<Input
															placeholder='Имя'
															autoComplete='additional-name'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.name}
															isValid={formik.isValid}
															isTouched={formik.touched.name}
															invalidFeedback={formik.errors.name}
														/>
													</FormGroup>
												</div>
												<div className='col-md-6'>
													<FormGroup
														id='surname'
														label='Фамилия'
														isFloating>
														<Input
															placeholder='Фамилия'
															autoComplete='family-name'
															onChange={formik.handleChange}
															value={formik.values.surname}
															onBlur={formik.handleBlur}
															isTouched={formik.touched.surname}
															isValid={formik.isValid}
															invalidFeedback={formik.errors.surname}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<FormGroup
														id='middleName'
														label='Отчество'
														isFloating>
														<Input
															placeholder='Отчество'
															autoComplete='family-name'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.middleName}
															isValid={formik.isValid}
															isTouched={formik.touched.middleName}
															invalidFeedback={
																formik.errors.middleName
															}
														/>
													</FormGroup>
												</div>
											</div>
										</CardBody>
									</Card>
								</WizardItem>

								<WizardItem id='step2' title='Данные аккаунта'>
									<Card>
										<CardHeader>
											<CardLabel icon='PersonPin' iconColor='primary'>
												<CardTitle>Спецализация и роли</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody className='pt-0'>
											<div className='row g-4'>
												{formik?.values?.clinics?.map(
													(item: any, index: number) => (
														<div className='col-12 d-flex justify-content-between flex-wrap'>
															{formik?.values?.clinics.length > 1 && (
																<div className='col-12'>
																	<div
																		key={`div-11-${index}`}
																		style={{
																			width: 'auto',
																		}}
																		className='d-flex flex justify-content-end gap-3 border-0'>
																		<Button
																			isOutline={true}
																			style={{
																				width: 45,
																				height: 34,
																			}}
																			onClick={() =>
																				deleteRole(index)
																			}
																			className='col-4 h-50 rounded delete-add-dental delete-dental'>
																			<Icon
																				icon={'Delete'}
																				color={'danger'}
																				size={'lg'}
																			/>
																		</Button>
																	</div>
																</div>
															)}
															<div className='col-12 d-flex justify-content-between flex-wrap'>
																<FormGroup
																	label={'Роль'}
																	className='col-3 fs-6'>
																	<div className='col-11'>
																		<select
																			onChange={(e: any) =>
																				handleCheckboxChange(
																					e,
																					index,
																				)
																			}
																			name='role'
																			disabled
																			className='form-select'>
																			<option value='admin'>
																				Админ
																			</option>
																		</select>
																	</div>
																</FormGroup>

																<FormGroup
																	label={'Спецализация'}
																	className='col-3 fs-6'>
																	<div className='col-11'>
																		<select
																			onChange={(e: any) =>
																				handleCheckboxChange(
																					e,
																					index,
																				)
																			}
																			name='categoryUuid'
																			disabled
																			className='form-select'>
																			{categories?.map(
																				(category: any) => (
																					<option
																						value={
																							category.uuid
																						}>
																						{
																							category
																								.name
																								.en
																						}
																					</option>
																				),
																			)}
																			<option
																				value=''
																				hidden
																				selected></option>
																		</select>
																	</div>
																</FormGroup>

																<FormGroup
																	label={'Подспецализация'}
																	className='col-3 fs-6'>
																	<div className='col-11'>
																		<select
																			onChange={(e: any) =>
																				handleCheckboxChange(
																					e,
																					index,
																				)
																			}
																			name='subCategoryUuid'
																			disabled={
																				!item.categoryUuid
																			}
																			className='form-select'>
																			{categories
																				?.filter(
																					(
																						subCategory: any,
																					) =>
																						subCategory.categoryUuid ===
																						item.categoryUuid,
																				)
																				?.map(
																					(
																						subCategory: any,
																					) => (
																						<option
																							value={
																								subCategory.uuid
																							}>
																							{
																								subCategory
																									.name
																									.en
																							}
																						</option>
																					),
																				)}
																			<option
																				value=''
																				hidden
																				selected></option>
																		</select>
																	</div>
																</FormGroup>

																<FormGroup
																	label={'Филиал'}
																	className='col-3 fs-6'>
																	<div className='col-11'>
																		<select
																			onChange={(e: any) =>
																				handleCheckboxChange(
																					e,
																					index,
																				)
																			}
																			value={item.uuid}
																			name='uuid'
																			className='form-select'>
																			{clinics?.data?.map(
																				(clinic: any) => (
																					<option
																						disabled={formik.values.clinics?.find(
																							(
																								clinic_item: any,
																							) =>
																								clinic_item.uuid ===
																								clinic.uuid,
																						)}
																						value={
																							clinic.uuid
																						}>
																						{
																							clinic.name
																						}
																					</option>
																				),
																			)}
																			<option
																				value=''
																				selected
																				hidden></option>
																		</select>
																	</div>
																</FormGroup>
															</div>
														</div>
													),
												)}
											</div>
											{isSuperAdmin &&
												formik.values.clinics.length <
													clinics?.data?.length && (
													<div
														style={{ marginTop: 25 }}
														className='col-12'>
														<Button
															onClick={addRole}
															color='primary'
															isDisable={false}>
															Добавить роль
														</Button>
													</div>
												)}
										</CardBody>
									</Card>
									<Card>
										<CardHeader>
											<CardLabel icon='MarkunreadMailbox' iconColor='success'>
												<CardTitle>Контактные данные</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody className='pt-0'>
											<div className='row g-4'>
												<div className='row g-4'>
													<div className='col-12'>
														<FormGroup
															id='phone'
															label='Номер телефона'
															isFloating>
															<Input
																placeholder='Номер телефона'
																type='tel'
																autoComplete='tel'
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																value={formik.values.phone}
																isValid={formik.isValid}
																isTouched={formik.touched.phone}
																invalidFeedback={
																	formik.errors.phone
																}
															/>
														</FormGroup>
													</div>
													<div className='col-12'>
														<FormGroup
															id='email'
															label='Ел. почта'
															isFloating>
															<Input
																type='email'
																placeholder='Ел. почта'
																autoComplete='email'
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																value={formik.values.email}
																isValid={formik.isValid}
																isTouched={formik.touched.email}
																invalidFeedback={
																	formik.errors.email
																}
															/>
														</FormGroup>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</WizardItem>
								<WizardItem id='step3' title='Данные аккаунта'>
									<Card>
										<CardBody className='pt-0'>
											<div className='row g-4'>
												<div className='row g-4'>
													<div className='row g-4'>
														<div className='row g-4'>
															<div className='row'>
																<FormGroup
																	aria-required
																	className='col-md-9'
																	id='fillDate'
																	// label='Загрузите фото пациента *'>
																>
																	<Upload
																		{...props}
																		fileList={fileList}
																		showUploadList={true}>
																		<Button
																			id='fileUpload'
																			color='primary'
																			isLight
																			// className='text-nowrap fw-normal f-w-b me-2 col-12 d-flex align-items-center justify-content-center'
																			onClick={
																				triggerFileInputClick
																			}
																			style={{
																				position:
																					'absolute',
																				zIndex: '-1',
																				opacity: '0',
																			}}>
																			Загрузить
																		</Button>
																	</Upload>
																	<div>
																		{!formik.values
																			.passportFile ? (
																			<div>
																				<p>Нет документа</p>
																			</div>
																		) : null}
																	</div>
																</FormGroup>
																<FormGroup
																	className='col-md-3'
																	id='name'
																	label=' '>
																	<Button
																		id='fileUpload'
																		color='primary'
																		isLight
																		className='text-nowrap fw-normal f-w-b me-2 col-12 d-flex align-items-center justify-content-center'
																		onClick={
																			triggerFileInputClick
																		}>
																		Загрузить
																	</Button>
																</FormGroup>
															</div>
															<div className='row'>
																<FormGroup
																	label={'Вид документа'}
																	className='col-3 fs-6'>
																	<div className='col-11'>
																		<select
																			name='passport.documentType'
																			value={
																				formik.values
																					.passport
																					.documentType
																			}
																			onChange={
																				formik.handleChange
																			}
																			className='form-select'>
																			<option
																				value='Паспорт'
																				selected>
																				Паспорт
																			</option>
																		</select>
																	</div>
																</FormGroup>
															</div>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
									<Card>
										<CardHeader>
											<CardLabel icon='Edit' iconColor='warning'>
												<CardTitle>Паспорт</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody className='pt-0'>
											<div className='row g-4'>
												<div className='row g-4'>
													<div className='col-12 d-flex justify-content-between'>
														<div className='col-6 justify-content-start'>
															<FormGroup
																className='col-11'
																id='name'
																label='Имя'
																isFloating>
																<Input
																	name='passport.name'
																	value={
																		formik.values.passport.name
																	}
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik?.touched?.passport
																			?.name
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.name
																	}
																	placeholder='Имя'
																	type='text'
																	autoComplete='text'
																/>
															</FormGroup>
														</div>

														<div className='col-6 justify-content-end'>
															{' '}
															<FormGroup
																className='col-11'
																id='phone'
																label='Фамилия'
																isFloating>
																<Input
																	name='passport.surname'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik?.touched?.passport
																			?.surname
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.surname
																	}
																	value={
																		formik.values.passport
																			.surname
																	}
																	placeholder='Фамилия'
																	type='text'
																	autoComplete='text'
																/>
															</FormGroup>
														</div>
													</div>
													<div className='col-12 d-flex'>
														<div className='col-6 justify-content-start'>
															<FormGroup
																className='col-11'
																id='email'
																label='Отчество'
																isFloating>
																<Input
																	name='passport.middleName'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik.touched?.passport
																			?.middleName
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.middleName
																	}
																	value={
																		formik.values.passport
																			.middleName
																	}
																	type='text'
																	placeholder='Отчество'
																	autoComplete='text'
																/>
															</FormGroup>
														</div>
														<div className='col-6 justify-content-end'>
															<FormGroup
																className='col-11'
																id='phone'
																label='Дата рождения'
																isFloating>
																<Input
																	name='passport.dateOfBirth'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik.touched?.passport
																			?.dateOfBirth
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.dateOfBirth
																	}
																	value={
																		formik.values.passport
																			.dateOfBirth
																	}
																	placeholder='Дата рождения'
																	type='date'
																	autoComplete='date'
																/>
															</FormGroup>
														</div>
													</div>
													<div className='col-12 d-flex'>
														<div className='col-6 justify-content-end'>
															<FormGroup
																className='col-11'
																id='phone'
																label='Дата выдачи *'
																isFloating>
																<Input
																	name='passport.dateOfExpiry'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik.touched?.passport
																			?.dateOfExpiry
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.dateOfExpiry
																	}
																	value={
																		formik.values.passport
																			.dateOfExpiry
																	}
																	placeholder='Дата выдачи *'
																	type='date'
																	autoComplete='date'
																/>
															</FormGroup>
														</div>
														<div className='col-6 justify-content-end'>
															<FormGroup
																className='col-11'
																id='phone'
																label='Дата оканчания *'
																isFloating>
																<Input
																	name='passport.dateOfIssue'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik.touched?.passport
																			?.dateOfIssue
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.dateOfIssue
																	}
																	value={
																		formik.values.passport
																			.dateOfIssue
																	}
																	placeholder='Дата оканчания *'
																	type='date'
																	autoComplete='date'
																/>
															</FormGroup>
														</div>
													</div>
													<div className='col-12 d-flex'>
														<div className='col-6 justify-content-end'>
															<FormGroup
																className='col-11'
																id='phone'
																label='Гражданство'
																isFloating>
																<Input
																	name='passport.country'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik.touched?.passport
																			?.country
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.country
																	}
																	value={
																		formik.values.passport
																			.country
																	}
																	placeholder='Гражданство'
																	type='tel'
																	autoComplete='tel'
																/>
															</FormGroup>
														</div>
														<div className='col-6 justify-content-end'>
															<FormGroup
																className='col-11'
																id='phone'
																label='Выдано'
																isFloating>
																<Input
																	name='passport.countryCode'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik.touched?.passport
																			?.countryCode
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.countryCode
																	}
																	value={
																		formik.values.passport
																			.countryCode
																	}
																	placeholder='Выдано'
																	type='number'
																	autoComplete='number'
																/>
															</FormGroup>
														</div>
													</div>

													<div className='col-12 d-flex'>
														<div className='col-6 justify-content-end'>
															<FormGroup
																className='col-11'
																id='phone'
																label='социальная карта'
																isFloating>
																<Input
																	name='passport.ssCardId'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik.touched?.passport
																			?.ssCardId
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.ssCardId
																	}
																	value={
																		formik.values.passport
																			.ssCardId
																	}
																	placeholder='социальная карта'
																	type='number'
																	autoComplete='number'
																/>
															</FormGroup>
														</div>
														<div className='col-6 justify-content-start'>
															<FormGroup
																className='col-11'
																id='email'
																label='Серия'
																isFloating>
																<Input
																	name='passport.passportNumber'
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																	isTouched={
																		formik.touched?.passport
																			?.passportNumber
																	}
																	isValid={formik.isValid}
																	invalidFeedback={
																		formik?.errors?.passport
																			?.passportNumber
																	}
																	value={
																		formik.values.passport
																			.passportNumber
																	}
																	type='text'
																	placeholder='Серия'
																	autoComplete='text'
																/>
															</FormGroup>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</WizardItem>
								<WizardItem id='step3' title='Preview'>
									<div className='row g-3'>
										<div className='col-9 offset-3'>
											<h3 className='mt-4'>Данные аккаунта</h3>
											<h4 className='mt-4'>Личные данные</h4>
										</div>
										<PreviewItem title='Имя' value={formik.values.name} />
										<PreviewItem
											title='Фамилия'
											value={formik.values.surname}
										/>
										<PreviewItem
											title='Очество'
											value={formik.values.middleName}
										/>
										<div className='col-9 offset-3'>
											<h4 className='mt-4'>Контактные данные</h4>
										</div>
										<PreviewItem
											title='Номер телефона'
											value={formik.values.phone}
										/>
										<PreviewItem
											title='Эл. почта'
											value={formik.values.email}
										/>
										<div className='col-9 offset-3'>
											<h4 className='mt-4'>Спецализация и роли</h4>
										</div>
										{formik.values.clinics.map((clinic: any) => (
											<>
												<div className='col-9 offset-3'>
													<h5 className=''>
														{clinics?.data?.find(
															(item: any) =>
																item.uuid === clinic.uuid,
														)?.name ?? ''}
													</h5>
												</div>
												<PreviewItem
													title='Роль'
													value={
														rolesTranslate[
															clinic.role as keyof typeof rolesTranslate
														]
													}
												/>
												<PreviewItem
													title='Спецализация'
													value={
														categoriesWithSubCategories?.data?.find(
															(item: any) =>
																item.uuid === clinic.categoryUuid,
														)?.title_all?.rus ?? ''
													}
												/>
												<PreviewItem
													title='Подспецализация'
													value={
														categoriesWithSubCategories?.data?.find(
															(item: any) =>
																item.uuid ===
																clinic.subCategoryUuid,
														)?.title_all?.rus ?? ''
													}
												/>
											</>
										))}
									</div>
								</WizardItem>
							</Wizard>
						)}
						{TABS.PASSWORD === activeTab && (
							<Card
								stretch
								tag='form'
								noValidate
								onSubmit={formikPassword.handleSubmit}>
								<CardHeader>
									<CardLabel icon='LocalPolice' iconColor='info'>
										<CardTitle>{TABS.PASSWORD}</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className='pb-0' isScrollable>
									<div className='row g-4'>
										<div className='col-12'>
											<FormGroup
												id='currentPassword'
												label='Current password'
												isFloating>
												<Input
													type='password'
													placeholder='Current password'
													autoComplete='current-password'
													onChange={formikPassword.handleChange}
													value={formikPassword.values.currentPassword}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='newPassword'
												label='New password'
												isFloating>
												<Input
													type='password'
													placeholder='New password'
													autoComplete='new-password'
													onChange={formikPassword.handleChange}
													onBlur={formikPassword.handleBlur}
													value={formikPassword.values.newPassword}
													isValid={formikPassword.isValid}
													isTouched={formikPassword.touched.newPassword}
													invalidFeedback={
														formikPassword.errors.newPassword
													}
													validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='confirmPassword'
												label='Confirm new password'
												isFloating>
												<Input
													type='password'
													placeholder='Confirm new password'
													autoComplete='new-password'
													onChange={formikPassword.handleChange}
													onBlur={formikPassword.handleBlur}
													value={formikPassword.values.confirmPassword}
													isValid={formikPassword.isValid}
													isTouched={
														formikPassword.touched.confirmPassword
													}
													invalidFeedback={
														formikPassword.errors.confirmPassword
													}
													validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
									</div>
								</CardBody>
								<CardFooter>
									<CardFooterLeft>
										<Button
											color='info'
											isLink
											type='reset'
											onClick={formikPassword.resetForm}>
											Reset
										</Button>
									</CardFooterLeft>
									<CardFooterRight>
										<Button
											type='submit'
											icon='Save'
											color='info'
											isOutline
											isDisable={
												!formikPassword.isValid &&
												!!formikPassword.submitCount
											}>
											Save
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}
						{TABS.MY_WALLET === activeTab && <CommonMyWallet />}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default AddAdminPage;
