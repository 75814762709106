import React, { FC, useState } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import { ModalBody } from '../../../../../components/bootstrap/Modal';
import Textarea from '../../../../../components/bootstrap/forms/Textarea';
import { useMutation, useQueryClient } from 'react-query';
import { IPatientCreate } from '@textnpayme/custom/api/interface/patient.interface';
import { PatientService } from '../../../../../custom/api/service/patient.service';

interface INewPatientSecondStepProps {
	setCreateVisit(...args: unknown[]): unknown;
	setNewPatientIsOpen(...args: unknown[]): unknown;
	setValues(...args: unknown[]): unknown;
	setPatientNext(...args: unknown[]): unknown;
	setPatientCreated(...args: unknown[]): unknown;
	setIsNewPatientChoosen(...args: unknown[]): unknown;
	setPatientCreatedId(...args: unknown[]): unknown;
	setIsPatientChoosen(...args: unknown[]): unknown;
	changeValues: any;
	values: any;
}
interface CheckboxProps {
	id: string;
	label: string;
	checked: boolean;
	onChange: any;
}
const checkboxOptions = [
	{ id: 'check', label: 'Фейсбук' },
	{ id: 'check1', label: 'Реклама (оффлайн)' },
	{ id: 'check2', label: 'Знакомый/друг/семья' },
	{ id: 'check3', label: 'Действующий пациент' },
];
const Checkbox: FC<CheckboxProps> = ({ id, label, checked, onChange }) => (
	<div className='d-flex justify-content-between gap-2'>
		<input type='checkbox' id={id} checked={checked} onChange={onChange} />
		<label htmlFor={id} className='checkBoxContainer d-flex justify-content-start gap-2 w-100'>
			<span className='custom-checkbox'>
				<span></span>
			</span>
			{label}
		</label>
	</div>
);

const NewPatientSecondStep: FC<INewPatientSecondStepProps> = ({
	setNewPatientIsOpen,
	setValues,
	setPatientNext,
	changeValues,
	setPatientCreatedId,
	setIsPatientChoosen,
	values,
	setPatientCreated,
	setIsNewPatientChoosen,
	setCreateVisit,
}) => {
	const [checkboxValue, setCheckboxValue] = useState<string>('');
	const [disabled, setDisabled] = useState(true);
	const [textareaValue, setTextareaValue] = useState<string>('');

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, checked } = event.target;
		const labelValue = document.querySelector(`label[for=${id}]`)?.textContent || '';
		changeValues('know', labelValue);
		if (checked) {
			if (id === 'check6') {
				setDisabled(false);
			} else {
				setDisabled(true);
			}
			setCheckboxValue(labelValue);
			setTextareaValue('');
		} else {
			setCheckboxValue('');
		}
	};

	const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		changeValues(
			'know',
			checkboxValue.includes('Свой вариант') ? event.target.value : checkboxValue,
		);
		setTextareaValue(event.target.value);
	};
	const queryClient = useQueryClient();

	const { mutate } = useMutation((inputDto: IPatientCreate) => PatientService.create(values), {
		onSuccess: (response, formData) => {
			queryClient.invalidateQueries('patients');
			queryClient.invalidateQueries('patient');
			setPatientCreated(true);
			setIsNewPatientChoosen(true);
			setNewPatientIsOpen(false);
			setPatientCreatedId(response?.data?.uuid);
			setValues({
				phone: '+374',
				name: '',
				surname: '',
				middleName: '',
				know: '',
			});
			setCheckboxValue('');
			setIsPatientChoosen(true);
			setTextareaValue('');
			setDisabled(true);
		},
		onError: (error) => {},
	});

	const handleSave = async () => {
		await mutate(values);
		setCreateVisit(true);
	};

	return (
		<ModalBody className='d-flex flex-column align-items-center p-0 gap-5'>
			<div className='d-flex col-12 flex-row justify-content-start gap-3'>
				<div className='d-flex col-12 gap-4 flex-wrap justify-content-between'>
					{checkboxOptions.map(({ id, label }) => (
						<Checkbox
							key={id}
							id={id}
							label={label}
							checked={checkboxValue.includes(label)}
							onChange={handleCheckboxChange}
						/>
					))}
				</div>
			</div>

			<div className='col-12'>
				<Checkbox
					key={'check6'}
					id={'check6'}
					label={'Свой вариант'}
					checked={checkboxValue.includes('Свой вариант')}
					onChange={handleCheckboxChange}
				/>
			</div>

			<Textarea value={textareaValue} disabled={disabled} onChange={handleTextareaChange} />
			<div className='d-flex justify-content-between w-100'>
				<Button
					className='fw-bold fs-6'
					color='link'
					isLight
					style={{ color: '#0D6EFD' }}
					onClick={() => {
						setPatientNext(true);
						setPatientNext(true);
						setPatientCreated(false);
						setIsNewPatientChoosen(false);
					}}>
					Назад
				</Button>
				<Button
					className='fw-bold fs-6'
					color='info'
					onClick={() => {
						handleSave();
					}}>
					Сохранить
				</Button>
			</div>
		</ModalBody>
	);
};

export default NewPatientSecondStep;
