import React, { FC, memo } from 'react';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import { SubHeaderLeft, SubheaderSeparator } from '../../../../layout/SubHeader/SubHeader';
import { CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import { DatePicker, Space } from 'antd';
import { formatDate, getDayOfWeekInRussian } from '../../../../utils/functions';
import { Dayjs } from 'dayjs';

interface ISubHeaderLeftProps {
	dateDay: any;
	handleGetTodayesDate(...args: unknown[]): Date;
	setAndSaveAllDayButton(...args: unknown[]): unknown;
	dateTimePicker: any;
	allDayButton: any;
	setAndSaveDate(...args: unknown[]): unknown;
	setAndSaveDateTimePicker(...args: unknown[]): unknown;
	setDatePickerOpen(...args: unknown[]): unknown;
	handleGetTodayesDate(...args: unknown[]): unknown;
	datePickerOpen: any;
}

const SubHeaderLeftComponent: FC<ISubHeaderLeftProps> = memo(
	({
		dateDay,
		handleGetTodayesDate,
		setAndSaveAllDayButton,
		dateTimePicker,
		allDayButton,
		setAndSaveDate,
		setAndSaveDateTimePicker,
		setDatePickerOpen,
		datePickerOpen,
	}) => {
		const { RangePicker } = DatePicker;

		const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
			if (dates && dates[0] && dates[1] && dates[0].isValid() && dates[1].isValid()) {
				setAndSaveDateTimePicker(dates);
				setAndSaveAllDayButton(false);
			} else {
				console.error('Invalid date range');
				setAndSaveDateTimePicker(null);
			}
		};

		const handlePrevDay = () => {
			const prevDate = new Date(dateDay.date1);
			prevDate.setDate(prevDate.getDate() - 1);
			if (dateDay) {
				setAndSaveAllDayButton(false);
			}
			setAndSaveDate(prevDate);
			if (dateTimePicker) {
				setAndSaveDateTimePicker(null);
			}
		};

		const handleNextDay = () => {
			const nextDate = new Date(dateDay.date1);
			nextDate.setDate(nextDate.getDate() + 1);
			if (dateDay) {
				setAndSaveAllDayButton(false);
			}
			setAndSaveDate(nextDate);
			if (dateTimePicker) {
				setAndSaveDateTimePicker(null);
			}
		};
		const handleToday = () => {
			setAndSaveDate(handleGetTodayesDate());
			setAndSaveAllDayButton(false);
			if (dateTimePicker) {
				setAndSaveDateTimePicker(null);
			}
		};

		const formatDateRange = (dateTimePicker: any[]) => {
			return dateTimePicker
				? `${dateTimePicker[0]?.format('DD.MM.YYYY')} - ${dateTimePicker[1]?.format(
						'DD.MM.YYYY',
				  )}`
				: 'Указать промежуток';
		};

		return (
			<SubHeaderLeft className='col-auto d-flex flex-nowrap align-items-center'>
				<CardLabel icon='AttachMoney' iconColor='info'>
					<CardTitle tag='div' className='fs-5'>
						Финансы
					</CardTitle>
				</CardLabel>
				<SubheaderSeparator />
				<div className='d-flex col-auto gap-4 flex-nowrap'>
					<div style={{ minWidth: '250px' }}>
						<div className='fs-5 fw-bold'>
							<div className='d-flex align-items-center flex-row gap-2 '>
								<span className='fs-3'>{dateDay.date2},</span>{' '}
								<small>{getDayOfWeekInRussian(dateDay.date1)}</small>
							</div>
						</div>
					</div>
					<div>
						<ButtonGroup>
							<Button isLight color={'primary'} onClick={handlePrevDay}>
								{'<'}
							</Button>
							<Button
								isLight={
									!(
										dateDay.date1 ===
											formatDate(handleGetTodayesDate()).date1 &&
										!dateTimePicker &&
										!allDayButton
									)
								}
								color={'primary'}
								onClick={handleToday}>
								Сегодня
							</Button>
							<Button isLight color={'primary'} onClick={handleNextDay}>
								{'>'}
							</Button>
						</ButtonGroup>
					</div>
					<div>
						<Button
							isLight={!allDayButton}
							color={'primary'}
							onClick={() => {
								setAndSaveDate(handleGetTodayesDate());
								setAndSaveAllDayButton(true);
								setAndSaveDateTimePicker(null);
							}}>
							За всё время
						</Button>
					</div>
					<div style={{ position: 'relative' }}>
						<Button
							isLight={!dateTimePicker}
							color='primary'
							icon='Window'
							className='text-nowrap'
							isDisable={datePickerOpen}
							onClick={() => setDatePickerOpen((prev: any) => !prev)}
							style={{ cursor: 'pointer' }}>
							{formatDateRange(dateTimePicker)}
						</Button>
						<div
							style={{
								position: 'absolute',
								zIndex: '-1',
								opacity: '0',
								maxHeight: 0,
								display: datePickerOpen ? 'block' : 'none',
							}}>
							<Space direction='vertical' size={12}>
								<RangePicker
									onOpenChange={(e: boolean) => setDatePickerOpen(e)}
									open={datePickerOpen}
									onChange={handleDateChange}
									value={dateTimePicker}
								/>
							</Space>
						</div>
					</div>
				</div>
			</SubHeaderLeft>
		);
	},
);
export default SubHeaderLeftComponent;
