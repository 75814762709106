import React, { FC } from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import Input from '../../../../../../../../components/bootstrap/forms/Input';
import SubHeader, { SubHeaderLeft } from '../../../../../../../../layout/SubHeader/SubHeader';
import SearchUserSelect from './SearchUserSelect';
import {
	CalendarTodayButton,
	CalendarViewModeButtons,
} from '../../../../../../../../components/extras/calendarHelper';

interface IHeaderProps {
	search: any;
	userSearch: any;
	isUserDoctor: boolean;
	setSearch(...args: unknown[]): unknown;
	values: any;
	searchValues: any;
	changeSearchValue(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	setUsersList(...args: unknown[]): unknown;
	setViewMode(...args: unknown[]): unknown;
	resetFilter(...args: unknown[]): unknown;
	viewMode: any;
	date: any;
	setDate: any;
	unitType: any;
}

const SearchUser: FC<IHeaderProps> = ({
	search,
	userSearch,
	setSearch,
	isUserDoctor,
	values,
	changeSearchValue,
	changeValues,
	searchValues,
	setUsersList,
	setViewMode,
	viewMode,
	date,
	setDate,
	unitType,
	resetFilter,
}) => {
	return (
		<>
			<div style={{ marginBottom: 15 }} className='d-flex align-items-center gap-3 w-100'>
				{!isUserDoctor ? (
					<>
						<SubHeader className='w-25'>
							<SubHeaderLeft>
								<label className='border-0 bg-transparent cursor-pointer'>
									<Icon icon='Search' size='2x' color='primary' />
								</label>
								<label
									className='border-0 bg-transparent cursor-pointer me-0'
									htmlFor='searchInput'>
									<Icon icon='SearchUser' size='2x' color='primary' />
								</label>
								<Input
									value={userSearch}
									onChange={(e: any) => setSearch(e.target.value)}
									id='searchInput'
									type='search'
									className='border-0 shadow-none bg-transparent'
									placeholder='Поиск врача по ФИО или по коду'
								/>
							</SubHeaderLeft>
						</SubHeader>
						<SearchUserSelect
							resetFilter={resetFilter}
							values={values}
							changeSearchValue={changeSearchValue}
							changeValues={changeValues}
							search={search}
							isUserDoctor={isUserDoctor}
							setUsersList={setUsersList}
						/>
					</>
				) : (
					''
				)}
				<div>
					<CalendarTodayButton
						unitType={unitType}
						date={date}
						setDate={setDate}
						viewMode={viewMode}
					/>
				</div>
				<div>
					<CalendarViewModeButtons
						doctors
						setViewMode={setViewMode}
						viewMode={viewMode}
					/>
				</div>
			</div>
		</>
	);
};

export default SearchUser;
