import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import Avatar from '../../../components/Avatar';
import User1Img from '../../../assets/img/wanna/wanna2.png';
import { demoPagesMenu, mainPages } from '../../../menu';
import { UserService } from '../../../custom/api/service/user.service';
import { IPatientCreate } from '../../../custom/api/interface/patient.interface';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ClinicService } from '../../../custom/api/service/clinic.service';
import * as qs from 'qs';
import { notification, Upload } from 'antd';
import type { NotificationArgsProps } from 'antd';
import CommonMyWallet from '../../../pages/_common/CommonMyWallet';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import editPasswordValidate from '../../../pages/presentation/demo-pages/helper/editPasswordValidate';
import { CategoryService } from '../../../custom/api/service/category.service';
import Wizard, { WizardItem } from '../../../components/Wizard';
import { rolesTranslate } from '../../../utils/dataUtil';
import Clinics from './Components/Clinics';
import ClinicsOrganisations from './Components/ClinicsOrganisations';
import Finance from './Components/Finance';
import Category from './Components/Category';
import SubCategory from './Components/SubCategory';
import Services from './Components/Services';
import Materials from './Components/Materials';
import MaterialGroups from './Components/MaterialGroups';
import MaterialsSubGroups from './Components/MaterialsSubGroups';
import Roles from './Components/Roles';
import Calendars from './Components/Calendars';
import Notifications from './Components/Notifications';

type NotificationPlacement = NotificationArgsProps['placement'];

const DirectoriesPage = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const queryParams = new URLSearchParams(location.search);
	const tab_name = queryParams.get('tab');

	const TABS = {
		CLINICS: {
			tab: 'Клиники',
			placeholder: 'Поиск по названию клиники',
		},
		CLINICS_ORGANIZATIONS: {
			tab: 'Организация клиник',
			placeholder: 'Поиск по названию организации клиник',
		},
		FINANCE: {
			tab: 'Финансы',
			placeholder: 'Поиск по названию финансового документа',
		},
		MARCETING_SALES: {
			tab: 'Маркетинговые акции',
			placeholder: 'Поиск по названию акции',
		},
		CATEGORY: {
			tab: 'Спецализация',
			placeholder: 'Поиск по названию Спецализация',
		},
		SUBCATEGORY: {
			tab: 'Подспецилизация',
			placeholder: 'Поиск по названию Подспецализация',
		},
		SERVICES: {
			tab: 'Услуги',
			placeholder: 'Поиск по названию услуги',
		},
		MATERIALS: {
			tab: 'Материалы',
			placeholder: 'Поиск по названию Материалы',
		},
		MATERIALS_GROUP: {
			tab: 'Группы материалов',
			placeholder: 'Поиск по названию Группы материалов',
		},
		MATERIALS_SUBGROUP: {
			tab: 'Подгруппы материалов',
			placeholder: 'Поиск по названию Подгруппы материалов',
		},
		ROLES: {
			tab: 'Должности и роли',
			placeholder: 'Поиск по названию должности или роли',
		},
		CALENDARS: {
			tab: 'Календари',
			placeholder: 'Поиск по названию календаря',
		},
		NOTIFICATIONS: {
			tab: 'Уведомления',
			placeholder: 'Поиск по названию уведомления',
		},
	};

	const tabs_to_placholder: Record<string, string> = {
		Клиники: 'Поиск по названию клиники',
		'Организация клиник': 'Поиск по названию организации клиник',
		'Маркетинговые акции': 'Поиск по названию финансового документа',
		Финансы: 'Поиск по названию акции',
		Спецализация: 'Поиск по названию Спецализация',
		Подспецилизация: 'Поиск по названию Подспецализация',
		Услуги: 'Поиск по названию услуги',
		Материалы: 'Поиск по названию Материалы',
		'Группы материалов': 'Поиск по названию Группы материалов',
		'Подгруппы материалов': 'Поиск по названию Подгруппы материалов',
		'Должности и роли': 'Поиск по названию должности или роли',
		Календари: 'Поиск по названию календаря',
		Уведомления: 'Поиск по названию уведомления',
	};

	const tabs_translat_to_eng: Record<string, string> = {
		Клиники: 'clinics',
		'Организация клиник': 'clinics_organizations',
		'Маркетинговые акции': 'marceting_sales',
		Финансы: 'finance',
		Спецализация: 'category',
		Подспецилизация: 'subCategory',
		Услуги: 'services',
		Материалы: 'materials',
		'Группы материалов': 'materials_group',
		'Подгруппы материалов': 'materials_subgroups',
		'Должности и роли': 'roles',
		Календари: 'calendars',
		Уведомления: 'notifications',
	};

	const tabs_translat_to_rus: Record<string, string> = {
		clinics: 'Клиники',
		clinics_organizations: 'Организация клиник',
		finance: 'Финансы',
		category: 'Спецализация',
		subCategory: 'Подспецилизация',
		marceting_sales: 'Маркетинговые акции',
		services: 'Услуги',
		materials: 'Материалы',
		materials_group: 'Группы материалов',
		materials_subgroups: 'Подгруппы материалов',
		roles: 'Должности и роли',
		calendars: 'Календари',
		notifications: 'Уведомления',
	};

	const [activeTab, setActiveTab] = useState<string>(
		tab_name && tabs_translat_to_rus[tab_name]
			? tabs_translat_to_rus[tab_name]
			: TABS.CLINICS.tab,
	);

	// Ensure state is updated when the URL changes
	useEffect(() => {
		if (tab_name && tabs_translat_to_rus[tab_name]) {
			setActiveTab(tabs_translat_to_rus[tab_name]);
		}
		setSearch('');
	}, [tab_name]);

	const setTab = (tab: string) => {
		const englishTab = tabs_translat_to_eng[tab];
		if (!englishTab) return;

		navigate(`${mainPages.servicesAndMaterials.subMenu.directories.path}?tab=${englishTab}`);
	};

	const [api, contextHolder] = notification.useNotification();

	const openNotification = (
		placement: NotificationPlacement,
		message: string,
		description: string,
	) => {
		api.info({
			message,
			description,
			placement,
		});
	};

	console.log(activeTab, 'jjhhojookokokok');
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [search, setSearch] = useState('');

	return (
		<PageWrapper title={mainPages.servicesAndMaterials.subMenu.directories.text}>
			<>
				<SubHeader>
					{activeTab !== TABS.CATEGORY.tab && activeTab !== TABS.SUBCATEGORY.tab && (
						<SubHeaderLeft>
							<label className='border-0 bg-transparent cursor-pointer'>
								<Icon icon='Search' size='2x' color='primary' />
							</label>
							<label
								className='border-0 bg-transparent cursor-pointer me-0'
								htmlFor='searchInput'>
								<Icon icon='SearchUser' size='2x' color='primary' />
							</label>
							<Input
								value={search}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setSearch(e.target.value)
								}
								id='searchInput'
								type='search'
								className='border-0 shadow-none bg-transparent'
								placeholder={
									tabs_to_placholder[activeTab as keyof typeof TABS] || ''
								}
							/>
						</SubHeaderLeft>
					)}
				</SubHeader>
			</>

			<Page container='fluid'>
				<span>{contextHolder}</span>
				<div style={{ overflow: 'hidden' }} className='row h-100 pb-3'>
					<div className='col-lg-2 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='AccountCircle'>
									<CardTitle tag='div' className='h5'>
										Справочники
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<p className='text-muted fs-4 fw-bold m-0 mt-2'>Предприятие</p>
									<div className='col-12'>
										<Button
											icon='Business'
											color='info'
											className='w-100 p-3 text-start fs-6'
											isLight={TABS.CLINICS.tab !== activeTab}
											onClick={() => setTab(TABS.CLINICS.tab)}>
											{TABS.CLINICS.tab}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Store'
											color='info'
											className='w-100 p-3 text-start fs-6'
											isLight={TABS.CLINICS_ORGANIZATIONS.tab !== activeTab}
											onClick={() => setTab(TABS.CLINICS_ORGANIZATIONS.tab)}>
											{TABS.CLINICS_ORGANIZATIONS.tab}
										</Button>
									</div>
									{/* <div className='col-12'>
										<Button
											icon='AttachMoney'
											color='info'
											className='w-100 p-3 text-start fs-5'
											isLight={TABS.FINANCE !== activeTab}
											onClick={() => setTab(TABS.FINANCE)}>
											{TABS.FINANCE}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='CardGiftcard'
											color='info'
											className='w-100 p-3 text-start fs-5'
											isLight={TABS.MARCETING_SALES !== activeTab}
											// onClick={() => setTab(TABS.FINANCE)}
										>
											{TABS.MARCETING_SALES}
										</Button>
									</div> */}

									<p className='text-muted fs-4 fw-bold m-0 mt-2'>Структура</p>
									<div className='col-12'>
										<Button
											icon='PeopleAlt'
											color='info'
											className='w-100 p-3 text-start fs-6'
											isLight={TABS.CATEGORY.tab !== activeTab}
											onClick={() => setTab(TABS.CATEGORY.tab)}>
											{TABS.CATEGORY.tab}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='AppRegistration'
											color='info'
											className='w-100 p-3 text-start fs-6'
											isLight={TABS.SUBCATEGORY.tab !== activeTab}
											onClick={() => setTab(TABS.SUBCATEGORY.tab)}>
											{TABS.SUBCATEGORY.tab}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='MedicalServices'
											color='info'
											className='w-100 p-3 text-start fs-6'
											isLight={TABS.SERVICES.tab !== activeTab}
											onClick={() => setTab(TABS.SERVICES.tab)}>
											{TABS.SERVICES.tab}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Cases'
											color='info'
											className='w-100 p-3 text-start fs-6'
											isLight={TABS.MATERIALS.tab !== activeTab}
											onClick={() => setTab(TABS.MATERIALS.tab)}>
											{TABS.MATERIALS.tab}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Medication'
											color='info'
											className='w-100 p-3 text-start fs-6'
											isLight={TABS.MATERIALS_GROUP.tab !== activeTab}
											onClick={() => setTab(TABS.MATERIALS_GROUP.tab)}>
											{TABS.MATERIALS_GROUP.tab}
										</Button>
									</div>
									{/* <div className='col-12'>
										<Button
											icon='SpaceDashboard'
											color='info'
											className='w-100 p-3 text-start fs-5'
											isLight={TABS.MATERIALS_SUBGROUP !== activeTab}
											onClick={() => setTab(TABS.MATERIALS_SUBGROUP)}>
											{TABS.MATERIALS_SUBGROUP}
										</Button>
									</div> */}
									{/* <p className='text-muted fs-4 fw-bold m-0 mt-2'>Персонал</p>
									<div className='col-12'>
										<Button
											icon='BusinessCenter'
											color='info'
											className='w-100 p-3 text-start fs-5'
											isLight={TABS.ROLES !== activeTab}
											onClick={() => setTab(TABS.ROLES)}>
											{TABS.ROLES}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='CalendarToday'
											color='info'
											className='w-100 p-3 text-start fs-5'
											isLight={TABS.CALENDARS !== activeTab}
											onClick={() => setTab(TABS.CALENDARS)}>
											{TABS.CALENDARS}
										</Button>
									</div> */}
									{/* <div className='col-12'>
										<Button
											icon='Notification'
											color='info'
											className='w-100 p-3 text-start fs-5'
											isLight={TABS.NOTIFICATIONS !== activeTab}
											onClick={() => setTab(TABS.NOTIFICATIONS)}>
											{TABS.NOTIFICATIONS}
										</Button>
									</div> */}
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-10'>
						{TABS.CLINICS.tab === activeTab && (
							<Clinics setSearch={setSearch} search={search} />
						)}
						{TABS.CLINICS_ORGANIZATIONS.tab === activeTab && (
							<ClinicsOrganisations setSearch={setSearch} search={search} />
						)}
						{TABS.FINANCE.tab === activeTab && (
							<Finance setSearch={setSearch} search={search} />
						)}
						{TABS.CATEGORY.tab === activeTab && (
							<Category setSearch={setSearch} search={search} />
						)}
						{TABS.SUBCATEGORY.tab === activeTab && (
							<SubCategory setSearch={setSearch} search={search} />
						)}
						{TABS.SERVICES.tab === activeTab && (
							<Services setSearch={setSearch} search={search} />
						)}
						{TABS.MATERIALS.tab === activeTab && (
							<Materials setSearch={setSearch} search={search} />
						)}
						{TABS.MATERIALS_GROUP.tab === activeTab && (
							<MaterialGroups setSearch={setSearch} search={search} />
						)}
						{TABS.MATERIALS_SUBGROUP.tab === activeTab && (
							<MaterialsSubGroups setSearch={setSearch} search={search} />
						)}
						{TABS.ROLES.tab === activeTab && (
							<Roles setSearch={setSearch} search={search} />
						)}
						{TABS.CALENDARS.tab === activeTab && (
							<Calendars setSearch={setSearch} search={search} />
						)}
						{TABS.NOTIFICATIONS.tab === activeTab && (
							<Notifications setSearch={setSearch} search={search} />
						)}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DirectoriesPage;
