import React from 'react';
import Icon from '../../../../components/icon/Icon';
import { VisitStatusStyles } from '../../../../utils/functions';

const Status: React.FC<{ status: string | undefined; statusName?: string | undefined }> = ({
	status,
	statusName,
}) => {
	return (
		<div className='text-nowrap fw-bold fs-6'>
			<Icon
				style={{
					fill:
						status !== undefined
							? VisitStatusStyles(status)?.iconeStyles.color
							: 'black',
				}}
				icon='Circle'
				className='me-2'
				color={
					status !== undefined
						? VisitStatusStyles(status)?.iconeStyles.iconeColor.color
						: 'black'
				}
			/>
			<span
				style={{
					color:
						status !== undefined
							? VisitStatusStyles(status)?.iconeStyles.color
							: 'black',
				}}>
				{statusName || status}
			</span>
		</div>
	);
};

export default Status;
