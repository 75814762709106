import React, { FC, memo } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../../../../components/bootstrap/Card';
import {
	CalendarTodayButton,
	CalendarViewModeButtons,
} from '../../../../../../../components/extras/calendarHelper';
import { Calendar, Views } from 'react-big-calendar';
import { VisitStatusStyles } from '../../../../../../../utils/functions';
import { MyEvent } from '../Events/MyEvent';
import dayjs from 'dayjs';
import UserImage3 from '../../../../../../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../../../../../../assets/img/wanna/wanna3.png';

interface IHeaderProps {
	viewMode: any;
	prevVisit: any;
	setDate: any;
	localizer: any;
	date: any;
	views: any;
	// unitType: any;
	visitData: any;
	// setViewMode(...args: unknown[]): unknown;
	setIsOpenSubmit(...args: unknown[]): unknown;
	setTimePickerIsOpen(...args: unknown[]): unknown;
	handleSelect(...args: unknown[]): unknown;
	handleViewMode(...args: unknown[]): unknown;
	eventStyleGetter: any;
}

const CalendarComponent: FC<IHeaderProps> = memo(
	({
		eventStyleGetter,
		viewMode,
		localizer,
		// unitType,
		views,
		setTimePickerIsOpen,
		handleSelect,
		setIsOpenSubmit,
		handleViewMode,
		date,
		setDate,
		// setViewMode,
		visitData,
		prevVisit,
	}) => {
		return (
			<div className='col-12'>
				<Card stretch style={{ minHeight: 500 }}>
					<CardHeader>
						<CardActions className='w-100'>
							<div className=' d-flex flex-row gap-4 justify-content-between w-100'>
								<CardLabel icon='Today' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										{dayjs(date).format('MMMM D YYYY')}
									</CardTitle>
									<CardSubTitle tag='div' className='h6'>
										{dayjs(date).fromNow()}
									</CardSubTitle>
								</CardLabel>
								{/* <CardActions className='d-flex'>
									<CalendarViewModeButtons
										notMonth
										doctors
										setViewMode={setViewMode}
										viewMode={viewMode}
									/>
									<CalendarTodayButton
										unitType={unitType}
										date={date}
										setDate={setDate}
										viewMode={viewMode}
									/>
								</CardActions> */}
							</div>
						</CardActions>
					</CardHeader>
					<CardBody isScrollable>
						<Calendar
							selectable
							toolbar={false}
							localizer={localizer}
							timeslots={1}
							events={
								visitData?.map((visit: any, idx: number) => ({
									color: VisitStatusStyles(visit.status)?.iconeStyles.iconeColor
										.color,
									uuid: visit?.uuid,
									name: visit?.services?.map((i: any) => (
										<>
											{i?.title} <br />
										</>
									)),
									start: new Date(visit.startDate),
									end: new Date(visit.endDate),
									user: {
										...visit?.user,
										name: '',
										src: UserImage3,
										srcSet: UserImage3Webp,
									},
									updateVisitUuid: !!prevVisit ? prevVisit.uuid : null,
									...visit,
								})) || []
							}
							defaultView={Views.WEEK}
							views={views}
							view={viewMode}
							date={date}
							onNavigate={(_date) => setDate(_date)}
							scrollToTime={new Date(1970, 1, 1, 6)}
							defaultDate={new Date()}
							onSelectSlot={handleSelect}
							onView={handleViewMode}
							onDrillDown={handleViewMode}
							components={{
								event: (props) => (
									<MyEvent
										{...props}
										setIsOpen={setIsOpenSubmit}
										setTimePickerIsOpen={setTimePickerIsOpen}
									/>
								),
							}}
							eventPropGetter={eventStyleGetter}
						/>
					</CardBody>
				</Card>
			</div>
		);
	},
);

export default CalendarComponent;
