import React, { SVGProps } from "react";

export const THEETH_28 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2.46rem" height="4.84rem" viewBox="0 0 32 63" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M23.2015 0.39645C11.3166 8.19796 8.53741 20.9771 9.66577 38.7668C9.95879 45.5592 25.9984 49.4745 25.6697 41.2258C25.124 27.5316 25.6697 26.5974 26.1658 6.2478C25.5984 3.42793 24.5689 -0.501133 23.2015 0.39645Z" fill="url(#paint0_linear_402_4964)"/>
<g filter="url(#filter0_i_402_4964)">
<path d="M29.1319 4.26685C31.1658 5.53797 25.6502 9.91413 29.1322 44.0193C22.728 48.9316 7.43065 58.4601 0.307751 49.7022C-0.390358 41.0719 1.66579 11.2668 15.1658 5.26685C19.1658 3.48907 11.3855 24.8195 14.6658 27.7668C15.0872 15.2668 25.6658 -0.233154 29.1319 4.26685Z" fill="url(#paint1_linear_402_4964)"/>
</g>
<path d="M28.6457 57.2668C24.6457 61.7668 19.6462 58.7671 17.6462 56.2671C15.1462 56.2671 10.7085 60.3271 8.20845 60.3271C5.70845 60.3271 -3.00021 57.6443 1.70846 46.3271C4.646 39.2668 13.646 35.7668 16.646 36.7668C32.1463 38.2668 33.8104 51.4565 28.6457 57.2668Z" fill="url(#paint2_linear_402_4964)"/>
<path d="M28.6457 57.2668C24.6457 61.7668 19.6462 58.7671 17.6462 56.2671C15.1462 56.2671 10.7085 60.3271 8.20845 60.3271C5.70845 60.3271 -3.00021 57.6443 1.70846 46.3271C4.646 39.2668 13.646 35.7668 16.646 36.7668C32.1463 38.2668 33.8104 51.4565 28.6457 57.2668Z" fill="url(#paint3_radial_402_4964)" fillOpacity="0.3"/>
<g filter="url(#filter1_f_402_4964)">
<path d="M17.7769 56.2661L15.7769 52.7661L18.7769 48.2661L16.7769 46.2661" stroke="#140000"/>
</g>
<defs>
<filter id="filter0_i_402_4964" x="-2.91104" y="0.370947" width="32.5075" height="52.8959" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-8" dy="-8"/>
<feGaussianBlur stdDeviation="1.55"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4964"/>
</filter>
<filter id="filter1_f_402_4964" x="9.18945" y="39.9126" width="16.2307" height="22.6016" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_402_4964"/>
</filter>
<linearGradient id="paint0_linear_402_4964" x1="17.8005" y1="45.8799" x2="17.8005" y2="0.266026" gradientUnits="userSpaceOnUse">
<stop stopColor="#5C472A"/>
<stop offset="1" stopColor="#503E25"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4964" x1="14.8927" y1="53.2668" x2="14.8927" y2="3.47106" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4964" x1="15.806" y1="60.3271" x2="15.806" y2="36.5989" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4964" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.4794 51.2861) rotate(-112.756) scale(3.41171 4.72905)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};