import React, { FC } from 'react';
import Tooltips from '../../../../../../../components/bootstrap/Tooltips';
import { TeethRectangle } from '.././UI/TeethRectangle';
import Input from '../../../../../../../components/bootstrap/forms/Input';
import { formatPrice } from '../../../../../../../utils/functions';

// type ItemType = 'bridge' | 'referral' | 'implant' | 'event' | 'deletion' | 'milk_theeth' | 'empty';

interface IServices {
	data: any;
	changeData(...args: unknown[]): unknown;
	index: number;
	canEdit: boolean;
	disabled: boolean;
}

export const Service: FC<IServices> = ({ data, changeData, index, canEdit, disabled }) => {
	return (
		<div className='col-12 d-flex justify-content-between'>
			<div className='col-5 d-flex'>
				<div className='col-12 d-flex align-items-center justify-content-start'>
					<span className='overflow-hidden w-100'>
						<Tooltips title={data?.title_all?.rus} placement='top' flip={['top']}>
							<span className='text-truncate  text-left p-2'>{data?.title_all?.rus}</span>
						</Tooltips>
					</span>
				</div>
				{data.service_type !== 'lor' && data.service_type !== 'cosmetology' && (
					<div className='col-6 d-flex flex-wrap align-items-center '>
						<span className='d-flex col-5  text-center p-2 gap-3'>Для зубов </span>
						<div className='d-flex flex-wrap col-5 gap-1'>
							<TeethRectangle data={data} />
						</div>
					</div>
				)}
			</div>
			<div className='col-5 d-flex justify-content-around'>
				<div className='col-5 d-flex justify-content-center  p-2'>
					<Input
						name='price'
						disabled={!disabled}
						value={data?.price}
						onChange={(e: any) => {
							changeData(e, 'service', index, null);
						}}
					/>
				</div>
				<div className='col-2 d-flex justify-content-center'>
					<span className='overflow-hidden w-100  d-flex align-items-center  text-center p-2'>
						<Tooltips title={data?.count} placement='top' flip={['top']}>
							<span className='text-truncate fw-bold'>{data?.count}</span>
						</Tooltips>
					</span>
				</div>
				<div className='col-4 d-flex justify-content-center'>
					<span className='overflow-hidden w-100  text-center d-flex align-items-center p-2'>
						<Tooltips
							title={formatPrice(data?.price * data?.count, 'AMD', 'ru-RU')}
							placement='top'
							flip={['top']}>
							<span className='text-truncate  fw-bold'>
								{formatPrice(data?.price * data?.count, 'AMD', 'ru-RU')}
							</span>
						</Tooltips>
					</span>
				</div>
			</div>
		</div>
	);
};
