import React, { SVGProps } from "react";

export const PRINT_TOOTH_25 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100"  viewBox="0 0 18 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M16.962 37.5725C15.1774 10.5148 18.0044 7.20846 16.962 6.2C10.0429 10.9602 1.8536 31.3531 2.2114 38.2" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M9.54791 31.2C17.326 32.7899 18.7758 43.8536 15.6893 48.4449C14.0283 50.9158 11.8566 51.5666 5.22963 51.0193C3.95015 51.0193 -0.506897 48.7625 1.90297 39.2423C3.40639 33.303 6.94278 31.2 9.54791 31.2Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}

