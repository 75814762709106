import React, { SVGProps } from "react";


export const PRINT_TOOTH_28 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100"  viewBox="0 0 20 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M18 42.3518C15.9433 10.2054 18.6569 5.1482 17.4555 3.95008C15.4081 -0.291472 9.15954 14.3183 8.91062 26.1004C6.97301 23.3223 11.5687 3.21697 9.20595 4.89265C1.23175 10.5481 0.68691 36.0654 1.09927 44.2" stroke="black" strokeWidth="0.7"/>
<path id="Vector 135" d="M10 8.54562C12.3189 5.19979 13.8029 2.826 12.5355 9.2" stroke="black" strokeWidth="0.35"/>
<path id="Vector 24" d="M17.5033 53.3424C15.1716 57.5443 12.2571 54.7433 11.0913 52.4088C9.63393 52.4088 7.04703 56.2 5.58969 56.2C4.13235 56.2 -0.944244 53.6948 1.80061 43.1272C3.513 36.5344 7.54097 34.2 10.5082 34.2C19.3675 35.9648 20.514 47.9168 17.5033 53.3424Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}

