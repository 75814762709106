import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import Button from '../../../../../components/bootstrap/Button';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft } from '../../../../../layout/SubHeader/SubHeader';
import Avatar from '../../../../../components/Avatar';
import User1Img from '../../../../../assets/img/wanna/wanna2.png';
import { demoPagesMenu } from '../../../../../menu';
import { UserService } from '../../../../../custom/api/service/user.service';
import { IPatientCreate } from '../../../../../custom/api/interface/patient.interface';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ClinicService } from '../../../../../custom/api/service/clinic.service';
import * as qs from 'qs';
import { notification, Upload } from 'antd';
import type { NotificationArgsProps } from 'antd';
import CommonMyWallet from '../../../../../pages/_common/CommonMyWallet';
import showNotification from '../../../../../components/extras/showNotification';
import editPasswordValidate from '../../../../../pages/presentation/demo-pages/helper/editPasswordValidate';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import Wizard, { WizardItem } from '../../../../../components/Wizard';
import { rolesTranslate } from '../../../../../utils/dataUtil';
import Icon from '../../../../../components/icon/Icon';
import { capitalizeFirstLetter } from '../../../../../utils/functions';

interface IClinicsItemButtonComponentProps {
	clinicUuid?: any;
	setClinicUuid(...args: unknown[]): unknown;
}

const ClinicsItemButtonComponent: FC<IClinicsItemButtonComponentProps> = ({
	clinicUuid,
	setClinicUuid,
}) => {
	const { data: clinics } = useQuery(['clinics'], () =>
		ClinicService.findAll(
			qs.stringify({
				queryMeta: {
					order: { createdAt: 'ASC' },
				},
			}),
		),
	);

	return (
		<div className='d-flex align-items-center gap-3'>
			{clinics?.data?.map((clinic: any) => (
				<Button
					onClick={() => {
						if (clinicUuid === clinic?.uuid) {
							setClinicUuid('');
						} else {
							setClinicUuid(clinic?.uuid);
						}
					}}
					className='d-flex align-items-center justify-content-center'
					style={{
						width: 200,
						height: 64,
						backgroundColor: clinic.uuid === clinicUuid ? '#0D6EFD' : '#E8F1FF',
						borderRadius: 12,
					}}>
					<div className='d-flex align-items-center'>
						<Icon
							icon={capitalizeFirstLetter(clinic.name) + 'Icon'}
							style={{
								width: 40,
								height: 40,
							}}
						/>
						<span
							className={`fs-3 fw-bold text-${
								clinicUuid === clinic?.uuid ? 'light' : 'dark'
							}`}>
							{clinic.name}
						</span>
					</div>
				</Button>
			))}
		</div>
	);
};

export default ClinicsItemButtonComponent;
