import React, { SVGProps } from "react";

export const THEETH_47 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
<svg xmlns="http://www.w3.org/2000/svg" width="2.7rem" height="5.7rem" viewBox="0 0 35 74" fill="none" {...props}>
<g filter="url(#filter0_i_2354_11234)">
<path d="M33.0902 73C38.2018 75.5 27.8361 52.971 30.855 20.0611C25.3024 15.321 12.6535 7.95349 6.47781 16.4044C1.60244 26.5618 2.82137 67.8558 15.8221 67.4495C18.7176 67.359 7.49809 42.1556 15.8221 39.5C20.4981 44.6556 27.5072 70.2694 33.0902 73Z" fill="url(#paint0_linear_2354_11234)"/>
</g>
<g filter="url(#filter1_i_2354_11234)">
<path d="M26.9512 17.241L30.8573 19.7258C30.9833 19.8059 31.0554 19.9424 31.0457 20.0914C30.5854 27.1177 31.2578 43.2047 32.4289 51.4995C33.1985 56.9499 33.4313 59.4862 33.6224 61.3995C34.001 65.1894 34.7209 69.6745 34.6967 71.8159C34.6901 72.3968 34.2075 72.418 33.9762 71.8851C27.7124 57.4529 25.9652 37.098 26.577 17.4384C26.5829 17.2497 26.7919 17.1397 26.9512 17.241Z" fill="url(#paint1_linear_2354_11234)"/>
</g>
<path d="M29 24C33.2014 24 33.7438 13.0045 33.6167 7.95335C33.6152 7.89428 33.607 7.83632 33.5916 7.77927C32.6638 4.3457 28.5637 4.56383 26.4904 5.4302C26.1342 5.57907 25.7112 5.52319 25.4318 5.2567C20.9954 1.02489 15.9064 3.01512 13.4194 4.89619C13.1073 5.13221 12.6745 5.14597 12.3606 4.91249C8.16455 1.792 3.12539 -0.0654678 0.37944 5.961C0.328751 6.07225 0.304428 6.201 0.30928 6.32315C0.73901 17.1455 3.38416 20.6921 5 21.5C6.30016 22.1501 12.884 21.2709 15.999 21C17.895 21.8126 24.7745 24 29 24Z" fill="url(#paint2_linear_2354_11234)"/>
<path d="M29 24C33.2014 24 33.7438 13.0045 33.6167 7.95335C33.6152 7.89428 33.607 7.83632 33.5916 7.77927C32.6638 4.3457 28.5637 4.56383 26.4904 5.4302C26.1342 5.57907 25.7112 5.52319 25.4318 5.2567C20.9954 1.02489 15.9064 3.01512 13.4194 4.89619C13.1073 5.13221 12.6745 5.14597 12.3606 4.91249C8.16455 1.792 3.12539 -0.0654678 0.37944 5.961C0.328751 6.07225 0.304428 6.201 0.30928 6.32315C0.73901 17.1455 3.38416 20.6921 5 21.5C6.30016 22.1501 12.884 21.2709 15.999 21C17.895 21.8126 24.7745 24 29 24Z" fill="url(#paint3_radial_2354_11234)" fillOpacity="0.3"/>
<g filter="url(#filter2_f_2354_11234)">
<path d="M25.6834 5.84766L27.3086 11.5358" stroke="black" strokeWidth="0.812592"/>
</g>
<g filter="url(#filter3_f_2354_11234)">
<path d="M12.6815 5.03516L14.3066 10.7233" stroke="black" strokeWidth="0.812592"/>
</g>
<g filter="url(#filter4_f_2354_11234)">
<path d="M21.9464 11.9419C21.1339 16.0049 24.9843 14.786 27.2282 14.786C29.4721 14.786 28.8535 12.9244 28.8535 11.1293C28.8535 9.33419 22.5651 7.47266 20.3212 7.47266C18.0773 7.47266 19.5087 10.723 21.9464 11.9419Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_i_2354_11234" x="0.591429" y="9.2926" width="33.9398" height="63.8964" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-3.25037" dy="-3.25037"/>
<feGaussianBlur stdDeviation="2.03148"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_2354_11234"/>
</filter>
<filter id="filter1_i_2354_11234" x="26.4619" y="17.2026" width="9.86054" height="56.6916" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1.62518" dy="1.62518"/>
<feGaussianBlur stdDeviation="1.62518"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_2354_11234"/>
</filter>
<filter id="filter2_f_2354_11234" x="20.4174" y="0.860288" width="12.1574" height="15.6627" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.43778" result="effect1_foregroundBlur_2354_11234"/>
</filter>
<filter id="filter3_f_2354_11234" x="7.41546" y="0.0477881" width="12.1574" height="15.6627" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.43778" result="effect1_foregroundBlur_2354_11234"/>
</filter>
<filter id="filter4_f_2354_11234" x="15.1382" y="3.4097" width="17.8564" height="15.6108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.03148" result="effect1_foregroundBlur_2354_11234"/>
</filter>
<linearGradient id="paint0_linear_2354_11234" x1="19.4829" y1="12.543" x2="19.4829" y2="61.8502" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_2354_11234" x1="26.1939" y1="22.559" x2="38.0324" y2="62.1886" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_2354_11234" x1="16.355" y1="2" x2="16.355" y2="22.3862" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_2354_11234" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.67589 9.76765) rotate(116.87) scale(3.03018 4.74747)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>
        </>
	);
};