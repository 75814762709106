import React, { FC } from 'react';
import Card from '../../../../components/bootstrap/Card';
import Table from './Table';
import Header from './Header';

interface IUserSummaryComponentProps {}

const UserSummaryComponent: FC<IUserSummaryComponentProps> = () => {
	return (
		<div className='col-lg-8'>
			<Card className='shadow-3d-primary'>
				<Header />
				<Table />
			</Card>
		</div>
	);
};

export default UserSummaryComponent;
