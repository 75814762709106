import React, { FC, memo, useCallback } from 'react';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import { IClinic } from '../../../../../custom/api/interface/clinic.interface';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import { useQuery } from 'react-query';
import { ClinicService } from '../../../../../custom/api/service/clinic.service';
import * as qs from 'qs';

interface IHeaderProps {
	setFilter(...args: unknown[]): unknown;
	handleCheckboxChange(...args: unknown[]): unknown;
	filter: any;
	values: any;
}

const ServicesFiltration: FC<IHeaderProps> = memo(
	({ setFilter, handleCheckboxChange, filter, values }) => {
		const changeFilterValues = useCallback(
			(key: string, value: string | number) => {
				setFilter((prev: any) => ({
					...prev,
					[key]: value,
				}));
			},
			[setFilter],
		);

		const { data: clinics } = useQuery<IClinic | null, Error>(
			['clinics'],
			() =>
				ClinicService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
			['categories'],
			() =>
				CategoryService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);
		return (
			<>
				<FormGroup id='surname' label='Введите название или код услуги' className='col-12'>
					<Input
						name='serviceName'
						type={'search'}
						onChange={(e: any) => handleCheckboxChange(e)}
						value={values?.serviceName}
					/>
				</FormGroup>
				<div className='col-12 d-flex gap-3'>
					<FormGroup id='clinic' label='Филиал' className='m-0 d-flex flex-column col-3'>
						<div style={{ position: 'relative' }}>
							<select
								required
								onChange={(e) => {
									changeFilterValues('clinic', e.target.value);
								}}
								value={filter?.clinic}
								className='form-select'
								aria-label='Выберите пол'>
								<option value={''}>Все</option>
								{clinics?.data.map((item: IClinic, index: number) => (
									<option key={item.uuid} value={item.uuid}>
										{item.name}
									</option>
								))}
							</select>
						</div>
					</FormGroup>
					<FormGroup
						id='healing_type'
						label='Тип Лечения'
						className='m-0 d-flex flex-column col-3'>
						<div style={{ position: 'relative' }}>
							<select
								required
								onChange={(e) => {
									changeFilterValues('treatment_type', e.target.value);
								}}
								value={values?.treatment_type}
								className='form-select'
								aria-label='Выберите пол'>
								<option value={''}>Все</option>{' '}
								<option value={'Амбулаторная'}>Амбулаторная</option>
								<option value={'Стационарная'}>Стационарная</option>
							</select>
						</div>
					</FormGroup>
					<FormGroup id='category' label='Категория' className='m-0 d-flex flex-column'>
						<div style={{ position: 'relative' }}>
							<select
								required
								onChange={(e) => {
									changeFilterValues('category', e.target.value);
								}}
								value={values?.category}
								className='form-select'
								aria-label='Выберите пол'>
								<option value={''}>Все</option>
								{categoriesWithSubCategories?.data?.map(
									(item: any, index: number) => (
										<option key={item.uuid} value={item.uuid}>
											{item?.name?.rus}
										</option>
									),
								)}
							</select>
						</div>
					</FormGroup>
				</div>
			</>
		);
	},
);

export default ServicesFiltration;
