import React, { SVGProps } from "react";

export const THEETH_14 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2rem" height="6.15rem" viewBox="0 0 26 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M18.5738 1.25787C6.57384 9.25799 3.41707 45.4843 5.08374 63.8176C5.51655 70.8176 23.0694 68.8182 22.5839 60.3174C21.7778 46.2047 22.0744 17.3174 22.0744 2.75735C21.2363 -0.148705 20.3158 0.0965118 18.5738 1.25787Z" fill="url(#paint0_linear_402_4957)"/>
<path d="M19.2145 65.2411C18.7 50.8255 15.6424 36.0244 21.194 1.07864C21.2141 0.951988 21.3487 0.876686 21.4395 0.967239C23.5922 3.11407 21.2634 24.757 22.2344 55.8271C23.1817 61.0357 22.7831 64.0022 19.7654 65.5759C19.5155 65.7062 19.2245 65.5228 19.2145 65.2411Z" fill="url(#paint1_linear_402_4957)"/>
<g filter="url(#filter0_f_402_4957)">
<path d="M8.82088 45.8263C8.96521 35.6495 12.9234 23.2009 13.9432 23.2154C14.963 23.2298 11.5503 35.6862 11.4059 45.863C11.2616 56.0398 12.1375 66.1941 11.1177 66.1796C10.0979 66.1652 8.67654 56.0031 8.82088 45.8263Z" fill="white"/>
</g>
<path d="M24.7355 66.4264C25.0584 58.0774 20.1138 50.3271 13.716 50.3271C5.37572 50.3271 2.96387 60.8067 2.73465 66.8677C2.71061 67.5034 2.82555 68.1367 3.11418 68.7036C5.41968 73.2314 10.8946 76.3271 13.716 76.3271C16.6302 76.3271 23.3187 71.6092 24.6157 67.2145C24.6911 66.9588 24.7252 66.6928 24.7355 66.4264Z" fill="url(#paint2_linear_402_4957)"/>
<path d="M24.7355 66.4264C25.0584 58.0774 20.1138 50.3271 13.716 50.3271C5.37572 50.3271 2.96387 60.8067 2.73465 66.8677C2.71061 67.5034 2.82555 68.1367 3.11418 68.7036C5.41968 73.2314 10.8946 76.3271 13.716 76.3271C16.6302 76.3271 23.3187 71.6092 24.6157 67.2145C24.6911 66.9588 24.7252 66.6928 24.7355 66.4264Z" fill="url(#paint3_radial_402_4957)" fillOpacity="0.2"/>
<g filter="url(#filter1_f_402_4957)">
<path d="M13.7505 75.8271L12.7505 73.3271L15.7505 67.3271" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
</g>
<g filter="url(#filter2_f_402_4957)">
<path d="M13.4198 72.582L10.8881 71.6652L8.40488 65.4335" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
</g>
<g filter="url(#filter3_f_402_4957)">
<circle cx="19.2505" cy="63.8271" r="2.5" transform="rotate(-180 19.2505 63.8271)" fill="white"/>
</g>
<defs>
<filter id="filter0_f_402_4957" x="0.810547" y="15.2153" width="21.3228" height="58.9644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_402_4957"/>
</filter>
<filter id="filter1_f_402_4957" x="9.47656" y="64.0771" width="9.52393" height="15" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_402_4957"/>
</filter>
<filter id="filter2_f_402_4957" x="5.15479" y="62.1833" width="11.5151" height="13.6487" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_402_4957"/>
</filter>
<filter id="filter3_f_402_4957" x="12.7505" y="57.3271" width="13" height="13" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_402_4957"/>
</filter>
<linearGradient id="paint0_linear_402_4957" x1="13.6322" y1="68.0615" x2="13.6322" y2="0.444969" gradientUnits="userSpaceOnUse">
<stop stopColor="#B1833C"/>
<stop offset="1" stopColor="#8F6729"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4957" x1="20.3009" y1="65.8271" x2="20.3009" y2="0.827148" gradientUnits="userSpaceOnUse">
<stop stopColor="#7D633C"/>
<stop offset="1" stopColor="#564428"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4957" x1="13.7333" y1="76.3271" x2="13.7333" y2="50.3271" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4957" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.75049 64.3271) rotate(-60.6422) scale(9.17878 7.77883)">
<stop stopOpacity="0"/>
<stop offset="1" stopColor="#5E5E5E"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};