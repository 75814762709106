import React, { SVGProps } from "react";


export const PRINT_TOOTH_38 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 20 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M2 14.6482C4.05671 46.7946 1.34314 51.8518 2.5445 53.0499C4.5919 57.2915 10.8405 42.6817 11.0894 30.8996C13.027 33.6777 8.43132 53.783 10.794 52.1074C18.7683 46.4519 19.3131 20.9346 18.9007 12.8" stroke="black" strokeWidth="0.7"/>
<path id="Vector 135" d="M10 48.4544C7.68111 51.8002 6.19714 54.174 7.46449 47.8" stroke="black" strokeWidth="0.35"/>
<path id="Vector 24" d="M2.49672 3.65763C4.82845 -0.544349 7.74286 2.25675 8.90874 4.59118C10.3661 4.59118 12.953 0.800003 14.4103 0.800003C15.8677 0.800003 20.9442 3.30516 18.1994 13.8728C16.487 20.4656 12.459 22.8 9.49178 22.8C0.632456 21.0352 -0.513973 9.08319 2.49672 3.65763Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}

