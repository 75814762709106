import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import Input from '../../../../../components/bootstrap/forms/Input';
import { useDebounce } from '../../../../../hooks/useDebounce';
import Icon from '../../../../../components/icon/Icon';
import { deepCopyAll } from '../../../../../utils/functions';
import dayjs from 'dayjs';

interface IPaymentProps {
	setPayment: (payment: any) => void;
	payment: any;
	status: any;
	print?: boolean;
}

const Payment: FC<IPaymentProps> = memo(({ payment, setPayment, status, print }) => {
	const removeNullStatusPayments = (data: any, print: boolean | undefined) => {
		if (print) {
			if (!Array.isArray(data)) {
				return data;
			}

			return data.filter((payment: any) => payment.status !== null);
		}
		return data;
	};
	const [localPayment, setLocalPayment] = useState<any[]>(
		payment.payments || [
			{
				payment_type: 'личные средства',
				payment_amount: '',
				status: null,
				date: dayjs().format('YYYY-MM-DD'),
			},
		],
	);

	useEffect(() => {
		setLocalPayment(
			payment.payments || [
				{
					payment_type: 'личные средства',
					payment_amount: '',
					status: null,
					date: dayjs().format('YYYY-MM-DD'),
				},
			],
		);
	}, [payment.payments]);

	const paymentDebounce = useDebounce(localPayment);

	useEffect(() => {
		setPayment((prev: any) => ({ ...prev, payments: paymentDebounce }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentDebounce]);

	const addItem = useCallback(() => {
		setLocalPayment((prev) => [
			...prev,
			{
				payment_type: 'личные средства',
				payment_amount: '',
				status: null,
				date: dayjs().format('YYYY-MM-DD'),
			},
		]);
	}, []);
	const changeValues = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
			const { name, value } = e.target;
			setLocalPayment((prev) => {
				const updatedPayments = [...prev];
				updatedPayments[index] = {
					...updatedPayments[index],
					[name]: name === 'payment_amount' ? + value : value,
				};
				return updatedPayments;
			});
		},
		[],
	);

	const deleteItem = useCallback((index: number) => {
		setLocalPayment((prev) => {
			const updatedPayments = deepCopyAll(prev);
			if (
				updatedPayments.filter((item: any) => item.status === null).length <= 1 &&
				updatedPayments[index].status === null
			) {
				return prev;
			}
			return updatedPayments.filter((_: any, i: any) => i !== index);
		});
	}, []);


	return (
		<div className={print ? 'col-12' : 'col-10'}>
			{removeNullStatusPayments(localPayment, print)?.length !== 0 ? (
				removeNullStatusPayments(localPayment, print)?.map(
					(payment: any, index: number) => (
						<div
							style={{ height: 50 }}
							key={`${index}-${payment?.uuid}`}
							className={`d-flex w-100 ${print ? '' : 'gap-4'} align-items-center`}>
							<div className='col-2'>
								<Input
									style={{
										textAlign: 'center',
										...(print && {
											background: 'none',
											boxShadow: 'none',
											border: 'none',
										}),
									}}
									disabled={true}
									type='date'
									value={payment.date}
									name='date'
									required
								/>
							</div>
							<div className={print ? 'col-3' : 'col-2'}>
								{print ? (
									<Input
										style={{
											...(print && {
												background: 'none',
												boxShadow: 'none',
												border: 'none',
											}),
										}}
										disabled={payment.status || status}
										type='text'
										name='payment_amount'
										value={payment.payment_type}
										onChange={(e: any) => {}}
										required
									/>
								) : (
									<select
										disabled={payment.status || status}
										name='payment_type'
										value={payment.payment_type}
										onChange={(e: any) => changeValues(e, index)}
										className='form-select'>
										<option value='страховка'>Страховка</option>
										<option value='личные средства'>Личные средства</option>
									</select>
								)}
							</div>

							<div className='col-2'>
								<Input
									style={{
										...(print && {
											background: 'none',
											boxShadow: 'none',
											border: 'none',
										}),
									}}
									disabled={payment.status || status}
									type='text'
									name='payment_amount'
									placeholder={print ? '' : 'сумма'}
									value={payment.payment_amount}
									onChange={(e: any) => {
										const value = e.target.value;
										if (/^\d*\.?\d*$/.test(value)) {
											changeValues(e, index);
										}
									}}
									required
								/>
							</div>

							{payment.status !== 'оплаченный' && (
								<div className='d-flex m-1  border-0'>
									<Button
										style={{
											borderRadius: 0,
											borderTopLeftRadius: 10,
											borderBottomLeftRadius: 10,
										}}
										isDisable={payment.status === 'оплаченный' || status}
										onClick={addItem}
										className='col-6 h-50 delete-add-dental add-dental'>
										<Icon icon={'AddCircle'} color={'info'} size={'lg'} />
									</Button>
									<Button
										style={{
											borderRadius: 0,
											borderTopRightRadius: 10,
											borderBottomRightRadius: 10,
										}}
										isDisable={payment.status === 'оплаченный' || status}
										onClick={() => deleteItem(index)}
										className='col-6 h-50 delete-add-dental delete-dental'>
										<Icon icon={'Delete'} color={'danger'} size={'lg'} />
									</Button>
								</div>
							)}
						</div>
					),
				)
			) : (
				<span style={{ marginLeft: 1 }} className='fs-4'>
					Нет оплат
				</span>
			)}
		</div>
	);
});

export default Payment;
