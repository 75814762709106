import React, { FC } from 'react';
import SubHeader from '../../../../layout/SubHeader/SubHeader';
import Search from './Search/Search';
// import NewPatient from './NewPatient/NewPatient';

interface IHeaderProps {
	search: any;
	setSearch(...args: unknown[]): unknown;
	sort: any;
	setSort(...args: unknown[]): unknown;
}

const Header: FC<IHeaderProps> = ({ search, setSearch, sort, setSort,  }) => {
	return (
		<SubHeader>
			<Search search={search} setSearch={setSearch} sort={sort} setSort={setSort} />
			{/* <NewPatient setEditModalStatus={setEditModalStatus} /> */}
		</SubHeader>
	);
};

export default Header;
