import React, { FC } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../../components/bootstrap/Modal';
import Icon from '../../../../../components/icon/Icon';
import Button from '../../../../../components/bootstrap/Button';

interface IStatusSubmitModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	changeStatus(...args: unknown[]): unknown;
    uuid?: string;
	patientUuid?: string;
}


const StatusSubmitModal: FC<IStatusSubmitModalProps> = ({ isOpen, setIsOpen, changeStatus, uuid, patientUuid}) => {


	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
        if(!!uuid) {
            changeStatus('Завершен', patientUuid, uuid);
            
        }else {
            changeStatus('Завершен', patientUuid);

        }
        setIsOpen(false)
	};

	return (
		<>
			<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-5 fw-bold'>
						<Icon icon='Payment' className='me-2 fs-2' color='primary' />
						Подтверждение оплаты
					</span>
				</ModalHeader>
				<form onSubmit={handleSubmit}>
					<ModalBody className='px-4'>
						<>
                        <p  className='fs-4 '>
                            Вы уверены что хотите подтвердить оплату ?
                        </p>
                        </>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex flex-row col-12 gap-3 px-3'>
							<Button
								color='danger'
								className='col-6'
								onClick={() => {
									setIsOpen(false);
								}}>
								Нет
							</Button>
							<Button
								color={'success'}
								type='submit'
								className='col-6'>
								Да
							</Button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default StatusSubmitModal;
