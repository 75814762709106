import React, { FC, memo } from 'react';
import Button from '../../../../components/bootstrap/Button';
import { CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import { CardActions } from '../../../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import dayjs from 'dayjs';

interface IHeaderProps {}

const Header: FC<IHeaderProps> = memo(({}) => {
	return (
		<>
			<CardHeader>
				<CardLabel icon='Summarize' iconColor='success'>
					<CardTitle tag='div' className='h5'>
						Summary
					</CardTitle>
				</CardLabel>
				<CardActions>
					<Dropdown>
						<DropdownToggle>
							<Button color='info' icon='Compare' isLight>
								Compared to <strong>{Number(dayjs().format('YYYY')) - 1}</strong>.
							</Button>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='sm'>
							<DropdownItem>
								<span>{Number(dayjs().format('YYYY')) - 2}</span>
							</DropdownItem>
							<DropdownItem>
								<span>{Number(dayjs().format('YYYY')) - 3}</span>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</CardActions>
			</CardHeader>
		</>
	);
});

export default Header;
