import { authHost } from '../axios';
import { IMkb10 } from '../interface/mkb10.interface';

export class Mkb10DiseaseService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query?: any): Promise<IMkb10[] | null> {
		const { data } = await authHost.get(`mkb10_disease?${query}`);		
		return data;
	}

	async findOne(uuid: string) {
		const { data } = await authHost.get(`mkb10_disease/${uuid}`);
		return data;
	}

	static async create(data: IMkb10) {
		const mkb10_disease = await authHost.post('mkb10_disease', data)
		return mkb10_disease;
	}
}
