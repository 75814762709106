import React, { SVGProps } from "react";

export const PRINT_TOOTH_23 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100"  viewBox="0 0 19 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M2 37.0776C3.80584 9.62018 15.4752 -4.66289 16.1663 3.47297C16.8573 11.6088 17.2194 32.252 16.8573 39.2" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M8.13369 33.2C0.0373697 34.8135 -0.325818 44.7443 2.88697 49.404C4.61593 51.9115 6.87644 52.5721 13.7745 52.0166C15.1063 52.0166 19.7806 49.7108 17.2722 40.0489C15.7073 34.0213 10.8454 33.2 8.13369 33.2Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}


