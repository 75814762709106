import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalHeader } from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import DocumentsModalTable from './DocumentsModalTable';
import AddDocumentsModal from './AddDocumentsModal';

interface IDocumentsModalProps {
	id: string;
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	userUuid: string | undefined;
	files: any
} 
const DocumentsModal: FC<IDocumentsModalProps> = ({
	id,
	isOpen,
	userUuid,
	setIsOpen,
	files
}) => {

	const [addDocument, setAddDocument]= useState<boolean>(false)

	return (
		<>
			<Modal
				isCentered
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				isStaticBackdrop
				size='lg'
				titleId={id.toString()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<div></div>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='d-flex flex-row justify-content-between align-items-center mb-4'>
						<span className='fs-3 fw-bold'>
							{' '}
							<Icon icon='Receipt' className='me-2 fs-2' color='primary' />
							Документы
						</span>
						<Button
							onClick={() => {
								setAddDocument(true);

							}}
							color='primary'
							className='text-nowrap'
							icon='Add'>
							Добавить документ{' '}
						</Button>
					</div>
					<DocumentsModalTable files={files} />
				</ModalBody>
			</Modal>


			<AddDocumentsModal 
				setIsOpen={setAddDocument}
				isOpen={addDocument}
				userUuid={userUuid}
			/>
		</>
	);
};
DocumentsModal.propTypes = {
	id: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default DocumentsModal;
