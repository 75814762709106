import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import Button from '../../../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import EVENT_STATUS from '../../../../../common/data/enumEventStatus';
import useDarkMode from '../../../../../hooks/useDarkMode';
import StatusSubmitModal from './StatusSubmitModal';
import { medicalCardPages } from '../../../../../menu';

interface ICommonUpcomingEventsProps {
	data?: any;
	parentUuid: any;
	setUpdateVisitModal(...args: unknown[]): unknown;
	setPatientId(...args: unknown[]): unknown;
	setMedicalCaseUuid(...args: unknown[]): unknown;
	setServiceUuid(...args: unknown[]): unknown;
	setClinicUuid(...args: unknown[]): unknown;
	setStageUuid(...args: unknown[]): unknown;
	setVisitUpdate(...args: unknown[]): unknown;
	handleOpenCancel(...args: unknown[]): unknown;
	setVisitUuid(...args: unknown[]): unknown;
	changeStatus(...args: unknown[]): unknown;
	setActModal(...args: unknown[]): unknown;
	setActVisit(...args: unknown[]): unknown;
	setCreateActModal(...args: unknown[]): unknown;
	setCanvasData?(...args: unknown[]): unknown;
	setEventAdding?(...args: unknown[]): unknown;
	handeleSetParent?(...args: unknown[]): unknown;
	setParentModal?(...args: unknown[]): unknown;
}

const VisitDropdownActions: FC<ICommonUpcomingEventsProps> = ({
	data,
	setUpdateVisitModal,
	setPatientId,
	setMedicalCaseUuid,
	setServiceUuid,
	setClinicUuid,
	setStageUuid,
	setVisitUpdate,
	handleOpenCancel,
	setVisitUuid,
	setCanvasData,
	changeStatus,
	setActModal,
	setCreateActModal,
	setEventAdding,
	setActVisit,
	handeleSetParent,
	setParentModal,
	parentUuid,
}) => {
	const [dropdownClose, setDropdownClose] = useState<boolean>(false);
	const [statusModal, setStatusModal] = useState<boolean>(false);
	const [getToStage, setGetToStage] = useState<boolean>(true);
	const { darkModeStatus } = useDarkMode();

	const handleStatusChange = useCallback(
		(status: string) => {
			changeStatus({
				uuid: data?.uuid,
				patientUuid: data?.patientUuid,
				status: status,
			});
			setDropdownClose(false);
		},
		[changeStatus, data?.patientUuid, data?.uuid],
	);

	const handleEdit = useCallback(() => {
		setUpdateVisitModal(true);
		if (setPatientId) setPatientId(data?.patient?.uuid);
		if (setMedicalCaseUuid) setMedicalCaseUuid(data?.medicalCase?.uuid);
		const services_uuids = data?.services.map((item: any) => item.event.uuid);
		if (setServiceUuid)
			setServiceUuid((prev: any) => {
				if (!prev) prev = [];

				if (services_uuids) {
					return [...prev, services_uuids];
				}
				return prev;
			});
		if (setClinicUuid) setClinicUuid(data?.clinic?.uuid);
		if (setStageUuid) setStageUuid(data?.medicalCase?.stage[0]?.uuid);
		if (setVisitUpdate) setVisitUpdate(data);
		if (setDropdownClose) setDropdownClose(false);
	}, [
		setClinicUuid,
		setMedicalCaseUuid,
		setPatientId,
		setServiceUuid,
		setStageUuid,
		setUpdateVisitModal,
		setVisitUpdate,
		data,
	]);

	const handleCanvas = useCallback(() => {
		if (setCanvasData && setEventAdding) {
			setCanvasData(data);
			setEventAdding(true);
			setDropdownClose(false);
		}
	}, [setCanvasData, setEventAdding, data]);

	const handleCancel = useCallback(() => {
		handleOpenCancel();
		if (data?.uuid) {
			setVisitUuid(data?.uuid);
		}
		setDropdownClose(false);
	}, [handleOpenCancel, setVisitUuid, data?.uuid]);

	const visitStagePath = useCallback(
		(statusChange?: boolean) => {
			if (data.uuid && data.stageUuid) {
				if (
					(data?.status === EVENT_STATUS.IN_EXPECTATION.name ||
						data?.status === EVENT_STATUS.TIME_EXISTS.name) &&
					getToStage &&
					statusChange
				) {
					handleStatusChange('В процессе');
				}
				window.location.href = `../${medicalCardPages.medicalCardDetails.path}/${
					data.medicalCaseUuid ? data.medicalCaseUuid : null
				}?visitUuid=${data.uuid}&stageUuid=${data.stageUuid}`;
			} else {
				window.location.href = `../${medicalCardPages.medicalCardDetails.path}/${
					data.medicalCaseUuid ? data.medicalCaseUuid : null
				}?visitUuid=${null}&stageUuid=${null}`;
			}
			setGetToStage(true);
		},
		[
			data.medicalCaseUuid,
			data.stageUuid,
			data?.status,
			data.uuid,
			getToStage,
			handleStatusChange,
		],
	);
	return (
		<>
			{data?.status === EVENT_STATUS.PENDING.name && (
				<Button
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					onClick={() => handleStatusChange('В ожидании')}
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Подтвердить
				</Button>
			)}

			{data?.status === EVENT_STATUS.IN_EXPECTATION.name && (
				<Button
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					onClick={() => {
						visitStagePath(true);
					}}
					isDisable={
						window.location.href ===
						`../${medicalCardPages.medicalCardDetails.path}/${
							data.medicalCaseUuid ? data.medicalCaseUuid : null
						}?visitUuid=${data.uuid}&stageUuid=${data.stageUuid}`
					}
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Начать прием
				</Button>
			)}

			{data?.status === EVENT_STATUS.TIME_EXISTS.name && (
				<Button
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					onClick={() => {
						visitStagePath(true);
					}}
					isDisable={
						window.location.href ===
						`../${medicalCardPages.medicalCardDetails.path}/${
							data.medicalCaseUuid ? data.medicalCaseUuid : null
						}?visitUuid=${data.uuid}&stageUuid=${data.stageUuid}`
					}
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Начать прием
				</Button>
			)}
			{data?.status === EVENT_STATUS.APPROVED.name && (
				<Button
					color='info'
					isLight
					className='d-flex align-items-center justify-content-center text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}
					onClick={handleCanvas}>
					Подробнее
				</Button>
			)}
			{data?.status === EVENT_STATUS.CANCELED.name && (
				<Button
					isOutline={!darkModeStatus}
					color='dark'
					isLight
					className='d-flex align-items-center justify-content-center text-nowrap me-2 fs-6'
					onClick={handleCanvas}
					icon='Info'
					style={{ maxWidth: '9.2rem', width: '100%' }}>
					Подробнее
				</Button>
			)}
			{data?.status === EVENT_STATUS.VISIT_ENDED.name && (
				<Button
					onClick={() => {
						if (data) {
							setActModal(true);
							setActVisit(data);
						}
					}}
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Создать смету
				</Button>
			)}
			{data?.status === EVENT_STATUS.IN_PROGRESS.name && (
				<Button
					onClick={() => {
						handleStatusChange('Визит завершен');
					}}
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Завершить
				</Button>
			)}
			{data?.status === EVENT_STATUS.CALCULATION.name && (
				<Button
					onClick={() => {
						setActModal(true);
						setActVisit(data);
					}}
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Создать акт
				</Button>
			)}
			{data?.status === EVENT_STATUS.DEBT.name && (
				<Button
					onClick={() => {
						setCreateActModal(true);
						setActVisit(data);
					}}
					color='info'
					isLight
					className='text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Оплатить акт
				</Button>
			)}
			{/* {data?.status === EVENT_STATUS.NOT_PAID.name && (
				<Button
					onClick={() => {
						setStatusModal(true);
					}}
					color='danger'
					isLight
					className='text-nowrap me-2 fs-6'
					style={{ maxWidth: '9.2rem', width: '100%', fontWeight: 'bold' }}>
					Оплатить
				</Button>
			)} */}
			{data?.phone && !setParentModal && !handeleSetParent && !parentUuid && (
				<Button
					color='info'
					isLight
					className='text-nowrap me-2'
					onClick={() => {
						if (data?.uuid && setEventAdding) {
							setPatientId(data);
							setEventAdding((prev: boolean) => !prev);
						}
					}}
					style={{
						fontWeight: 'bold',
					}}>
					Запланировать визит
				</Button>
			)}
			{data?.phone && setParentModal && handeleSetParent && (
				<Button
					color={'info'}
					isLight
					className='text-nowrap me-2'
					style={{
						fontWeight: 'bold',
					}}
					isDisable={parentUuid === data.uuid}
					onClick={() => {
						handeleSetParent(data);
					}}>
					{`Привязать`}
				</Button>
			)}
			{data?.status !== EVENT_STATUS.CANCELED.name &&
				data?.status !== 'Не оплачен' &&
				!setParentModal &&
				!handeleSetParent && (
					<Dropdown isOpen={dropdownClose} setIsOpen={setDropdownClose}>
						<DropdownToggle hasIcon={false}>
							<Button
								isOutline={!darkModeStatus}
								color='dark'
								isLight={darkModeStatus}
								className={classNames(
									'text-nowrap',

									{
										'border-light': !darkModeStatus,
									},
									'fw-normal',
									'fs-6',
								)}>
								•••
							</Button>
						</DropdownToggle>
						<DropdownMenu className='fw-bold'>
							{data?.phone && (
								<>
									<DropdownItem>
										<Button
											tag='a'
											to={`../${medicalCardPages.customerID.path}/${
												data.uuid ? data.uuid : null
											}`}>
											Перейти в ЭМК
										</Button>
									</DropdownItem>
								</>
							)}
							{data?.status === EVENT_STATUS.APPROVED.name && (
								<DropdownItem>
									<Button
										onClick={() => {
											setCreateActModal(true);
											setActVisit(data);
											setDropdownClose(false);
										}}>
										Просмотреть акт
									</Button>
								</DropdownItem>
							)}
							{data?.status === EVENT_STATUS.PENDING.name && (
								<>
									<DropdownItem>
										<Button onClick={handleEdit}>Редактировать</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCancel}>Отменить визит</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											isDisable={
												window.location.href ===
												`../${medicalCardPages.medicalCardDetails.path}/${
													data.medicalCaseUuid
														? data.medicalCaseUuid
														: null
												}?visitUuid=${data.uuid}&stageUuid=${
													data.stageUuid
												}`
											}
											onClick={visitStagePath}>
											Перейти в план лечения
										</Button>
									</DropdownItem>
								</>
							)}
							{data?.status === EVENT_STATUS.IN_EXPECTATION.name && (
								<>
									<DropdownItem>
										<Button onClick={handleCancel}>Отменить визит</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											isDisable={
												window.location.href ===
												`../${medicalCardPages.medicalCardDetails.path}/${
													data.medicalCaseUuid
														? data.medicalCaseUuid
														: null
												}?visitUuid=${data.uuid}&stageUuid=${
													data.stageUuid
												}`
											}
											onClick={() => {
												setGetToStage(false);
												visitStagePath();
											}}>
											Перейти в план лечения
										</Button>
									</DropdownItem>
								</>
							)}
							{data?.status === EVENT_STATUS.TIME_EXISTS.name && (
								<>
									<DropdownItem>
										<Button onClick={handleCancel}>Отменить визит</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											isDisable={
												window.location.href ===
												`../${medicalCardPages.medicalCardDetails.path}/${
													data.medicalCaseUuid
														? data.medicalCaseUuid
														: null
												}?visitUuid=${data.uuid}&stageUuid=${
													data.stageUuid
												}`
											}
											onClick={() => {
												setGetToStage(false);
												visitStagePath();
											}}>
											Перейти в план лечения
										</Button>
									</DropdownItem>
								</>
							)}
							{data?.status === EVENT_STATUS.IN_PROGRESS.name && (
								<>
									<DropdownItem>
										<Button onClick={handleCancel}>Отменить визит</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											isDisable={
												window.location.href ===
												`../${medicalCardPages.medicalCardDetails.path}/${
													data.medicalCaseUuid
														? data.medicalCaseUuid
														: null
												}?visitUuid=${data.uuid}&stageUuid=${
													data.stageUuid
												}`
											}
											onClick={visitStagePath}>
											Перейти в план лечения
										</Button>
									</DropdownItem>
								</>
							)}
							{data?.status === EVENT_STATUS.DEBT.name && (
								<>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											isDisable={
												window.location.href ===
												`../${medicalCardPages.medicalCardDetails.path}/${
													data.medicalCaseUuid
														? data.medicalCaseUuid
														: null
												}?visitUuid=${data.uuid}&stageUuid=${
													data.stageUuid
												}`
											}
											onClick={visitStagePath}>
											Перейти в план лечения
										</Button>
									</DropdownItem>
								</>
							)}
							{data?.status === EVENT_STATUS.CALCULATION.name && (
								<>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											isDisable={
												window.location.href ===
												`../${medicalCardPages.medicalCardDetails.path}/${
													data.medicalCaseUuid
														? data.medicalCaseUuid
														: null
												}?visitUuid=${data.uuid}&stageUuid=${
													data.stageUuid
												}`
											}
											onClick={visitStagePath}>
											Перейти в план лечения
										</Button>
									</DropdownItem>
								</>
							)}
							{data?.status === EVENT_STATUS.VISIT_ENDED.name && (
								<>
									<DropdownItem>
										<Button onClick={handleCanvas}>Подробнее</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											isDisable={
												window.location.href ===
												`../${medicalCardPages.medicalCardDetails.path}/${
													data.medicalCaseUuid
														? data.medicalCaseUuid
														: null
												}?visitUuid=${data.uuid}&stageUuid=${
													data.stageUuid
												}`
											}
											onClick={visitStagePath}>
											Перейти в план лечения
										</Button>
									</DropdownItem>
								</>
							)}
						</DropdownMenu>
					</Dropdown>
				)}

			<StatusSubmitModal
				setIsOpen={setStatusModal}
				isOpen={statusModal}
				changeStatus={handleStatusChange}
			/>
		</>
	);
};

export default VisitDropdownActions;
