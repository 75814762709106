import React from 'react';
import { Select } from 'antd';
import useDarkMode from '../../../hooks/useDarkMode';

interface SelectProps {
	changeValues: (key: string, value: string | number, index?: number) => void;
	changeSearchValue: (key: string, value: string | number) => void;
	options: any[] | undefined;
	value: string | undefined;
	name: string;
	placeholder: string;
	disabled?: any;
	index?: number;
	search: string;
}
export default function GroupMaterialSelect({
	changeValues,
	changeSearchValue,
	value,
	name,
	placeholder,
	disabled = false,
	options,
	index,
	search,
}: SelectProps) {
	const { themeStatus } = useDarkMode();

	return (
		<Select
			disabled={disabled}
			notFoundContent={'Нет данных'}
			className={themeStatus === 'dark' ? 'dark-select' : ''}
			rootClassName={themeStatus === 'dark' ? 'dark-select' : ''}
			dropdownStyle={{ position: 'absolute', zIndex: '10000000' }}
			showSearch
			allowClear
			style={{ width: '100%' }}
			placeholder={placeholder}
			optionFilterProp='children'
			value={value ? value : null}
			onChange={(e) => {
				changeValues(`${name}`, e, index);
			}}
			onSelect={(value, option) => {
				changeValues(`${name}`, value, index);
			}}
			onSearch={(e) => changeSearchValue(search, e)}
			filterOption={false}
			options={options}
		/>
	);
}
