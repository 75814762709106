import React, { FC, memo, useCallback, useState } from 'react';
import SearchFilterCompnent from './FilterComponentItems/SearchFilterCompnent';
import CodeFilterComponent from './FilterComponentItems/CodeFilterComponent';
import ServiceFilterComponent from './FilterComponentItems/ServiceFilterComponent';
import UserFilterComponent from './FilterComponentItems/UserFilterComponent';
import StatusFilterComponent from './FilterComponentItems/StatusFilterComponent';

interface IVisitFilterComponentProps {
	values: any;
	isLargeDesktop: boolean;
	isSmallDesktop: boolean;
	isUserDoctor: boolean;
	isTablet: boolean;
	searchIconClicked: boolean;
	setValues(...args: unknown[]): unknown;
	setSearchIconClicked(...args: unknown[]): unknown;
	canvasColor: any;
	dynamicFont: any;
}

const VisitFilterComponent: FC<IVisitFilterComponentProps> = memo(
	({
		values,
		canvasColor,
		isUserDoctor,
		dynamicFont,
		isSmallDesktop,
		isLargeDesktop,
		setValues,
		isTablet,
		searchIconClicked,
		setSearchIconClicked,
	}) => {
		const [popperIsOpenCode, setPopperIsOpenCode] = useState(false);
		const [popperIsOpenService, setPopperIsOpenService] = useState(false);
		const [popperIsOpenUser, setPopperIsOpenUser] = useState(false);
		const [popperIsOpenStatus, setPopperIsOpenStatus] = useState(false);

		const changeValues = useCallback(
			(key: string, value: string | number) => {
				if (key === 'services') {
					setValues((prev: any) => ({
						...prev,
						[key]: [...(values[key] || []), value], // Ensure values[key] is an array
					}));
				}
				if (key === 'users')
					setValues((prev: any) => ({
						...prev,
						userName: undefined,
						[key]: [...values[key], value],
					}));
				setValues((prev: any) => ({ ...prev, [key]: [...values[key], value] }));
			},
			[setValues, values],
		);

		const handleCheckboxChange = useCallback(
			(e: any) => {
				const name = e.target.name;
				if (e.target.type === 'checkbox') {
					const isChecked = e.target.checked;
					if (isChecked) {
						if (!values[name].includes(e.target.defaultValue)) {
							changeValues(name, e.target.defaultValue);
						}
					} else {
						setValues((prev: any) => ({
							...prev,
							[name]: prev[name].filter(
								(value: string) => value !== e.target.defaultValue,
							),
						}));
					}
				}

				if (e.target.type === 'button') {
					setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
				}

				if (e.target.type === 'search') {
					if (name === 'visitId') {
						if (/^\d*$/.test(e.target.value)) {
							setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
						}
					}
					if (name === 'visitSearch') {
							setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
						
					}
					if (name === 'serviceName') {
						setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
					}
					if (name === 'userName') {
						setValues((prev: any) => ({ ...prev, [name]: e.target.value }));
					}
				}
			},
			[changeValues, setValues, values],
		);

		return (
			<div className='d-flex flex-row align-items-center w-full grow'>
				<div className='d-flex align-items-center flex-xl-row flex-lg-column me-3'>
					<SearchFilterCompnent
						searchIconClicked={searchIconClicked}
						values={values}
						setSearchIconClicked={setSearchIconClicked}
						handleCheckboxChange={handleCheckboxChange}
						isLargeDesktop={isLargeDesktop}
						isSmallDesktop={isSmallDesktop}
						isTablet={isTablet}
					/>
				</div>
				<div className='d-flex flex-row align-items-center'>
					{isLargeDesktop && <span className='text-nowrap me-2'> Фильтровать по:</span>}
					<CodeFilterComponent
						handleCheckboxChange={handleCheckboxChange}
						setPopperIsOpenCode={setPopperIsOpenCode}
						popperIsOpenCode={popperIsOpenCode}
						values={values}
					/>
					<ServiceFilterComponent
						handleCheckboxChange={handleCheckboxChange}
						setPopperIsOpenService={setPopperIsOpenService}
						popperIsOpenService={popperIsOpenService}
						values={values}
						dynamicFont={dynamicFont}
						canvasColor={canvasColor}
					/>
					<UserFilterComponent
						handleCheckboxChange={handleCheckboxChange}
						setPopperIsOpenUser={setPopperIsOpenUser}
						popperIsOpenUser={popperIsOpenUser}
						values={values}
						dynamicFont={dynamicFont}
						isUserDoctor={isUserDoctor}
						canvasColor={canvasColor}
					/>
					<StatusFilterComponent
						handleCheckboxChange={handleCheckboxChange}
						setPopperIsOpenStatus={setPopperIsOpenStatus}
						popperIsOpenStatus={popperIsOpenStatus}
						values={values}
						dynamicFont={dynamicFont}
					/>
				</div>
			</div>
		);
	},
);
export default VisitFilterComponent;
