import React, { SVGProps } from "react";

export const PRINT_TOOTH_37 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 19 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M1.59998 15.5129C3.65669 45.3072 0.943111 49.9943 2.14447 51.1048C4.19187 55.036 10.4404 41.4952 10.6894 30.5752C12.627 33.1501 8.03129 51.7843 10.394 50.2312C18.3682 44.9896 18.9131 21.3394 18.5007 13.8" stroke="black" strokeWidth="0.7"/>
<path id="Vector 136" d="M6.74415 45.8C6.24477 49.9741 7.00627 49.622 9.59998 45.8" stroke="black" strokeWidth="0.35"/>
<path id="Vector 24" d="M2.0967 4.65763C4.42843 0.455651 7.34284 3.25675 8.50871 5.59118C9.96605 5.59118 12.5529 1.8 14.0103 1.8C15.4676 1.8 20.5442 4.30516 17.7994 14.8728C16.087 21.4656 12.059 23.8 9.09175 23.8C0.232432 22.0352 -0.913997 10.0832 2.0967 4.65763Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}