import React, { FC, memo } from 'react';

interface IDeviderProps {}

const Devider: FC<IDeviderProps> = memo(() => {
	return (
		<div
			className='col-1 d-flex justify-content-center aligin-items-center'
			style={{ width: '4%' }}>
			<div
				style={{
					width: 3,
					height: '100%',
					backgroundColor: 'rgba(123, 123, 123, 0.4)',
				}}></div>
		</div>
	);
});

export default Devider;
