import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Avatar from '../../../../components/Avatar';
import User1Img from '../../../../assets/img/wanna/wanna2.png';
import { demoPagesMenu } from '../../../../menu';
import { UserService } from '../../../../custom/api/service/user.service';
import { IPatientCreate } from '../../../../custom/api/interface/patient.interface';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ClinicService } from '../../../../custom/api/service/clinic.service';
import * as qs from 'qs';
import { notification, Upload } from 'antd';
import type { NotificationArgsProps } from 'antd';
import CommonMyWallet from '../../../../pages/_common/CommonMyWallet';
import Icon from '../../../../components/icon/Icon';
import showNotification from '../../../../components/extras/showNotification';
import editPasswordValidate from '../../../../pages/presentation/demo-pages/helper/editPasswordValidate';
import { CategoryService } from '../../../../custom/api/service/category.service';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import { rolesTranslate } from '../../../../utils/dataUtil';

interface IFinance {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const Finance:FC<IFinance> = ({search, setSearch}) => {
	return <h1>Финансы</h1>;
};

export default Finance;
