import React, { useState, useEffect, memo } from 'react';

interface CurrentDate {
	formattedDate: string;
	weekday: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const CurrentDate: React.FC = memo(() => {
	const [currentDate, setCurrentDate] = useState<CurrentDate>({
		formattedDate: '',
		weekday: '',
	});

	useEffect(() => {
		const intervalId = setInterval(() => {
			const date = new Date();
			const formattedDate = `${addZero(date.getDate())}.${addZero(
				date.getMonth() + 1,
			)}.${date.getFullYear()}`;
			const weekday = date.toLocaleDateString('ru-RU', {
				weekday: 'long',
			});
			setCurrentDate({ formattedDate, weekday });
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	const addZero = (value: number) => {
		return value < 10 ? `0${value}` : value;
	};

	return (
		<div className='d-flex align-items-center flex-row gap-2'>
			<span className=' fs-2'>{currentDate.formattedDate},</span>{' '}
			<span>{currentDate.weekday}</span>
		</div>
	);
});

export default CurrentDate;
