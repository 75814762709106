import React, { FC } from 'react';
import Card from '../../../../components/bootstrap/Card';
import Table from './Table';
import Header from './Header';

interface IUserStatisticComponentProps {}

const UserStatisticComponent: FC<IUserStatisticComponentProps> = () => {
	return (
		<div className='col-12'>
			<Card>
				<Header />
				<Table />
			</Card>
		</div>
	);
};

export default UserStatisticComponent;
