import React, { FC, useContext } from 'react';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { InputWithTitle } from '../../medicalCard/medicalCaseComponents/UI/Inputs';
import { TheethDropdown } from './TheethDropdown';
import DentalContext from '../../../../contexts/dentalContext';

type ItemType =
	| 'bridge'
	| 'referral'
	| 'implant'
	| 'event'
	| 'deletion'
	| 'milk_theeth'
	| 'empty'
	| 'cosmetology'
	| 'lor';

interface IServices {
	index: number;
	isDisabled: boolean;
	item: any;
	changeChosenServicesValue?: any;
	notification?: any;
	handleDeleteService?: any;
	chosenServices: any;
	setChosenServices: any;
	isChecked?: boolean;
	handleCheckboxChange?: any;
	addFavorite?: any;
	localChosenServices: any;
	setLocalChosenServices: any;
	type: ItemType;
	themeStatus?: any;
}

export const ServicesListButton: FC<IServices> = ({
	index,
	isDisabled,
	changeChosenServicesValue,
	chosenServices,
	setChosenServices,
	addFavorite,
	notification,
	item,
	type,
	localChosenServices,
	setLocalChosenServices,
	handleDeleteService,
	isChecked,
	handleCheckboxChange,
	themeStatus,
}) => {
	const { selectMany, selectedItesm } = useContext(DentalContext);

	return (
		<div
			key={`${index}-${item.serviceId}-${item?.uuid}`}
			className='shadow col-12 rounded-1 light-border-services-tab p-2'>
			<div className='form-check m-0 gap-2' key={index}>
				<div className='d-flex'>
					{handleCheckboxChange && (
						<Button
							// isDisable={
							// 	(item.service_type !== 'bridge' &&
							// 		selectMany &&
							// 		selectedItesm.length > 0) ||
							// 	(item.service_type === 'bridge' &&
							// 		!selectMany &&
							// 		selectedItesm.length === 1)
							// }
							onClick={(e: any) => {
								addFavorite({ serviceUuid: item?.uuid });
							}}
							className='d-flex justify-content-center align-items-center form-check-input favorite'>
							{' '}
							<Icon
								className='favoriteIcon'
								color={item?.user ? 'warning' : 'primary'}
								size={'3x'}
								icon='Star'></Icon>
						</Button>
					)}
					{handleCheckboxChange ? (
						<Button
							isDisable={
								(item.service_type !== 'bridge' &&
									selectMany &&
									selectedItesm?.length > 0) ||
								(item.service_type === 'bridge' &&
									!selectMany &&
									selectedItesm?.length === 1)
							}
							onClick={(e: any) => handleCheckboxChange(e, 'services', item)}
							className='d-flex justify-content-center align-items-center form-check-input'>
							{' '}
							<Icon
								className='deletIcone'
								color={'primary'}
								size={'3x'}
								icon='AddCircle'></Icon>
						</Button>
					) : (
						<Button
							isDisable={isDisabled}
							onClick={() => handleDeleteService(type, item, index)}
							className='d-flex justify-content-center align-items-center form-check-input'>
							<Icon
								className='deletIcone'
								color={'red'}
								size={'2x'}
								icon='Delete'></Icon>
						</Button>
					)}
				</div>

				<label
					aria-disabled={isDisabled}
					className='form-check-label col-12 d-flex gap-3 p-0'>
					<div style={{ width: '11%' }} className='col-2'>
						<span
							className='p-2 d-flex justify-content-center col-12 fw-normal text-white'
							style={{
								borderRadius: '0.5rem',
								backgroundColor: '#2c3178',
								width: '100%',
							}}>
							{item?.serviceId}
						</span>
					</div>
					<p className='d-flex m-0' style={{ width: '70%' }}>
					<span className='overflow-hidden w-100'>
							<Tooltips title={item?.title_all?.rus} placement='top' flip={['top']}>
								<span className='text-truncate'>{item?.title_all?.rus}</span>
							</Tooltips>
						</span>
					</p>
				</label>
			</div>
			<div className='d-flex gap-3 align-items-center'>
				{!handleCheckboxChange && type !== 'lor' && type !== 'cosmetology' && (
					<>
						<TheethDropdown
							index={index}
							setData={setChosenServices}
							all_data={chosenServices}
							data={item}
							type={type}
						/>
						<InputWithTitle
							title='*Кол.'
							item={item}
							name={'count'}
							index={index}
							setValue={changeChosenServicesValue}
							placeholder='1'
							width='4rem'
							value={item.count}
						/>
						<InputWithTitle
							item={item}
							index={index}
							name='price'
							title='*Сумма за 1х'
							placeholder='60,000'
							setValue={changeChosenServicesValue}
							value={item.price}
						/>
						<InputWithTitle
							isDisabled
							name='amount'
							item={item}
							index={index}
							title='*Общая сумма'
							placeholder='60,000'
							setValue={changeChosenServicesValue}
							value={item.amount}
						/>
					</>
				)}
			</div>
		</div>
	);
};
