import React, { FC } from 'react';
import classNames from 'classnames';
import { HeaderLeft } from '../../../../../layout/Header/Header';
import useDarkMode from '../../../../../hooks/useDarkMode';
import { CardLabel } from '../../../../../components/bootstrap/Card';
import Clock from './HeaderLeftComponentItems/Clock';
import CurrentDate from './HeaderLeftComponentItems/Date';
import Weather from './HeaderLeftComponentItems/Weather';

interface IHeaderLeftComponentProps {}

const HeaderLeftComponent: FC<IHeaderLeftComponentProps> = () => {
	const { darkModeStatus } = useDarkMode();
	return (
		<>
			<HeaderLeft>
				<div className='fs-2 d-flex align-items-center gap-3 '>
					<div
						className={classNames('fs-2', 'fw-bold', {
							'text-dark': !darkModeStatus,
						})}>
						<CardLabel
							className='d-flex flex-row align-items-center gap-2'
							icon='AccessTime'
							iconColor='primary'>
							<Clock />
						</CardLabel>
					</div>
					<CurrentDate />
					<Weather />
				</div>
			</HeaderLeft>
		</>
	);
};

export default HeaderLeftComponent;
