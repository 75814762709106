import React, { FC, memo, useCallback, useState } from 'react';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import PaginationButtons from '../../../components/PaginationButtons';
import TableComponent from '../../components/tables/TableComponent';
import MaterialsFilterComponent from './components/UI/FilterComponent/MaterialsFilterComponent';
import ResetComponent from './components/UI/ResetComponent/ResetComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import AddGroup from './AddGroup';
import MaterialInfo from './MaterialInfo';
import GroupsDropdownActions from './GroupsDropdownActions';

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	groups: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
}

const GroupsTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		groups,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
	}) => {
		const [openModal, setOpenModal] = useState<boolean>(false);
		const [data, setData] = useState<any>({});
		const [uuid, setUuid] = useState<any>({});
		const [groupModal, setGroupModal] = useState<boolean>(false);

		const tableHeaders = [
			{ header: 'Название', key: 'title_all' },
			{ header: 'еще', key: 'group_actions' },

			// { header: 'Срок годности', key: 'expiration_date' },
		];

		const reset = useCallback(() => {
			setValues({
				specialization: [],
				serviceName: [],
				name: undefined,
				order: 'DESC',
			});
			setFilter({
				clinicUuid: '',
				material_type: '',
				categoryUuid: '',
				store_type: '',
			});
		}, [setFilter, setValues]);

		const renderCell = (key: string, item: any, index: number) => {
			switch (key) {
				case 'title_all':
					return <span>{item.title_all.en}</span>;
				case 'group_actions':
					return (
						<div className='d-flex'>
							<GroupsDropdownActions
								data={item}
								setOpenModal={setOpenModal}
								setData={setData}
								setUuid={setUuid}
								setOpenSubmitModal={function (...args: unknown[]): unknown {
									throw new Error('Function not implemented.');
								}}
							/>
						</div>
					);
				default:
					return null; // Handle unexpected cases
			}
		};

		return (
			<>
				<Card stretch={isFluid}>
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						{/* <SubHeaderRightComponent /> turn back, when api is ready */}
					</div>
					<div className='table-row-header'>
						<div className='d-flex flex-row align-items-center w-full grow'></div>
						<div>
							<ResetComponent setGroupModal={setGroupModal} reset={reset} />
						</div>
					</div>
					{groups?.data?.length > 0 && (
						<CardBody className='table-responsive col-6' isScrollable={isFluid}>
							{/* <TableComponent
								data={groups?.data?.length ? groups?.data : []}
								headers={tableHeaders}
								visitDropDownProps={undefined}
								groupDropDownProps={groupDropDownProps}
							/> */}
							{groups?.data.length > 0 && (
								<table className='table table-modern table-hover'>
									<thead>
										<tr className={`col-1`}>
											{tableHeaders.map((name, index) => (
												<th
													className={`col-1`}
													key={`${index}-${name.header}`}>
													{name.header}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{groups?.data?.map((i: any, index: number) => (
											<tr key={`${index}-${i.middleName}`}>
												{tableHeaders.map((name, idx) => (
													<td
														key={idx}
														style={{
															maxWidth:
																name.key === 'string'
																	? '25rem'
																	: 'unset',
														}}
														className={`col-2`}>
														{renderCell(name?.key, i, index)}
													</td>
												))}
											</tr>
										))}
									</tbody>
								</table>
							)}
						</CardBody>
					)}
					<PaginationButtons
						data={groups || []}
						label='customers'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
						totalPage={Math.ceil(groups?.meta?.count / perPage) || 1}
					/>
				</Card>
				<AddGroup isOpen={groupModal} setIsOpen={setGroupModal} />
				<MaterialInfo isOpen={openModal} setIsOpen={setOpenModal} materials={data} />
			</>
		);
	},
);

export default GroupsTable;
