import React, { FC, memo } from 'react';
import { Carousel } from 'antd';
import UserCard from './UserCard';

interface UserCarouselProps {
	users: any[];
	usersList: any[];
	themeStatus: string;
	selectUser(...args: unknown[]): unknown;
}

const UserCarousel: FC<UserCarouselProps> = memo(
	({ users, usersList, themeStatus, selectUser }) => (
		<Carousel slidesToShow={18} dots={false} arrows infinite={false}>
			{users.map((user, index) => (
				<div  key={`${index}-${user.uudi}`}>
					<UserCard
						key={`UserCard-${index}`}
						user={user}
						themeStatus={themeStatus}
						isSelected={usersList.includes(user.uuid)}
						onSelect={() => selectUser(user)}
					/>
				</div>
			))}
		</Carousel>
	),
);

export default UserCarousel;
