import React, { SVGProps } from "react";

export const THEETH_37 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
         <svg xmlns="http://www.w3.org/2000/svg" width="2.7rem" height="5.3rem" viewBox="0 0 35 69" fill="none" {...props}>
<g filter="url(#filter0_i_2354_11114)">
<path d="M3.835 68.6684C-4.48418 71.749 6.85386 52.971 3.83496 20.0611C9.38749 15.321 22.0365 7.95349 28.2122 16.4044C33.0876 26.5618 31.8686 67.8558 18.8679 67.4495C15.9724 67.359 27.4158 38.3444 18.8679 38.3444C14.2158 40.4995 9.66327 66.5101 3.835 68.6684Z" fill="url(#paint0_linear_2354_11114)"/>
</g>
<g filter="url(#filter1_i_2354_11114)">
<path d="M7.73828 17.241L3.83209 19.7258C3.70615 19.8059 3.63401 19.9424 3.64376 20.0914C4.104 27.1177 3.43162 43.2047 2.2605 51.4995C1.49098 56.9499 1.25814 59.4862 1.06703 61.3995C0.762409 64.4491 0.562161 65.9784 0.623293 66.878C0.662682 67.4575 1.30878 67.4157 1.54198 66.8836C7.76208 52.6926 8.73522 37.0269 8.11261 17.4409C8.10661 17.2522 7.89755 17.1397 7.73828 17.241Z" fill="url(#paint1_linear_2354_11114)"/>
</g>
<path d="M5.95137 22.9C1.75014 22.9 0.949512 12.9927 1.07357 7.95381C1.07503 7.89474 1.08322 7.83632 1.09864 7.77928C2.02642 4.3457 6.12648 4.56383 8.19977 5.4302C8.55601 5.57907 8.97899 5.52319 9.25837 5.2567C13.6948 1.02489 18.7837 3.01512 21.2707 4.89619C21.5828 5.13221 22.0156 5.14597 22.3295 4.91249C26.5255 1.792 31.5647 -0.0654652 34.3106 5.961C34.3613 6.07225 34.3856 6.20167 34.3807 6.32382C33.9442 17.1278 30.7219 19.1405 29.1062 19.9484C27.806 20.5985 21.2511 20.2193 18.1362 19.9484C16.2401 20.761 10.1768 22.9 5.95137 22.9Z" fill="url(#paint2_linear_2354_11114)"/>
<path d="M5.95137 22.9C1.75014 22.9 0.949512 12.9927 1.07357 7.95381C1.07503 7.89474 1.08322 7.83632 1.09864 7.77928C2.02642 4.3457 6.12648 4.56383 8.19977 5.4302C8.55601 5.57907 8.97899 5.52319 9.25837 5.2567C13.6948 1.02489 18.7837 3.01512 21.2707 4.89619C21.5828 5.13221 22.0156 5.14597 22.3295 4.91249C26.5255 1.792 31.5647 -0.0654652 34.3106 5.961C34.3613 6.07225 34.3856 6.20167 34.3807 6.32382C33.9442 17.1278 30.7219 19.1405 29.1062 19.9484C27.806 20.5985 21.2511 20.2193 18.1362 19.9484C16.2401 20.761 10.1768 22.9 5.95137 22.9Z" fill="url(#paint3_radial_2354_11114)" fillOpacity="0.3"/>
<g filter="url(#filter2_f_2354_11114)">
<path d="M9.00702 5.84766L7.38184 11.5358" stroke="black" strokeWidth="0.812592"/>
</g>
<g filter="url(#filter3_f_2354_11114)">
<path d="M22.009 5.03516L20.3838 10.7233" stroke="black" strokeWidth="0.812592"/>
</g>
<g filter="url(#filter4_f_2354_11114)">
<path d="M12.744 11.9419C13.5566 16.0049 9.70616 14.786 7.46225 14.786C5.21833 14.786 5.83692 12.9244 5.83692 11.1293C5.83692 9.33419 12.1253 7.47266 14.3692 7.47266C16.6131 7.47266 15.1818 10.723 12.744 11.9419Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_i_2354_11114" x="-2.52674" y="9.2926" width="33.3754" height="59.704" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-3.25037" dy="-3.25037"/>
<feGaussianBlur stdDeviation="2.03148"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_2354_11114"/>
</filter>
<filter id="filter1_i_2354_11114" x="0.612305" y="17.2031" width="9.28729" height="51.7199" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1.62518" dy="1.62518"/>
<feGaussianBlur stdDeviation="1.62518"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_2354_11114"/>
</filter>
<filter id="filter2_f_2354_11114" x="2.11566" y="0.860288" width="12.1574" height="15.6627" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.43778" result="effect1_foregroundBlur_2354_11114"/>
</filter>
<filter id="filter3_f_2354_11114" x="15.1176" y="0.0477881" width="12.1574" height="15.6627" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.43778" result="effect1_foregroundBlur_2354_11114"/>
</filter>
<filter id="filter4_f_2354_11114" x="1.69583" y="3.4097" width="17.8564" height="15.6108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.03148" result="effect1_foregroundBlur_2354_11114"/>
</filter>
<linearGradient id="paint0_linear_2354_11114" x1="15.2056" y1="12.543" x2="15.2056" y2="61.8502" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint1_linear_2354_11114" x1="8.49456" y1="22.559" x2="-3.34397" y2="62.1886" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_2354_11114" x1="18.3354" y1="2" x2="18.3354" y2="22.3862" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_2354_11114" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.0145 9.76765) rotate(63.1296) scale(3.03018 4.74747)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>
        </>
	);
};