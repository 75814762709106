import React, { FC, memo } from 'react';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../../components/bootstrap/Dropdown';
import Button from '../../../../../../components/bootstrap/Button';
import FormGroup from '../../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../../components/bootstrap/forms/Input';
import useDarkMode from '../../../../../../hooks/useDarkMode';

interface ICodeFilterComponentProps {
	handleCheckboxChange(...args: unknown[]): unknown;
	setPopperIsOpenCode(...args: unknown[]): unknown;
	popperIsOpenCode: any;
	values: any;
}

const CodeFilterComponent: FC<ICodeFilterComponentProps> = memo(
	({ values, setPopperIsOpenCode, popperIsOpenCode, handleCheckboxChange }) => {
		const { themeStatus, darkModeStatus } = useDarkMode();

		return (
			<Dropdown isOpen={popperIsOpenCode} setIsOpen={setPopperIsOpenCode}>
				<DropdownToggle>
					<Button
						style={{
							boxSizing: 'border-box',
							border: values.visitId
								? '2px solid #1266f1'
								: `2px solid ${darkModeStatus ? '#212529' : '#f8f9fa'}`,
						}}
						color={themeStatus}
						className='text-nowrap me-2'>
						Визиту
					</Button>
				</DropdownToggle>
				<DropdownMenu style={{ width: '400px', padding: '15px 0 15px 0' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexWrap: 'wrap',
						}}>
						<FormGroup
							style={{ marginTop: '15px' }}
							id='surname'
							label='Введите id или номер визита'
							className='col-10'>
							<Input
								name='visitId'
								type={'search'}
								onChange={(e: any) => handleCheckboxChange(e)}
								value={values.visitId}
							/>
						</FormGroup>
					</div>
				</DropdownMenu>
			</Dropdown>
		);
	},
);
export default CodeFilterComponent;
