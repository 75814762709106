import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authPage, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import Sidebar from '../custom/components/sidebar/Sidebar';

const asides: RouteProps[] = [
	{ path: authPage.login.path, element: null },
	{ path: authPage.createPassword.path, element: null },
	{ path: authPage.setPassword.path, element: null },
	{ path: authPage.resetPassword.path, element: null },
	{ path: authPage.checkOtp.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: '*', element: <Sidebar /> },
];

export default asides;
