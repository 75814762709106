import React, { FC, useEffect, useRef, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import { IPatientCreate } from '@textnpayme/custom/api/interface/patient.interface';
import NewPatientSecondStep from './NewPatientSecondStep';
import { ValidatedInput } from '../../../custom/components/inputs/ValidatedInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { useMutation, useQueryClient } from 'react-query';
import { PatientService } from '../../../custom/api/service/patient.service';

interface INewPatientVisitModalProps {
	isOpen: any;
	setIsOpen(...args: unknown[]): unknown;
}
const nameMask = /^[A-Za-zА-Яа-яЁёԱ-Ֆա-ֆ-]{3,}$/;

const NewPatientVisitModal: FC<INewPatientVisitModalProps> = ({ isOpen, setIsOpen }) => {
	const [countryData, setCountryData] = useState<{ dialCode?: string; format?: string } | null>(
		null,
	);
	const [hasInteracted, setHasInteracted] = useState(false);

	const [patientNext, setPatientNext] = useState(true);
	const [textAreaShow, setTextAreaShow] = useState(false);
	const [know, setKnow] = useState('');

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setPatientNext(false);
	};
	const [isFormValid, setIsFormValid] = useState(false);
	const [values, setValues] = useState<IPatientCreate>({
		phone: '+374',
		name: '',
		surname: '',
		middleName: '',
		know: 'Фейсбук',
	});

	const handleChange = (value: any, data: any) => {
		changeValues('phone', value);
		setCountryData(data);
	};

	const knowOptions = [
		'Фейсбук',
		'Реклама (оффлайн)',
		'Знакомый/друг/семья',
		'Действующий пациент',
	];

	const changeValues = (key: string, value: string | number) => {
		if (key === 'know') {
			if (value === 'Свой вариант') {
				setKnow(value);
				setTextAreaShow(true);
				return setValues((prev) => ({ ...prev, [key]: '' })); // Оставляем пустым, чтобы пользователь мог ввести свой текст
			}
			if (knowOptions.includes(`${value}`)) {
				setKnow(value.toString());
				setTextAreaShow(false);
				return setValues((prev) => ({ ...prev, [key]: value })); // Записываем выбранное значение
			}
			if (!knowOptions.includes(`${value}`)) {
				return setValues((prev) => ({ ...prev, [key]: value })); // Записываем выбранное значение
			}
			return;
		}
		setValues((prev) => ({ ...prev, [key]: value }));
	};
	const validatePhoneNumber = () => {
		if (countryData && values?.phone) {
			const { format } = countryData;
			const numericFormat = format?.replace(/[()\-+ ]/g, '');
			const expectedLength = numericFormat?.length;
			const numericInput = values?.phone.replace(/[()\-+ ]/g, '');
			return numericInput.length === expectedLength;
		}
		return false;
	};
	useEffect(() => {
		if (values.name && values.surname && values.middleName) {
			const isValid =
				validatePhoneNumber() &&
				values.name.match(nameMask) &&
				values.surname.match(nameMask) &&
				values.middleName.match(nameMask);
			setIsFormValid(Boolean(isValid));
		}
	}, [values]);

	const handleFocus = () => {
		setHasInteracted(true);
	};
	const queryClient = useQueryClient();

	const { mutate } = useMutation((inputDto: IPatientCreate) => PatientService.create(values), {
		onSuccess: (response, formData) => {
			queryClient.invalidateQueries('patients');
			queryClient.invalidateQueries('patient');
			setValues({
				phone: '+374',
				name: '',
				surname: '',
				middleName: '',
				know: '',
			});

			setIsOpen(false);
			setPatientNext(true);
		},
		onError: (error) => {},
	});
	const handleSave = async (e: any) => {
		e.preventDefault();
		await mutate(values);
	};

	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			size={patientNext ? undefined : 'lg'}
			// type={patientNext ? 'md' : 'xl'}
			isCentered>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='title' style={{ fontSize: '1.75rem' }}>
					{patientNext
						? `1. Новый пациент`
						: ` 2. Выберите откуда пациент узнал о клинике`}
				</ModalTitle>
			</ModalHeader>
			<form onSubmit={handleSave}>
				<ModalBody className='px-4 d-flex flex-column gap-4'>
					<ValidatedInput
						id='surname'
						label='Фамилия'
						changeValues={changeValues}
						value={values.surname}
						validationMask={nameMask}
					/>
					<ValidatedInput
						id='name'
						label='Имя'
						changeValues={changeValues}
						value={values.name}
						validationMask={nameMask}
					/>
					<ValidatedInput
						id='middleName'
						label='Отчество'
						changeValues={changeValues}
						value={values.middleName}
						validationMask={nameMask}
					/>
					<div>
						<PhoneInput
							onFocus={handleFocus}
							// containerClass='form-control'
							inputClass='form-control'
							inputStyle={{
								// borderRadius: 30,
								backgroundColor: '#f8f9fa',
								fontSize: '1rem',
								fontWeight: 600,
								lineHeight: 1.5,

								borderRadius: '1rem',
								border: '1px solid #f8f9fa',
								width: '100%',
								boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
								transition:
									'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out',
							}}
							containerStyle={{
								borderRadius: 30,
								width: '100%',
								display: 'flex',
								alignItems: 'center',
							}}
							isValid={validatePhoneNumber}
							buttonStyle={{
								borderRadius: 30,
								border: 'none',
								backgroundColor: 'inherit',
								height: 20,
								marginTop: 7,
								marginLeft: 4,
								// backgroundColor: 'inherit',
								// pointerEvents: 'none',
							}}
							country={'am'}
							value={values?.phone?.replace(/[()\-+ ]/g, '')}
							onChange={(value, data) => handleChange(`+${value}`, data)}
							enableSearch={false}
							countryCodeEditable={true}
							showDropdown={false}
						/>
						{hasInteracted && !validatePhoneNumber() && (
							<div style={{ color: 'red' }}>Номер телефона не может быть пустым.</div>
						)}
					</div>
					<FormGroup
						id='healing_type'
						label='Откуда пациент узнал о клинкике'
						className='m-0 d-flex flex-column col-12'>
						<div style={{ position: 'relative' }}>
							<select
								name='know'
								required
								onChange={(e) => {
									changeValues('know', e.target.value);
								}}
								value={know}
								className='form-select'>
								<option value={'Фейсбук'}>Фейсбук</option>
								<option value={'Реклама (оффлайн)'}>Товар</option>
								<option value={'Знакомый/друг/семья'}>Знакомый/друг/семья</option>
								<option value={'Действующий пациент'}>Действующий пациент</option>
								<option value={'Свой вариант'}>Свой вариант</option>
							</select>
						</div>
						<div style={{ marginTop: 10 }}>
							{textAreaShow ? (
								<Textarea
									value={values?.know}
									onChange={(e: any) => changeValues('know', e.target.value)}
								/>
							) : null}
						</div>
					</FormGroup>
				</ModalBody>

				<ModalFooter className='px-4 pb-4 '>
					<Button
						color='info'
						type='submit'
						className='col-12'
						isDisable={!isFormValid}
						isOutline={!isFormValid}>
						Cоздать нового пациента
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default NewPatientVisitModal;
