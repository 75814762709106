import React, { FC } from 'react';
import { SubHeaderRight } from '../../../../../../layout/SubHeader/SubHeader';
import Button from '../../../../../../components/bootstrap/Button';
import Icon from '../../../../../../components/icon/Icon';

interface ISubHeaderRightComponentProps {
	setAddMaterial(...args: unknown[]): unknown;
	setStatusModal(...args: unknown[]): unknown;
	handleSubmit(...args: unknown[]): unknown;
	handleCancele(...args: unknown[]): unknown;
	addMaterial: boolean;
	uuid: string;
}

const SubHeaderRightComponent: FC<ISubHeaderRightComponentProps> = ({
	setAddMaterial,
	setStatusModal,
	handleSubmit,
	addMaterial,
	handleCancele,
	uuid,
}) => {
	return (
		<SubHeaderRight className='mw-auto'>
			<div className='d-flex gap-2'>
				{!addMaterial && (
					<>
						<Button color='primary' onClick={setAddMaterial}>
							Добавить
						</Button>
					</>
				)}

				{addMaterial && uuid && (
					<>
						<Button color='primary' onClick={(e: any) => handleSubmit(e, uuid)}>
							Сохранить
						</Button>

						<Button color='danger' isLight onClick={handleCancele}>
							Отменить
						</Button>

						<Button
							onClick={(e: any) => {
								setStatusModal(true);
							}}>
							<Icon
								color='danger'
								style={{ width: 23, height: 23 }}
								icon={'Delete'}
							/>
						</Button>
					</>
				)}

				{addMaterial && !uuid && (
					<>
						<Button color='danger' isLight onClick={handleCancele}>
							Отменить
						</Button>
						<Button color='primary' onClick={(e: any) => handleSubmit(e)}>
							Создать
						</Button>
					</>
				)}
			</div>
		</SubHeaderRight>
	);
};

export default SubHeaderRightComponent;
