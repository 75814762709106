import React, { SVGProps } from "react";

export const PRINT_TOOTH_34 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 18 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M16.4 15.9382C14.5798 43.5993 12.569 46.9413 8.73792 48.7231C4.90687 50.5049 1.05914 20.7996 1.42409 13.8" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M10.2663 19.8C18.3627 18.1865 18.7258 8.25569 15.5131 3.59604C13.7841 1.08846 11.5236 0.427944 4.62551 0.983362C3.29368 0.983362 -1.38062 3.28923 1.12784 12.9511C2.69276 18.9787 7.55463 19.8 10.2663 19.8Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}


