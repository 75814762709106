import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';
import { SubHeaderLeft, SubHeaderRight } from '../../../../../layout/SubHeader/SubHeader';
import { CardBody } from '../../../../../components/bootstrap/Card';
import Button from '../../../../../components/bootstrap/Button';
import { StageService } from '../../../../../custom/api/service/stage.service';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import StageModal from '../../../medicalCard/StageModal';
import { StagaeServiceService } from '../../../../../custom/api/service/stage-visit-service.service';
import ServiceChooseComponent from '../../../../../custom/pages/services/ServiceChooseComponent/ServiceChooseComponent';

interface IStageProps {
	setCreateVisit(...args: unknown[]): unknown;
	setServiceId(...args: unknown[]): unknown;
	medicalCaseId: string;
	setMedicalCaseUuid(...args: unknown[]): unknown;
	setStageUuid(...args: unknown[]): unknown;
	setCloseModal(...args: unknown[]): unknown;
	patientUuid: any;
	setIsBackHidden(...args: unknown[]): unknown;
	setValues(...args: unknown[]): unknown;
	values: any;
	setStageId(...args: unknown[]): unknown;
	setIsNoServices(...args: unknown[]): unknown;
	setChosenServicesCount(...args: unknown[]): unknown;
	stageId: any;
	setAndLogServices: any;
	formRef?: any;
}

const Stage: FC<IStageProps> = ({
	setCreateVisit,
	setServiceId,
	setStageUuid,
	setCloseModal,
	setMedicalCaseUuid,
	medicalCaseId,
	patientUuid,
	setIsBackHidden,
	setValues,
	values,
	setStageId,
	stageId,
	setAndLogServices,
	formRef,
	setIsNoServices,
	setChosenServicesCount,
}) => {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [modalStage, setModalStage] = useState<boolean>(false);
	useEffect(() => {
		setActiveIndex(null);
		setAndLogServices()
		setStageId('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [medicalCaseId]);

	const getServices = (uuid: string | undefined, active: number | null) => {
		setActiveIndex(active);
		setStageId(uuid);

		if (active === activeIndex) {
			setActiveIndex(null);
			setStageId('');
		}
	};

	const { data: stages }: { data: IStage[] | any; isLoading: boolean } = useQuery(
		['stages'],
		() =>
			StageService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
					},
					filterMeta: {
						medicalCaseUuid: medicalCaseId,
						status: 'Предстоящий',
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!medicalCaseId,
		},
	);

	const { data: stag_services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['stag_services', stageId],
		() =>
			StagaeServiceService.findAll(
				qs.stringify({
					includeMeta: [
						{
							association: 'stage',
							where: {
								uuid: stageId,
							},
						},
					],
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!stageId,
		},
	);
	
	
	return (
		<div className='d-flex flex-column justify-content-between m-0'>
			<div className='d-flex flex-column justify-content-between p-0 gap-2'>
				<SubHeaderLeft className='col-4 text-muted m-0'>
					<p className='fs-4 fw-semibold d-flex flex-column'>
						План лечения
						<span className='fs-6 text-nowrap'>
							Выберите этап и услуги. Можно выбрать только один этап.
						</span>
					</p>
				</SubHeaderLeft>
				<SubHeaderRight className='col-12 d-flex flex-nowrap stage_buttons'>
					{stages &&
						stages?.data.map((i: IStage, index: number) => (
							<Button
								key={`${i.uuid}-${index}-Button`}
								onClick={() => {
									getServices(i.uuid, index);
									setIsBackHidden(true);
								}}
								color='info'
								isLight
								isActive={index === activeIndex}
								className='text-nowrap me-2 fw-bold d-flex'
								style={{ padding: '0.6rem 1.8rem', maxWidth: '15rem' }}>
								<span className='text-truncate'>{i.title}</span>
							</Button>
						))}
					<Button
						onClick={() => setModalStage(true)}
						color='info'
						isLight
						className='text-nowrap me-2'
						style={{
							fontWeight: 'bold',
							padding: '0.6rem 1.8rem',
							maxWidth: '15rem',
						}}>
						Создать этап
					</Button>
				</SubHeaderRight>
			</div>
			<div className='d-flex flex-column gap-4'>
				<CardBody className='d-flex flex-wrap column-gap-4 p-0 px-0'>
					{stageId && stag_services?.data && (
						<ServiceChooseComponent
							stage={stageId}
							isStage
							setChosenValues={setAndLogServices}
							formRef={formRef}
							setIsNoServices={setIsNoServices}
							setChosenServicesCount={setChosenServicesCount}
						/>
					)}
				</CardBody>
			</div>

			<StageModal setIsOpen={setModalStage} isOpen={modalStage} medicalCase={medicalCaseId} />
		</div>
	);
};

export default Stage;
