import React, { FC, memo, useCallback } from 'react';
import FormGroup from '../../../../../../../../components/bootstrap/forms/FormGroup';
import { IClinic } from '../../../../../../../../custom/api/interface/clinic.interface';
import * as qs from 'qs';
import { CategoryService } from '../../../../../../../../custom/api/service/category.service';
import { useQuery } from 'react-query';
import { ClinicService } from '../../../../../../../../custom/api/service/clinic.service';
import Button from '../../../../../../../../components/bootstrap/Button';
interface IVisitFilterComponentProps {
	filter: any;
	setFilter(...args: unknown[]): unknown;
	reset(...args: unknown[]): unknown;
}

const ServicesFilterComponent: FC<IVisitFilterComponentProps> = memo(
	({ filter, setFilter, reset }) => {
		const changeFilterValues = useCallback(
			(e: any) => {
				const { value, name } = e.target;
				setFilter((prev: any) => ({
					...prev,
					[name]: value,
				}));
			},
			[setFilter],
		);

		const { data: clinics } = useQuery(['clinics'], () =>
			ClinicService.findAll(qs.stringify({})),
		);

		const { data: categoriesWithSubCategories } = useQuery<any | null, Error>(
			['categories'],
			() =>
				CategoryService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
						},
						includeMeta: [
							{
								association: 'subCategory',
							},
						],
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const categories = categoriesWithSubCategories?.data
			.filter((item: any) => !item.categoryUuid)
			.map((category: any) => ({
				...category,
				categoriesUuid: category.uuid,
			}));

		const subCategories = categoriesWithSubCategories?.data.filter(
			(item: any) => item.categoryUuid,
		);

		return (
			<div className='d-flex flex-row align-items-center w-full grow'>
				<div className='d-flex flex-row align-items-center gap-2'>
					<FormGroup id='clinic' label='ФИЛИАЛ:' className='m-0 d-flex flex-column col-3'>
						<div style={{ position: 'relative' }}>
							<select
								required
								onChange={(e) => {
									changeFilterValues(e);
								}}
								name='clinic'
								value={filter?.clinic}
								className='form-select'>
								<option value={''}>Все</option>
								{clinics?.data?.map((item: IClinic, index: number) => (
									<option value={item.uuid}>{item.name}</option>
								))}
							</select>
						</div>
					</FormGroup>
					<FormGroup
						id='healing_type'
						label='ТИП ЛЕЧЕНИЯ:'
						className='m-0 d-flex flex-column col-3'>
						<div style={{ position: 'relative' }}>
							<select
								name='treatment_type'
								required
								onChange={(e) => {
									changeFilterValues(e);
								}}
								value={filter?.treatment_type}
								className='form-select'>
								<option value={''}>Все</option>{' '}
								<option value={'Амбулаторная'}>Амбулаторная</option>
								<option value={'Стационарная'}>Стационарная</option>
							</select>
						</div>
					</FormGroup>
					<FormGroup
						id='category'
						label='СПЕЦАЛИЗАЦИЯ:'
						className='m-0 d-flex flex-column'>
						<div style={{ position: 'relative' }}>
							<select
								required
								name='category'
								onChange={(e) => {
									changeFilterValues(e);
								}}
								value={filter?.category}
								className='form-select'>
								<option value={''}>Все</option>
								{categories?.map((item: any, index: number) => (
									<option value={item.uuid}>{item?.name?.rus}</option>
								))}
							</select>
						</div>
					</FormGroup>

					<FormGroup
						id='category'
						label='ПОДСПЕЦАЛИЗАЦИЯ:'
						className='m-0 d-flex flex-column'>
						<div style={{ position: 'relative' }}>
							<select
								required
								name='subCategory'
								onChange={(e) => {
									changeFilterValues(e);
								}}
								value={filter?.subCategory}
								className='form-select'>
								<option value={''}>Все</option>
								{subCategories?.map((item: any, index: number) => (
									<option value={item.uuid}>{item?.name?.rus}</option>
								))}
							</select>
						</div>
					</FormGroup>
				</div>
			</div>
		);
	},
);
export default ServicesFilterComponent;
