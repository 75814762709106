import React, { SVGProps } from "react";

export const PRINT_TOOTH_21 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 20 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 11">
<path id="Vector 70" d="M1.99602 42.2C0.895245 29.3663 4.451 7.78508 12.1775 1.46395C13.3252 0.525027 14.7847 2.23904 15.3382 4.27334L17.8 42.2" stroke="black" strokeWidth="0.7"/>
<path id="Vector 17" d="M14.9653 57.2H2.52752C1.14554 57.2 0.800049 55.7882 0.800049 55.0823C0.800049 54.3765 1.14554 33.2 10.4739 33.2C19.8023 33.2 18.7658 51.9059 18.7658 53.3176C18.7658 54.7294 16.3473 57.2 14.9653 57.2Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>

  )
}

