import React, { FC } from 'react';
import { SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Button from '../../../../components/bootstrap/Button';
import classNames from 'classnames';
import useDarkMode from '../../../../hooks/useDarkMode';

interface ISubHeaderRightCompnentProps {}

const SubHeaderRightCompnent: FC<ISubHeaderRightCompnentProps> = () => {
	const { darkModeStatus } = useDarkMode();
	return (
		<SubHeaderRight className='mw-auto'>
			<Button
				color='primary'
				className={classNames('text-nowrap', {
					'border-light': !darkModeStatus,
				})}
				onClick={() => null}>
				Выгрузить таблицу в Excel{' '}
			</Button>
		</SubHeaderRight>
	);
};

export default SubHeaderRightCompnent;
