import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Button from '../../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../../hooks/useDarkMode';
import { medicalCardPages } from '../../../../menu';
import Icon from '../../../../components/icon/Icon';

interface ICommonUpcomingEventsProps {
	data?: any;
	uuid: any;
	setUuid(...args: unknown[]): unknown;
	setAddClinicOrganisation(...args: unknown[]): unknown;
	setStatusModal(...args: unknown[]): unknown;
}

const ClinicOrganisationActions: FC<ICommonUpcomingEventsProps> = ({
	data,
	setUuid,
	setAddClinicOrganisation,
	setStatusModal,
	uuid,
}) => {
	const [dropdownClose, setDropdownClose] = useState<boolean>(false);
	const { darkModeStatus } = useDarkMode();

	return (
		<div className='d-flex gap-2'>
			<Button
				className='p-0'
				onClick={(e: any) => {
					setUuid(data.uuid);
					setAddClinicOrganisation(true);
				}}>
				<Icon color='primary' style={{ width: 23, height: 23 }} icon={'Edit'} />
			</Button>
			<Button
				className='p-0'
				onClick={(e: any) => {
					setUuid(data.uuid);
					setStatusModal(true);
				}}>
				<Icon color='danger' style={{ width: 23, height: 23 }} icon={'Delete'} />
			</Button>
			<Dropdown isOpen={dropdownClose} setIsOpen={setDropdownClose}>
				<DropdownToggle hasIcon={false}>
					<Button
						isOutline={!darkModeStatus}
						color='dark'
						isLight={darkModeStatus}
						className={classNames(
							'text-nowrap',

							{
								'border-light': !darkModeStatus,
							},
							'fw-normal',
							'fs-6',
							'p-0',
						)}>
						•••
					</Button>
				</DropdownToggle>
				<DropdownMenu className='fw-bold'>
					<DropdownItem>
						<Button
							tag='a'
							to={`../${medicalCardPages.customerID.path}/${
								data.uuid ? data.uuid : null
							}`}>
							Перейти в ЭМК
						</Button>
					</DropdownItem>

					<DropdownItem>
						<Button
							tag='a'
							to={`../${medicalCardPages.customerID.path}/${
								data.uuid ? data.uuid : null
							}`}>
							Перейти в ЭМК
						</Button>
					</DropdownItem>

					<DropdownItem>
						<Button
							tag='a'
							to={`../${medicalCardPages.customerID.path}/${
								data.uuid ? data.uuid : null
							}`}>
							Перейти в ЭМК
						</Button>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

export default ClinicOrganisationActions;
