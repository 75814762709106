import React, { SVGProps } from "react";

export const THEETH_22 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg width="2rem" height="6.15rem" viewBox="0 0 26 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M7.89206 1.25787C19.892 9.25799 23.0487 45.4843 21.3821 63.8176C20.9493 70.8176 3.39642 68.8182 3.88196 60.3174C4.68802 46.2047 4.3914 17.3174 4.3914 2.75735C5.22952 -0.148705 6.15005 0.0965118 7.89206 1.25787Z" fill="url(#paint0_linear_402_4978)"/>
        <path d="M7.25134 65.2411C7.7658 50.8255 10.8234 36.0244 5.27183 1.07864C5.25171 0.951988 5.11708 0.876686 5.02628 0.967239C2.87363 3.11407 5.20239 24.757 4.23145 55.8271C3.28415 61.0357 3.68277 64.0022 6.70042 65.5759C6.95037 65.7062 7.24129 65.5228 7.25134 65.2411Z" fill="url(#paint1_linear_402_4978)"/>
        <g filter="url(#filter0_f_402_4978)">
        <path d="M17.6449 45.8263C17.5006 35.6495 13.5424 23.2009 12.5226 23.2154C11.5028 23.2298 14.9156 35.6862 15.0599 45.863C15.2042 56.0398 14.3283 66.1941 15.3481 66.1796C16.3679 66.1652 17.7893 56.0031 17.6449 45.8263Z" fill="white"/>
        </g>
        <path d="M1.73028 66.4264C1.40744 58.0774 6.35206 50.3271 12.7498 50.3271C21.0901 50.3271 23.5019 60.8067 23.7312 66.8677C23.7552 67.5034 23.6403 68.1367 23.3516 68.7036C21.0461 73.2314 15.5712 76.3271 12.7498 76.3271C9.83565 76.3271 3.14708 71.6092 1.85015 67.2145C1.77468 66.9588 1.74058 66.6928 1.73028 66.4264Z" fill="url(#paint2_linear_402_4978)"/>
        <path d="M1.73028 66.4264C1.40744 58.0774 6.35206 50.3271 12.7498 50.3271C21.0901 50.3271 23.5019 60.8067 23.7312 66.8677C23.7552 67.5034 23.6403 68.1367 23.3516 68.7036C21.0461 73.2314 15.5712 76.3271 12.7498 76.3271C9.83565 76.3271 3.14708 71.6092 1.85015 67.2145C1.77468 66.9588 1.74058 66.6928 1.73028 66.4264Z" fill="url(#paint3_radial_402_4978)" fillOpacity="0.2"/>
        <g filter="url(#filter1_f_402_4978)">
        <path d="M12.7153 75.8271L13.7153 73.3271L10.7153 67.3271" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
        </g>
        <g filter="url(#filter2_f_402_4978)">
        <path d="M13.046 72.582L15.5777 71.6652L18.0609 65.4335" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
        </g>
        <g filter="url(#filter3_f_402_4978)">
        <circle cx="2.5" cy="2.5" r="2.5" transform="matrix(1 0 0 -1 4.71533 66.3271)" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f_402_4978" x="4.33252" y="15.2153" width="21.3228" height="58.9644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_402_4978"/>
        </filter>
        <filter id="filter1_f_402_4978" x="7.46533" y="64.0771" width="9.52393" height="15" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_402_4978"/>
        </filter>
        <filter id="filter2_f_402_4978" x="9.7959" y="62.1833" width="11.5151" height="13.6487" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_402_4978"/>
        </filter>
        <filter id="filter3_f_402_4978" x="0.715332" y="57.3271" width="13" height="13" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_402_4978"/>
        </filter>
        <linearGradient id="paint0_linear_402_4978" x1="12.8337" y1="68.0615" x2="12.8337" y2="0.444969" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B1833C"/>
        <stop offset="1" stopColor="#8F6729"/>
        </linearGradient>
        <linearGradient id="paint1_linear_402_4978" x1="6.16491" y1="65.8271" x2="6.16491" y2="0.827148" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D633C"/>
        <stop offset="1" stopColor="#564428"/>
        </linearGradient>
        <linearGradient id="paint2_linear_402_4978" x1="12.7326" y1="76.3271" x2="12.7326" y2="50.3271" gradientUnits="userSpaceOnUse">
        <stop offset="0.64" stopColor="white"/>
        <stop offset="1" stopColor="#B48B8B"/>
        </linearGradient>
        <radialGradient id="paint3_radial_402_4978" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.7153 64.3271) rotate(-119.358) scale(9.17878 7.77883)">
        <stop stopOpacity="0"/>
        <stop offset="1" stopColor="#5E5E5E"/>
        </radialGradient>
        </defs>
        </svg>


        </>
	);
};