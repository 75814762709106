import React, { SVGProps } from "react";


export const PRINT_TOOTH_13 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg  width="57" height="100"
    viewBox="0 0 33 54" 
    fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21 37.0776C19.1942 9.62018 7.52476 -4.66289 6.83372 3.47297C6.14268 11.6088 5.78062 32.252 6.14268 39.2" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
    <path d="M14.8663 33.2C22.9626 34.8135 23.3258 44.7443 20.113 49.404C18.3841 51.9115 16.1236 52.5721 9.22549 52.0166C7.89366 52.0166 3.21936 49.7108 5.72782 40.0489C7.29273 34.0213 12.1546 33.2 14.8663 33.2Z" stroke="black" strokeWidth="0.63"/>
    </svg>
  )
}



