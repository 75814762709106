import React, { SVGProps } from 'react';

const SvgTheethDirection = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 25"
			fill="none"
			width='1rem'
			height='1rem'
			{...props}
		>
			<g clipPath="url(#clip0_701_28543)">
				<path d="M12.3516 3.21868C12.1563 3.02342 11.8398 3.02342 11.6445 3.21868L8.46251 6.40066C8.26725 6.59593 8.26725 6.91251 8.46251 7.10777C8.65778 7.30303 8.97436 7.30303 9.16962 7.10777L11.998 4.27934L14.8265 7.10777C15.0217 7.30303 15.3383 7.30303 15.5336 7.10777C15.7288 6.91251 15.7288 6.59593 15.5336 6.40066L12.3516 3.21868ZM12.498 11.2163V3.57224H11.498V11.2163H12.498Z" fill="#0D6EFD"/>
				<path d="M15.9531 17.7593H8.04297C7.21454 17.7593 6.54297 17.0877 6.54297 16.2593V12.1637C6.54297 11.3353 7.21454 10.6637 8.04297 10.6637H15.9531C16.7816 10.6637 17.4531 11.3353 17.4531 12.1637V16.2593C17.4531 17.0877 16.7816 17.7593 15.9531 17.7593Z" stroke="#0D6EFD" strokeWidth="1.5"/>
			</g>
			<defs>
			<clipPath id="clip0_701_28543">
			<rect y="0.100098" width="24" height="24" rx="8" fill="white"/>
			</clipPath>
			</defs>
			</svg>

	);
};

export default SvgTheethDirection;
