import React, { FC, memo } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import Icon from '../../../../../components/icon/Icon';
import Avatar from '../../../../../components/Avatar';
import UserImage3 from '../../../../../assets/img/wanna/wanna3.png';
import useDarkMode from '../../../../../hooks/useDarkMode';

interface IAddParentButtonProps {
	setAddParent(...args: unknown[]): unknown;
	patient: any;
}

const AddParentButton: FC<IAddParentButtonProps> = memo(({ setAddParent, patient }) => {
	const { darkModeStatus } = useDarkMode();
	return (
		<>
			<div className='col-12'>
				{!!patient?.parent && (
					<div className='col-xl-12'>
						<div
							className={`d-flex align-items-center bg-l${
								darkModeStatus ? 'o25' : '10'
							}-secondary rounded-2 p-3`}>
							<div className='flex-shrink-0'>
								<Avatar
									src={
										patient?.parent?.image
											? `https://backend.amracode.am${patient?.parent?.image}`
											: UserImage3
									}
									size={32}
									color='primary'
								/>
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-4 mb-1'>
									{patient?.parent?.name} {patient?.parent?.surname}{' '}
									{patient?.parent?.middleName}
								</div>
								<div className='text-muted mt-n2 fs-sm truncate-line-1'>
									Родитель{' '}
								</div>
							</div>
							<Button onClick={() => setAddParent(true)} color='info' isLink>
								<Icon icon='ArrowForward' size='2x' />
							</Button>
						</div>
					</div>
				)}
				{!!!patient?.parent && (
					<div className='col-xl-12'>
						<div
							className={`d-flex align-items-center bg-l${
								darkModeStatus ? 'o25' : '10'
							}-info rounded-2 p-3`}>
							<div className='flex-shrink-0'>
								<Icon icon='FamilyRestroom' size='2x' color='info' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-5 mb-1'>Активные пациенты </div>
								<div className='text-muted mt-n2 fs-sm truncate-line-1'>
									Нажмите на стрелочку{' '}
								</div>
							</div>
							<Button color='info' isLink onClick={() => setAddParent(true)}>
								<Icon icon='ArrowForward' size='2x' />
							</Button>
						</div>
					</div>
				)}
			</div>
		</>
	);
});

export default AddParentButton;
