import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithEmail, IUserProps } from '../custom/data/customData/receptionistData';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	setFontSize: any;
	userData: Partial<IUserProps>;
	fontSize: string;
	formData: any;
	setFormData: any;
	manipulations: any;
	generateDescendingSequence: any;
	bridge_default: any;
	implant_default: any;
	setIsGlobalLoading: any;
	isGlobalLoading: any;
	createActModal: any;
	setCreateActModal: any;
	actVisit: any;
	setActVisit: any;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
} // Import necessary modules and types

// Define your AuthContext and related interfaces

// Define your AuthContextProvider component
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const generateDescendingSequence = (num1: string | number, num2: string | number) => {
		let result = [];
		let start = Math.max(+num1, +num2);
		let end = Math.min(+num1, +num2);
		for (let i = start; i >= end; i--) {
			result.push(i);
		}
		return result.join(',');
	};

	const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
	const [actVisit, setActVisit] = useState<IVisit | null>(null);
	const [createActModal, setCreateActModal] = useState<boolean>(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const bridge_default = {
		start: '18',
		end: '11',
		restoration_type: 'Выберите тип реставрации',
		material: 'Выберите материал',
		fixation_type: 'Выберите тип фиксации',
		count: '1',
		price: '0',
		amount: '0',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const implant_default = {
		theeth: '',
		amount: '',
		brand: '',
		implantName: '',
		size: '',

		prosthetics_restorations: {
			plug: {
				selected: false,
			},
			gum_former: {
				selected: false,
				price: '0',
			},
			temporary_teeth_with_abutment: {
				selected: false,
				price: '0',
			},
		},

		manipulation: false,
		manipulationData: [],
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const manipulations = {
		membrane: {
			name: 'Мембранная манипуляция',
			checked: false,
			data: [
				{
					title: null,
					items: [
						{
							name: 'Коллаген',
							price: '0',
							checked: false,
						},
					],
				},
				{
					title: null,
					items: [
						{
							name: 'Графт (Губка)',
							price: '0',
							checked: false,
						},
					],
				},
			],
		},
		bone: {
			name: 'Костная манипуляция',
			checked: false,
			data: [
				{
					title: 'Allo (Xeno)',
					checked: false,
					items: [
						{
							name: 'Боковая',
							price: '0',
							checked: false,
						},
						{
							name: 'Вертикальная',
							price: '0',
							checked: false,
						},
						{
							name: 'Синус',
							price: '0',
							checked: false,
						},
					],
				},
				{
					title: 'Auto',
					checked: false,
					items: [
						{
							name: 'Боковая',
							price: '0',
							checked: false,
						},
						{
							name: 'Вертикальная',
							price: '0',
							checked: false,
						},
						{
							name: 'Синус',
							price: '0',
							checked: false,
						},
					],
				},
			],
		},
		soft_tissue: {
			name: 'Мягкотканная манипуляция',
			checked: false,
			data: [
				{
					title: null,
					items: [
						{
							name: 'Трансплантат',
							price: '0',
							checked: false,
						},
					],
				},
				{
					title: null,
					items: [
						{
							name: 'Местными тканями',
							price: '0',
							checked: false,
						},
					],
				},
			],
		},
		default: {
			name: 'Выберите тип манипуляции',
		},
	};
	const [user, setUser] = useState<string>(localStorage.getItem('facit_authEmail') || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const [fontSize, setFontSize] = useState(
		JSON.parse(localStorage.getItem('fontSize') || '{}')?.name || 'Нормальный',
	);
	const [formData, setFormData] = useState({
		implant: [
			{
				theeth: '',
				amount: '',
				brand: '',
				implantName: '',
				size: '',

				prosthetics_restorations: {
					plug: {
						selected: false,
					},
					gum_former: {
						selected: false,
						price: '0',
					},
					temporary_teeth_with_abutment: {
						selected: false,
						price: '0',
					},
				},

				manipulation: false,
				manipulationData: [],
			},
		],
		bridge: [
			{
				start: '18',
				end: '11',
				restoration_type: 'Выберите тип реставрации',
				material: 'Выберите материал',
				fixation_type: 'Выберите тип фиксации',
				count: '1',
				price: '0',
				amount: '0',
			},
		],
	});

	// useEffect to store user email in localStorage
	useEffect(() => {
		localStorage.setItem('facit_authEmail', user);
	}, [user]);

	// useEffect to fetch user data when user changes
	useEffect(() => {
		if (user !== '') {
			setUserData(getUserDataWithEmail(user));
		} else {
			setUserData({});
		}
	}, [user]);

	// Function to update user state
	const updateUser = (email: string) => {
		setUser(email);
	};

	const value = useMemo(
		() => ({
			user,
			setUser: updateUser,
			userData,
			setFontSize,
			fontSize,
			generateDescendingSequence,
			formData,
			setIsGlobalLoading,
			isGlobalLoading,
			setActVisit,
			setCreateActModal,
			actVisit,
			createActModal,
			manipulations,
			bridge_default,
			setFormData,
			implant_default,
		}),
		[
			user,
			userData,
			fontSize,
			formData,
			isGlobalLoading,
			manipulations,
			bridge_default,
			implant_default,
			actVisit,
			createActModal,
		],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Define PropTypes for AuthContextProvider component

// Export AuthContextProvider component
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
