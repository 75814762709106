import React, { SVGProps } from "react";

export const PRINT_TOOTH_26 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100"  viewBox="0 0 20 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M18.5328 41.7917C16.5309 11.4264 19.7023 7.01172 18.533 5.87998C17.1266 3.05232 14.2711 9.47704 12.0261 17.1464M1.98634 43.2C1.58499 35.5161 1.11924 14.3907 8.88069 9.04866C10.0129 8.26938 9.27823 12.2684 8.70685 16.6742M8.70685 16.6742C8.11768 21.2171 7.70211 26.1925 9.686 26.8074C10.2604 23.7576 11.0902 20.3438 12.0261 17.1464M8.70685 16.6742C11.8849 11.3339 12.7277 11.0967 12.0261 17.1464" stroke="black" strokeWidth="0.7" strokeLinecap="round"/>
<path id="Vector 24" d="M17.3033 52.3424C14.9716 56.5443 12.0572 53.7433 10.8913 51.4088C9.43397 51.4088 6.84708 55.2 5.38974 55.2C3.9324 55.2 -1.1442 52.6948 1.60065 42.1272C3.31305 35.5344 7.34102 33.2 10.3083 33.2C19.1676 34.9648 20.314 46.9168 17.3033 52.3424Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}


