import React, { FC } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import { formatPrice } from '../../../../../utils/functions';
import useDarkMode from '../../../../../hooks/useDarkMode';
import { TColor } from '../../../../../type/color-type';

interface IOffCanvasComponentServiceProps {
	canvasData: any;
	canvasColor: TColor;
}

const OffCanvasComponentService: FC<IOffCanvasComponentServiceProps> = ({
	canvasData,
	canvasColor,
}) => {
	const { themeStatus } = useDarkMode();

	return (
		<Card
			className={`mb-10 ${themeStatus === 'light' ? `bg-l10-${canvasColor}` : 'bg-dark'}`}
			shadow='sm'>
			<CardHeader
				className={`${
					themeStatus === 'light' ? `bg-l25-${canvasColor}` : `bg-${canvasColor}`
				}`}>
				<CardLabel
					iconColor={themeStatus === 'light' ? `${canvasColor}` : 'light'}
					icon='MedicalServices'>
					<CardTitle
						className={`${
							themeStatus === 'light' ? `text-${canvasColor}` : 'text-white'
						}`}>
						Услуга - Материал - Цена
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<FormGroup id='date' label='Услуга - Материал - Цена' className='mb-2'>
					{canvasData?.services?.length > 0 ? (
						canvasData?.services?.map((i: any) => (
							<>
								<p
									style={{
										color: themeStatus === 'light' ? 'black' : 'white',
									}}>
									{i?.event?.title_all?.rus} -{' '}
									{formatPrice(i?.event?.amount || '0', 'AMD', 'ru-RU')}
								</p>
								<span className='text-muted'>Материалы</span>
								{i?.event?.materials?.length > 0 ? (
									i?.event?.materials?.map((item: any) => (
										<p
											style={{
												color: themeStatus === 'light' ? 'black' : 'white',
											}}>
											{item.name} -{' '}
											{formatPrice(
												item?.amount || item.price * item.count || '0',
												'AMD',
												'ru-RU',
											)}
										</p>
									))
								) : (
									<p
										style={{
											color: themeStatus === 'light' ? 'black' : 'white',
										}}>
										Нет добавленных материалов
									</p>
								)}
								<hr
									className={`hr-divder-costum text-${canvasColor} bg-${canvasColor}`}
								/>
							</>
						))
					) : (
						<p
							style={{
								color: themeStatus === 'light' ? 'black' : 'white',
							}}>
							Нет Услуг
						</p>
					)}
				</FormGroup>
			</CardBody>
		</Card>
	);
};
export default OffCanvasComponentService;
