import React, { FC } from 'react';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import HighlightedTitle from '../../../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { CardBody } from '../../../../../components/bootstrap/Card';

interface IAddSubCategory {
	uuid?: string;
	setValues(...args: unknown[]): unknown;
	values: any;
	categories: any;
}

const AddSubCategory: FC<IAddSubCategory> = ({ uuid, setValues, values, categories }) => {
	const changeValuesArray = (key: string, value: any) => {
		setValues((prev: any) => {
			const updatedCategories = [...prev.categories];
			if (updatedCategories[0]) {
				if (key === 'categoryUuid') {
					updatedCategories[0] = {
						...updatedCategories[0],
						[key]: value,
					};
				} else {
					updatedCategories[0] = {
						...updatedCategories[0],
						name: {
							...updatedCategories[0].name,
							[key]: value,
						},
					};
				}
			}
			return { ...prev, categories: updatedCategories };
		});
	};

	const changeValues = (key: string, value: any) => {
		setValues((prev: any) => ({
			...prev,
			...(key === 'categoryUuid'
				? { ...prev, [key]: value }
				: {
						name: {
							...prev.name,
							[key]: value,
						},
				  }),
		}));
	};

	return (
		<div className={'col-12'}>
			<CardBody style={{ borderRadius: 12 }}>
				<div className='p-1 ' style={{ maxWidth: '100%', overflow: 'auto' }}>
					{!uuid && (
						<div className='col-12 d-flex flex-column  gap-2'>
							<HighlightedTitle title={'*Название Подспециализации'} />

							<FormGroup label='RUS' className='col-12'>
								<Input
									required
									type='text'
									onChange={(e: any) => changeValuesArray('rus', e.target.value)}
									value={values.categories[0].name.rus}
								/>
							</FormGroup>
							<FormGroup label='ARM' className='col-12'>
								<Input
									required
									type='text'
									onChange={(e: any) => changeValuesArray('arm', e.target.value)}
									value={values.categories[0].name.arm}
								/>
							</FormGroup>
							<FormGroup label='ENG' className='col-12'>
								<Input
									required
									type='text'
									onChange={(e: any) => changeValuesArray('en', e.target.value)}
									value={values.categories[0].name.en}
								/>
							</FormGroup>

							<div className='col-3'>
								<HighlightedTitle
									title={'*Выберите принадлежность к Спецализации'}
								/>

								<div style={{ position: 'relative' }}>
									<select
										required
										onChange={(e: any) =>
											changeValuesArray('categoryUuid', e.target.value)
										}
										value={values.categories[0].categoryUuid}
										className='form-select'>
										{categories?.map((category: any) => (
											<option value={category.uuid}>
												{category.name.rus}
											</option>
										))}
										<option value='' hidden selected></option>
									</select>
								</div>
							</div>
						</div>
					)}
					{uuid && (
						<div className='col-12 d-flex flex-column  gap-2'>
							<HighlightedTitle title={'*Название Специализации'} />

							<FormGroup label='RUS' className='col-12'>
								<Input
									required
									type='text'
									onChange={(e: any) => changeValues('rus', e.target.value)}
									value={values.name.rus}
								/>
							</FormGroup>
							<FormGroup label='ARM' className='col-12'>
								<Input
									required
									type='text'
									onChange={(e: any) => changeValues('arm', e.target.value)}
									value={values.name.arm}
								/>
							</FormGroup>
							<FormGroup label='ENG' className='col-12'>
								<Input
									required
									type='text'
									onChange={(e: any) => changeValues('en', e.target.value)}
									value={values.name.en}
								/>
							</FormGroup>

							<div className='col-3'>
								<HighlightedTitle
									title={'*Выберите принадлежность к Спецализации'}
								/>

								<div style={{ position: 'relative' }}>
									<select
										required
										onChange={(e: any) =>
											changeValues('categoryUuid', e.target.value)
										}
										value={values.categoryUuid}
										className='form-select'>
										{categories?.map((category: any) => (
											<option value={category.uuid}>
												{category.name.rus}
											</option>
										))}
										<option value='' hidden selected></option>
									</select>
								</div>
							</div>
						</div>
					)}
				</div>
			</CardBody>
		</div>
	);
};

export default AddSubCategory;
