import React, { FC, useCallback } from 'react';

import Tooltips from '../../../../../components/bootstrap/Tooltips';
import useDarkMode from '../../../../../hooks/useDarkMode';

interface IGroupItem {
	uuid?: string;
	setValues(...args: unknown[]): unknown;
	values: any;
	item: any;
	index: any;
}

const GroupItem: FC<IGroupItem> = ({ uuid, setValues, item, index, values }) => {
	const { themeStatus } = useDarkMode();

	const handleFilterCheckboxChange = useCallback(
		(e: any) => {
			const value = e.target.value;
			if (e.target.type === 'checkbox') {
				const isChecked = e.target.checked;

				setValues((prev: any) => {
					const updatedGroups = isChecked
						? [...prev.groups, value]
						: prev.groups.filter((item: string) => item !== value);
					return {
						...prev,
						groups: updatedGroups,
					};
				});
			}
		},
		[setValues],
	);

	return (
		<div
			key={`${index}-${item?.uuid}`}
			className=' col-12 rounded-1 light-border-services-tab p-2'>
			<div className='form-check m-0 gap-2' key={index}>
				<input
					color={themeStatus}
					className='form-check-input'
					onChange={(e: any) => handleFilterCheckboxChange(e)}
					type='checkbox'
					value={item.uuid}
					name='services'
					checked={values?.groups?.includes(item?.uuid)}
					id={`flexCheckDefault-${index}`}
					// disabled={isDisabled}
				/>
				<label
					htmlFor={`flexCheckDefault-${index}`}
					className='form-check-label col-12 d-flex gap-3 p-0'>
					<p className='d-flex m-0' style={{ width: '70%' }}>
						<span className='overflow-hidden w-100'>
							<Tooltips title={item?.title_all?.rus} placement='top' flip={['top']}>
								<span className='text-truncate'>{item?.title_all?.rus}</span>
							</Tooltips>
						</span>
					</p>
				</label>
			</div>
		</div>
	);
};

export default GroupItem;
