import { TC_TL } from '../../assets/svg/TC_TL';
import { TC_TR } from '../../assets/svg/TC_TR';
import { TC_BL } from '../../assets/svg/TC_BL';
import { TC_BR } from '../../assets/svg/TC_BR';
import { TC_C } from '../../assets/svg/TC_C';
import { THEETH_18 } from '../../assets/svg/THEETH_18';
import { THEETH_17 } from '../../assets/svg/THEETH_17';
import { THEETH_16 } from '../../assets/svg/THEETH_16';
import { THEETH_15 } from '../../assets/svg/THEETH_15';
import { THEETH_14 } from '../../assets/svg/THEETH_14';
import { THEETH_13 } from '../../assets/svg/THEETH_13';
import { THEETH_12 } from '../../assets/svg/THEETH_12';
import { THEETH_11 } from '../../assets/svg/THEETH_11';
import { THEETH_21 } from '../../assets/svg/THEETH_21';
import { THEETH_22 } from '../../assets/svg/THEETH_22';
import { THEETH_23 } from '../../assets/svg/THEETH_23';
import { THEETH_24 } from '../../assets/svg/THEETH_24';
import { THEETH_25 } from '../../assets/svg/THEETH_25';
import { THEETH_26 } from '../../assets/svg/THEETH_26';
import { THEETH_27 } from '../../assets/svg/THEETH_27';
import { THEETH_28 } from '../../assets/svg/THEETH_28';
import { THEETH_48 } from '../../assets/svg/THEETH_48';
import { THEETH_47 } from '../../assets/svg/THEETH_47';
import { THEETH_46 } from '../../assets/svg/THEETH_46';
import { THEETH_45 } from '../../assets/svg/THEETH_45';
import { THEETH_44 } from '../../assets/svg/THEETH_44';
import { THEETH_43 } from '../../assets/svg/THEETH_43';
import { THEETH_42 } from '../../assets/svg/THEETH_42';
import { THEETH_41 } from '../../assets/svg/THEETH_41';
import { THEETH_31 } from '../../assets/svg/THEETH_31';
import { THEETH_32 } from '../../assets/svg/THEETH_32';
import { THEETH_33 } from '../../assets/svg/THEETH_33';
import { THEETH_34 } from '../../assets/svg/THEETH_34';
import { THEETH_35 } from '../../assets/svg/THEETH_35';
import { THEETH_36 } from '../../assets/svg/THEETH_36';
import { THEETH_37 } from '../../assets/svg/THEETH_37';
import { THEETH_38 } from '../../assets/svg/THEETH_38';
import { PRINT_TOOTH_18 } from '../../assets/svg/PRINT_TOOTH_18';
import { PRINT_TOOTH_17 } from '../../assets/svg/PRINT_TOOTH_17';
import { PRINT_TOOTH_16 } from '../../assets/svg/PRINT_TOOTH_16';
import { PRINT_TOOTH_15 } from '../../assets/svg/PRINT_TOOTH_15';
import { PRINT_TOOTH_14 } from '../../assets/svg/PRINT_TOOTH_14';
import { PRINT_TOOTH_13 } from '../../assets/svg/PRINT_TOOTH_13';
import { PRINT_TOOTH_12 } from '../../assets/svg/PRINT_TOOTH_12';
import { PRINT_TOOTH_11 } from '../../assets/svg/PRINT_TOOTH_11';
import { PRINT_TOOTH_21 } from '../../assets/svg/PRINT_TOOTH_21';
import { PRINT_TOOTH_22 } from '../../assets/svg/PRINT_TOOTH_22';
import { PRINT_TOOTH_23 } from '../../assets/svg/PRINT_TOOTH_23';
import { PRINT_TOOTH_24 } from '../../assets/svg/PRINT_TOOTH_24';
import { PRINT_TOOTH_25 } from '../../assets/svg/PRINT_TOOTH_25';
import { PRINT_TOOTH_26 } from '../../assets/svg/PRINT_TOOTH_26';
import { PRINT_TOOTH_27 } from '../../assets/svg/PRINT_TOOTH_27';
import { PRINT_TOOTH_28 } from '../../assets/svg/PRINT_TOOTH_28';
import { PRINT_TOOTH_48 } from '../../assets/svg/PRINT_TOOTH_48';
import { PRINT_TOOTH_47 } from '../../assets/svg/PRINT_TOOTH_47';
import { PRINT_TOOTH_46 } from '../../assets/svg/PRINT_TOOTH_46';
import {PRINT_TOOTH_45 } from '../../assets/svg/PRINT_TOOTH_45';
import { PRINT_TOOTH_44 } from '../../assets/svg/PRINT_TOOTH_44';
import { PRINT_TOOTH_43 } from '../../assets/svg/PRINT_TOOTH_43';
import { PRINT_TOOTH_42 } from '../../assets/svg/PRINT_TOOTH_42';
import { PRINT_TOOTH_41 } from '../../assets/svg/PRINT_TOOTH_41';
import { PRINT_TOOTH_31 } from '../../assets/svg/PRINT_TOOTH_31';
import { PRINT_TOOTH_32 } from '../../assets/svg/PRINT_TOOTH_32';
import { PRINT_TOOTH_33 } from '../../assets/svg/PRINT_TOOTH_33';
import { PRINT_TOOTH_34 } from '../../assets/svg/PRINT_TOOTH_34';
import { PRINT_TOOTH_35 } from '../../assets/svg/PRINT_TOOTH_35';
import { PRINT_TOOTH_36 } from '../../assets/svg/PRINT_TOOTH_36';
import { PRINT_TOOTH_37 } from '../../assets/svg/PRINT_TOOTH_37';
import { PRINT_TOOTH_38 } from '../../assets/svg/PRINT_TOOTH_38';

export const TEETH = {
    TC_TL,
    TC_TR,
    TC_BL,
    TC_BR,
    TC_C,
    THEETH_18,
    THEETH_17,
    THEETH_16,
    THEETH_15,
    THEETH_14,
    THEETH_13,
    THEETH_12,
    THEETH_11,
    THEETH_21,
    THEETH_22,
    THEETH_23,
    THEETH_24,
    THEETH_25,
    THEETH_26,
    THEETH_27,
    THEETH_28,
    THEETH_48,
    THEETH_47,
    THEETH_46,
    THEETH_45,
    THEETH_44,
    THEETH_43,
    THEETH_42,
    THEETH_41,
    THEETH_31,
    THEETH_32,
    THEETH_33,
    THEETH_34,
    THEETH_35,
    THEETH_36,
    THEETH_37,
    THEETH_38,
    PRINT_TOOTH_18,
    PRINT_TOOTH_17,
    PRINT_TOOTH_16,
    PRINT_TOOTH_15,
    PRINT_TOOTH_14,
    PRINT_TOOTH_13,
    PRINT_TOOTH_12,
    PRINT_TOOTH_11,
    PRINT_TOOTH_21,
    PRINT_TOOTH_22,
    PRINT_TOOTH_23,
    PRINT_TOOTH_24,
    PRINT_TOOTH_25,
    PRINT_TOOTH_26,
    PRINT_TOOTH_27,
    PRINT_TOOTH_28,
    PRINT_TOOTH_48,
    PRINT_TOOTH_47,
    PRINT_TOOTH_46,
    PRINT_TOOTH_45,
    PRINT_TOOTH_44,
    PRINT_TOOTH_43,
    PRINT_TOOTH_42,
    PRINT_TOOTH_41,
    PRINT_TOOTH_31,
    PRINT_TOOTH_32,
    PRINT_TOOTH_33,
    PRINT_TOOTH_34,
    PRINT_TOOTH_35,
    PRINT_TOOTH_36,
    PRINT_TOOTH_37,
    PRINT_TOOTH_38,
};
