import React, { SVGProps } from "react";

export const PRINT_TOOTH_46 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 20 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M18.5328 15.2083C16.5309 45.5736 19.7023 49.9883 18.533 51.12C17.1266 53.9477 14.2711 47.523 12.0261 39.8536M1.98634 13.8C1.58499 21.4839 1.11924 42.6093 8.88069 47.9513C10.0129 48.7306 9.27823 44.7316 8.70685 40.3258M8.70685 40.3258C8.11768 35.7829 7.70211 30.8075 9.686 30.1926C10.2604 33.2424 11.0902 36.6562 12.0261 39.8536M8.70685 40.3258C11.8849 45.6661 12.7277 45.9033 12.0261 39.8536" stroke="black" strokeWidth="0.7" strokeLinecap="round"/>
<path id="Vector 24" d="M17.3033 4.65763C14.9716 0.455651 12.0572 3.25675 10.8913 5.59118C9.43397 5.59118 6.84708 1.8 5.38974 1.8C3.9324 1.8 -1.1442 4.30516 1.60065 14.8728C3.31305 21.4656 7.34102 23.8 10.3083 23.8C19.1676 22.0352 20.314 10.0832 17.3033 4.65763Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}

