import React, { SVGProps } from "react";


export const PRINT_TOOTH_43 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 19 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 20">
<path id="Vector 66" d="M2 15.9224C3.80584 43.3798 15.4752 57.6629 16.1663 49.527C16.8573 41.3912 17.2194 20.748 16.8573 13.8" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M8.13369 19.8C0.0373697 18.1865 -0.325818 8.25569 2.88697 3.59604C4.61593 1.08846 6.87644 0.427944 13.7745 0.983362C15.1063 0.983362 19.7806 3.28923 17.2722 12.9511C15.7073 18.9787 10.8454 19.8 8.13369 19.8Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}

