import React, { FC } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import { formatPrice } from '../../../../../utils/functions';
import useDarkMode from '../../../../../hooks/useDarkMode';
import { TColor } from '../../../../../type/color-type';

interface IOffCanvasComponentActProps {
	canvasData: any;
	canvasColor: TColor;
}

const OffCanvasComponentAct: FC<IOffCanvasComponentActProps> = ({ canvasColor, canvasData }) => {
	const { themeStatus } = useDarkMode();

	return (
		<Card
			className={`mb-10 ${themeStatus === 'light' ? `bg-l10-${canvasColor}` : 'bg-dark'}`}
			shadow='sm'>
			<CardHeader
				className={`${
					themeStatus === 'light' ? `bg-l25-${canvasColor}` : `bg-${canvasColor}`
				}`}>
				<CardLabel
					iconColor={themeStatus === 'light' ? `${canvasColor}` : 'light'}
					icon='ReceiptLong'>
					<CardTitle
						className={`${
							themeStatus === 'light' ? `text-${canvasColor}` : 'text-white'
						}`}>
						Смета
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<FormGroup id='date' label='Сумма и описание' className='mb-2'>
					<p
						style={{
							color: themeStatus === 'light' ? 'black' : 'white',
						}}>
						Сумма - {formatPrice(canvasData?.estimate?.act?.total_amount, 'AMD', 'ru-RU')}{' '}
					</p>
					{/* <p
						style={{
							color: themeStatus === 'light' ? 'black' : 'white',
						}}>
						Описание - {canvasData?.act?.description}
						<span className={`fw-bold text-${canvasColor}`}>
							{' '}
							{canvasData?.status === 'Не оплачен' && 'Не оплачено'}
							{canvasData?.status === 'Завершен' && 'Оплачено'}
						</span>
					</p> */}
				</FormGroup>
			</CardBody>
		</Card>
	);
};
export default OffCanvasComponentAct;
