import React, { FC } from 'react';
import Button from '../../../../../../components/bootstrap/Button';
import { useQuery } from 'react-query';
import { NotificationService } from '../../../../../api/service/notification.service';
import * as qs from 'qs';
import { INotification } from '../../../../../api/interface/notification.interface';
import Popovers from '../../../../../../components/bootstrap/Popovers';

interface INotificationAllItemProps {
	styledBtn: any;
	setIsOpen(...args: unknown[]): unknown;
}

const NotificationAllItem: FC<INotificationAllItemProps> = ({ styledBtn, setIsOpen }) => {
	const { data: notifications_count }: { data: INotification[] | any; isLoading: boolean } =
		useQuery(
			['notifications_count'],
			() =>
				NotificationService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},
						includeMeta: [
							{
								association: 'visit',
							},
						],
						filterMeta: {
							isRead: false,
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				refetchInterval: 60 * 1000,
			},
		);

	return (
		<div className='col-auto fs-6 d-flex align-items-center justify-content-center'>
			<Popovers trigger='hover' desc='Уведомления'>
				<div className='not_circle'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setIsOpen(true)}
						aria-label='Notifications'
						className='not_circle fs-6 d-flex align-items-center justify-content-center'>
						{notifications_count?.data?.length > 0 && (
							<span className='span_not_circle'>
								{notifications_count?.data?.length}
							</span>
						)}
					</Button>
				</div>
			</Popovers>
		</div>
	);
};
export default NotificationAllItem;
