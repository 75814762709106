import React, { FC, memo } from 'react';
import Button from '../../../../../components/bootstrap/Button';

interface IUserEditButtonProps {
	setEditCard(...args: unknown[]): unknown;
}

const UserEditButton: FC<IUserEditButtonProps> = memo(({ setEditCard }) => {
	return (
		<>
			<div className='col-12'>
				<div className='d-flex align-items-center'>
					<Button
						color='primary'
						className='text-nowrap col-12'
						onClick={() =>{
							console.log('firstName')
							setEditCard(true)}}>
						Редактировать данные{' '}
					</Button>{' '}
				</div>
			</div>
		</>
	);
});

export default UserEditButton;
