import React, { FC, useCallback, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import { useMutation, useQueryClient } from 'react-query';
import Input from '../../../components/bootstrap/forms/Input';
import HighlightedTitle from '../medicalCard/medicalCaseComponents/UI/HighlightedTitle';
import { GroupService } from '../../../custom/api/service/group.service';
import Loading from '../../../custom/components/loading/Loading';

interface IEditConfigProps {
	isOpen: any;
	setIsOpen(...args: unknown[]): unknown;
}

const AddGroup: FC<IEditConfigProps> = ({ isOpen, setIsOpen }) => {
	const queryClient = useQueryClient();

	const { mutate, isLoading } = useMutation(
		({ inputDto }: { inputDto: any }) => GroupService.create(inputDto),
		{
			onSuccess: (response, formData) => {
				queryClient.invalidateQueries('groups');
				setIsOpen(false);
			},
			onError: (error) => {
				console.log(error);
			},
		},
	);

	const [values, setValues] = useState<any>({
		title_all: {
			en: '',
			arm: '',
			rus: '',
		},
	});

	const changeValues = (key: string, value: any) => {
		setValues((prev: any) => ({
			...prev,
			title_all: {
				...prev.title_all,
				[key]: value,
			},
		}));
	};

	const handleSubmit = useCallback(
		(e: any) => {
			e.preventDefault();
			mutate({ inputDto: values });
		},
		[mutate, values],
	);

	return (
		<Modal isStaticBackdrop isOpen={isOpen} setIsOpen={setIsOpen} size={'lg'} isCentered>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='title' style={{ fontSize: '1.75rem' }}>
					Группа{' '}
				</ModalTitle>
			</ModalHeader>
			<form onSubmit={handleSubmit}>
				<ModalBody className='px-4 d-flex flex-column gap-4'>
					<div className='col-12 d-flex flex-column  gap-2'>
						<HighlightedTitle title={'*Название Группы Материалов'} />

						<FormGroup label='RUS' className='col-12'>
							<Input
								type='text'
								onChange={(e: any) => changeValues('rus', e.target.value)}
								value={values.title_all.rus}
							/>
						</FormGroup>
						<FormGroup label='ARM' className='col-12'>
							<Input
								type='text'
								onChange={(e: any) => changeValues('arm', e.target.value)}
								value={values.title_all.arm}
							/>
						</FormGroup>
						<FormGroup label='ENG' className='col-12'>
							<Input
								type='text'
								onChange={(e: any) => changeValues('en', e.target.value)}
								value={values.title_all.en}
							/>
						</FormGroup>
					</div>
				</ModalBody>

				<ModalFooter className='px-4 pb-4 '>
					<Button isDisable={isLoading} color='info' type='submit' className='col-12 position-relative'>
						<Loading loading={isLoading} />
						Создать
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default AddGroup;
