import React, { FC } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import { parseDateTime } from '../../../../../utils/functions';
import useDarkMode from '../../../../../hooks/useDarkMode';
import { TColor } from '../../../../../type/color-type';
interface IOffCanvasComponentVisitProps {
	canvasData: any;
	canvasColor: TColor;
}

const OffCanvasComponentVisit: FC<IOffCanvasComponentVisitProps> = ({
	canvasColor,
	canvasData,
}) => {
	const { themeStatus } = useDarkMode();

	return (
		<Card
			color={themeStatus}
			className={`mb-10 ${themeStatus === 'light' ? `bg-l10-${canvasColor}` : 'bg-dark'}`}
			shadow='sm'>
			<CardHeader
				className={`${
					themeStatus === 'light' ? `bg-l25-${canvasColor}` : `bg-${canvasColor}`
				}`}>
				<CardLabel
					iconColor={themeStatus === 'light' ? `${canvasColor}` : 'light'}
					icon='Alarm'>
					<CardTitle
						className={`${
							themeStatus === 'light' ? `text-${canvasColor}` : 'text-white'
						}`}>
						Визит
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<FormGroup id='date' label='Дата и время визита' className='mb-2'>
					<p
						style={{
							color: themeStatus === 'light' ? 'black' : 'white',
						}}>
						{parseDateTime(canvasData?.visitDate).date}
					</p>
					<p
						style={{
							color: themeStatus === 'light' ? 'black' : 'white',
						}}>
						{parseDateTime(canvasData?.visitDate).time}
						{canvasData?.endDate && ` - ${parseDateTime(canvasData?.endDate).time}`}
					</p>
				</FormGroup>

				<FormGroup id='date' label='Статус' className='mb-2'>
					<p
						style={{
							color: themeStatus === 'light' ? 'black' : 'white',
						}}>
						{canvasData?.status}{' '}
						{canvasData?.status === 'Отменен' && (
							<span className={`fw-bold text-${canvasColor}`}>
								{canvasData?.status === 'Отменен' &&
									canvasData?.canceledVisit[0]?.description}
								{', '}
								{canvasData?.status === 'Отменен' &&
								canvasData?.canceledVisit[0]?.type === 'patient'
									? 'Отказ от получения услуг'
									: 'Отказ от предоставления услуг'}
							</span>
						)}
					</p>
				</FormGroup>
			</CardBody>
		</Card>
	);
};
export default OffCanvasComponentVisit;
