import React, { useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../../../../../components/bootstrap/Modal';
import Button from '../../../../../../components/bootstrap/Button';
import { formatDate, formatPrice } from '../../../../../../utils/functions';
import { TeethRectangle } from '../ActComponents/UI/TeethRectangle';
import Loading from '../../../../../../custom/components/loading/Loading';
import Payment from '../Payments';
import { useReactToPrint } from 'react-to-print';

interface PrintModalProps {
	data: any;
	onClose: () => void;
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
}

export const PrintModal: React.FC<PrintModalProps> = ({ data, isOpen, setIsOpen, onClose }) => {
	const [loading, setLoading] = useState<boolean>(false);

	const generatePDF = () => {
		setLoading(true);
		const contentElement = document.getElementById('pdf-content');
		if (!contentElement) {
			console.error('PDF content not found.');
			return;
		}
		const clonedContent = contentElement.cloneNode(true) as HTMLElement;

		const pdfPages = clonedContent.querySelectorAll('.pdf-page');
		pdfPages.forEach((page) => {
			const titleElement = document.createElement('h2');
			titleElement.style.textAlign = 'center';
			titleElement.textContent = 'ПЛАН ЛЕЧЕНИЯ';
			page.insertBefore(titleElement, page.firstChild);
		});

		const options = {
			margin: 0.2,
			filename: `Act_${data?.visit?.estimate?.act?.id}.pdf`,
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 3, logging: false },
			jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
			pagebreak: { mode: ['css'], before: '.pdf-page', avoid: ['div'] },
		};

		html2pdf()
			.set(options)
			.from(clonedContent)
			.save()
			.then(() => {
				setLoading(false);
			})
			.catch((err: any) => {});
	};

	const calcAmount = () => {
		const payments_amount = data.payment?.payments?.reduce((acc: any, item: any) => {
			return acc + +item.payment_amount;
		}, 0);
		return data.act?.total_amount - payments_amount;
	};

	const printRef = useRef<HTMLDivElement>(null);
	const printContent = useReactToPrint({
		contentRef: printRef,
	});

	return (
		<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen} modalLevel={1056} size='xl'>
			<ModalHeader style={{ paddingRight: 30 }} setIsOpen={setIsOpen}>
				<h5>Печатная форма</h5>
			</ModalHeader>
			<ModalBody
				className='modal-content'
				style={{
					overflow: 'auto',
					// minHeight: '80vh',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					boxShadow: 'none',
				}}>
				<div
					id='pdf-content'
					className='act-print'
					ref={printRef}
					style={{
						background: '#fff',
						color: '#000',
						width: '210mm',
						padding: '0mm 12mm 0mm 12mm',
						boxSizing: 'border-box',
						fontSize: '12pt',
						// zoom: 0.75,
						fontFamily: 'Tahoma',
					}}>
					<div className='d-flex justify-content-between w-100 pb-4'>
						<div className='d-flex gap-3'>
							<span className='fs-3 fw-bold'>Акт №{data.act?.id}</span>
							<div className='d-flex gap-2'>
								<span className='fs-3 fw-bold'>Итого к оплате:</span>
								<span className='fs-3 fw-bold'>
									{formatPrice(data.act?.total_amount, 'AMD', 'ru-RU')}
								</span>
							</div>
						</div>
					</div>
					<div className='d-flex align-items-center gap-4'>
						<div className='d-flex flex-column gap-3'>
							<p className='m-0 fs-6'>
								Пациент:{' '}
								{`${data.visit?.patient?.name} ${data.visit?.patient?.middleName} ${data.visit?.patient?.surname}`}
							</p>
							<p className='m-0 fs-6'>
								Дата создания:{' '}
								{data.visit?.createdAt &&
									formatDate(new Date(data.visit.createdAt)).date2}
							</p>
						</div>
						<div className='d-flex flex-column gap-3'>
							<p className='m-0 fs-6'>
								На основе мед. случая №{data.visit?.medicalCase?.medicalCaseId}
							</p>
							<p className='m-0 fs-6'>
								План лечения: {data.visit?.medicalCase?.title}
							</p>
						</div>
					</div>
					<div>
						<span className='fs-6 p-0'>На основе сметы №{data.estimate.id}</span>
						<hr style={{ marginTop: 2, marginBottom: 2 }} />
						<span className='fs-5 p-0 fw-bold'>
							{data.stage?.title}
							<hr style={{ marginTop: 2, opacity: 1 }} />
						</span>

						{data?.localServices?.length > 0 ? (
							<table
								style={{
									width: '100%',
									borderCollapse: 'collapse',
									marginBottom: '0',
								}}>
								<thead>
									<tr>
										<th>№</th>
										<th>Мероприятия</th>
										<th className='text-center'>Цена за ед.</th>
										<th className='text-center'>Кол-во</th>
										<th className='text-center'>Всего</th>
									</tr>
								</thead>
								<tbody>
									{data?.localServices?.map((service: any, index: number) => (
										<React.Fragment key={`${index}-${service?.event_uuid}`}>
											<tr>
												<td
													style={{
														textAlign: 'left',
														verticalAlign: 'top',
													}}>
													<span className='fw-bold'>{index + 1}</span>
												</td>
												<td className='col-12' style={{ display: 'block' }}>
													{service?.title_all?.rus}
													{data.service_type !== 'lor' &&
														data.service_type !== 'cosmetology' && (
															<span className='d-flex text-center'>
																Для зубов{' '}
																<TeethRectangle
																	data={service}
																	print
																/>
															</span>
														)}
													{service.materials.length > 0 &&
														service.materials.map(
															(material: any, i: number) => (
																<div
																	key={`${i}-${service.event_uuid}-material`}>
																	{material.name} {material.size}
																</div>
															),
														)}
												</td>
												<td className='text-left col-2'>
													{formatPrice(service.price, 'AMD', 'ru-RU')}
													{service.materials.length > 0 &&
														service.materials.map(
															(material: any, i: number) => (
																<div
																	style={{ marginTop: 7 }}
																	key={`${i}-${service.event_uuid}-material-price`}>
																	{formatPrice(
																		material.price,
																		'AMD',
																		'ru-RU',
																	)}
																</div>
															),
														)}
												</td>
												<td className='text-center col-2'>
													{service?.count}{' '}
													{service.materials.length > 0 &&
														service.materials.map(
															(material: any, i: number) => (
																<div
																	style={{ marginTop: 7 }}
																	key={`${i}-${service.event_uuid}-material-count`}>
																	{material.count}
																</div>
															),
														)}
												</td>
												<td className='text-left col-1'>
													{formatPrice(service.amount, 'AMD', 'ru-RU')}
													{service.materials.length > 0 &&
														service.materials.map(
															(material: any, i: number) => (
																<div
																	style={{ marginTop: 7 }}
																	key={`${i}-${service.event_uuid}-material-amount`}>
																	{formatPrice(
																		material.amount,
																		'AMD',
																		'ru-RU',
																	)}
																</div>
															),
														)}
												</td>
											</tr>
											<tr>
												<td colSpan={5} style={{ padding: 0 }}>
													<div
														style={{
															borderTop: '1px solid #ccc',
															margin: '0',
														}}></div>
												</td>
											</tr>
										</React.Fragment>
									))}
								</tbody>
							</table>
						) : (
							<p className='fs-3 text-center'>нет услуг</p>
						)}
					</div>
					<div className='d-flex gap-2'>
						<span className='fs-3 fw-bold'>Оплаты:</span>
					</div>
					<Payment
						print
						setPayment={() => {}}
						payment={data.payment}
						status={!data.act?.total_amount_for_pay}
					/>
					<div className='d-flex gap-2'>
						<span className='fs-3 fw-bold'>
							{!data.act?.total_amount_for_pay ? 'Оплачено:' : 'Остаток:'}
						</span>
						<span className='fs-3 fw-bold'>
							{formatPrice(
								!data.act?.total_amount_for_pay
									? data.act?.total_amount
									: calcAmount(),
								'AMD',
								'ru-RU',
							)}
						</span>
					</div>
					<hr />
				</div>
			</ModalBody>
			<ModalFooter style={{ paddingRight: 30 }}>
				<Button
					isDisable={loading}
					color='info'
					className='position-relative'
					isLight
					onClick={generatePDF}>
					<Loading loading={loading} />
					Скачать PDF
				</Button>
				<Button
					isDisable={loading}
					color='info'
					className='position-relative'
					isLight
					onClick={printContent}>
					<Loading loading={loading} />
					Печать
				</Button>
			</ModalFooter>
		</Modal>
	);
};
