import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TEETH } from '../assets/svg/index';

export interface IDistalSurface {
	surfaceTypeId: number;
	label: string;
	color: string;
	fillColor: string;
	startColor: string;
	endColor: string;
}

export interface IDentalContextProps {
	manipulations: any;
	bridge_default: any;
	implant_default: any;
	dentalFormula: any;
	setDentalFormula: any;
	getObjectBasedOnId: any;
	event_default: any;
	referral_default: any;
	selectMany: any;
	setSelectMany: any;
	selectManyAll: any;
	setSelectManyAll: any;
	selectedItesm: any;
	setSelectedItems: any;
	intermediateItems: any;
	setIntermediateItems: any;
	default_dental_formula: any;
	empty_default: any;
	delation_default: any;
	setSelectedItemsAll: any;
	selectedItesmAll: any;
	milk_theeth_default: any;
	distalSurfaces: IDistalSurface[];
	dentalFormulaDrodown: any;
	setDentalFormulaDropdown: any;
	selected: any;
	setSelected: any;
	crown_default: any;
	default_dental_formula_dropdown: any;
	SurfaceSVG: any;
	rootsAndChannels: IDistalSurface[];
}
const DentalContext = createContext<IDentalContextProps>({} as IDentalContextProps);

interface IDentalContextProviderProps {
	children: ReactNode;
} // Import necessary modules and types

// Define your DentalContext and related interfaces

// Define your DentalContextProvider component
export const DentalContextProvider: FC<IDentalContextProviderProps> = ({ children }) => {
	const SurfaceSVG: FC<{
		startColor: string | undefined;
		endColor: string | undefined;
		surface: string;
	}> = ({ startColor, endColor, surface }) => (
		<>
			{surface === 'top_left' && <TEETH.TC_TL startColor={startColor} endColor={endColor} />}
			{surface === 'top_right' && <TEETH.TC_TR startColor={startColor} endColor={endColor} />}
			{surface === 'bottom_left' && (
				<TEETH.TC_BL startColor={startColor} endColor={endColor} />
			)}
			{surface === 'bottom_right' && (
				<TEETH.TC_BR startColor={startColor} endColor={endColor} />
			)}
			{surface === 'center' && <TEETH.TC_C startColor={startColor} endColor={endColor} />}
		</>
	);
	const [selectMany, setSelectMany] = useState<boolean>(false);
	const [selectManyAll, setSelectManyAll] = useState<boolean>(false);
	const [intermediateItems, setIntermediateItems] = useState<[]>([]);
	const [selectedItesm, setSelectedItems] = useState<[]>([]);
	const [selectedItesmAll, setSelectedItemsAll] = useState<[]>([]);
	const [color, setColor] = useState(undefined);
	const [selected, setSelected] = useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const rootsAndChannels = [
		{
			surfaceTypeId: 0,
			label: 'Пульпит',
			color: 'linear-gradient(134deg, rgba(156, 160, 244, 0.80) 25.93%, rgba(3, 2, 46, 0.80) 75.32%), #D9D9D9',
			fillColor: '#48de3ccc',
			startColor: '',
			endColor: '',
		},
		{
			surfaceTypeId: 1,
			label: 'Перидонтит',
			color: 'linear-gradient(134deg, rgba(142, 10, 113, 0.80) 25.93%, rgba(86, 226, 255, 0.80) 75.32%), #D9D9D9',
			fillColor: '#786c03cc',
			startColor: '',
			endColor: '',
		},
		{
			surfaceTypeId: 2,
			label: 'Гранулема',
			color: 'linear-gradient(134deg, rgba(140, 17, 171, 0.80) 25.93%, rgba(210, 179, 134, 0.80) 75.32%), #D9D9D9',
			fillColor: '#f294d8cc',
			startColor: '',
			endColor: '',
		},
		{
			surfaceTypeId: 3,
			label: 'Киста зуба',
			color: 'linear-gradient(134deg, rgba(227, 123, 90, 0.80) 25.93%, rgba(3, 120, 92, 0.80) 75.32%), #D9D9D9',
			fillColor: '#e15ae3cc',
			startColor: '',
			endColor: '',
		},
		{
			surfaceTypeId: 4,
			label: 'Абсцесс',
			color: 'linear-gradient(134deg, rgba(183, 227, 90, 0.80) 25.93%, rgba(223, 146, 57, 0.80) 75.32%), #D9D9D9',
			fillColor: '#b7e35acc',
			startColor: '',
			endColor: '',
		},
		{
			surfaceTypeId: 5,
			label: 'Резорбция корня',
			color: 'linear-gradient(134deg, rgba(174, 84, 72, 0.80) 25.93%, rgba(97, 21, 94, 0.80) 75.32%), #D9D9D9',
			fillColor: '#ae5448cc',
			startColor: '',
			endColor: '',
		},
		{
			surfaceTypeId: 6,
			label: 'Перфорация канала',
			color: 'linear-gradient(134deg, rgba(141, 141, 141, 0.80) 25.93%, rgba(213, 136, 159, 0.80) 75.32%), #D9D9D9',
			fillColor: '#8d8d8dcc',
			startColor: '',
			endColor: '',
		},
		{
			surfaceTypeId: 7,
			label: 'Остеомиелит челюсти',
			color: 'linear-gradient(134deg, rgba(24, 73, 1, 0.80) 25.93%, rgba(229, 242, 148, 0.80) 75.32%), #D9D9D9',
			fillColor: '#184901cc',
			startColor: '',
			endColor: '',
		},
		{
			surfaceTypeId: 8,
			label: 'Клиновидный дефект',
			color: 'linear-gradient(134deg, rgba(227, 90, 90, 0.80) 25.93%, rgba(141, 120, 123, 0.80) 75.32%), #D9D9D9',
			fillColor: '#e35a5acc',
			startColor: '',
			endColor: '',
		},
	];

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const distalSurfaces = [
		//TODO: start and end color for gradients in circles
		{
			surfaceTypeId: 0,
			label: 'Пломба',
			color: 'linear-gradient(134deg, rgba(184, 244, 156, 0.80) 25.93%, rgba(72, 222, 60, 0.80) 75.32%), #D9D9D9',
			fillColor: '#48de3ccc',
			startColor: '#b8f49ccc',
			endColor: '#48de3ccc',
		},
		{
			surfaceTypeId: 1,
			label: 'Герметизация фиссур',
			color: 'linear-gradient(134deg, rgba(227, 214, 90, 0.80) 25.93%, rgba(120, 108, 3, 0.80) 75.32%), #D9D9D9',
			fillColor: '#786c03cc',
			startColor: '#e3d65acc',
			endColor: '#786c03cc',
		},
		{
			surfaceTypeId: 2,
			label: 'Микроабразия эмали',
			color: 'linear-gradient(134deg, rgba(249, 251, 248, 0.80) 25.93%, rgba(242, 148, 216, 0.80) 75.32%), #D9D9D9',
			fillColor: '#f294d8cc',
			startColor: '#f9fbf8cc',
			endColor: '#f294d8cc',
		},
		{
			surfaceTypeId: 3,
			label: 'Клиновидный дефект',
			color: 'linear-gradient(134deg, rgba(225, 90, 227, 0.80) 25.93%, rgba(122, 6, 76, 0.80) 75.32%), #D9D9D9',
			fillColor: '#e15ae3cc',
			startColor: '#e15ae3cc',
			endColor: '#7a064ccc',
		},
		{
			surfaceTypeId: 4,
			label: 'Кариес',
			color: 'linear-gradient(135deg, #163900 0%, #015829 100%)',
			fillColor: '#015829',
			startColor: '#163900',
			endColor: '#015829',
		},
	];

	// eslint-disable-next-line react-hooks/exhaustive-deps

	// eslint-disable-next-line react-hooks/exhaustive-deps

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const default_dental_formula = {
		top: [
			{
				id: '18',
				transform: false,
				implant: false,
				event: false,
				referral: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				image: <TEETH.THEETH_18 />,
				roots_count: '4',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_18 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '17',
				image: <TEETH.THEETH_17 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_17 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '16',
				image: <TEETH.THEETH_16 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_16 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '15',
				image: <TEETH.THEETH_15 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '2',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_15 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '14',
				image: <TEETH.THEETH_14 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '2',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_14 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '13',
				image: <TEETH.THEETH_13 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_13 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '12',
				image: <TEETH.THEETH_12 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_12 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '11',
				image: <TEETH.THEETH_11 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_11 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '21',
				image: <TEETH.THEETH_21 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_21 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '22',
				image: <TEETH.THEETH_22 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_22 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '23',
				image: <TEETH.THEETH_23 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_23 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '24',
				image: <TEETH.THEETH_24 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '2',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_24 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '25',
				image: <TEETH.THEETH_25 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '2',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_25 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '26',
				image: <TEETH.THEETH_26 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_26 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '27',
				image: <TEETH.THEETH_27 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_27 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '28',
				image: <TEETH.THEETH_28 />,
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_28 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
		],
		bottom: [
			{
				id: '38',
				image: <TEETH.THEETH_38 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_38 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '37',
				image: <TEETH.THEETH_37 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_37 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '36',
				image: <TEETH.THEETH_36 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_36 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '35',
				image: <TEETH.THEETH_35 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '2',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_35 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '34',
				image: <TEETH.THEETH_34 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '2',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_34 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '33',
				image: <TEETH.THEETH_33 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_33 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '32',
				image: <TEETH.THEETH_32 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_32 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '31',
				image: <TEETH.THEETH_31 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_31 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '41',
				image: <TEETH.THEETH_41 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_41 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '42',
				image: <TEETH.THEETH_42 />,
				implant: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_42 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '43',
				image: <TEETH.THEETH_43 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '1',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_43 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: null,
				},
			},
			{
				id: '44',
				image: <TEETH.THEETH_44 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '2',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_44 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '45',
				image: <TEETH.THEETH_45 />,
				implant: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '2',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_45 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '46',
				image: <TEETH.THEETH_46 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_46 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '47',
				image: <TEETH.THEETH_47 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_47 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
			{
				id: '48',
				image: <TEETH.THEETH_48 />,
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
				roots_count: '3',
				diagnostics: '',
				note: '',
				printImage: <TEETH.PRINT_TOOTH_48 />,
				disease: {
					root: [],
					canal: [],
					gum: [],
					distal: {
						top_left: [],
						top_right: [],
						bottom_left: [],
						bottom_right: [],
						center: [],
					},
				},
				enamel: {
					top_left: <SurfaceSVG surface='top_left' startColor={color} endColor={color} />,
					top_right: (
						<SurfaceSVG surface='top_right' startColor={color} endColor={color} />
					),
					bottom_left: (
						<SurfaceSVG surface='bottom_left' startColor={color} endColor={color} />
					),
					bottom_right: (
						<SurfaceSVG surface='bottom_right' startColor={color} endColor={color} />
					),
					center: <SurfaceSVG surface='center' startColor={color} endColor={color} />,
				},
			},
		],
	};

	const default_dental_formula_dropdown = JSON.stringify({
		top: [
			{
				id: '18',
				transform: false,
				implant: false,
				event: false,
				referral: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '17',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '16',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '15',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '14',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '13',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '12',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '11',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '21',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '22',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '23',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '24',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '25',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '26',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '27',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '28',
				implant: false,
				event: false,
				referral: false,
				transform: false,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
		],
		bottom: [
			{
				id: '38',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '37',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '36',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '35',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '34',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '33',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '32',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '31',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '41',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '42',
				implant: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '43',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '44',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '45',
				implant: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '46',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '47',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
			{
				id: '48',
				implant: false,
				event: false,
				referral: false,
				transform: true,
				bridge: false,
				bridge_position: null,
				deletion: false,
				crown: false,
				empty: false,
				milk_theeth: false,
			},
		],
	});

	const [dentalFormula, setDentalFormula] = useState(default_dental_formula);
	const [dentalFormulaDrodown, setDentalFormulaDropdown] = useState(
		JSON.parse(default_dental_formula_dropdown),
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const bridge_default = {
		start: '',
		end: '',
		restoration_type: 'Выберите тип реставрации',
		materialName: '',
		materialUuid: '',
		fixation_type: 'Выберите тип фиксации',
		count: '1',
		price: '0',
		amount: '0',
		service_type: 'bridge',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const crown_default = {
		theeth: '',
		restoration_type: 'Выберите тип реставрации',
		materialName: '',
		materialUuid: '',
		fixation_type: 'Выберите тип фиксации',
		count: '1',
		price: '0',
		amount: '0',
		service_type: 'crown',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const empty_default = {
		theeth: '',
		description: '',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delation_default = {
		theeth: '',
		description: '',
		service_type: 'deletion',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const milk_theeth_default = {
		theeth: '',
		description: '',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const event_default = {
		title: '',
		serviceId: '',
		theeth: '',
		count: '1',
		price: '0',
		amount: '0',
		description: '',
		uuid: '',
		materials: [],
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const referral_default = {
		theeth: '',
		description: '',
		direction: '',
		userUuid: undefined,
		doctorUuid: undefined,
		doctor_type: '',
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const implant_default = {
		theeth: '',
		amount: '',
		brand: '',
		implantName: '',
		size: '',
		prosthetic: 'Заглушка',
		service_type: 'implant',
		prosthetic_price: '',
		manipulation: false,
		manipulationData: [],
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const manipulations = {
		membrane: {
			name: 'Мембранная манипуляция',
			checked: false,
			data: [
				{
					title: null,
					checked: true,
					items: [
						{
							name: 'Коллаген',
							price: '',
							checked: false,
						},
					],
				},
				{
					title: null,
					checked: true,
					items: [
						{
							name: 'Графт(Губка)',
							price: '',
							checked: false,
						},
					],
				},
			],
		},
		bone: {
			name: 'Костная манипуляция',
			checked: false,
			column: true,
			data: [
				{
					title: 'Allo(Xeno)',
					checked: false,
					items: [
						{
							name: 'Боковая',
							price: '',
							checked: false,
						},
						{
							name: 'Вертикальная',
							price: '',
							checked: false,
						},
						{
							name: 'Синус',
							price: '',
							checked: false,
						},
					],
				},
				{
					title: 'Auto',
					checked: false,
					items: [
						{
							name: 'Боковая',
							price: '',
							checked: false,
						},
						{
							name: 'Вертикальная',
							price: '',
							checked: false,
						},
						{
							name: 'Синус',
							price: '',
							checked: false,
						},
					],
				},
			],
		},
		soft_tissue: {
			name: 'Мягкотканная манипуляция',
			checked: false,

			data: [
				{
					title: null,
					checked: true,
					items: [
						{
							name: 'Трансплантат',
							price: '',
							checked: false,
						},
					],
				},
				{
					title: null,
					checked: true,
					items: [
						{
							name: 'Местными тканями',
							price: '',
							checked: false,
						},
					],
				},
			],
		},
		default: {
			name: 'Выберите тип манипуляции',
		},
	};

	const getObjectBasedOnId = (arrayOfIds: any[], id: number | string) => {
		switch (true) {
			case arrayOfIds.includes(id):
				return {
					disabled: true,
					color: 'bg-info',
				};
			default:
				return {
					disabled: false,
					color: 'bg-default',
				};
		}
	};

	const value = useMemo(
		() => ({
			manipulations,
			bridge_default,
			implant_default,
			dentalFormula,
			setDentalFormula,
			getObjectBasedOnId,
			event_default,
			referral_default,
			selectMany,
			setSelectMany,
			selectManyAll,
			setSelectManyAll,
			selectedItesm,
			setSelectedItems,
			setSelectedItemsAll,
			selectedItesmAll,
			intermediateItems,
			setIntermediateItems,
			default_dental_formula,
			empty_default,
			delation_default,
			milk_theeth_default,
			setColor,
			selected,
			setSelected,
			crown_default,
			distalSurfaces,
			rootsAndChannels,
			dentalFormulaDrodown,
			setDentalFormulaDropdown,
			SurfaceSVG,
			default_dental_formula_dropdown,
		}),
		[
			selected,
			manipulations,
			bridge_default,
			crown_default,
			implant_default,
			dentalFormula,
			event_default,
			referral_default,
			selectMany,
			selectManyAll,
			selectedItesm,
			selectedItesmAll,
			intermediateItems,
			default_dental_formula,
			empty_default,
			delation_default,
			milk_theeth_default,
			distalSurfaces,
			rootsAndChannels,
			dentalFormulaDrodown,
			default_dental_formula_dropdown,
		],
	);

	return <DentalContext.Provider value={value}>{children}</DentalContext.Provider>;
};

// Define PropTypes for DentalContextProvider component

// Export DentalContextProvider component
DentalContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DentalContext;
