import React, { SVGProps } from "react";

export const PRINT_TOOTH_12 = (props: SVGProps<SVGSVGElement>) => {
  return (<svg   width="67" height="100" viewBox="0 0 38 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M25.6 36.1063C23.8258 9.02043 16.0956 1.90955 13.0404 1.21166C9.98521 0.513768 12.6847 31.346 13.0404 38.2" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path d="M20.5667 33.2C28.5222 34.8078 27.1288 41.8211 24.7111 46.4912C23.3161 49.1858 22.855 50.6368 16.0768 50.0833C14.7682 50.0833 9.81266 48.9345 12.2775 39.3065C13.8152 33.3 17.9021 33.2 20.5667 33.2Z" stroke="black" strokeWidth="0.63"/>
</svg>
  )
}



