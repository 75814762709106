import React, { FC, useCallback } from 'react';
import Button from '../../../../../../components/bootstrap/Button';

interface IHeaderProps {
	values: any;
	isUserDoctor: boolean;
	setIsOpen(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	setUsersList(...args: unknown[]): unknown;
}

const TimePickerSave: FC<IHeaderProps> = ({ setUsersList, changeValues, setIsOpen, values }) => {
	const reset = useCallback(() => {
		changeValues('visitDate', '');
		changeValues('endDate', '');
		changeValues('startDate', '');
		setUsersList('');
		changeValues('userFullName', '');
		changeValues('userUuid', '');
	}, [changeValues, setUsersList]);
	return (
		<div className='d-flex flex-row col-12 gap-3 px-3'>
			<Button
				color='primary'
				className='col-12'
				onClick={() => {
					setIsOpen(false);
					if (
						values.userUuid &&
						!values.visitDate &&
						!values.endDate &&
						!values.startDate
					) {
						reset();
					}
				}}>
				Сохранить
			</Button>
		</div>
	);
};

export default TimePickerSave;
