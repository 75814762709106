import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';
import PaginationButtons from '../../../../../components/PaginationButtons';
import TableComponent from '../../../../components/tables/TableComponent';
import ClinicOrganistaionFilterComponent from './components/UI/FilterComponent/ClinicOrganistaionFilterComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import SubHeaderRightComponent from './SubHeaderComponent/SubHeaderRightComponent';
import AddClinicOrganisation from './AddClinicOrganisation';
import { ClinicOrganisationService } from '../../../../../custom/api/service/clinicOrganisations.service';
import { useMutation, useQueryClient } from 'react-query';
import { notification, NotificationArgsProps } from 'antd';
import StatusSubmitModal from '../StatusSubmitModal';
import ResetComponent from './components/UI/ResetComponent/ResetComponent';

type NotificationPlacement = NotificationArgsProps['placement'];

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	clinic_organisation: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
	sort: any;
	setSort: any;
}

const ClinicOrganisationTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		clinic_organisation,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
		sort,
		setSort,
	}) => {
		const [api, contextHolder] = notification.useNotification();

		const openNotification = (
			placement: NotificationPlacement,
			message: string,
			description: string,
		) => {
			api.info({
				message,
				description,
				placement,
			});
		};

		const [addClinicOrganisation, setAddClinicOrganisation] = useState(false);
		const [statusModal, setStatusModal] = useState(false);
		const [uuid, setUuid] = useState('');

		const tableHeaders = [
			{ header: 'Название', key: 'titleOrganisation' },
			{ header: 'Филиал', key: 'clinic' },
			{ header: 'Специализация', key: 'category' },
			{ header: 'Тип кабинета', key: 'officeType' },
			{ header: 'Ответственный', key: 'responibleUser' },
			{ header: 'Кол. коек', key: 'bedCount' },
			{ header: 'Действие', key: 'clinicOrganisationActions' },
		];
		const directionDropDownProps = {
			setUuid: setUuid,
			setAddClinicOrganisation: setAddClinicOrganisation,
			setStatusModal: setStatusModal,
			uuid: uuid,
		};

		const queryClient = useQueryClient();

		const reset = useCallback(() => {
			setValues({
				officeName: '',
				officeType: '',
				clinicUuid: '',
				categoryUuid: '',
				officeNumber: '',
				bedCount: '',
				phone: '',
				responibleUserUuid: '',
			});
			setFilter({
				clinic: '',
				officeType: '',
				category: '',
				responsibleUser: '',
			});
			setSort(false);
		}, [setFilter, setSort, setValues]);

		const { mutate: createOrganisation } = useMutation(
			({ inputDto }: { inputDto: any }) => ClinicOrganisationService.create(inputDto),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('clinic_organisations');
					queryClient.invalidateQueries('clinic_organisation');
					setValues({
						officeName: '',
						officeType: '',
						clinicUuid: '',
						categoryUuid: '',
						officeNumber: '',
						bedCount: '',
						phone: '',
						responibleUserUuid: '',
					});
					setUuid('');
					setAddClinicOrganisation(false);
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: updateOrganisation } = useMutation(
			({ inputDto, uuid }: { inputDto: any; uuid: string }) =>
				ClinicOrganisationService.update(uuid, inputDto),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('clinic_organisations');
					queryClient.invalidateQueries('clinic_organisation');
					setValues({
						officeName: '',
						officeType: '',
						clinicUuid: '',
						categoryUuid: '',
						officeNumber: '',
						bedCount: '',
						phone: '',
						responibleUserUuid: '',
					});
					setAddClinicOrganisation(false);
					setUuid('');
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: deleteOrganisation } = useMutation(
			({ uuid }: { uuid: string }) => ClinicOrganisationService.delete(uuid),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('clinic_organisations');
					queryClient.invalidateQueries('clinic_organisation');
					setValues({
						officeName: '',
						officeType: '',
						clinicUuid: '',
						categoryUuid: '',
						officeNumber: '',
						bedCount: '',
						phone: '',
						responibleUserUuid: '',
					});
					setAddClinicOrganisation(false);
					setUuid('');
					setStatusModal(false);
				},

				onError: (error: any) => {
					if (error.response.data.message === 'cant_create_whithout_office_name')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Название кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_office_type')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Тип кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_clinic')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без филиала',
						);

					if (error.response.data.message === 'cant_create_whithout_responible_user')
						openNotification(
							'bottomRight',
							'Важно !',
							'Вы не можете создать организацию для филиала без  ответственного  персонала',
						);
				},
			},
		);
		const handleSubmit = useCallback(
			(e: any, uuid?: string) => {
				e.preventDefault();
				if (uuid) {
					return updateOrganisation({ inputDto: values, uuid: uuid });
				}
				return createOrganisation({ inputDto: values });
			},
			[createOrganisation, updateOrganisation, values],
		);

		const handleSubmitDelete = useCallback(
			(e: any, uuid: string) => {
				e.preventDefault();
				deleteOrganisation({ uuid });
			},
			[deleteOrganisation],
		);

		const handleCancele = useCallback(() => {
			setValues({
				officeName: '',
				officeType: '',
				clinicUuid: '',
				categoryUuid: '',
				officeNumber: '',
				bedCount: '',
				phone: '',
				responibleUserUuid: '',
			});
			setAddClinicOrganisation(false);
			setUuid('');
			setStatusModal(false);
		}, [setValues]);
		useEffect(() => {
			if (
				uuid &&
				values.officeName === '' &&
				values.officeType === '' &&
				values.clinicUuid === '' &&
				values.categoryUuid === '' &&
				values.officeNumber === '' &&
				values.bedCount === '' &&
				values.phone === '' &&
				values.responibleUserUuid === ''
			) {
				setValues((prev: any) => {
					const data = clinic_organisation?.data.find((item: any) => item.uuid === uuid);
					return {
						...prev,
						officeName: data?.officeName ?? '',
						officeType: data?.officeType ?? '',
						clinicUuid: data?.clinicUuid ?? '',
						categoryUuid: data?.categoryUuid ?? '',
						officeNumber: data?.officeNumber ?? '',
						bedCount: data?.bedCount ?? '',
						phone: data?.phone ?? '',
						responibleUserUuid: data?.responibleUserUuid ?? '',
						...(data?.responibleUser2Uuid && {
							responibleUser2Uuid: data?.responibleUser2Uuid ?? '',
						}),
					};
				});
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [uuid]);

		return (
			<>
				<Card className={'col-12'} stretch={isFluid}>
					{contextHolder}
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						<SubHeaderRightComponent
							setStatusModal={setStatusModal}
							handleSubmit={handleSubmit}
							uuid={uuid}
							addClinicOrganisation={addClinicOrganisation}
							setAddClinicOrganisation={setAddClinicOrganisation}
							handleCancele={handleCancele}
						/>
					</div>
					{!addClinicOrganisation && (
						<div className='table-row-header '>
							<div className='d-flex flex-row align-items-center w-full grow'>
								<ClinicOrganistaionFilterComponent
									filter={filter}
									setFilter={setFilter}
								/>
							</div>
							<div>
								<ResetComponent reset={reset} />
							</div>
						</div>
					)}
					<CardBody className='table-responsive' isScrollable={isFluid}>
						{!addClinicOrganisation ? (
							<TableComponent
								data={clinic_organisation?.data}
								headers={tableHeaders}
								directionDropDownProps={directionDropDownProps}
								visitDropDownProps={undefined}
							/>
						) : (
							<AddClinicOrganisation setValues={setValues} values={values} />
						)}
					</CardBody>
					{!addClinicOrganisation && (
						<PaginationButtons
							data={clinic_organisation || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(clinic_organisation?.meta?.count / perPage) || 1}
						/>
					)}
				</Card>
				<StatusSubmitModal
					setIsOpen={setStatusModal}
					isOpen={statusModal}
					handleSubmitDelete={handleSubmitDelete}
					uuid={uuid}
				/>
			</>
		);
	},
);

export default ClinicOrganisationTable;
