import React, { SVGProps } from "react";

export const PRINT_TOOTH_36 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 20 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="n 19" {...props}>
<path id="Vector 66" d="M1.46724 15.2083C3.46908 45.5736 0.297666 49.9883 1.46697 51.12C2.8734 53.9477 5.72894 47.523 7.97387 39.8536M18.0137 13.8C18.415 21.4839 18.8808 42.6093 11.1193 47.9513C9.9871 48.7306 10.7218 44.7316 11.2931 40.3258M11.2931 40.3258C11.8823 35.7829 12.2979 30.8075 10.314 30.1926C9.73959 33.2424 8.9098 36.6562 7.97387 39.8536M11.2931 40.3258C8.11507 45.6661 7.27234 45.9033 7.97387 39.8536" stroke="black" strokeWidth="0.7" strokeLinecap="round"/>
<path id="Vector 24" d="M2.69668 4.65763C5.0284 0.455651 7.94282 3.25675 9.10869 5.59118C10.566 5.59118 13.1529 1.8 14.6103 1.8C16.0676 1.8 21.1442 4.30516 18.3993 14.8728C16.6869 21.4656 12.659 23.8 9.69173 23.8C0.832407 22.0352 -0.314022 10.0832 2.69668 4.65763Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}

