import React, { FC, memo } from 'react';
import UserInfo from './UserInfo';

interface IUserHeaderProps {
	setAddParent(...args: unknown[]): unknown;
	user: any;
}

const UserHeader: FC<IUserHeaderProps> = memo(({ setAddParent, user }) => {
	return (
		<>
			<UserInfo user={user} />
		</>
	);
});

export default UserHeader;
