import React, { FC } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';

interface IStatusSubmitModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    submit(...args: unknown[]): unknown;
}

const DeletionSubmit: FC<IStatusSubmitModalProps> = ({ isOpen, setIsOpen, submit }) => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submit();
        setIsOpen(false);
    };

    return (
        <>
            <Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen} modalLevel={1060}>
                <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                    <span className='fs-5 fw-bold'>
                        <Icon icon='Delete' className='me-2 fs-2' color='primary' />
                        Подтверждение удаления
                    </span>
                </ModalHeader>
                <form onSubmit={handleSubmit}>
                    <ModalBody className='px-4'>
                        <>
                            <p className='fs-4 '>Вы уверены что хотите удалить время филифл?</p>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex flex-row col-12 gap-3 px-3'>
                            <Button
                                color='danger'
                                className='col-6'
                                onClick={() => {
                                    setIsOpen(false);
                                }}>
                                Нет
                            </Button>
                            <Button color={'success'} type='submit' className='col-6'>
                                Да
                            </Button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default DeletionSubmit;
