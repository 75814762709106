import React, { useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { demoPagesMenu } from '../../../menu';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { ConfigService } from '../../../custom/api/service/config.service';
import PaginationButtons, {
	dataPagination,
	PER_COUNT_FILTER,
} from '../../../components/PaginationButtons';
import ConfigComponent from './ConfigComponent';
import EditConfigModal from './EditConfigModal';
import SubHeader, { SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import Icon from '../../../components/icon/Icon';
import Input from '../../../components/bootstrap/forms/Input';

const ConfigurationPage = () => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [editConfig, setEditConfig] = useState<boolean>(false);
	const [config, setConfig] = useState(null);
	const [search, setSearch] = useState('');
	const [perPage, setPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const { data: configs } = useQuery<any>(['configs', currentPage, perPage], () =>
		ConfigService.findAll(
			qs.stringify({
				queryMeta: {
					order: { createdAt: 'DESC' },
				},
			}),
		),
	);
	return (
		<PageWrapper title={demoPagesMenu.listPages.subMenu.listFluid.text}>
			<Page container='fluid' className='d-flex flex-column gap-3 align-items-center'>
				{/* <SubHeader>
					<SubHeaderLeft>
						<label className='border-0 bg-transparent cursor-pointer'>
							<Icon icon='Search' size='2x' color='primary' />
						</label>
						<label
							className='border-0 bg-transparent cursor-pointer me-0'
							htmlFor='searchInput'>
							<Icon icon='SearchUser' size='2x' color='primary' />
						</label>
						<Input
							value={search}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setSearch(e.target.value)
							}
							id='searchInput'
							type='search'
							className='border-0 shadow-none bg-transparent'
							placeholder={'поиск по описанию запроса'}
						/>
					</SubHeaderLeft>
				</SubHeader> */}
				{configs?.data?.length > 0 &&
					dataPagination(configs?.data, currentPage, perPage)?.map(
						(item: any, index: number) => (
							<div
								key={`${index}-${item?.controller}`}
								className='col-11 d-flex flex-column gap-3'>
								<span className='fs-3 fw-bold'>
									{item?.controller.toUpperCase()}
								</span>
								{item?.data?.map((item: any, index: number) => (
									<ConfigComponent
										key={item.uuid}
										setConfig={setConfig}
										index={index}
										config={item}
										setEditConfig={setEditConfig}
									/>
								))}
							</div>
						),
					)}
				{configs?.data?.length > 0 ? (
					<PaginationButtons
						data={configs?.data || []}
						label='customers'
						small
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
						totalPage={Math.ceil(configs?.data?.length / perPage) || 1}
					/>
				) : (
					<></>
				)}
			</Page>
			<EditConfigModal
				config={config}
				setConfig={setConfig}
				isOpen={editConfig}
				setIsOpen={setEditConfig}
				id={''}
			/>
		</PageWrapper>
	);
};

export default ConfigurationPage;
