import React, { SVGProps } from "react";

export const PRINT_TOOTH_45 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 18 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M16.962 14.4275C15.1774 41.4852 18.0044 44.7915 16.962 45.8C10.0429 41.0398 1.8536 20.6469 2.2114 13.8" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M9.54791 20.8C17.326 19.2101 18.7758 8.14638 15.6893 3.55505C14.0283 1.08423 11.8566 0.433399 5.22963 0.980674C3.95015 0.980674 -0.506897 3.23751 1.90297 12.7577C3.40639 18.697 6.94278 20.8 9.54791 20.8Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}

