import React, { FC, memo } from 'react';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import Button from '../../../../../components/bootstrap/Button';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Status from '../../../../../custom/components/tables/columns/Status';

interface IStatusFilterComponentProps {
	handleCheckboxChange(...args: unknown[]): unknown;
	setPopperIsOpenStatus(...args: unknown[]): unknown;
	popperIsOpenStatus: any;
	values: any;
	dynamicFont: any;
}

const StatusFilterComponent: FC<IStatusFilterComponentProps> = memo(
	({ values, setPopperIsOpenStatus, popperIsOpenStatus, handleCheckboxChange }) => {
		const { themeStatus, darkModeStatus } = useDarkMode();

		return (
			<Dropdown isOpen={popperIsOpenStatus} setIsOpen={setPopperIsOpenStatus}>
				<DropdownToggle>
					<Button
						style={{
							boxSizing: 'border-box',
							border:
								values?.statuses?.length > 0
									? '2px solid #1266f1'
									: `2px solid ${darkModeStatus ? '#212529' : '#f8f9fa'}`,
						}}
						color={themeStatus}
						className='text-nowrap me-2'>
						Статусу
					</Button>
				</DropdownToggle>
				<DropdownMenu className='py-4 px-2' style={{ width: '400px' }}>
					<div
						className='p-0'
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexWrap: 'wrap',
						}}>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='Задолженность'
								id='flexCheckDefault-3'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('Задолженность')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckDefault-3'>
								<Status status={'Задолженность'} />
							</label>
						</div>
						<div className='form-check col-10'>
							<input
								className='form-check-input'
								type='checkbox'
								value='Завершен'
								id='flexCheckChecked-4'
								onChange={(e: any) => handleCheckboxChange(e)}
								checked={values.statuses.includes('Завершен')}
								name='statuses'
							/>
							<label
								color={themeStatus}
								className='form-check-label'
								htmlFor='flexCheckChecked-4'>
								<Status status='Завершен' />
							</label>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		);
	},
);
export default StatusFilterComponent;
