import React, { FC, useEffect, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import { IPatientCreate } from '@textnpayme/custom/api/interface/patient.interface';
import NewPatientSecondStep from './steps/NewPatientSecondStep';
import { ValidatedInput } from '../../../../custom/components/inputs/ValidatedInput';
import PhoneInput from 'react-phone-input-2';

const phoneMask = /^\+?\d{1,3}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,3}[-.\s]?\d{1,3}[-.\s]?\d{3,7}$/;
const nameMask = /^[A-Za-zА-Яа-яЁёԱ-Ֆա-ֆ-]{3,}$/;

interface INewPatientVisitModalProps {
	newPatientIsOpen: boolean;
	patientCreated: boolean;
	setPatientCreatedId(...args: unknown[]): unknown;
	setIsNewPatientChoosen(...args: unknown[]): unknown;
	setPatientCreated(...args: unknown[]): unknown;
	setNewPatientIsOpen(...args: unknown[]): unknown;
	setCreateVisit(...args: unknown[]): unknown;
	setIsPatientChoosen(...args: unknown[]): unknown;
	createVisit: boolean;
}
const NewPatientVisitModal: FC<INewPatientVisitModalProps> = ({
	setIsNewPatientChoosen,
	newPatientIsOpen,
	setCreateVisit,
	setIsPatientChoosen,
	setNewPatientIsOpen,
	patientCreated,
	setPatientCreated,
	setPatientCreatedId,
	createVisit,
}) => {
	const [countryData, setCountryData] = useState<{ dialCode?: string; format?: string } | null>(
		null,
	);
	const [hasInteracted, setHasInteracted] = useState(false);
	const [patientNext, setPatientNext] = useState(true);
	const [isFormValid, setIsFormValid] = useState(false);
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setPatientNext(false);
		setIsNewPatientChoosen(true);
	};

	const [values, setValues] = useState<IPatientCreate>({
		phone: '+374',
		name: '',
		surname: '',
		middleName: '',
		know: '',
	});

	const changeValues = (key: string, value: string | number) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	};

	const handleChange = (value: any, data: any) => {
		changeValues('phone', value);
		setCountryData(data);
	};
	const validatePhoneNumber = () => {
		if (countryData && values?.phone) {
			const { format } = countryData;
			const numericFormat = format?.replace(/[()\-+ ]/g, '');
			const expectedLength = numericFormat?.length;
			const numericInput = values?.phone.replace(/[()\-+ ]/g, '');
			return numericInput.length === expectedLength;
		}
		return false;
	};
	useEffect(() => {
		if (values.name && values.surname && values.middleName) {
			const isValid =
				validatePhoneNumber() &&
				values.name.match(nameMask) &&
				values.surname.match(nameMask) &&
				values.middleName.match(nameMask);
			setIsFormValid(Boolean(isValid));
		}
	}, [values]);

	const handleFocus = () => {
		setHasInteracted(true);
	};

	return (
		<>
			{(patientNext && (
				<Modal
					isOpen={newPatientIsOpen}
					isStaticBackdrop
					setIsOpen={setNewPatientIsOpen}
					type={'md'}
					isCentered
					modalLevel={1060}>
					<ModalHeader setIsOpen={setNewPatientIsOpen} className='p-4'>
						<ModalTitle id='title' style={{ fontSize: '1.75rem' }}>
							Новый пациент
						</ModalTitle>
					</ModalHeader>
					<form onSubmit={handleSubmit}>
						<ModalBody className='px-4 d-flex flex-column gap-4'>
							<ValidatedInput
								id='surname'
								label='Фамилия'
								changeValues={changeValues}
								value={values.surname}
								validationMask={nameMask}
							/>
							<ValidatedInput
								id='name'
								label='Имя'
								changeValues={changeValues}
								value={values.name}
								validationMask={nameMask}
							/>
							<ValidatedInput
								id='middleName'
								label='Отчество'
								changeValues={changeValues}
								value={values.middleName}
								validationMask={nameMask}
							/>
							<div>
								<PhoneInput
									onFocus={handleFocus}
									// containerClass='form-control'
									inputClass='form-control'
									inputStyle={{
										// borderRadius: 30,
										backgroundColor: '#f8f9fa',
										fontSize: '1rem',
										fontWeight: 600,
										lineHeight: 1.5,

										borderRadius: '1rem',
										border: '1px solid #f8f9fa',
										width: '100%',
										boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
										transition:
											'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out',
									}}
									containerStyle={{
										borderRadius: 30,
										width: '100%',
										display: 'flex',
										alignItems: 'center',
									}}
									isValid={validatePhoneNumber}
									buttonStyle={{
										borderRadius: 30,
										border: 'none',
										backgroundColor: 'inherit',
										height: 20,
										marginTop: 7,
										marginLeft: 4,
										// backgroundColor: 'inherit',
										// pointerEvents: 'none',
									}}
									country={'am'}
									value={values?.phone?.replace(/[()\-+ ]/g, '')}
									onChange={(value, data) => handleChange(`+${value}`, data)}
									enableSearch={false}
									countryCodeEditable={true}
									showDropdown={false}
								/>
								{hasInteracted && !validatePhoneNumber() && (
									<div style={{ color: 'red' }}>
										Номер телефона не может быть пустым.
									</div>
								)}
							</div>
							
						</ModalBody>
						<ModalFooter className='px-4 pb-4 '>
							<Button
								color='info'
								type='submit'
								className='col-12'
								isDisable={!isFormValid}
								isOutline={!isFormValid}>
								Cоздать нового пациента
							</Button>
						</ModalFooter>
					</form>
				</Modal>
			)) || (
				<NewPatientSecondStep
					setValues={setValues}
					changeValues={changeValues}
					values={values}
					setCreateVisit={setCreateVisit}
					setPatientNext={setPatientNext}
					setNewPatientIsOpen={setNewPatientIsOpen}
					setIsPatientChoosen={setIsPatientChoosen}
					setPatientCreated={setPatientCreated}
					setIsNewPatientChoosen={setIsNewPatientChoosen}
					setPatientCreatedId={setPatientCreatedId}
				/>
			)}
		</>
	);
};

export default NewPatientVisitModal;
