import React, { SVGProps } from "react";

export const PRINT_TOOTH_47 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 19 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="n 19" {...props}>
<path id="Vector 66" d="M17.4001 15.5129C15.3434 45.3072 18.057 49.9943 16.8556 51.1048C14.8082 55.036 8.55968 41.4952 8.31076 30.5752C6.37316 33.1501 10.9688 51.7843 8.6061 50.2312C0.631896 44.9896 0.0870564 21.3394 0.499416 13.8" stroke="black" strokeWidth="0.7"/>
<path id="Vector 136" d="M12.256 45.8C12.7554 49.9741 11.9939 49.622 9.40015 45.8" stroke="black" strokeWidth="0.35"/>
<path id="Vector 24" d="M16.9034 4.65763C14.5717 0.455651 11.6573 3.25675 10.4914 5.59118C9.03407 5.59118 6.44717 1.8 4.98983 1.8C3.53249 1.8 -1.5441 4.30516 1.20075 14.8728C2.91315 21.4656 6.94112 23.8 9.90837 23.8C18.7677 22.0352 19.9141 10.0832 16.9034 4.65763Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}

