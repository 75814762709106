import React, { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { demoPagesMenu } from '../../../menu';
import FinancesTable from './FinancesTable';
import PlanVisitModal from '../visit/planVisit/PlanVisitModal';
import SubHeaderRightCompnent from './ShubHeaderComponent/SubHeaderRightCompnent';
import SubHeaderLeftComponent from './ShubHeaderComponent/SubHeaderLeftComponent';
import { formatDate } from '../../../utils/functions';


const FinancesPage = () => {
	const [allDayButton, setAllDayButton] = useState<boolean>(false);
	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
	const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
	const [dateDay, setDateDay] = useState<any>({});
	const [dateTimePicker, setDateTimePicker] = useState<[Dayjs | null, Dayjs | null] | null>();

	useEffect(() => {
		const savedAllDay = localStorage.getItem('allDay') || 'false';
		const initialAllDay = JSON.parse(savedAllDay);
		setAllDayButton(initialAllDay);
	}, []);


	useEffect(() => {
		const savedDate = localStorage.getItem('dateOfVisit');
		const initialDate = savedDate ? new Date(JSON.parse(savedDate)) : handleGetTodayesDate();
		setDateDay(formatDate(initialDate));
	}, []);

	useEffect(() => {
		const savedDatePickerDays = localStorage.getItem('datePicker');
		const initialDatePickerDays: [Dayjs | null, Dayjs | null] | null = savedDatePickerDays
			? (JSON.parse(savedDatePickerDays)?.map((date: string | null) =>
					date ? dayjs(date) : null,
			  ) as [Dayjs | null, Dayjs | null])
			: null;
		setDateTimePicker(initialDatePickerDays);
	}, []);

	const handleGetTodayesDate = () => {
		const dateNow = new Date();
		dateNow.setHours(4, 0, 0, 0);
		return new Date(dateNow.getTime());
	};

	const setAndSaveDate = useCallback((date: Date | null) => {
		if (date) {
			setDateDay(formatDate(date));
		} else {
			setDateDay(null);
		}
		if (date?.getTime() !== handleGetTodayesDate().getTime()) {
			setDateTimePicker(null);
			localStorage.setItem('datePicker', JSON.stringify(null));
		}
		localStorage.setItem('dateOfVisit', JSON.stringify(date));
	}, []);

	const setAndSaveDateTimePicker = useCallback(
		(days: [Dayjs | null, Dayjs | null] | null) => {
			setDateTimePicker(days);
			days && setAndSaveDate(handleGetTodayesDate());
			localStorage.setItem('datePicker', JSON.stringify(days));
		},
		[setAndSaveDate],
	);

	const setAndSaveAllDayButton = useCallback(
		(allDay: boolean) => {
			setAllDayButton(allDay);
			localStorage.setItem('allDay', JSON.stringify(allDay));
			if (dateDay) {
				setAndSaveDate(handleGetTodayesDate());
			}
			if (dateTimePicker) {
				localStorage.setItem('allDay', 'false');
			}
		},
		[dateDay, dateTimePicker, setAndSaveDate],
	);

	return (
		<PageWrapper title={demoPagesMenu.listPages.subMenu.listFluid.text}>
			<div
				className='subheader mt-1 pt-2 pb-2 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
				style={{ maxWidth: 'unset' }}>
				<SubHeaderLeftComponent
						dateDay={dateDay}
						allDayButton={allDayButton}
						setAndSaveDateTimePicker={setAndSaveDateTimePicker}
						setAndSaveAllDayButton={setAndSaveAllDayButton}
						setDatePickerOpen={setDatePickerOpen}
						datePickerOpen={datePickerOpen}
						setAndSaveDate={setAndSaveDate}
						handleGetTodayesDate={handleGetTodayesDate}
						dateTimePicker={dateTimePicker}
				/>
				<SubHeaderRightCompnent />
			</div>
			<Page container='fluid'>
				<FinancesTable
					setDateDay={setDateDay}
					dateDay={dateDay}
					dateInterval={dateTimePicker}
					setDateInterval={setAndSaveDateTimePicker}
					allDayButton={allDayButton}
					setAllDayButton={setAndSaveAllDayButton}
					isFluid
				/>
			</Page>
			<PlanVisitModal
				setIsOpen={setUpcomingEventsEditOffcanvas}
				isOpen={upcomingEventsEditOffcanvas}
			/>
		</PageWrapper>
	);
};

export default FinancesPage;
