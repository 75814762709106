import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import PaginationButtons, {
	dataPagination,
	PER_COUNT_FILTER,
} from '../../../../../components/PaginationButtons';
import { IService } from '../../../../../custom/api/interface/service.interface';
import { ServicesList } from '../../components/ServicesList';
import useDarkMode from '../../../../../hooks/useDarkMode';
import { StagaeServiceService } from '../../../../../custom/api/service/stage-visit-service.service';
import { useQuery } from 'react-query';
import { itemIndexInArray } from '../../../../../utils/functions';

interface IServicesProps {
	setServices(...args: unknown[]): unknown;
	setChosenService(...args: unknown[]): unknown;
	setLocalChosenService(...args: unknown[]): unknown;
	localChoosenServices: any;
	choosenServices: any;
	choosenServicesData: any;
	createVisit: any;
	isStage: any;
	medicalCard: any;
	stage: any;
	handleCheckboxChange(...args: unknown[]): unknown;
	setChosenServicesCount(...args: unknown[]): unknown;
	setIsNoServices(...args: unknown[]): unknown;
	setDisplayedServices(...args: unknown[]): unknown;
	displayedServices: any;
}

const Services: FC<IServicesProps> = memo(
	({
		setServices,
		choosenServicesData,
		handleCheckboxChange,
		choosenServices,
		setChosenService,
		localChoosenServices,
		setLocalChosenService,
		displayedServices,
		setDisplayedServices,
		setChosenServicesCount,
		setIsNoServices,
		medicalCard,
		stage,
		createVisit,
		isStage,
	}) => {
		const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
		const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER[5]);
		const [arryLength, setArrayLength] = useState<number>(0);

		const { themeStatus } = useDarkMode();
		const isItemInArray = useCallback(
			(item: any) => {
				return choosenServicesData.some((service: any) => {
					if (service.uuid === item.uuid) {
						return service.event_uuid === item.event_uuid;
					}
					return false;
				});
			},
			[choosenServicesData],
		);

		const { data: services } = useQuery<any>(
			['services', servicesCurrentPage, servicesPerPage, isStage, stage, medicalCard],
			() => StagaeServiceService.findAllFreeServices(stage),
			{
				enabled: !!stage,
				keepPreviousData: true,
				refetchOnWindowFocus: false,
				onSuccess(data) {
					if (data?.length > 0) {
						const data_copy = data;
						const paginatedData = dataPagination(
							data_copy,
							servicesCurrentPage,
							servicesPerPage,
						);						
						setDisplayedServices(JSON.parse(JSON.stringify([...paginatedData])));
					}
				},
			},
		);

		useEffect(() => {
			const totalPages = Math.ceil(services?.length / servicesPerPage) || 1;
			setArrayLength(totalPages);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [services, servicesPerPage]);

		useEffect(() => {
			if (services?.length === servicesPerPage) {
				setServicesCurrentPage(1);
			}
		}, [services?.length, servicesCurrentPage, servicesPerPage]);
		// useEffect(() => {
		// 	if (services?.length > 0) {
		// 		setLocalChosenService((prev: any) => {
		// 			return [...services];
		// 		});
		// 		setServices(services);

		// 		setChosenService((prev: any) => {
		// 			return [...services];
		// 		});
		// 	}
		// }, [services, setChosenService, setLocalChosenService, setServices]);

		useEffect(() => {
			setIsNoServices(services?.length > 0 ? false : true);
			setChosenServicesCount(choosenServicesData?.length);
		}, [
			services?.length,
			choosenServicesData?.length,
			setIsNoServices,
			setChosenServicesCount,
		]);

		// useEffect(() => {
		// 	if (services?.length > 0) {
		// 		const paginatedData = dataPagination(
		// 			services,
		// 			servicesCurrentPage,
		// 			servicesPerPage,
		// 		);
		// 		if (paginatedData.length > 0) {
		// 			setDisplayedServices(paginatedData);
		// 		}
		// 	}
		// }, [services, servicesCurrentPage, servicesPerPage]);
		
		return (
			<div className='col-5 d-flex flex-column gap-2' style={{ width: '48%' }}>
				<span className='fs-5 fw-bold'>Список Услуг</span>
				<div className='col-12 d-flex flex-column h-100 justify-content-between pb-5'>
					<div className='d-flex flex-column flex-wrap col-12 gap-2'>
						{services?.length ? (
							displayedServices?.map((i: IService, index: number) => (
								<ServicesList
									key={`${index}-${i.serviceId}`}
									localChosenServices={localChoosenServices}
									setLocalChosenServices={setLocalChosenService}
									type={i.service_type}
									chosenServices={choosenServices}
									setChosenServices={setChosenService}
									index={itemIndexInArray(services, i)}
									disable
									item={{
										...i,
										count: '1',
										amount: i.price,
									}}
									isDisabled={
										!createVisit &&
										!isStage &&
										services.data
											.map((item: any) => item.service)
											?.some(
												(item: any) =>
													item?.services.some(
														(service: any) => service?.uuid === i?.uuid,
													),
											)
									}
									isChecked={isItemInArray(i)}
									handleCheckboxChange={handleCheckboxChange}
									themeStatus={themeStatus}
								/>
							))
						) : (
							<h4>Врач не выбрал услуги</h4>
						)}
					</div>

					<div className='col-12'>
						{services?.length > 0 && (
							<PaginationButtons
								data={displayedServices || []}
								label='customers'
								className='card-Services'
								filterPagination
								setCurrentPage={setServicesCurrentPage}
								currentPage={servicesCurrentPage}
								perPage={servicesPerPage}
								setPerPage={setServicesPerPage}
								totalPage={arryLength}
							/>
						)}
					</div>
				</div>
			</div>
		);
	},
);

export default Services;
