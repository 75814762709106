import React, { FC, useState } from 'react';

import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import { roles, rolesTranslate } from '../../../utils/dataUtil';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import classNames from 'classnames';

interface IConfigProps {
	index: number;
	config: any;
	setEditConfig(...args: unknown[]): unknown;
	setConfig(...args: unknown[]): unknown;
}

const ConfigComponent: FC<IConfigProps> = ({ index, config, setEditConfig, setConfig }) => {
	const [dropdownClose, setDropdownClose] = useState<boolean>(false);
	const { darkModeStatus, themeStatus } = useDarkMode();

	return (
		<Card
			key={`${config.id}-Card-1-${index}`}
			className={`w-100 px-4 pt-0 m-0 d-flex justify-content-between align-items-center rounded-1`}>
			<CardHeader
				key={`${config.id}-Card-2-${index}`}
				className={`h5 d-flex justify-content-between align-items-center w-100 m-0 px-0 py-0 bg-card p-0 `}>
				<div
					key={`${config.id}-div-1-${index}`}
					className='d-flex align-items-center gap-4'>
					<p key={`${config.id}-p-1-${index}`} className='d-flex flex-column m-0'>
						{config.value.operation} - {config.value.description ?? 'description'}
						<span
							key={`${config.id}-span-2-${index}`}
							className='text-muted fs-sm'></span>
					</p>
					<div className='d-flex justify-content-start flex-wrap'>
						{config.value.access.map((role: string, role_index: number) => (
							<div
								className='form-check m-0'
								style={{
									border: 'unset',
									minWidth: 120,
								}}>
								<input
									disabled
									color={themeStatus}
									className='form-check-input'
									style={{
										top: 7,
										right: 0,
										left: '2rem',
									}}
									type='checkbox'
									name='services'
									checked={true}
									id={`${config.controller}_${role_index}_${index}`}
								/>
								<label
									aria-disabled
									className=' col-12 d-flex gap-3 p-2 bg-transparent border-0 border-bottom-0'
									htmlFor={`${config.controller}_${role_index}_${index}`}>
									<span
										className='d-flex m-0 ms-4 ps-2 text-truncate'
										style={{ width: '100%' }}>
										{rolesTranslate[role]}
									</span>
								</label>
							</div>
						))}
					</div>
				</div>
				<div
					key={`${config.id}-div-2-${index}`}
					className='d-flex align-items-center gap-2'>
					<Dropdown isOpen={dropdownClose} setIsOpen={setDropdownClose}>
						<DropdownToggle hasIcon={false}>
							<Button
								isOutline={!darkModeStatus}
								color='dark'
								isLight={darkModeStatus}
								aria-label='More actions'
								className={classNames(
									'text-nowrap',

									{
										'border-light': !darkModeStatus,
									},
									'fw-normal',
									'fs-6',
								)}>
								•••
							</Button>
						</DropdownToggle>
						<DropdownMenu className='fw-bold'>
							<>
								<DropdownItem>
									<Button
										onClick={() => {
											setEditConfig(true);
											setDropdownClose(false);
											setConfig(config);
										}}>
										Редактировать
									</Button>
								</DropdownItem>
							</>
						</DropdownMenu>
					</Dropdown>
				</div>
			</CardHeader>
			{/* <CardBody className='col-12'>
				<div className='col-12'>
					<span className='fs-4 fw-bold'>Доступы</span>
					<div className='d-flex justify-content-start col-12 flex-wrap'>
						{config.value.access.map((role: string, role_index: number) => (
							<div
								className='form-check m-0'
								style={{
									border: 'unset',
									minWidth: 120,
								}}>
								<input
									disabled
									color={themeStatus}
									className='form-check-input'
									style={{
										top: 7,
										right: 0,
										left: '2rem',
									}}
									type='checkbox'
									name='services'
									checked={true}
									id={`${config.controller}_${role_index}_${index}`}
								/>
								<label
									aria-disabled
									className=' col-12 d-flex gap-3 p-2 bg-transparent border-0 border-bottom-0'
									htmlFor={`${config.controller}_${role_index}_${index}`}>
									<span
										className='d-flex m-0 ms-4 ps-2 text-truncate'
										style={{ width: '100%' }}>
										{rolesTranslate[role]}
									</span>
								</label>
							</div>
						))}
					</div>
				</div>
			</CardBody> */}
		</Card>
	);
};

export default ConfigComponent;
