import React, { FC, memo } from 'react';
import { SubHeaderLeft } from '../../../../../../layout/SubHeader/SubHeader';
import { CardLabel, CardTitle } from '../../../../../../components/bootstrap/Card';
interface ISubHeaderLeftComponentProps {
	addGroup?: boolean;
}

const SubHeaderLeftComponent: FC<ISubHeaderLeftComponentProps> = memo(({ addGroup }) => {
	return (
		<SubHeaderLeft className='col-12 d-flex flex-nowrap align-items-center'>
			<CardLabel
				className='d-flex justify-content-between'
				icon={addGroup ? 'Medication' : 'Cases'}
				iconColor='info'>
				<CardTitle tag='div' className='fs-5'>
					{addGroup ? 'Группы материалов' : 'Материалы'}
				</CardTitle>
			</CardLabel>
		</SubHeaderLeft>
	);
});
export default SubHeaderLeftComponent;
