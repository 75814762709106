import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';
import PaginationButtons, { dataPagination } from '../../../../../components/PaginationButtons';
import TableComponent from '../../../../components/tables/TableComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import SubHeaderRightComponent from './SubHeaderComponent/SubHeaderRightComponent';
import AddSubCategory from './AddSubCategory';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import { useMutation, useQueryClient } from 'react-query';
import { notification, NotificationArgsProps } from 'antd';
import StatusSubmitModal from '../StatusSubmitModal';

type NotificationPlacement = NotificationArgsProps['placement'];

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	subCategory: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
	sort: any;
	setSort: any;
	categories: any;
}

const SubCategoryTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		subCategory,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
		sort,
		setSort,
		categories,
	}) => {
		const [api, contextHolder] = notification.useNotification();

		const openNotification = (
			placement: NotificationPlacement,
			message: string,
			description: string,
		) => {
			api.info({
				message,
				description,
				placement,
			});
		};

		const [addSubCategory, setAddSubCategory] = useState(false);
		const [statusModal, setStatusModal] = useState(false);
		const [uuid, setUuid] = useState('');

		const tableHeaders = [
			{ header: 'Название Ru', key: 'name_rus' },
			{ header: 'Название Arm ', key: 'name_arm' },
			{ header: 'Название En', key: 'name_en' },
			{ header: 'Специализация', key: 'categoryForSubCategory' },
			{ header: 'Действие', key: 'clinicOrganisationActions' },
		];
		const directionDropDownProps = {
			setUuid: setUuid,
			setAddSubCategory: setAddSubCategory,
			setStatusModal: setStatusModal,
			uuid: uuid,
		};

		const queryClient = useQueryClient();

		const { mutate: createOrganisation } = useMutation(
			({ inputDto }: { inputDto: any }) => CategoryService.create(inputDto),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('categoriesWithSubCategories');
					queryClient.invalidateQueries('SubCategory');
					setValues({
						categories: [
							{
								name: {
									en: '',
									arm: '',
									rus: '',
								},
								categoryUuid: '',
							},
						],
						name: {
							en: '',
							arm: '',
							rus: '',
						},
						categoryUuid: '',
					});
					setUuid('');
					setAddSubCategory(false);
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: updateOrganisation } = useMutation(
			({ inputDto, uuid }: { inputDto: any; uuid: string }) => {
				return CategoryService.update(uuid, inputDto);
			},
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('categoriesWithSubCategories');
					queryClient.invalidateQueries('SubCategory');
					setValues({
						categories: [
							{
								name: {
									en: '',
									arm: '',
									rus: '',
								},
								categoryUuid: '',
							},
						],
						name: {
							en: '',
							arm: '',
							rus: '',
						},
						categoryUuid: '',
					});
					setAddSubCategory(false);
					setUuid('');
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: deleteOrganisation } = useMutation(
			({ uuid }: { uuid: string }) => CategoryService.delete(uuid),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('categoriesWithSubCategories');
					queryClient.invalidateQueries('SubCategory');
					setValues({
						categories: [
							{
								name: {
									en: '',
									arm: '',
									rus: '',
								},
								categoryUuid: '',
							},
						],
						name: {
							en: '',
							arm: '',
							rus: '',
						},
						categoryUuid: '',
					});
					setAddSubCategory(false);
					setUuid('');
					setStatusModal(false);
				},

				onError: (error: any) => {
					if (error.response.data.message === 'cant_create_whithout_office_name')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Название кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_office_type')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Тип кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_clinic')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без филиала',
						);

					if (error.response.data.message === 'cant_create_whithout_responible_user')
						openNotification(
							'bottomRight',
							'Важно !',
							'Вы не можете создать организацию для филиала без  ответственного  персонала',
						);
				},
			},
		);
		const handleSubmit = useCallback(
			(e: any, uuid?: string) => {
				e.preventDefault();
				if (uuid) {
					console.log({ inputDto: values, uuid: uuid }, 'kkkjjjhhhihi');
					return updateOrganisation({ inputDto: values, uuid: uuid });
				}
				return createOrganisation({ inputDto: values });
			},
			[createOrganisation, updateOrganisation, values],
		);

		const handleSubmitDelete = useCallback(
			(e: any, uuid: string) => {
				e.preventDefault();
				deleteOrganisation({ uuid });
			},
			[deleteOrganisation],
		);
		useEffect(() => {
			if (
				uuid &&
				values?.name?.en === '' &&
				values?.name?.arm === '' &&
				values?.name?.rus === '' &&
				values?.categoryUuid === ''
			) {
				setValues((prev: any) => {
					const data = subCategory?.data.find((item: any) => item.uuid === uuid);
					return {
						categories: [
							{
								name: {
									en: '',
									arm: '',
									rus: '',
								},
								categoryUuid: '',
							},
						],
						name: {
							en: data?.name?.en,
							arm: data?.name?.arm,
							rus: data?.name?.rus,
						},
						categoryUuid: data?.categoryUuid,
					};
				});
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [uuid]);
		const handleCancele = useCallback(() => {
			setValues({
				categories: [
					{
						name: {
							en: '',
							arm: '',
							rus: '',
						},
						categoryUuid: '',
					},
				],
				name: {
					en: '',
					arm: '',
					rus: '',
				},
				categoryUuid: '',
			});
			setAddSubCategory(false);
			setUuid('');
			setStatusModal(false);
		}, [setValues]);
		return (
			<>
				<Card
					className={addSubCategory ? 'col-8' : 'col-12'}
					stretch={addSubCategory ? false : 'full'}>
					{contextHolder}
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						<SubHeaderRightComponent
							setStatusModal={setStatusModal}
							handleSubmit={handleSubmit}
							uuid={uuid}
							addSubCategory={addSubCategory}
							setAddSubCategory={setAddSubCategory}
							handleCancele={handleCancele}
						/>
					</div>
					<CardBody
						className='table-responsive'
						isScrollable={addSubCategory ? false : true}>
						{!addSubCategory ? (
							<TableComponent
								data={dataPagination(subCategory?.data, currentPage, perPage)}
								headers={tableHeaders}
								directionDropDownProps={directionDropDownProps}
								visitDropDownProps={undefined}
							/>
						) : (
							<AddSubCategory
								categories={categories}
								setValues={setValues}
								values={values}
								uuid={uuid}
							/>
						)}
					</CardBody>
					{!addSubCategory && (
						<PaginationButtons
							data={subCategory || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(subCategory?.data?.length / perPage) || 1}
						/>
					)}
				</Card>
				<StatusSubmitModal
					setIsOpen={setStatusModal}
					isOpen={statusModal}
					handleSubmitDelete={handleSubmitDelete}
					uuid={uuid}
				/>
			</>
		);
	},
);

export default SubCategoryTable;
