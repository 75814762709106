import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import * as qs from 'qs';

import { CardBody } from '../../../../components/bootstrap/Card';
import Card, { CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import Chart, { IChartOptions } from '../../../../components/extras/Chart';
import COLORS from '../../../../common/data/enumColors';
import useDarkMode from '../../../../hooks/useDarkMode';

interface ITableProps {}

const Table: FC<ITableProps> = ({}) => {
	const { darkModeStatus } = useDarkMode();
	const [dayHours] = useState<IChartOptions>({
		series: [
			{
				data: [8, 12, 15, 20, 15, 22, 9],
			},
		],
		options: {
			colors: [process.env.REACT_APP_SUCCESS_COLOR],
			chart: {
				type: 'radar',
				width: 200,
				height: 200,
				sparkline: {
					enabled: true,
				},
			},
			xaxis: {
				categories: [
					'Monday',
					'Tuesday',
					'Wednesday',
					'Thursday',
					'Friday',
					'Saturday',
					'Sunday',
				],
			},
			tooltip: {
				theme: 'dark',
				fixed: {
					enabled: false,
				},
				x: {
					show: true,
				},
				y: {
					title: {
						formatter() {
							return 'Hours';
						},
					},
				},
			},
			stroke: {
				curve: 'smooth',
				width: 2,
			},
			plotOptions: {
				radar: {
					polygons: {
						strokeColors: `${COLORS.SUCCESS.code}50`,
						strokeWidth: '1',
						connectorColors: `${COLORS.SUCCESS.code}50`,
					},
				},
			},
		},
	});
	return (
		<CardBody>
			<div className='row g-4'>
				<div className='col-md-6'>
					<Card
						className={`bg-l${darkModeStatus ? 'o25' : '25'}-primary bg-l${
							darkModeStatus ? 'o50' : '10'
						}-primary-hover transition-base rounded-2 mb-4`}
						shadow='sm'>
						<CardHeader className='bg-transparent'>
							<CardLabel>
								<CardTitle tag='div' className='h5'>
									Customer Happiness
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='d-flex align-items-center pb-3'>
								<div className='flex-shrink-0'>
									<Icon icon='EmojiEmotions' size='4x' color='primary' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-3 mb-0'>
										100%
										<span className='text-info fs-5 fw-bold ms-3'>
											0
											<Icon icon='TrendingFlat' />
										</span>
									</div>
									<div className='text-muted'>Compared to ($5000 last year)</div>
								</div>
							</div>
						</CardBody>
					</Card>
					<Card
						className={`bg-l${darkModeStatus ? 'o25' : '25'}-danger bg-l${
							darkModeStatus ? 'o50' : '10'
						}-danger-hover transition-base rounded-2 mb-0`}
						shadow='sm'>
						<CardHeader className='bg-transparent'>
							<CardLabel>
								<CardTitle tag='div' className='h5'>
									Injury
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='d-flex align-items-center pb-3'>
								<div className='flex-shrink-0'>
									<Icon icon='Healing' size='4x' color='danger' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-3 mb-0'>
										1
										<span className='text-danger fs-5 fw-bold ms-3'>
											-50%
											<Icon icon='TrendingDown' />
										</span>
									</div>
									<div className='text-muted'>Compared to (2 last week)</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
				<div className='col-md-6'>
					<Card
						className={`bg-l${darkModeStatus ? 'o25' : '25'}-success bg-l${
							darkModeStatus ? 'o50' : '10'
						}-success-hover transition-base rounded-2 mb-0`}
						stretch
						shadow='sm'>
						<CardHeader className='bg-transparent'>
							<CardLabel>
								<CardTitle tag='div' className='h5'>
									Daily Occupancy
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody className='pt-0'>
							<Chart
								className='d-flex justify-content-center'
								series={dayHours.series}
								options={dayHours.options}
								type={dayHours.options.chart?.type}
								height={dayHours.options.chart?.height}
								width={dayHours.options.chart?.width}
							/>
							<div className='d-flex align-items-center pb-3'>
								<div className='flex-shrink-0'>
									<Icon icon='Timer' size='4x' color='success' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-3 mb-0'>
										~22H
										<span className='text-success fs-5 fw-bold ms-3'>
											+12.5%
											<Icon icon='TrendingUp' />
										</span>
									</div>
									<div className='text-muted'>
										Compared to (~19H 30M last week)
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</CardBody>
	);
};

export default Table;
