import React, { FC, memo, useState } from 'react';
import { HeaderRight } from '../../../../../layout/Header/Header';
import FontItem from './HeaderRightComponentItems/FontItem';
import FullScreenItem from './HeaderRightComponentItems/FullScreenItem';
import NotificationActItem from './HeaderRightComponentItems/NotificationActItem';
import NotificationAllItem from './HeaderRightComponentItems/NotificationAllItem';
import { IButtonProps } from '../../../../../components/bootstrap/Button';
import useDarkMode from '../../../../../hooks/useDarkMode';
import NotificationComponentAct from '../NotificationComponent/NotificationComponentAct';
import NotificationComponentAll from '../NotificationComponent/NotificationComponentAll';
import ThemeItem from './HeaderRightComponentItems/ThemeItem';

interface IHeaderRightComponentProps {}

const HeaderRightComponent: FC<IHeaderRightComponentProps> = memo(() => {
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const [offcanvasActStatus, setOffcanvasActStatus] = useState(false);
	const { darkModeStatus } = useDarkMode();

	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
		className: 'fs-6 d-flex align-items-center justify-content-center responsive-icon',
	};
	return (
		<>
			<HeaderRight>
				<div className='row g-3'>
					<FontItem styledBtn={styledBtn} />
					<ThemeItem styledBtn={styledBtn} />
					<FullScreenItem styledBtn={styledBtn} />
					<NotificationActItem styledBtn={styledBtn} setIsOpen={setOffcanvasActStatus} />
					<NotificationAllItem styledBtn={styledBtn} setIsOpen={setOffcanvasStatus} />
				</div>
				<NotificationComponentAll setIsOpen={setOffcanvasStatus} isOpen={offcanvasStatus} />
				<NotificationComponentAct
					setIsOpen={setOffcanvasActStatus}
					isOpen={offcanvasActStatus}
				/>
			</HeaderRight>
		</>
	);
});
HeaderRightComponent.defaultProps = {
	isFluid: false,
};

export default HeaderRightComponent;
