import React, { FC } from 'react';
import { useQuery } from 'react-query';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalTitle,
} from '../../../../../components/bootstrap/Modal';
import * as qs from 'qs';
import Icon from '../../../../../components/icon/Icon';
import GroupItem from './GroupItem';

interface IAddGroupForMaterial {
	groups: any;
	setValues(...args: unknown[]): unknown;
	setIsOpen(...args: unknown[]): unknown;
	isOpen: boolean;
	values: any;
	uuid?: string;
}

const AddGroupForMaterial: FC<IAddGroupForMaterial> = ({
	uuid,
	setValues,
	values,
	isOpen,
	setIsOpen,
	groups,
}) => {

	return (
		<Modal isStaticBackdrop isOpen={isOpen} setIsOpen={setIsOpen} size={'lg'} isCentered>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='title' style={{ fontSize: '1.75rem' }}>
					<Icon color={'primary'} icon={'Medication'} />
					Группы материалов{' '}
				</ModalTitle>
			</ModalHeader>
			<ModalBody className='px-4 d-flex flex-column gap-4'>
				<div className='d-flex flex-column gap-3'>
					{groups?.data?.map((group: any, index: number) => (
						<GroupItem
							setValues={setValues}
							values={values}
							item={group}
							index={index}
						/>
					))}
				</div>
			</ModalBody>
		</Modal>
	);
};

export default AddGroupForMaterial;
