import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';
import PaginationButtons, { dataPagination } from '../../../../../components/PaginationButtons';
import TableComponent from '../../../../components/tables/TableComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import SubHeaderRightComponent from './SubHeaderComponent/SubHeaderRightComponent';
import AddMaterialGroups from './AddMaterialGroups';
import { GroupService } from '../../../../../custom/api/service/group.service';
import { useMutation, useQueryClient } from 'react-query';
import { notification, NotificationArgsProps } from 'antd';
import StatusSubmitModal from '../StatusSubmitModal';

type NotificationPlacement = NotificationArgsProps['placement'];

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	groups: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
	sort: any;
	setSort: any;
}

const MaterialGroupsTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		groups,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
		sort,
		setSort,
	}) => {
		const [api, contextHolder] = notification.useNotification();

		const openNotification = (
			placement: NotificationPlacement,
			message: string,
			description: string,
		) => {
			api.info({
				message,
				description,
				placement,
			});
		};

		const [addMaterialGroups, setAddMaterialGroups] = useState(false);
		const [statusModal, setStatusModal] = useState(false);
		const [uuid, setUuid] = useState('');

		const tableHeaders = [
			{ header: 'Название Ru', key: 'title_all_rus' },
			{ header: 'Название Arm ', key: 'title_all_arm' },
			{ header: 'Название En', key: 'title_all_en' },
			{ header: 'Действие', key: 'clinicOrganisationActions' },
		];
		const directionDropDownProps = {
			setUuid: setUuid,
			setAddMaterialGroups: setAddMaterialGroups,
			setStatusModal: setStatusModal,
			uuid: uuid,
		};

		const queryClient = useQueryClient();

		const { mutate: createGroups } = useMutation(
			({ inputDto }: { inputDto: any }) => GroupService.create(inputDto),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('groups');
					setValues({
						title_all: {
							en: '',
							arm: '',
							rus: '',
						},
					});
					setUuid('');
					setAddMaterialGroups(false);
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: updateGroups } = useMutation(
			({ inputDto, uuid }: { inputDto: any; uuid: string }) => {
				return GroupService.update(uuid, inputDto);
			},
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('groups');
					queryClient.invalidateQueries('group');
					setValues({
						title_all: {
							en: '',
							arm: '',
							rus: '',
						},
					});
					setAddMaterialGroups(false);
					setUuid('');
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: deleteGroups } = useMutation(
			({ uuid }: { uuid: string }) => GroupService.delete(uuid),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('groups');
					queryClient.invalidateQueries('group');
					setValues({
						title_all: {
							en: '',
							arm: '',
							rus: '',
						},
					});
					setAddMaterialGroups(false);
					setUuid('');
					setStatusModal(false);
				},

				onError: (error: any) => {
					if (error.response.data.message === 'cant_create_whithout_office_name')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Название кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_office_type')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Тип кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_clinic')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без филиала',
						);

					if (error.response.data.message === 'cant_create_whithout_responible_user')
						openNotification(
							'bottomRight',
							'Важно !',
							'Вы не можете создать организацию для филиала без  ответственного  персонала',
						);
				},
			},
		);
		const handleSubmit = useCallback(
			(e: any, uuid?: string) => {
				e.preventDefault();
				if (uuid) {
					console.log({ inputDto: values, uuid: uuid }, 'kkkjjjhhhihi');
					return updateGroups({ inputDto: values, uuid: uuid });
				}
				return createGroups({ inputDto: values });
			},
			[createGroups, updateGroups, values],
		);

		const handleSubmitDelete = useCallback(
			(e: any, uuid: string) => {
				e.preventDefault();
				deleteGroups({ uuid });
			},
			[deleteGroups],
		);

		const handleCancele = useCallback(() => {
			setValues({
				title_all: {
					en: '',
					arm: '',
					rus: '',
				},
			});
			setAddMaterialGroups(false);
			setUuid('');
			setStatusModal(false);
		}, [setValues]);
		useEffect(() => {
			if (
				uuid &&
				values.title_all.en === '' &&
				values.title_all.arm === '' &&
				values.title_all.rus === ''
			) {
				setValues((prev: any) => {
					const data = groups?.data.find((item: any) => item.uuid === uuid);
					return {
						title_all: {
							en: data?.title_all?.en,
							arm: data?.title_all?.arm,
							rus: data?.title_all?.rus,
						},
					};
				});
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [uuid]);

		return (
			<>
				<Card className={addMaterialGroups ? 'col-8' : 'col-12'}  stretch={isFluid}>
					{contextHolder}
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						<SubHeaderRightComponent
							setStatusModal={setStatusModal}
							handleSubmit={handleSubmit}
							uuid={uuid}
							addMaterialGroups={addMaterialGroups}
							setAddMaterialGroups={setAddMaterialGroups}
							handleCancele={handleCancele}
						/>
					</div>
					<CardBody className='table-responsive' isScrollable={isFluid}>
						{!addMaterialGroups ? (
							<TableComponent
								data={dataPagination(groups?.data, currentPage, perPage)}
								headers={tableHeaders}
								directionDropDownProps={directionDropDownProps}
								visitDropDownProps={undefined}
							/>
						) : (
							<AddMaterialGroups setValues={setValues} values={values} />
						)}
					</CardBody>
					{!addMaterialGroups && (
						<PaginationButtons
							data={groups || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(groups?.meta?.count / perPage) || 1}
						/>
					)}
				</Card>
				<StatusSubmitModal
					setIsOpen={setStatusModal}
					isOpen={statusModal}
					handleSubmitDelete={handleSubmitDelete}
					uuid={uuid}
				/>
			</>
		);
	},
);

export default MaterialGroupsTable;
