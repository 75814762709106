import React, { SVGProps } from "react";

export const PRINT_TOOTH_33 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 17 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M15 15.8937C13.2258 42.9796 5.49562 50.0904 2.44043 50.7883C-0.614768 51.4862 2.08471 20.654 2.44043 13.8" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M9.96671 18.8C17.9223 17.1922 16.5289 10.1789 14.1112 5.50883C12.7162 2.81421 12.255 1.36323 5.47687 1.9167C4.1682 1.9167 -0.787317 3.06555 1.67753 12.6935C3.21524 18.7 7.30216 18.8 9.96671 18.8Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}

