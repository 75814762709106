import { authHost } from '../axios';
import { INotification } from '../interface/notification.interface';

export class NotificationService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async findAll(query: any): Promise<INotification[] | null> {
		const { data } = await authHost.get(`notification?${query}`);
		return data;
	}

	static async readAllNots() {
		const notification = await authHost.patch(`notification/read_not_all`);
		return notification;
	}

	static async readNot(uuid:string) {
		const notification = await authHost.patch(`notification/read_not/${uuid}`);
		return notification;
	}

	static async clearNots() {
		const notification = await authHost.patch(`notification/clear_not`);
		return notification;
	}

	static async deleteNot(uuid: string) {
		const notification = await authHost.patch(`notification/delete_not/${uuid}`);
		return notification;
	}
}
