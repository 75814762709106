import React, { FC } from 'react';


interface ICalendars {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const Calendars:FC<ICalendars> = ({search, setSearch}) => {
	return <h1>Календари</h1>;
};

export default Calendars;
