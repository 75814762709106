import React from 'react';

export const Sum: React.FC<{
	text?: string;
	status: string;
	className?: string | {};
	debt?: boolean;
}> = ({ text, status, className, debt }) => {
	const getColor = () => {
		if (!debt) {
			return 'success';
		} else {
			return 'danger';
		}
	};
	const dateStyles = () => {
		if (debt) {
			return {
				backgroundColor: '#e183cd',
				borderColor: '#ba0092',
			};
		} else {
			return {
				backgroundColor: 'rgba(25, 135, 84, 0.3)',
				borderColor: 'rgba(25, 135, 84, 1)',
			};
		}
	};

	return (
		<div className={`d-flex align-items-center ${className}`}>
			<div
				className={`date-frame rounded-2 d-flex align-items-center justify-content-center border-0`}
				style={{ ...dateStyles(),  }}>
				<div className='d-flex flex-nowrap  align-items-center gap-1'>
					<span className={`text-${getColor()} fs-5 fw-bold align-text-bottom`}>
						{debt ? '' : '+'}
					</span>{' '}
					<span
						className={`text-${getColor()} fs-4 fw-bold text-nowrap`}>{`${text} AMD`}</span>
				</div>
			</div>
		</div>
	);
};
