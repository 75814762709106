import React, { SVGProps } from "react";


export const PRINT_TOOTH_41 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 20 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 11">
<path id="Vector 70" d="M1.99602 15.8C0.895245 28.6337 4.451 50.2149 12.1775 56.5361C13.3252 57.475 14.7847 55.761 15.3382 53.7267L17.8 15.8" stroke="black" strokeWidth="0.7"/>
<path id="Vector 17" d="M14.9653 0.800003H2.52752C1.14554 0.800003 0.800049 2.21177 0.800049 2.91765C0.800049 3.62353 1.14554 24.8 10.4739 24.8C19.8023 24.8 18.7658 6.09412 18.7658 4.68236C18.7658 3.27059 16.3473 0.800003 14.9653 0.800003Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}


