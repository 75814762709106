import React, { SVGProps } from 'react';

const SvgTheethBridge = (props: SVGProps<SVGSVGElement>) => {
	return (
			<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M24.5 0.22998H0.5V24.23H24.5V0.22998Z" fill="none"/>
				<g filter="url(#filter0_i_596_1712)">
					<path d="M7.88176 5.47998C2.14714 5.48002 3.35396 13.7191 4.29759 18.578C4.36141 18.9067 4.75951 19.0206 4.98372 18.777L7.76232 15.7575C7.83856 15.6746 7.94469 15.6277 8.05572 15.6277H16.5997C16.7029 15.6277 16.8023 15.6683 16.8772 15.7412L20.0929 18.8678C20.3171 19.0858 20.6878 18.9696 20.7574 18.6608C23.0594 8.45227 19.5238 5.47998 16.7605 5.47998C14.8525 5.47998 13.5888 6.23568 12.9695 6.72629C12.7424 6.90618 12.3485 6.86602 12.133 6.67162C11.5899 6.18157 10.3767 5.47994 7.88176 5.47998Z" fill="#009FE8"/>
				</g>
				<path d="M0.384766 19.0178H4.98309L7.97615 15.5874H16.7003L20.1857 19.0178H24.8257" stroke="#009FE8" strokeWidth="2"/>
				<defs>
				<filter id="filter0_i_596_1712" x="3.5" y="5.47998" width="18" height="17.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix"/>
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
				<feOffset dy="4"/>
				<feGaussianBlur stdDeviation="4.25"/>
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow_596_1712"/>
				</filter>
				</defs>
			</svg>
	);
};

export default SvgTheethBridge;
