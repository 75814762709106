import React, { FC, useEffect, useState } from 'react';
import MaterialTable from './MaterialTable';
import { useQuery } from 'react-query';
import qs from 'qs';
import { PER_COUNT } from '../../../../../components/PaginationButtons';
import { IService } from '../../../../api/interface/service.interface';
import { UserService } from '../../../../api/service/user.service';
import { CategoryService } from '../../../../../custom/api/service/category.service';
import { MaterialService } from '../../../../../custom/api/service/materials-service.service';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface IMaterialPage {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const MaterialPage: FC<IMaterialPage> = ({ search, setSearch }) => {
	const [serviceSort, setServiceSort] = useState<boolean>(false);

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	const [values, setValues] = useState<IService>({
		name: '',
		code: '',
		expiration_date: '',
		brand: '',
		price: '',
		store_type: '',
		material_type: '',
		unit_measurment: '',
		size: '',
		categoryUuid: undefined,
		clinicUuid: localStorage.getItem('clinicUuid'),
		groups: [],
	});

	const [filter, setFilter] = useState<IService>({
		clinicUuid: '',
		material_type: '',
		categoryUuid: '',
		store_type: '',
		search: '',
	});
	const filterDebounce = useDebounce(filter);
	const deboncedSearch = useDebounce(search);

	const { data: materials } = useQuery<any | null, Error>(
		['materials', filterDebounce, currentPage, perPage, deboncedSearch],
		() =>
			MaterialService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
						order: values.order,
					},
					includeMeta: [
						{
							association: 'clinic',
							...(!!filter.clinicUuid && {
								where: {
									uuid: filter.clinicUuid,
								},
							}),
						},
						{
							association: 'groups',
						},
						{
							association: 'category',
							...(!!filter.categoryUuid && {
								where: {
									uuid: filter.categoryUuid,
								},
							}),
						},
					],
					filterMeta: {
						...(!!deboncedSearch && {
							websearchQuery: { searchVector: deboncedSearch },
						}),
						...(!!filter.material_type && {
							material_type: filter.material_type,
						}),
						...(!!filter.store_type && {
							store_type: filter.store_type,
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const { data: categoriesWithCategories } = useQuery<any | null, Error>(
		['categoriesWithCategories'],
		() =>
			CategoryService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const categories = categoriesWithCategories?.data?.filter((item: any) => !item.categoryUuid);

	return (
		<>
			<MaterialTable
				isFluid
				setValues={setValues}
				values={values}
				materials={materials}
				categories={categories}
				isUserDoctor={isUserDoctor}
				setCurrentPage={setCurrentPage}
				setPerPage={setPerPage}
				currentPage={currentPage}
				perPage={perPage}
				filter={filter}
				setFilter={setFilter}
				sort={serviceSort}
				setSort={setServiceSort}
			/>
		</>
	);
};

export default MaterialPage;
