import { authHost } from '../axios';
import { IEstimate } from '../interface/act.interface';

export class EstimateService {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {}
	static async createEstimate(data: IEstimate) {
		const estimate = await authHost.post('estimate', data);
		return estimate;
	}

	static async getEstimate(uuid: string) {
		const estimate = await authHost.get(`estimate/${uuid}`);
		return estimate;
	}

	static async updateEstimate(uuid: string, data: IEstimate) {
		const estimate = await authHost.patch(`estimate/${uuid}`, data);
		return estimate;
	}

	static async getStageAmounts(stageUuid: string, visitUuid: null | string) {
		const estimate = await authHost.get(`estimate/getStageAmounts/${stageUuid}/${visitUuid}`);
		return estimate.data;
	}
}
