import React, { SVGProps } from "react";


export const PRINT_TOOTH_42 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg  width="36" height="100" viewBox="0 0 17 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 20">
<path id="Vector 66" d="M1.40002 15.8937C3.17423 42.9796 10.9044 50.0904 13.9596 50.7883C17.0148 51.4862 14.3153 20.654 13.9596 13.8" stroke="black" strokeWidth="0.63" strokeLinecap="round"/>
<path id="Vector 24" d="M6.43331 18.8C-1.52225 17.1922 -0.128838 10.1789 2.28886 5.50883C3.68387 2.81421 4.14501 1.36323 10.9232 1.9167C12.2318 1.9167 17.1873 3.06555 14.7225 12.6935C13.1848 18.7 9.09787 18.8 6.43331 18.8Z" stroke="black" strokeWidth="0.63"/>
</g>
</svg>
  )
}

