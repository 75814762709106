import React, { SVGProps } from "react";


export const PRINT_TOOTH_17 = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="36" height="100" viewBox="0 0 19 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g id="n 19">
<path id="Vector 66" d="M1.59998 41.6871C3.65669 11.8928 0.943111 7.20566 2.14447 6.0952C4.19187 2.16401 10.4404 15.7048 10.6894 26.6248C12.627 24.0499 8.03129 5.41574 10.394 6.9688C18.3682 12.2104 18.9131 35.8607 18.5007 43.4" stroke="black" strokeWidth="0.7"/>
<path id="Vector 136" d="M6.74415 11.4C6.24477 7.2259 7.00627 7.57797 9.59998 11.4" stroke="black" strokeWidth="0.35"/>
<path id="Vector 24" d="M2.0967 52.5424C4.42843 56.7444 7.34284 53.9433 8.50871 51.6088C9.96605 51.6088 12.5529 55.4 14.0103 55.4C15.4676 55.4 20.5442 52.8948 17.7994 42.3272C16.087 35.7344 12.059 33.4 9.09175 33.4C0.232432 35.1648 -0.913997 47.1168 2.0967 52.5424Z" stroke="black" strokeWidth="0.7"/>
</g>
</svg>
  )
}

