import React, { FC, memo } from 'react';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import UserHeader from './UserHeader/UserHeader';
import UserFooter from './UserFooter/UserFooter';

interface IUserProfileComponentProps {
	setEditCard(...args: unknown[]): unknown;
	setAddParent(...args: unknown[]): unknown;
	setActivation(...args: unknown[]): unknown;
	setViewDocuments(...args: unknown[]): unknown;
	user: any;
}

const UserProfileComponent: FC<IUserProfileComponentProps> = memo(
	({ user, setEditCard, setAddParent, setActivation, setViewDocuments }) => {
		return (
			<Card style={{ height: 'fit-content' }} className='col-12 mb-0'>
				<CardBody style={{ height: 'fit-content' }}>
					<div className='row g-4 '>
						<UserHeader setAddParent={setAddParent} user={user} />
						<UserFooter
							setEditCard={setEditCard}
							setAddParent={setAddParent}
							setActivation={setActivation}
							setViewDocuments={setViewDocuments}
							user={user}
						/>
					</div>
					
				</CardBody>
			</Card>
		);
	},
);

export default UserProfileComponent;
