import React, { memo } from 'react';
import { SubHeaderLeft } from '../../../../../../layout/SubHeader/SubHeader';
import { CardLabel, CardTitle } from '../../../../../../components/bootstrap/Card';

const SubHeaderLeftComponent = memo(() => {
	return (
		<SubHeaderLeft className='col-12 d-flex flex-nowrap align-items-center'>
			<CardLabel
				className='d-flex justify-content-between'
				icon='MedicalServices'
				iconColor='info'>
				<CardTitle tag='div' className='fs-5'>
					Услуги
				</CardTitle>
			</CardLabel>
		</SubHeaderLeft>
	);
});
export default SubHeaderLeftComponent;
