import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';
import PaginationButtons from '../../../../../components/PaginationButtons';
import TableComponent from '../../../../components/tables/TableComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import SubHeaderRightComponent from './SubHeaderComponent/SubHeaderRightComponent';
import AddService from './AddService';
import { ServiceService } from '../../../../../custom/api/service/visit-service.service';
import { useMutation, useQueryClient } from 'react-query';
import { notification, NotificationArgsProps } from 'antd';
import StatusSubmitModal from '../StatusSubmitModal';
import ServicesFilterComponent from './components/UI/FilterComponent/ServicesFilterComponent';
import ResetComponent from './components/UI/ResetComponent/ResetComponent';

type NotificationPlacement = NotificationArgsProps['placement'];

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	services: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
	sort: any;
	setSort: any;
	categories: any;
}

const ServiceTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		services,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
		sort,
		setSort,
		categories,
	}) => {
		const [api, contextHolder] = notification.useNotification();

		const openNotification = (
			placement: NotificationPlacement,
			message: string,
			description: string,
		) => {
			api.info({
				message,
				description,
				placement,
			});
		};

		const [addService, setAddService] = useState(false);
		const [addGroupService, setAddGroupService] = useState(false);
		const [statusModal, setStatusModal] = useState(false);
		const [uuid, setUuid] = useState('');

		const tableHeaders = [
			{ header: '№', key: 'index' },
			{ header: 'ID', key: 'serviceId' },
			{ header: 'Название', key: 'title_all' },
			{ header: 'Код', key: 'code' },
			{ header: 'Специализация', key: 'category' },
			{ header: 'Тип лечения', key: 'treatment_type' },
			{ header: 'Стоимость', key: 'price' },
			{ header: 'Действие', key: 'clinicOrganisationActions' },
		];

		const reset = useCallback(() => {
			setFilter({
				clinic: '',
				treatment_type: '',
				category: '',
				subCategory: '',
				code: '',
				search: '',
			});
		}, [setFilter]);

		const directionDropDownProps = {
			setUuid: setUuid,
			setAddService: setAddService,
			setStatusModal: setStatusModal,
			uuid: uuid,
		};

		const queryClient = useQueryClient();

		const { mutate: createOrganisation } = useMutation(
			({ inputDto }: { inputDto: any }) => ServiceService.create(inputDto),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('services');
					queryClient.invalidateQueries('material');
					setValues({
						categoryUuid: '',
						clinicUuid: '',
						code: '',
						currency: '',
						groups: [''],
						material: true,
						mkb10DiseaseUuid: [''],
						price: '',
						service_type: '',
						subCategoryUuid: '',
						title_all: { rus: '', arm: '', en: '' },
						treatment_type: '',
						unit_measurement: '',
						order: { createdAt: 'DESC' },
					});
					setUuid('');
					setAddService(false);
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: updateOrganisation } = useMutation(
			({ inputDto, uuid }: { inputDto: any; uuid: string }) => {
				return ServiceService.update(uuid, inputDto);
			},
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('services');
					queryClient.invalidateQueries('material');
					setValues({
						categoryUuid: '',
						clinicUuid: '',
						code: '',
						currency: '',
						groups: [''],
						material: true,
						mkb10DiseaseUuid: [''],
						price: '',
						service_type: '',
						subCategoryUuid: '',
						title_all: { rus: '', arm: '', en: '' },
						treatment_type: '',
						unit_measurement: '',
						order: { createdAt: 'DESC' },
					});
					setAddService(false);
					setUuid('');
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: deleteOrganisation } = useMutation(
			({ uuid }: { uuid: string }) => ServiceService.delete(uuid),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('services');
					queryClient.invalidateQueries('material');
					setValues({
						categoryUuid: '',
						clinicUuid: '',
						code: '',
						currency: '',
						groups: [''],
						material: true,
						mkb10DiseaseUuid: [''],
						price: '',
						service_type: '',
						subCategoryUuid: '',
						title_all: { rus: '', arm: '', en: '' },
						treatment_type: '',
						unit_measurement: '',
						order: { createdAt: 'DESC' },
					});
					setAddService(false);
					setUuid('');
					setStatusModal(false);
				},

				onError: (error: any) => {
					if (error.response.data.message === 'cant_create_whithout_office_name')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Название кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_office_type')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Тип кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_clinic')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без филиала',
						);

					if (error.response.data.message === 'cant_create_whithout_responible_user')
						openNotification(
							'bottomRight',
							'Важно !',
							'Вы не можете создать организацию для филиала без  ответственного  персонала',
						);
				},
			},
		);
		const handleSubmit = useCallback(
			(e: any, uuid?: string) => {
				e.preventDefault();
				if (uuid) {
					return updateOrganisation({ inputDto: values, uuid: uuid });
				}
				return createOrganisation({ inputDto: values });
			},
			[createOrganisation, updateOrganisation, values],
		);

		const handleSubmitDelete = useCallback(
			(e: any, uuid: string) => {
				e.preventDefault();
				deleteOrganisation({ uuid });
			},
			[deleteOrganisation],
		);

		const handleCancele = useCallback(() => {
			setValues({
				categoryUuid: '',
				clinicUuid: '',
				code: '',
				currency: '',
				groups: [''],
				material: true,
				mkb10DiseaseUuid: [''],
				price: '',
				service_type: '',
				subCategoryUuid: '',
				title_all: { rus: '', arm: '', en: '' },
				treatment_type: '',
				unit_measurement: '',
				order: { createdAt: 'DESC' },
			});
			setAddService(false);
			setUuid('');
			setStatusModal(false);
		}, [setValues]);
		useEffect(() => {
			if (!uuid) return;

			const selectedService = services?.data.find((item: any) => item.uuid === uuid);

			// Extract all values except clinicUuid for checking emptiness
			const fieldsToCheck = { ...values };
			delete fieldsToCheck.title_all;

			// Check if all relevant fields are empty
			const isEmpty = Object.values(fieldsToCheck).every(
				(val) => val === '' || val === undefined || [''],
			);
			if (
				selectedService &&
				isEmpty &&
				values.title_all.en === '' &&
				values.title_all.arm === '' &&
				values.title_all.rus === ''
			) {
				setValues((prev: any) => ({
					categoryUuid: selectedService?.categoryUuid ?? '',
					clinicUuid: selectedService?.clinicUuid ?? '',
					code: selectedService?.code ?? '',
					currency: selectedService?.currency ?? '',
					groups:
						selectedService?.groups?.map((item: any) => item?.uuid)?.length > 0
							? selectedService?.groups?.map((item: any) => item?.uuid)
							: [''],
					material: true,
					mkb10DiseaseUuid:
						selectedService?.mkb10s?.map((item: any) => item?.uuid)?.lenth > 0
							? selectedService?.mkb10s?.map((item: any) => item?.uuid)
							: [''],
					price: selectedService?.price ?? '',
					service_type: selectedService?.service_type ?? '',
					subCategoryUuid: selectedService?.subCategoryUuid ?? '',
					title_all: {
						en: selectedService?.title_all?.en,
						arm: selectedService?.title_all?.arm,
						rus: selectedService?.title_all?.rus,
					},
					treatment_type: selectedService?.treatment_type ?? '',
					unit_measurement: selectedService?.unit_measurement ?? '',
					order: { createdAt: 'DESC' },
				}));
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [uuid]); // Dependencies include values for correct reactivity

		return (
			<>
				<Card className={addService ? 'col-8' : 'col-12'} stretch={'full'}>
					{contextHolder}
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						<SubHeaderRightComponent
							setStatusModal={setStatusModal}
							handleSubmit={handleSubmit}
							uuid={uuid}
							addService={addService}
							setAddService={setAddService}
							handleCancele={handleCancele}
						/>
					</div>
					{!addService && (
						<div className='table-row-header '>
							<div className='d-flex flex-row align-items-center w-full grow'>
								<ServicesFilterComponent
									reset={reset}
									filter={filter}
									setFilter={setFilter}
								/>
							</div>
							<div>
								<ResetComponent setAddService={setAddService} reset={reset} />
							</div>
						</div>
					)}
					<CardBody className='table-responsive' isScrollable={true}>
						{!addService ? (
							<TableComponent
								data={services?.data}
								headers={tableHeaders}
								directionDropDownProps={directionDropDownProps}
								visitDropDownProps={undefined}
							/>
						) : (
							<AddService
								setValues={setValues}
								setIsOpen={setAddGroupService}
								isOpen={addGroupService}
								values={values}
								uuid={uuid}
							/>
						)}
					</CardBody>
					{!addService && (
						<PaginationButtons
							data={services || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(services?.meta?.count / perPage) || 1}
						/>
					)}
				</Card>
				<StatusSubmitModal
					setIsOpen={setStatusModal}
					isOpen={statusModal}
					handleSubmitDelete={handleSubmitDelete}
					uuid={uuid}
				/>
			</>
		);
	},
);

export default ServiceTable;
