import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Card, { CardBody } from '../../../../../components/bootstrap/Card';
import PaginationButtons from '../../../../../components/PaginationButtons';
import TableComponent from '../../../../components/tables/TableComponent';
import SubHeaderLeftComponent from './SubHeaderComponent/SubHeaderLeftComponent';
import SubHeaderRightComponent from './SubHeaderComponent/SubHeaderRightComponent';
import AddMaterial from './AddMaterial';
import { MaterialService } from '../../../../../custom/api/service/materials-service.service';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notification, NotificationArgsProps } from 'antd';
import StatusSubmitModal from '../StatusSubmitModal';
import MaterialsFilterComponent from '../MaterialComponents/components/UI/FilterComponent/MaterialsFilterComponent';
import AddGroupForMaterial from './AddGroupForMaterial';
import ResetComponent from './components/UI/ResetComponent/ResetComponent';
import { GroupService } from '../../../../../custom/api/service/group.service';
import * as qs from 'qs';
import AddMaterialGroups from '../GroupComponents/AddMaterialGroups';

type NotificationPlacement = NotificationArgsProps['placement'];

interface ICommonUpcomingEventsProps {
	isFluid?: boolean;
	setValues(...args: unknown[]): unknown;
	setFilter(...args: unknown[]): unknown;
	setCurrentPage(...args: unknown[]): unknown;
	setPerPage(...args: unknown[]): unknown;
	values: any;
	filter: any;
	materials: any;
	isUserDoctor: boolean;
	currentPage: any;
	perPage: any;
	sort: any;
	setSort: any;
	categories: any;
}

const MaterialTable: FC<ICommonUpcomingEventsProps> = memo(
	({
		isFluid,
		values,
		materials,
		setValues,
		isUserDoctor,
		currentPage,
		perPage,
		setCurrentPage,
		setPerPage,
		filter,
		setFilter,
		sort,
		setSort,
		categories,
	}) => {
		const [api, contextHolder] = notification.useNotification();
		const [openGroup, setOpenGroup] = useState<boolean>(false);
		const [groupsValues, setGroupValues] = useState({
			title_all: {
				en: '',
				arm: '',
				rus: '',
			},
		});
		const openNotification = (
			placement: NotificationPlacement,
			message: string,
			description: string,
		) => {
			api.info({
				message,
				description,
				placement,
			});
		};
		const { data: groups } = useQuery(['groups'], () => GroupService.findAll(qs.stringify({})));

		const [addMaterial, setAddMaterial] = useState(false);
		const [addGroupMaterial, setAddGroupMaterial] = useState(false);
		const [statusModal, setStatusModal] = useState(false);
		const [uuid, setUuid] = useState('');

		const tableHeaders = [
			{ header: '№', key: 'index' },
			{ header: 'Название', key: 'item_name' },
			{ header: 'Код материала', key: 'code' },
			{ header: 'Бренд', key: 'brand' },
			{ header: 'Филиал', key: 'clinic' },
			{ header: 'Склад', key: 'store_type' },
			{ header: 'Тип материала', key: 'material_type' },
			{ header: 'Категория', key: 'category' },
			{ header: 'Стоимость', key: 'price' },
			{ header: 'Ед.изм', key: 'unit_measurment' },
			{ header: 'Размеры', key: 'size' },
			{ header: 'Срок годности', key: 'expiration_date' },
			{ header: 'Действие', key: 'clinicOrganisationActions' },
		];
		const directionDropDownProps = {
			setUuid: setUuid,
			setAddMaterial: setAddMaterial,
			setStatusModal: setStatusModal,
			uuid: uuid,
		};

		const queryClient = useQueryClient();

		const { mutate: createOrganisation } = useMutation(
			({ inputDto }: { inputDto: any }) => MaterialService.create(inputDto),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('materials');
					queryClient.invalidateQueries('material');
					setValues({
						name: '',
						code: '',
						expiration_date: '',
						brand: '',
						price: '',
						store_type: '',
						material_type: '',
						unit_measurment: '',
						size: '',
						categoryUuid: undefined,
						clinicUuid: localStorage.getItem('clinicUuid'),
						groups: [],
					});
					setUuid('');
					setAddMaterial(false);
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: createGroups } = useMutation(
			({ inputDto }: { inputDto: any }) => GroupService.create(inputDto),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('groups');
					setGroupValues({
						title_all: {
							en: '',
							arm: '',
							rus: '',
						},
					});
					setOpenGroup(false);
				},
				onError: (error: any) => {
					if (error.response.data.message === 'cant_create_whithout_title_all')
						openNotification(
							'bottomRight',
							'Важно !',
							'Заполните все поля для названия ',
						);
				},
			},
		);

		const { mutate: updateOrganisation } = useMutation(
			({ inputDto, uuid }: { inputDto: any; uuid: string }) => {
				return MaterialService.update(uuid, inputDto);
			},
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('materials');
					queryClient.invalidateQueries('material');
					setValues({
						name: '',
						code: '',
						expiration_date: '',
						brand: '',
						price: '',
						store_type: '',
						material_type: '',
						unit_measurment: '',
						size: '',
						categoryUuid: undefined,
						clinicUuid: localStorage.getItem('clinicUuid'),
						groups: [],
					});
					setAddMaterial(false);
					setUuid('');
				},
				onError: (error) => {
					console.log(error);
				},
			},
		);

		const { mutate: deleteOrganisation } = useMutation(
			({ uuid }: { uuid: string }) => MaterialService.delete(uuid),
			{
				onSuccess: (response, formData) => {
					queryClient.invalidateQueries('materials');
					queryClient.invalidateQueries('material');
					setValues({
						name: '',
						code: '',
						expiration_date: '',
						brand: '',
						price: '',
						store_type: '',
						material_type: '',
						unit_measurment: '',
						size: '',
						categoryUuid: undefined,
						clinicUuid: localStorage.getItem('clinicUuid'),
						groups: [],
					});
					setAddMaterial(false);
					setUuid('');
					setStatusModal(false);
				},

				onError: (error: any) => {
					if (error.response.data.message === 'cant_create_whithout_office_name')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Название кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_office_type')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без Тип кабинета',
						);

					if (error.response.data.message === 'cant_create_whithout_clinic')
						openNotification(
							'bottomRight',
							'Важно !',
							'вы не можете создать организацию для филиала без филиала',
						);

					if (error.response.data.message === 'cant_create_whithout_responible_user')
						openNotification(
							'bottomRight',
							'Важно !',
							'Вы не можете создать организацию для филиала без  ответственного  персонала',
						);
				},
			},
		);
		const handleSubmit = useCallback(
			(e: any, uuid?: string) => {
				e.preventDefault();
				if (uuid) {
					return updateOrganisation({ inputDto: values, uuid: uuid });
				}
				return createOrganisation({ inputDto: values });
			},
			[createOrganisation, updateOrganisation, values],
		);

		const handleSubmitDelete = useCallback(
			(e: any, uuid: string) => {
				e.preventDefault();
				deleteOrganisation({ uuid });
			},
			[deleteOrganisation],
		);

		const handleCancele = useCallback(() => {
			setValues({
				name: '',
				code: '',
				expiration_date: '',
				brand: '',
				price: '',
				store_type: '',
				material_type: '',
				unit_measurment: '',
				size: '',
				categoryUuid: undefined,
				clinicUuid: localStorage.getItem('clinicUuid'),
				groups: [],
			});
			setAddMaterial(false);
			setUuid('');
			setStatusModal(false);
		}, [setValues]);

		const handleCanceleCreateGroup = useCallback(() => {
			setGroupValues({
				title_all: {
					en: '',
					arm: '',
					rus: '',
				},
			});
			setOpenGroup(false);
		}, []);

		const handleSubmitCreateGroup = useCallback(
			(e: any, uuid?: string) => {
				e.preventDefault();
				return createGroups({ inputDto: groupsValues });
			},
			[createGroups, groupsValues],
		);

		useEffect(() => {
			if (!uuid) return;

			const selectedMaterial = materials?.data.find((item: any) => item.uuid === uuid);

			// Extract all values except clinicUuid for checking emptiness
			const fieldsToCheck = { ...values };
			delete fieldsToCheck.clinicUuid;

			// Check if all relevant fields are empty
			const isEmpty = Object.values(fieldsToCheck).every(
				(val) => val === '' || val === undefined || [],
			);
			if (selectedMaterial && isEmpty) {
				setValues((prev: any) => ({
					...prev,
					name: selectedMaterial.name || '',
					code: selectedMaterial.code || '',
					expiration_date: selectedMaterial.expiration_date || '',
					brand: selectedMaterial.brand || '',
					price: selectedMaterial.price || '',
					store_type: selectedMaterial.store_type || '',
					material_type: selectedMaterial.material_type || '',
					unit_measurment: selectedMaterial.unit_measurment || '',
					size: selectedMaterial.size || '',
					categoryUuid: selectedMaterial.categoryUuid || '',
					clinicUuid: prev.clinicUuid || localStorage.getItem('clinicUuid'),
					groups: selectedMaterial?.groups?.map((item: any) => item?.uuid) ?? [''],
				}));
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [uuid]); // Dependencies include values for correct reactivity

		const reset = useCallback(() => {
			setValues({
				officeName: '',
				officeType: '',
				clinicUuid: '',
				categoryUuid: '',
				officeNumber: '',
				bedCount: '',
				phone: '',
				responibleUserUuid: '',
				responibleUser2Uuid: '',
			});
			setFilter({
				clinicUuid: '',
				material_type: '',
				categoryUuid: '',
				store_type: '',
				search: '',
			});
			setSort(false);
		}, [setFilter, setSort, setValues]);

		return (
			<>
				<Card
					className={addMaterial ? 'col-8' : 'col-12'}
					stretch={addMaterial ? false : 'full'}>
					{contextHolder}
					<div
						className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
						style={{ maxWidth: 'unset' }}>
						<SubHeaderLeftComponent />
						<SubHeaderRightComponent
							setStatusModal={setStatusModal}
							handleSubmit={handleSubmit}
							uuid={uuid}
							addMaterial={addMaterial}
							setAddMaterial={setAddMaterial}
							handleCancele={handleCancele}
						/>
					</div>
					{!addMaterial && (
						<div className='table-row-header '>
							<div className='d-flex flex-row align-items-center w-full grow'>
								<MaterialsFilterComponent filter={filter} setFilter={setFilter} />
							</div>
							<div>
								<ResetComponent reset={reset} />
							</div>
						</div>
					)}
					<CardBody
						className='table-responsive'
						isScrollable={addMaterial ? false : true}>
						{!addMaterial ? (
							<TableComponent
								data={materials?.data}
								headers={tableHeaders}
								directionDropDownProps={directionDropDownProps}
								visitDropDownProps={undefined}
							/>
						) : (
							<AddMaterial
								addGroup={openGroup}
								setAddGroup={setOpenGroup}
								groups={groups}
								categories={categories}
								setValues={setValues}
								setIsOpen={setAddGroupMaterial}
								isOpen={addGroupMaterial}
								values={values}
								uuid={uuid}
							/>
						)}
					</CardBody>
					{!addMaterial && (
						<PaginationButtons
							data={materials || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(materials?.meta?.count / perPage) || 1}
						/>
					)}
				</Card>

				{openGroup && (
					<Card
						className={addMaterial ? 'col-8' : 'col-12'}
						stretch={addMaterial ? false : 'full'}>
						{contextHolder}
						<div
							className='mt-1 px-4 py-3 d-flex flex-nowrap overflow-x-auto overflow-y-hidden align-items-center gap-5'
							style={{ maxWidth: 'unset' }}>
							<SubHeaderLeftComponent addGroup />
							<SubHeaderRightComponent
								setStatusModal={setStatusModal}
								handleSubmit={handleSubmitCreateGroup}
								uuid={''}
								addMaterial={openGroup}
								setAddMaterial={setAddGroupMaterial}
								handleCancele={handleCanceleCreateGroup}
							/>
						</div>

						<CardBody
							className='table-responsive'
							isScrollable={addMaterial ? false : true}>
							<AddMaterialGroups setValues={setGroupValues} values={groupsValues} />
						</CardBody>
					</Card>
				)}
				<StatusSubmitModal
					setIsOpen={setStatusModal}
					isOpen={statusModal}
					handleSubmitDelete={handleSubmitDelete}
					uuid={uuid}
				/>
				<AddGroupForMaterial
					groups={groups}
					setIsOpen={setAddGroupMaterial}
					isOpen={addGroupMaterial}
					uuid={uuid}
					setValues={setValues}
					values={values}
				/>
			</>
		);
	},
);

export default MaterialTable;
