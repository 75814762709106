import React, { FC, useState } from 'react';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../../../components/bootstrap/OffCanvas';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationService } from '../../../../../custom/api/service/notification.service';
import * as qs from 'qs';
import { INotification } from '../../../../../custom/api/interface/notification.interface';
import Icon from '../../../../../components/icon/Icon';
import { VisitService } from '../../../../../custom/api/service/visit.service';
import PaginationButtons, { PER_COUNT_FILTER } from '../../../../../components/PaginationButtons';
import NotificationComponentItem from './NotificationComponentItem';
import Button from '../../../../../components/bootstrap/Button';
import CreateActModal from '../../../../../custom/pages/visit/VisistPageComponents/Modals/CreateActModal';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { medicalCardPages } from '../../../../../menu';
import { UserService } from '../../../../../custom/api/service/user.service';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface INotificationComponentAllProps {
	setIsOpen(...args: unknown[]): unknown;
	isOpen: boolean;
}

const NotificationComponentAll: FC<INotificationComponentAllProps> = ({ setIsOpen, isOpen }) => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT_FILTER['5']);
	const [actVisit, setActVisit] = useState<IVisit | null>(null);
	const [createActModal, setCreateActModal] = useState<boolean>(false);
	const [filterNot, setFilterNot] = useState<string>('all');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries('notifications');
				queryClient.invalidateQueries('notifications_act');
				queryClient.invalidateQueries('notifications_count');
				queryClient.invalidateQueries('notifications_act_count');
			},
			refetchOnWindowFocus: true,
		},
	);

	const filterQuery = [
		{
			association: 'patient',
		},
		{
			association: 'services',
		},
		{
			association: 'services',
		},
		{
			association: 'canceledVisit',
		},
		{
			association: 'user',
			include: [
				{
					association: 'roles',
				},
			],
		},
		{
			association: 'canceledVisit',
		},

		{
			association: 'medicalCase',
			include: [
				{
					association: 'stage',
				},
			],
		},

		{
			association: 'clinic',
		},
		{
			association: 'estimate',
			include: [
				{
					association: 'act',
				},
				{
					association: 'services',
				},
			],
		},
	];

	const debounceFilter = useDebounce(filterNot, 300);

	const { data: notifications }: { data: INotification[] | any; isLoading: boolean } = useQuery(
		['notifications', debounceFilter, currentPage, perPage],
		() =>
			NotificationService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: currentPage,
						order: { createdAt: 'DESC' },
					},
					includeMeta: [
						{
							association: 'visit',
							include: filterQuery,
						},
					],
					filterMeta: {
						...(filterNot === 'read' && {
							isRead: true,
						}),
						...(filterNot === 'new' && {
							isRead: false,
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			refetchInterval: 60 * 1000,
			onSuccess: (response: any) => {
				queryClient.invalidateQueries('notifications_count');
				queryClient.invalidateQueries('notifications_act_count');
				queryClient.invalidateQueries('visits');
			},
		},
	);

	const queryClient = useQueryClient();

	const queryOptions = {
		onSuccess: (response: any, formData: any) => {
			queryClient.invalidateQueries('visits');
			queryClient.invalidateQueries('notifications');
			queryClient.invalidateQueries('notifications_act');
			queryClient.invalidateQueries('notifications_count');
			queryClient.invalidateQueries('notifications_act_count');
		},
		onError: (error: any) => {},
	};

	const { mutate: readNots } = useMutation(() => NotificationService.readAllNots(), queryOptions);
	const { mutate: readNot } = useMutation(
		(uuid: string) => NotificationService.readNot(uuid),
		queryOptions,
	);

	const { mutate: clearNots } = useMutation(() => NotificationService.clearNots(), queryOptions);

	const { mutate: deleteNot } = useMutation(
		(uuid: string) => NotificationService.deleteNot(uuid),
		queryOptions,
	);

	const { mutate: chnageStatusVisit } = useMutation(
		(data: any) => VisitService.update(data.uuid, data),
		{
			onSuccess: (response: any, formData: any) => {
				if (response.data.uuid && response.data.stageUuid) {
					window.location.href = `../${medicalCardPages.medicalCardDetails.path}/${
						response.data.medicalCaseUuid ? response.data.medicalCaseUuid : null
					}?visitUuid=${response.data.uuid}&stageUuid=${response.stageUuid}`;
				} else {
					window.location.href = `../${medicalCardPages.medicalCardDetails.path}/${
						response.data.medicalCaseUuid ? response.data.medicalCaseUuid : null
					}?visitUuid=${null}&stageUuid=${null}`;
				}
				queryClient.invalidateQueries('visits');
				queryClient.invalidateQueries('notifications');
				queryClient.invalidateQueries('notifications_act');
				queryClient.invalidateQueries('notifications_count');
				queryClient.invalidateQueries('notifications_act_count');
				setIsOpen(false);
			},
			onError: (error: any) => {},
		},
	);

	return (
		<>
			<OffCanvas
				isBackdrop={!createActModal}
				// id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={isOpen}
				// className='bg-light'
				setOpen={setIsOpen}
				style={{
					width: '510px',
					transform: 'translateX(0%) translateY(0%) translateZ(0px)',
					padding: '1.5rem',
					paddingTop: '0rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '2rem',
					backgroundColor: 'white',
				}}>
				<OffCanvasHeader className='p-0' setOpen={setIsOpen}>
					<div className='d-flex flex-column'>
						<div className='d-flex gap-3'>
							<Icon icon='Notifications' size='2x' color='info' />
							<OffCanvasTitle id='offcanvasExampleLabel'>Уведомления</OffCanvasTitle>
						</div>
					</div>
				</OffCanvasHeader>
				<OffCanvasBody style={{ marginTop: 5 }} className='p-0 d-flex flex-column'>
					<div style={{ paddingBottom: 25 }} className='d-flex gap-3'>
						<div className='d-flex gap-3 p-1'>
							<Button onClick={clearNots} color={'primary'} className='fs-6'>
								<Icon icon='Clear' color='light' size={'lg'}></Icon> Очистить
							</Button>
							<Button onClick={readNots} color={'primary'} className='fs-6'>
								<Icon icon='NotSuccessful' color='light' size={'lg'}></Icon> Все
								прочитано
							</Button>
						</div>
						<div className='d-flex gap-3 p-1'>
							<select
								className='form-select'
								value={filterNot}
								onChange={(e) => {
									setFilterNot(e.target.value);
								}}
								name=''
								id=''>
								<option value='all'>Все</option>
								<option value='new'>Новые</option>
								<option value='read'>Прочитанные</option>
							</select>
						</div>
					</div>
					<div
						className='p-0 d-flex flex-column justify-content-between'
						style={{ height: '100%' }}>
						<div className='d-flex flex-column align-items-center'>
							{notifications?.data?.length > 0 ? (
								notifications?.data?.map((i: any, index: number) => (
									<NotificationComponentItem
										key={`${index}-${i.uuid}`}
										not={i}
										readNots={readNots}
										clearNots={clearNots}
										deleteNot={deleteNot}
										readNot={readNot}
										chnageStatusVisit={chnageStatusVisit}
										setActVisit={setActVisit}
										setCreateActModal={setCreateActModal}
									/>
								))
							) : (
								<p>У вас нет новых уведомлений</p>
							)}
						</div>

						<PaginationButtons
							data={notifications || []}
							label='customers'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(notifications?.meta?.count / perPage) || 1}
						/>
					</div>
				</OffCanvasBody>
			</OffCanvas>

			<CreateActModal
				isOpen={createActModal}
				setIsOpen={setCreateActModal}
				setActVisit={setActVisit}
				estimate={actVisit?.estimate}
				visit={actVisit}
			/>
		</>
	);
};

export default NotificationComponentAll;
