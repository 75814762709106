import React, { FC } from 'react';
import MaterialGroupsPage from './GroupComponents/MaterialGroupsPage';

interface IMaterialGroups {
	search: any;
	setSearch(...args: unknown[]): unknown;
}

const MaterialGroups:FC<IMaterialGroups> = ({search, setSearch}) => {
	return <MaterialGroupsPage setSearch={setSearch} search={search} />;
};

export default MaterialGroups;
