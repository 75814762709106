import React, { SVGProps } from "react";

export const THEETH_38 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="5.53rem" viewBox="0 0 39 72" fill="none" {...props}>
<g filter="url(#filter0_i_2354_11103)">
<path d="M5.85806 68.7111C-8.25059 73.9361 8.95051 60.6107 5.06812 18.2881C12.2088 12.1923 29.2656 0.368031 37.2077 11.2361C37.986 21.9457 32.761 71.0608 18.1308 71.5833C11.3225 71.8265 25.4459 48.0707 21.7883 44.4132C14.9949 44.9356 13.3533 65.9353 5.85806 68.7111Z" fill="url(#paint0_radial_2354_11103)"/>
</g>
<g filter="url(#filter1_i_2354_11103)">
<path d="M5.40541 17.8896C18.9905 6.40044 3.31479 66.9657 1.2251 69.5823C-0.00497818 71.1226 -0.86466 68.5401 3.83782 50.7751C5.07026 46.1192 5.08952 40.3731 5.40542 34.055C5.92793 23.605 2.58354 20.2761 5.40541 17.8896Z" fill="url(#paint1_linear_2354_11103)"/>
</g>
<path d="M2.68154 8.36002C5.18958 -0.836003 15.57 2.4384 20.4468 5.2251C22.0142 2.61267 28.807 0 31.4195 0C34.032 0 40.8246 2.09001 38.2121 14.63C36.1221 24.6621 25.4977 23.3384 20.4468 21.4226C8.42912 27.6926 -0.453496 19.8551 2.68154 8.36002Z" fill="url(#paint2_linear_2354_11103)"/>
<path d="M2.68154 8.36002C5.18958 -0.836003 15.57 2.4384 20.4468 5.2251C22.0142 2.61267 28.807 0 31.4195 0C34.032 0 40.8246 2.09001 38.2121 14.63C36.1221 24.6621 25.4977 23.3384 20.4468 21.4226C8.42912 27.6926 -0.453496 19.8551 2.68154 8.36002Z" fill="url(#paint3_radial_2354_11103)" fillOpacity="0.3"/>
<g filter="url(#filter2_f_2354_11103)">
<path d="M21.1721 10.4361L25.3585 5.55203C25.7402 5.10683 25.2117 4.46741 24.7026 4.75833L23.6641 5.35172L20.5291 7.44173L15.5882 5.69792C15.0083 5.49323 14.6128 6.28424 15.1245 6.62538L20.8975 10.474C20.9855 10.5326 21.1033 10.5164 21.1721 10.4361Z" fill="#BBA8A8"/>
<path d="M21.1721 10.4361L25.3585 5.55203C25.7402 5.10683 25.2117 4.46741 24.7026 4.75833L23.6641 5.35172L20.5291 7.44173L15.5882 5.69792C15.0083 5.49323 14.6128 6.28424 15.1245 6.62538L20.8975 10.474C20.9855 10.5326 21.1033 10.5164 21.1721 10.4361Z" stroke="#BBA8A8" strokeWidth="1.04501"/>
</g>
<g filter="url(#filter3_f_2354_11103)">
<path d="M18.0163 13.7114C19.0614 18.9364 14.1096 17.3689 11.2239 17.3689C8.33817 17.3689 9.13368 14.9749 9.13368 12.6664C9.13368 10.3578 17.2207 7.96387 20.1064 7.96387C22.9921 7.96387 21.1514 12.1439 18.0163 13.7114Z" fill="#FDFDFD"/>
</g>
<defs>
<filter id="filter0_i_2354_11103" x="-3.98081" y="2.63247" width="41.263" height="68.9525" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-6.27005" dy="-6.27005"/>
<feGaussianBlur stdDeviation="2.09002"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_2354_11103"/>
</filter>
<filter id="filter1_i_2354_11103" x="0.255859" y="16.4414" width="12.7228" height="55.6091" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2.09002" dy="2.09002"/>
<feGaussianBlur stdDeviation="2.09002"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_2354_11103"/>
</filter>
<filter id="filter2_f_2354_11103" x="11.2273" y="1.02318" width="17.9234" height="13.1436" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.56751" result="effect1_foregroundBlur_2354_11103"/>
</filter>
<filter id="filter3_f_2354_11103" x="3.80816" y="2.73883" width="22.9638" height="20.0756" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.61252" result="effect1_foregroundBlur_2354_11103"/>
</filter>
<radialGradient id="paint0_radial_2354_11103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.2156 35.6562) rotate(95.3322) scale(39.3579 22.5329)">
<stop stopColor="#D7BA8E"/>
<stop offset="0.4967" stopColor="#A47F4A"/>
<stop offset="1" stopColor="#886024"/>
</radialGradient>
<linearGradient id="paint1_linear_2354_11103" x1="11.1719" y1="22.989" x2="-2.41306" y2="70.0142" gradientUnits="userSpaceOnUse">
<stop stopColor="#725935"/>
<stop offset="1" stopColor="#5B482A"/>
</linearGradient>
<linearGradient id="paint2_linear_2354_11103" x1="20.4233" y1="0" x2="20.4233" y2="23.6443" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_2354_11103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.6829 9.8353) rotate(147.095) scale(10.5799 16.4453)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>


        </>
	);
};