import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { demoPagesMenu } from '../../../menu';
import PlanVisitModal from '../visit/planVisit/PlanVisitModal';
import Header from './SubHeaderComponent/Header';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useDebounce } from '../../../hooks/useDebounce';
import { PER_COUNT_FILTER, PER_COUNT } from '../../../components/PaginationButtons';
import { ServiceService } from '../../api/service/visit-service.service';
import { IService } from '../../api/interface/service.interface';
import { UserService } from '../../api/service/user.service';
import ServicesTable from './ServicesTable';

const ServicesPage = () => {
	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
	const [serviceSort, setServiceSort] = useState<boolean>(false);
	const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
	const [servicesPerPage, setServicesPerPage] = useState<number>(PER_COUNT_FILTER['5']);

	const [isUserDoctor, setIsUserDoctor] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [search, setSearch] = useState('');
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);

	const { data: user_role } = useQuery(
		['user_role'],
		() => UserService.getProfile(qs.stringify({})),
		{ refetchOnWindowFocus: true },
	);

	const isMainDoctorOrDoctor = user_role?.roles?.some(
		(role: any) => role?.role === 'mainDoctor' || role?.role === 'Doctor',
	);

	useEffect(() => {
		setIsUserDoctor(isMainDoctorOrDoctor);
	}, [isMainDoctorOrDoctor, user_role]);

	const [values, setValues] = useState<IService>({
		serviceName: [],
		serviceId: undefined,
		title: undefined,
		order: { createdAt: 'DESC' },
	});

	const [filter, setFilter] = useState<IService>({
		clinic: '',
		treatment_type: '',
		category: '',
		subCategory: '',
		code: '',
		search: '',
	});
	useEffect(() => {
		setValues((prev) => ({
			...prev,
			order: serviceSort ? { title: 'ASC' } : { createdAt: 'DESC' },
		}));
	}, [serviceSort]);

	const filterDebounce = useDebounce(filter);
	const searchDebounce = useDebounce(search);

	const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
		['services', currentPage, perPage, filterDebounce, serviceSort, searchDebounce],
		() =>
			ServiceService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
						limit: perPage,
						page: filterDebounce?.search.length ? 1 : currentPage,
						order: values.order,
					},
					includeMeta: [
						{
							association: 'user',
						},
						{
							association: 'clinic',
							...(!!filter.clinic && {
								where: {
									uuid: filter.clinic,
								},
							}),
						},
						{
							association: 'category',
							...(!!filter.category && {
								where: {
									categoryUuid: filter.category,
								},
							}),
						},
						// {
						// 	association: 'subCategory',
						// 	...(!!filter.subCategory && {
						// 		where: {
						// 			subCategoryUuid: filter.subCategory,
						// 		},
						// 	}),
						// },
					],
					filterMeta: {
						...(search && {
							websearchQuery: { searchVector: search },
						}),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	// const { data: servicesCount }: { data: IService[] | any; isLoading: boolean } = useQuery(
	// 	['servicesCount', DebouncedSearch],
	// 	() =>
	// 		ServiceService.findAll(
	// 			qs.stringify({
	// 				queryMeta: {
	// 					order: { createdAt: 'DESC' },
	// 				},

	// 				filterMeta: {
	// 					...(values.serviceName && {
	// 						...(values.serviceName && /\d/.test(values.serviceName)
	// 							? { serviceId: { iLike: `%${values.serviceName}%` } }
	// 							: { title: { iLike: `%${values.serviceName}%` } }),
	// 					}),
	// 				},
	// 			}),
	// 		),
	// 	{
	// 		keepPreviousData: true,
	// 		refetchOnWindowFocus: false,
	// 	},
	// );
	return (
		<PageWrapper title={demoPagesMenu.listPages.subMenu.listFluid.text}>
			<Header
				search={search}
				setSearch={setSearch}
				sort={serviceSort}
				setSort={setServiceSort}
			/>
			<Page container='fluid'>
				<div />
				<ServicesTable
					isFluid
					setValues={setValues}
					values={values}
					services={services?.data}
					isUserDoctor={isUserDoctor}
					setCurrentPage={setCurrentPage}
					setPerPage={setPerPage}
					currentPage={currentPage}
					perPage={perPage}
					filter={filter}
					setFilter={setFilter}
					sort={serviceSort}
					setSort={setServiceSort}
				/>
			</Page>
			<PlanVisitModal
				setIsOpen={setUpcomingEventsEditOffcanvas}
				isOpen={upcomingEventsEditOffcanvas}
			/>
		</PageWrapper>
	);
};

export default ServicesPage;
