import React, { SVGProps } from 'react';

const SvgMaterialIcone = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path d="M25.3324 4H6.66569C5.19902 4 3.99902 5.2 3.99902 6.66667V16C3.99902 17.4667 5.19902 18.6667 6.66569 18.6667H25.3324C26.799 18.6667 27.999 17.4667 27.999 16V6.66667C27.999 5.2 26.799 4 25.3324 4ZM6.66569 13.3333H10.839C11.119 14.3733 11.7324 15.2933 12.5324 16H6.66569V13.3333ZM25.3324 16H19.4657C20.2657 15.2933 20.879 14.3733 21.159 13.3333H25.3324V16ZM25.3324 10.6667H18.6657V12C18.6657 13.4267 17.4257 14.6667 15.999 14.6667C14.5724 14.6667 13.3324 13.4267 13.3324 12V10.6667H6.66569V6.66667H25.3324V10.6667ZM18.6657 20V21.3333C18.6657 21.96 18.4124 22.5333 18.0257 23C17.5324 23.6 16.799 24 15.999 24C15.199 24 14.4657 23.6 13.9724 23C13.5857 22.5333 13.3324 21.96 13.3324 21.3333V20H3.99902V25.3333C3.99902 26.8 5.19902 28 6.66569 28H25.3324C26.799 28 27.999 26.8 27.999 25.3333V20H18.6657ZM6.66569 22.6667H10.839C10.8657 22.7867 10.919 22.8933 10.959 23C11.279 23.9067 11.8257 24.7067 12.5324 25.3333H6.66569V22.6667ZM25.3324 25.3333H19.4657C20.1857 24.7067 20.7324 23.9067 21.039 23C21.079 22.8933 21.1324 22.7867 21.159 22.6667H25.3324V25.3333Z" fill="#0D6EFD"/>
		<path opacity="0.3" d="M10.8393 13.334H6.66602V16.0007H12.5327C11.7327 15.294 11.1193 14.374 10.8393 13.334ZM19.466 16.0007H25.3327V13.334H21.1593C20.8793 14.374 20.266 15.294 19.466 16.0007ZM10.9593 23.0007C10.9193 22.894 10.8793 22.7873 10.8393 22.6673H6.66602V25.334H12.5327C11.826 24.7073 11.2793 23.9073 10.9593 23.0007ZM21.1593 22.6673C21.1327 22.7873 21.0793 22.894 21.0393 23.0007C20.7327 23.9073 20.186 24.7073 19.466 25.334H25.3327V22.6673H21.1593Z" fill="#0D6EFD"/>
		</svg>
		
	);
};

export default SvgMaterialIcone;
