import React, { FC, memo, useState } from 'react';
import PaginationButtons, { PER_COUNT } from '../../../../../components/PaginationButtons';
import Button from '../../../../../components/bootstrap/Button';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../components/bootstrap/forms/Input';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../../../components/bootstrap/Dropdown';
import { formatPrice } from '../../../../../utils/functions';
import Tooltips from '../../../../../components/bootstrap/Tooltips';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { IService } from '@textnpayme/custom/api/interface/service.interface';
import { ServiceService } from '../../../../../custom/api/service/visit-service.service';
import { useDebounce } from '../../../../../hooks/useDebounce';
import useDarkMode from '../../../../../hooks/useDarkMode';

interface IServiceFilterComponentProps {
	handleCheckboxChange(...args: unknown[]): unknown;
	setPopperIsOpenService(...args: unknown[]): unknown;
	popperIsOpenService: any;
	values: any;
	dynamicFont: any;
	canvasColor: any;
}

const ServiceFilterComponent: FC<IServiceFilterComponentProps> = memo(
	({
		values,
		setPopperIsOpenService,
		popperIsOpenService,
		canvasColor,
		handleCheckboxChange,
	}) => {
		const DebounsedSearch = useDebounce(values);
		const [currentPage, setCurrentPage] = useState<number>(1);
		const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
		const { themeStatus, darkModeStatus } = useDarkMode();

		const { data: services }: { data: IService[] | any; isLoading: boolean } = useQuery(
			['services', DebounsedSearch, currentPage, perPage],
			() =>
				ServiceService.findAll(
					qs.stringify({
						queryMeta: {
							paginate: true,
							limit: perPage,
							page: DebounsedSearch?.serviceName?.length ? 1 : currentPage,
							order: { createdAt: 'DESC' },
						},

						filterMeta: {
							...(values.serviceName && {
								websearchQuery: { searchVector: values.serviceName },
							}),
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		const { data: servicesCount }: { data: IService[] | any; isLoading: boolean } = useQuery(
			['servicesCount', DebounsedSearch],
			() =>
				ServiceService.findAll(
					qs.stringify({
						queryMeta: {
							order: { createdAt: 'DESC' },
						},

						filterMeta: {
							...(values.serviceName && {
								websearchQuery: { searchVector: values.serviceName },
							}),
						},
					}),
				),
			{
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		);

		return (
			<Dropdown isOpen={popperIsOpenService} setIsOpen={setPopperIsOpenService}>
				<DropdownToggle>
					<Button
						style={{
							boxSizing: 'content-box',
							border:
								values.services?.length > 0
									? '2px solid #1266f1'
									: `2px solid ${darkModeStatus ? '#212529' : '#f8f9fa'}`,
						}}
						color={themeStatus}
						className='text-nowrap me-2'>
						Услуге
					</Button>
				</DropdownToggle>
				<DropdownMenu style={{ maxHeight: 600, width: '400px', overflow: 'scroll' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexWrap: 'wrap',
						}}>
						<FormGroup
							style={{ margin: '15px 0 30px 0' }}
							id='surname'
							label='Введите название или код услуги'
							className='col-10'>
							<Input
								name='serviceName'
								type={'search'}
								onChange={(e: any) => handleCheckboxChange(e)}
								value={values?.serviceName}
							/>
							<div className='mb-1 col-12'>
								{services?.data?.length > 0 &&
									servicesCount?.data?.filter(
										(i: any) => values?.services?.includes(i.uuid),
									)?.length > 0 &&
									servicesCount?.data
										.filter((i: any) => values?.services?.includes(i.uuid))
										.reverse()
										.map((i: any) => (
											<>
												<div className='d-flex p-1 col-12' key={i.uuid}>
													<div className='col-8'>
														<p className='mb-1'>{i?.title_all?.rus}</p>
													</div>
													<div className='d-flex align-items-start justify-content-end col-4'>
														<span className='text-nowrap fw-bolder fs-6'>
															{formatPrice(i?.price, 'AMD', 'ru-RU')}
														</span>
													</div>
												</div>
												<hr
													className={`hr-divder-costum text-${canvasColor} bg-${canvasColor}`}
												/>
											</>
										))}
							</div>
						</FormGroup>
						{services?.data ? (
							services.data.map((i: IService, index: number) => (
								<div className='form-check col-10' key={`${index}-number`}>
									<input
										className='form-check-input'
										onChange={(e: any) => handleCheckboxChange(e)}
										type='checkbox'
										value={i.uuid}
										name='services'
										checked={values?.services?.includes(i.uuid)}
										id={`flexCheckDefault-${index}`}
									/>
									<label
										className='form-check-label col-12'
										htmlFor={`flexCheckDefault-${index}`}>
										<div className='col-3'>
											<span
												className='p-2 d-flex justify-content-center col-9 fw-normal text-white'
												style={{
													borderRadius: '0.5rem',
													backgroundColor: '#2c3178',
													width: '100%',
												}}>
												{i.serviceId}
											</span>
										</div>
										<div className='col-9'>
											<p
												className='d-flex m-2 text-truncate'
												style={{ width: '95%' }}>
												<Tooltips
													title={i?.title_all?.rus}
													placement='top'
													flip={['top']}>
													<span className='text-truncate overflow-hidden w-100 d-inline-block'>
														{i?.title_all?.rus}
													</span>
												</Tooltips>
											</p>
										</div>
									</label>
								</div>
							))
						) : (
							<h4>Услуги не найдены</h4>
						)}
					</div>
					<div className='col-12'>
						<PaginationButtons
							data={services || []}
							label='customers'
							small
							filterPagination
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
							totalPage={Math.ceil(services?.meta?.count / perPage) || 1}
						/>
					</div>
				</DropdownMenu>
			</Dropdown>
		);
	},
);
export default ServiceFilterComponent;
