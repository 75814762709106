import React, { FC, useCallback, useEffect } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../../../../components/bootstrap/Modal';
import Button from '../../../../../components/bootstrap/Button';
import { notification } from 'antd';
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup';
import dayjs from 'dayjs';
import Input from '../../../../../components/bootstrap/forms/Input';
import {
	calculateDuration,
	calculateEndTime,
	checkDateInRanges,
	isDateBeforeNow,
	joinTimeAndDate,
} from '../../../../../utils/functions';
import { VisitService } from '../../../../../custom/api/service/visit.service';
import { IVisit } from '@textnpayme/custom/api/interface/visit.interface';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import ReactInputMask from 'react-input-mask';

interface ITimePickerModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	changeValues(...args: unknown[]): unknown;
	chooseTimeDate: Date;
	visitData: any;
	setVisitData(...args: unknown[]): unknown;
	setStart(...args: unknown[]): unknown;
	setEnd(...args: unknown[]): unknown;
	setStartTime(...args: unknown[]): unknown;
	setEndTime(...args: unknown[]): unknown;
	duration: any;
	setDuration(...args: unknown[]): unknown;
	start: any;
	end: any;
	startTime: any;
	endTime: any;
	prevVisit: any;
	isUserDoctor: any;
	usersList: any;
}

const TimePickerModal: FC<ITimePickerModalProps> = ({
	isOpen,
	chooseTimeDate,
	changeValues,
	setVisitData,
	start,
	setStart,
	end,
	startTime,
	endTime,
	visitData,
	setEnd,
	setStartTime,
	setEndTime,
	setIsOpen,
	prevVisit,
	usersList,
	isUserDoctor,
	duration,
	setDuration,
}) => {
	const [api, contextHolder] = notification.useNotification();

	const { data: visits }: { data: IVisit[] | any; isLoading: boolean } = useQuery(
		['visits', usersList, isUserDoctor],
		() =>
			VisitService.findAll(
				qs.stringify({
					queryMeta: {
						paginate: true,
					},
					includeMeta: [
						{
							association: 'patient',
						},
						{
							association: 'medicalCase',
						},
						{
							association: 'services',
						},
						{
							association: 'user',
							...(!!!usersList && { where: { uuid: usersList } }),
							// ...(!!!usersList && isUserDoctor && { where: { uuid: usersList } }),
						},
					],
					filterMeta: {
						status: [
							'Предстоящий',
							'В процессе',
							'Завершен',
							'В ожидании',
							'Визит завершен',
							'Калькуляция',
							'Задолженность',
						],
					},
				}),
			),
		{
			enabled: usersList.length > 0,
			refetchOnWindowFocus: true,
		},
	);

	const showNotification = useCallback(
		(description: string) => {
			api.info({
				message: `Важно !`,
				description,
				placement: 'bottomRight',
				className: 'ant-notification-custom',
				props: {
					style: {
						zIndex: 150000,
					},
				},
			});
		},
		[api],
	);

	const getFormattedDate = useCallback((): string => {
		if (chooseTimeDate) {
			return chooseTimeDate.toISOString();
		}
		return '';
	}, [chooseTimeDate]);
	const reset = useCallback(() => {
		setStart('');
		setEnd('');
		setStartTime('');
		setEndTime('');
	}, [setEnd, setEndTime, setStart, setStartTime]);

	const handleSelect = useCallback(() => {
		if (checkDateInRanges(start, end, visitData)) {
			changeValues('startDate', '');
			changeValues('endDate', '');
			setStart('');
			setEnd('');
			changeValues('visitDate', '');
			setStartTime('');
			setEndTime('');
			setDuration(null);
			return showNotification('выберите другое время, этот диапазон времени занят');
		}

		if (!isDateBeforeNow(start)) {
			changeValues('startDate', '');

			changeValues('endDate', '');

			setStart('');
			setEnd('');

			changeValues('visitDate', '');

			setStartTime('');
			setEndTime('');
			setDuration(null);
			return showNotification('Вы не можете выбрать прошедшую дату для визита');
		}

		if (start > end) {
			changeValues('startDate', '');

			changeValues('endDate', '');

			setStart('');
			setEnd('');

			changeValues('visitDate', '');

			setStartTime('');
			setEndTime('');
			setDuration(null);
			return showNotification('Дата начала должна быть раньше даты окончания');
		}

		if (
			!visitData.find((i: any) => i?.id === 'visitDate') &&
			end &&
			start &&
			isDateBeforeNow(start)
		) {
			setVisitData((prev: any) => [
				...prev,
				{
					id: 'visitDate',
					visitData: visitData,
					name: 'asdasd',
					setVisitData: setVisitData,
					prevVisit: !!prevVisit ? prevVisit : null,
					changeValues: changeValues,
					start: new Date(start),
					end: new Date(end),
				},
			]);
			changeValues('startDate', joinTimeAndDate(startTime, getFormattedDate()));
			changeValues('visitDate', joinTimeAndDate(startTime, getFormattedDate()));
			changeValues('endDate', joinTimeAndDate(endTime, getFormattedDate()));
		}

		if (
			visitData.find((i: any) => i?.id === 'visitDate') &&
			end &&
			start &&
			isDateBeforeNow(start) &&
			!prevVisit
		) {
			setVisitData([
				...visits?.data,
				{
					id: 'visitDate',
					visitData: visitData,
					setVisitData: setVisitData,
					name: 'asdasd',
					prevVisit: !!prevVisit ? prevVisit : null,
					changeValues: !!prevVisit ? changeValues : null,
					start: new Date(start),
					end: new Date(end),
				},
			]);
			changeValues('startDate', joinTimeAndDate(startTime, getFormattedDate()));
			changeValues('visitDate', joinTimeAndDate(startTime, getFormattedDate()));
			changeValues('endDate', joinTimeAndDate(endTime, getFormattedDate()));
		}

		if (
			visitData.find((i: any) => i?.id === 'visitDate') &&
			end &&
			start &&
			isDateBeforeNow(start) &&
			prevVisit
		) {
			setVisitData((prev: any) =>
				prev.map((i: any) => {
					if (i?.uuid === prevVisit?.uuid && i.id === 'visitDate') {
						return {
							uuid: i.uuid,
							id: 'visitDate',
							visitData: visitData,
							setVisitData: setVisitData,
							name: 'asdasd',
							prevVisit: !!prevVisit ? prevVisit : null,
							changeValues: !!prevVisit ? changeValues : null,
							start: new Date(start),
							end: new Date(end),
						};
					}
					return i;
				}),
			);
			changeValues('startDate', joinTimeAndDate(startTime, getFormattedDate()));
			changeValues('visitDate', joinTimeAndDate(startTime, getFormattedDate()));
			changeValues('endDate', joinTimeAndDate(endTime, getFormattedDate()));
		}

		if (!start) {
			showNotification('выберите время начала визита');
		}
		if (!end) {
			showNotification('выберите время окончания визита');
		}
		if (start && end) setIsOpen(false);
	}, [
		changeValues,
		end,
		endTime,
		getFormattedDate,
		prevVisit,
		setDuration,
		setEnd,
		setEndTime,
		setIsOpen,
		setStart,
		setStartTime,
		setVisitData,
		showNotification,
		start,
		startTime,
		visitData,
		visits?.data,
	]);

	useEffect(() => {
		if (startTime && duration !== null) {
			const calculatedEndTime = calculateEndTime(startTime, duration);
			const formattedEndDate = joinTimeAndDate(calculatedEndTime, getFormattedDate());

			setEndTime(calculatedEndTime);
			setEnd(formattedEndDate);
			changeValues('endDate', formattedEndDate);
		} else if ((!prevVisit?.startDate || !prevVisit?.endDate) && duration === null) {
			setEndTime('');
			setEnd('');
			changeValues('endDate', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		startTime,
		duration,
		prevVisit?.startDate,
		prevVisit?.endDate,
		changeValues,
		getFormattedDate,
		setEnd,
		setEndTime,
	]);

	const handleDurationChange = useCallback(
		(e: any) => {
			const value = Number(e.target.value);
			if (value > 0) {
				setDuration(value);
			} else {
				setDuration(null);
			}
			if (!startTime) {
				showNotification('bottomRight');
				setDuration(null);
			}
		},
		[setDuration, showNotification, startTime],
	);
	return (
		<Modal
			modalLevel={140000}
			isStaticBackdrop
			isScrollable={false}
			isOpen={isOpen}
			closeModalCustom={reset}
			setIsOpen={setIsOpen}
			fullScreen={'md'}>
			<ModalHeader closeModalCustom={reset} setIsOpen={setIsOpen} className='p-4'>
				<span className='fs-3 fw-bold'>Выберите длительность визита</span>
			</ModalHeader>
			<ModalBody className='px-4' style={{ overflow: 'hidden' }}>
				{contextHolder}
				<div className='col-12 d-flex justify-content-center mb-3'>
					<div className='col-12'>
						{!!getFormattedDate() && (
							<span className='text-info fw-bold'>
								Дата визита - {dayjs(getFormattedDate()).format('DD.MM.YYYY')}
							</span>
						)}
					</div>
				</div>
				<div className='col-12 d-flex flex-column justify-content-center'>
					<FormGroup className='col-12 mb-4' id='name' label='Время прихода'>
						<ReactInputMask
							mask='99:99'
							value={startTime}
							type='text'
							placeholder='HH:MM'
							className='form-control search-input-cross-increaser'
							onChange={(e: any) => {
								setStartTime(e.target.value);
								changeValues(
									'startDate',
									joinTimeAndDate(e.target.value, getFormattedDate()),
								);
								setStart(joinTimeAndDate(e.target.value, getFormattedDate()));
								changeValues(
									'visitDate',
									joinTimeAndDate(e.target.value, getFormattedDate()),
								);
							}}
						/>
					</FormGroup>

					<FormGroup className='col-12 mb-4' id='duration' label='Длительность (минуты)'>
						<Input
							value={duration || ''}
							onChange={handleDurationChange}
							type={'number'}
							placeholder='Введите длительность в минутах'
						/>
					</FormGroup>

					<FormGroup className='col-12' id='name' label='Время окончания'>
						<ReactInputMask
							className='form-control search-input-cross-increaser'
							mask='99:99'
							value={endTime}
							type='text'
							placeholder='HH:MM'
							onChange={(e: any) => {
								const newEndTime = e.target.value;
								setEndTime(newEndTime);
								if (startTime) {
									setDuration(calculateDuration(startTime, newEndTime));
								} else {
									showNotification('Нужно выбрать время прихода');
									setEndTime('');
									setEnd('');
									changeValues('endDate', '');
								}
								setEndTime(e.target.value);
								changeValues(
									'endDate',
									joinTimeAndDate(newEndTime, getFormattedDate()),
								);
								setEnd(joinTimeAndDate(newEndTime, getFormattedDate()));
							}}
						/>
					</FormGroup>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='d-flex flex-row col-12 gap-3 px-3'>
					<Button
						color='primary'
						className='col-12'
						onClick={() => {
							handleSelect();
						}}>
						Сохранить
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default TimePickerModal;
