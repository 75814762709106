import React, { SVGProps } from "react";

export const THEETH_17 = (props: SVGProps<SVGSVGElement>) => {

    return (
		<>
           
           <svg width="2.46rem" height="5.53rem" viewBox="0 0 32 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M11.4106 0.719776C23.2955 8.52129 27.3951 44.3691 26.2667 62.1588C25.9737 68.9512 8.61361 49.7979 8.94233 41.5491C9.48804 27.8549 8.94233 26.9207 8.44629 6.57113C9.01371 3.75125 10.0432 -0.177807 11.4106 0.719776Z" fill="url(#paint0_linear_402_4965)"/>
<g filter="url(#filter0_i_402_4965)">
<path d="M10.4463 6.58936C-1.05371 7.58936 5.39257 20.2366 1.91064 54.3418C8.3148 59.2541 23.6122 68.7826 30.735 60.0247C31.4332 51.3945 32.4464 11.0894 16.377 5.08936C12.2763 3.55821 21.9464 21.0894 16.9464 35.5894C10.4463 20.0894 14.4806 6.23854 10.4463 6.58936Z" fill="url(#paint1_linear_402_4965)"/>
</g>
<path d="M0.465744 63.0894C1.82947 68.0893 11.966 70.2012 13.9034 66.3271C15.4033 68.827 21.9035 71.3271 24.4035 71.3271C26.9035 71.3271 33.4035 69.3271 30.9035 57.3271C28.9035 47.7271 24.4035 46.5894 15.9658 48.0894C3.02839 44.0894 -1.57985 55.5894 0.465744 63.0894Z" fill="url(#paint2_linear_402_4965)"/>
<path d="M0.465744 63.0894C1.82947 68.0893 11.966 70.2012 13.9034 66.3271C15.4033 68.827 21.9035 71.3271 24.4035 71.3271C26.9035 71.3271 33.4035 69.3271 30.9035 57.3271C28.9035 47.7271 24.4035 46.5894 15.9658 48.0894C3.02839 44.0894 -1.57985 55.5894 0.465744 63.0894Z" fill="url(#paint3_radial_402_4965)" fillOpacity="0.3"/>
<defs>
<filter id="filter0_i_402_4965" x="-1.18936" y="1.89561" width="32.2455" height="61.6937" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-8" dy="-8"/>
<feGaussianBlur stdDeviation="1.55"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_402_4965"/>
</filter>
<linearGradient id="paint0_linear_402_4965" x1="17.4463" y1="63.5894" x2="17.4463" y2="0.589355" gradientUnits="userSpaceOnUse">
<stop stopColor="#5C472A"/>
<stop offset="1" stopColor="#685233"/>
</linearGradient>
<linearGradient id="paint1_linear_402_4965" x1="16.4835" y1="63.5894" x2="16.4835" y2="4.99563" gradientUnits="userSpaceOnUse">
<stop stopColor="#D29439"/>
<stop offset="1" stopColor="#887250"/>
</linearGradient>
<linearGradient id="paint2_linear_402_4965" x1="15.7329" y1="71.3271" x2="15.7329" y2="47.2671" gradientUnits="userSpaceOnUse">
<stop offset="0.64" stopColor="white"/>
<stop offset="1" stopColor="#B48B8B"/>
</linearGradient>
<radialGradient id="paint3_radial_402_4965" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22 57.0894) rotate(29.0546) scale(5.14782 7.15006)">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="1" stopColor="#9B9B9B"/>
</radialGradient>
</defs>
</svg>

        </>
	);
};