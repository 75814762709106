import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useMutation, useQueryClient } from 'react-query';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import { message, notification, Upload } from 'antd';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';
import type { UploadFile } from 'antd';
import { IFile } from '@textnpayme/custom/api/interface/file.interface';
import { FileService } from '../../../../custom/api/service/file.service';
import { NotificationPlacement } from 'antd/es/notification/interface';
import { UserService } from '../../../../custom/api/service/user.service';

interface IStageModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	userUuid: any;
}

interface CreateFileParams {
	inputDto: IFile;
	userUuid: string;
}

const AddDocumentsModal: FC<IStageModalProps> = ({ isOpen, setIsOpen, userUuid }) => {
	// Function to trigger the file input click
	const [values, setValues] = useState<IStage>({
		title: '',
		description: '',
		userUuid: '',
		file: null,
	});

	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const [api, contextHolder] = notification.useNotification();

	const openNotification = (placement: NotificationPlacement, description: string) => {
		api.info({
			message: 'Важно!',
			description,
			placement,
			className: 'ant-notification-custom',
			props: { style: { zIndex: 150000 } },
		});
	};

	const handleChange = (info: { file: UploadFile; fileList: UploadFile[] }) => {
		const { status, originFileObj, name } = info.file;

		if (status !== 'uploading') {
		}

		if (status === 'done') {
			const reader = new FileReader();
			reader.onload = (e) => {
				message.success(`${name} file uploaded successfully`);
			};
			if (originFileObj) {
				reader.readAsDataURL(originFileObj);
			}
		} else if (status === 'error') {
			message.error(`${name} file upload failed.`);
		}

		// Update the fileList state
		setFileList(info.fileList);
	};

	const props = {
		fileList,
		onChange: handleChange,
		beforeUpload: (file: UploadFile) => {
			// You can add file validation here if needed
			return false; // Prevent automatic upload
		},
	};

	// Function to trigger the file input click
	const triggerFileInputClick = () => {
		const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
		if (fileInput && fileList.length < 1) {
			fileInput.click();
		}
	};

	const changeValues = (key: string, value: any) => {
		setValues((prev) => ({ ...prev, [key]: value }));
	};

	useEffect(() => {
		if (fileList.length) {
			const originFileObj = fileList[0]?.originFileObj;
			if (originFileObj) {
				changeValues('file', originFileObj);
			} else {
				changeValues('file', null);
			}
		} else {
			changeValues('file', null);
		}
	}, [fileList]);

	useEffect(() => {
		setValues({
			title: '',
			description: '',
			file: '',
			userUuid: userUuid || null,
		});
	}, [userUuid]);

	const queryClient = useQueryClient();

	const { mutate: createfile } = useMutation(
		({ inputDto, userUuid }: CreateFileParams) => UserService.addFile(userUuid, inputDto),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('user');
				setIsOpen(false);
				setFileList([]);
				setValues({
					title: '',
					description: '',
					userUuid: userUuid || null,
					file: null,
				});
			},
			onError: (error) => {
				console.error(error); // Using console.error for error logging
			},
		},
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData() as any;
		if (fileList.length <= 0) {
			return openNotification('bottomRight', 'выберите файл для создания документа');
		} else {
			Object.entries({
				...values,
			}).forEach(([key, value]) => {
				return formData.append(key, value);
			});

			createfile({ inputDto: formData, userUuid });
		}
	};

	return (
		<>
			<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen} size='lg' modalLevel={1060}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-3 fw-bold'>
						<Icon icon='Receipt' className='me-2 fs-2' color='primary' />
						Добавить документ
					</span>
				</ModalHeader>
				<form onSubmit={handleSubmit}>
					<ModalBody className='px-4'>
						<span>{contextHolder}</span>
						<div className='medicalCard'>
							<div className='row'>
								<FormGroup
									aria-required
									className='col-md-9'
									id='fillDate'
									label='Загрузите фото пациента *'>
									<Upload {...props} fileList={fileList} showUploadList={true}>
										<Button
											id='fileUpload'
											color='primary'
											isLight
											className='text-nowrap fw-normal f-w-b me-2 col-12 d-flex align-items-center justify-content-center'
											onClick={triggerFileInputClick}
											style={{
												position: 'absolute',
												zIndex: '-1',
												opacity: '0',
											}}>
											Загрузить
										</Button>
									</Upload>
									<div>
										{!fileList.length ? (
											<div>
												<p>Нет документа</p>
											</div>
										) : null}
									</div>
								</FormGroup>
								<FormGroup className='col-md-3' id='name' label=' '>
									<Button
										id='fileUpload'
										color='primary'
										isLight
										className='text-nowrap fw-normal f-w-b me-2 col-12 d-flex align-items-center justify-content-center'
										onClick={triggerFileInputClick}>
										<span style={{ marginLeft: '10px' }}>Загрузить</span>
									</Button>
								</FormGroup>
							</div>
							<div className='row'>
								<FormGroup className='col-12' id='medicine' label=' Название'>
									<Input
										required
										type='text'
										onChange={(e: any) => changeValues('title', e.target.value)}
										value={values.title}
									/>
								</FormGroup>
							</div>
							<div className='row'>
								<FormGroup className='col-12' id='medicine' label='Описание'>
									<Textarea
										style={{ maxHeight: 300 }}
										onChange={(e: any) =>
											changeValues('description', e.target.value)
										}
										value={values.description}
									/>
								</FormGroup>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex flex-row col-12 gap-3 px-3'>
							<Button
								color='primary'
								className='col-6'
								onClick={() => {
									setIsOpen(false);
									setFileList([]);
									setValues({
										title: '',
										description: '',
										userUuid: userUuid || null,
									});
								}}>
								Отменить
							</Button>
							<Button
								color='primary'
								type='submit'
								className='col-6'
								onClick={() => {
									// setIsOpen(false);
								}}>
								Добавить
							</Button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default AddDocumentsModal;
